import { yupResolver } from '@hookform/resolvers/yup'
import { ClockCountdown } from '@phosphor-icons/react'
import { addDays } from 'date-fns'
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledSelect from '../../../components/ControlledSelect'
import ControlledCheckbox from '../../../components/controlled-checkbox'
import RelatedFieldContent from '../../../components/related-field-content'
import { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import Shimmer from '../../../components/ui/shimmer'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import {
  CONTRACT_CATEGORY,
  CONTRACT_STATUS,
  contractTypes,
} from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import {
  amendContract,
  getCycleText,
  getOccurrences,
  reactivateContract,
} from '../../../services/api'
import { datePickerDateFormat } from '../../../utils/formatters/date-picker-date-format'
import { getErrorMessage } from '../../../utils/get-errors'
import {
  getOptionFromList,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import { FormCol } from '../CreateContract/components/form-col'
import LabelContent from '../CreateContract/components/label-content'
import {
  CycleStartEndText,
  ProrataFields,
  buildPaymentFieldsSchema,
  fieldKeys,
  isDefinite,
  useProRataUpdate,
} from '../CreateContract/create-contract-v3'
import { mapFrequencyToOption } from '../CreateContract/utils/map-frequency-to-option'

export function ReactivateAction({ contract, isAdmin, onUpdate }) {
  const status = contract?.status
  const contractId = contract?.id
  const contractType = contract?.type

  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggleModal() {
    return setIsModalOpen((isOpen) => !isOpen)
  }

  const { startFetch: reactivate, isLoading: reactivating } = useFetch({
    action: reactivateContract,
    onComplete: () => {
      onUpdate?.(false)
      toastr.success('Contract reactivated successfully')
    },
    onError: (error) => {
      toastr.error(getErrorMessage(error))
    },
  })

  const { startFetch: amend, isLoading: amending } = useFetch({
    action: amendContract,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to amend contract')
      } else {
        toastr.success('Contract amended successfully')
        onUpdate?.(false)
        toggleModal()
      }
    },
    onError: (error) => {
      toastr.error(getErrorMessage(error))
    },
  })

  const isFte = contractType === CONTRACT_TYPES.FULL_TIME
  const isMilestone = contractType === CONTRACT_TYPES.MILESTONES

  function handleReactivate() {
    if (isFte || isMilestone) {
      reactivate({ contract_id: contractId })
    } else {
      toggleModal()
    }
  }

  function handleAmendAndReactivate(amendData) {
    amend(amendData)
  }

  const loading = reactivating || amending

  return (
    <>
      {![
        CONTRACT_STATUS.TERMINATED.value,
        CONTRACT_STATUS.ENDED.value,
      ].includes(status?.id) ? null : (
        <Button
          disabled={isAdmin || loading}
          onClick={handleReactivate}
          className='mt-3'
          loading={loading}
          color='light'
          outline
          block
        >
          Reactivate
        </Button>
      )}

      {!isModalOpen ? null : (
        <ReactivationModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          contract={contract}
          submitReactivation={handleAmendAndReactivate}
          loading={loading}
        />
      )}
    </>
  )
}

const reactivationSchema = yup.object().shape({
  ...buildPaymentFieldsSchema(),
})
const reactivationFormId = 'reactivation-form-id'
function ReactivationModal({
  isOpen,
  toggle,
  contract,
  submitReactivation,
  loading,
}) {
  const { currencies, frequencies } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const { ...defaultData } = contract

  const typeMap = {
    [CONTRACT_TYPES.FIXED]: contractTypes.FIXED,
    [CONTRACT_TYPES.MILESTONES]: contractTypes.MILESTONES,
    [CONTRACT_TYPES.PAY_AS_YOU_GO]: contractTypes.PAYG,
  }
  const employeeTypeMap = {
    [CONTRACT_TYPES.DIRECT_EMPLOYEE]: contractTypes.DIRECT_EMPLOYEE,
    [CONTRACT_TYPES.FULL_TIME]: contractTypes.EOR,
  }

  const isContractor = [
    CONTRACT_TYPES.FIXED,
    CONTRACT_TYPES.MILESTONES,
    CONTRACT_TYPES.PAY_AS_YOU_GO,
  ].includes(contract.type)

  // This data is needed for various calculations, including pro-rata, validation, etc.
  const additionalData = {
    contract_type: isContractor
      ? CONTRACT_CATEGORY.CONTRACTOR
      : CONTRACT_CATEGORY.EMPLOYEE,
    type: !isContractor ? null : typeMap[contract.type],
    employee_type: isContractor ? null : employeeTypeMap[contract.type],
    amount: defaultData?.amount,
  }

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      ...additionalData,
      [fieldKeys.startDate]: datePickerDateFormat(defaultData?.start_date),
      [fieldKeys.endDate]: datePickerDateFormat(defaultData?.end_date),
      [fieldKeys.frequencyId]: defaultData?.frequency?.id,
      [fieldKeys.proRata]: false,
      is_monthly_cycle: false,
    },
    resolver: yupResolver(reactivationSchema),
  })

  const {
    [fieldKeys.type]: type,
    [fieldKeys.startDate]: startDate,
    [fieldKeys.currencyId]: currencyId,
    [fieldKeys.frequencyId]: frequencyId,
    [fieldKeys.occurrenceId]: occurrenceId,
    [fieldKeys.firstPaymentDate]: firstPaymentDate,
    [fieldKeys.proRata]: proRata,
    is_monthly_cycle: isMonthlyCycle,
  } = useWatch({ control })

  useProRataUpdate({
    amount: defaultData?.amount,
    endDate: defaultData?.end_date,
    firstPaymentDate,
    frequencyId,
    occurrenceId,
    proRata,
    startDate,
    onComplete: (data) => {
      setValue(fieldKeys.proRataAmount, data?.amount)
    },
  })

  const { data: occurrencesData } = useFetch(
    {
      action: getOccurrences,
      autoFetch: !!startDate,
      body: { start_date: startDate },
    },
    [startDate],
  )

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  const hideProRata =
    !firstPaymentDate ||
    ![contractTypes.FIXED, contractTypes.FULL_TIME].includes(type)

  const isFrequencyMonthly = frequencyId === 4

  const {
    startFetch: fetchCycleText,
    isLoading: fetchingCycleText,
    data: cycleText,
  } = useFetch({ action: getCycleText })

  function onSubmit(data) {
    const formattedBody = formatBody(data)

    submitReactivation(formattedBody)
  }

  const employmentTerm = contract?.employment_term
  const isDeDefinite = isDefinite(employmentTerm)

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Reactivate Contract
      </ModalHeader>
      <ModalBody className='!tw-px-0'>
        <PrimaryAlert className='tw-mx-3 !tw-mb-4'>
          To re-activate the contract, you must confirm the payment cycle
          details. The new cycle information will be shown on the amendment
          page.
        </PrimaryAlert>

        <form onSubmit={handleSubmit(onSubmit)} id={reactivationFormId}>
          <input
            type='hidden'
            {...register('contract_id', { value: contract.id })}
          />

          <Row className='!tw-mx-0'>
            <FormCol>
              <ControlledDatePicker
                control={control}
                name='end_date'
                minDate={!startDate ? null : addDays(new Date(startDate), 1)}
                placeholder={isDeDefinite ? 'End date' : '(Optional)'}
                label={
                  <LabelContent required={isDeDefinite}>End Date:</LabelContent>
                }
              />
            </FormCol>

            <FormCol>
              <ControlledSelect
                label={
                  <LabelContent required>Frequency of payments:</LabelContent>
                }
                control={control}
                name='frequency_id'
                isSearchable={false}
                options={frequencies?.map(mapFrequencyToOption)}
                transform={{
                  output: (value) => {
                    setValue(fieldKeys.occurrenceId, null)
                    setValue(fieldKeys.firstPaymentDate, null)
                    return value
                  },
                }}
              />
            </FormCol>

            {!frequencyId ? null : (
              <FormCol>
                <ControlledSelect
                  label={
                    <LabelContent required>
                      When do you want to process payments?
                    </LabelContent>
                  }
                  control={control}
                  name='occurrence_id'
                  isSearchable={false}
                  options={occurrencesData
                    ?.filter((o) => o.frequency_id === frequencyId)
                    ?.map((e) => ({ value: e.id, label: e.name }))}
                  transform={{
                    output: (value) => {
                      setValue(fieldKeys.firstPaymentDate, null)
                      return value
                    },
                  }}
                  selectStyles={
                    hideProRata
                      ? null
                      : {
                          control: () => ({
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                          }),
                        }
                  }
                />

                <ProrataFields
                  hidden={hideProRata || isFrequencyMonthly}
                  control={control}
                  errors={errors}
                  proRata={proRata}
                  firstPaymentDate={firstPaymentDate}
                  type={type}
                  selectedCurrency={selectedCurrency}
                />

                {hideProRata || !isFrequencyMonthly ? null : (
                  <RelatedFieldContent>
                    <div className='d-flex align-items-center gap-8 text-slate-700 mb-2'>
                      <ClockCountdown size={20} />
                      <span className='rp-font-bold'>Payment cycle</span>
                    </div>

                    <div style={{ minHeight: 42 }}>
                      {isMonthlyCycle ? (
                        <CycleStartEndText starts='the first' ends='last day' />
                      ) : fetchingCycleText ? (
                        <Shimmer
                          width='100%'
                          height='36px'
                          backgroundColor='var(--slate-100)'
                        />
                      ) : (
                        <p
                          className='mb-0'
                          dangerouslySetInnerHTML={{
                            __html: cycleText?.default_period,
                          }}
                        />
                      )}
                    </div>

                    <hr className='border-gray-b' />

                    <ControlledCheckbox
                      control={control}
                      id='is_monthly_cycle'
                      name='is_monthly_cycle'
                      error={errors?.is_monthly_cycle}
                      label={
                        <LabelContent>
                          Reset to 1st to the end of every month
                        </LabelContent>
                      }
                    />
                  </RelatedFieldContent>
                )}
              </FormCol>
            )}

            {!occurrenceId ? null : (
              <FormCol>
                <ControlledSelect
                  label={
                    <LabelContent required>
                      Date for the first payment:
                    </LabelContent>
                  }
                  control={control}
                  name='first_payment_date'
                  error={errors?.first_payment_date}
                  isSearchable={false}
                  options={
                    occurrencesData?.find((o) => o.id === occurrenceId)?.dates
                  }
                  transform={{
                    output: (value) => {
                      fetchCycleText({ first_payment_date: value.value })
                      return value
                    },
                  }}
                  selectStyles={
                    hideProRata || !isFrequencyMonthly
                      ? null
                      : {
                          control: () => ({
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                          }),
                        }
                  }
                />

                <ProrataFields
                  hidden={hideProRata || !isFrequencyMonthly}
                  control={control}
                  errors={errors}
                  proRata={proRata}
                  firstPaymentDate={firstPaymentDate}
                  type={type}
                  selectedCurrency={selectedCurrency}
                />
              </FormCol>
            )}
          </Row>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          type='button'
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          type='submit'
          formId={reactivationFormId}
          disabled={loading}
          loading={loading}
        >
          Reactivate
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function formatBody(data) {
  const body = { ...data }

  delete body.contract_type
  delete body.type
  delete body.employee_type
  delete body.amount
  delete body.start_date

  if (body.first_payment_prorata === false) {
    delete body.prorata_amount
  }

  return body
}
