import { parseISO } from 'date-fns'
import React, { useMemo } from 'react'
import { Card, Container, Row } from 'reactstrap'

import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import NoContent from '../../../../components/ui/no-content'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import { adminCardsTransactions } from '../../../../services/api'
import {
  getCurrencyFormatter,
  usdFormatter,
} from '../../../../utils/formatters/currency'
import { datePickerDateFormat } from '../../../../utils/formatters/date-picker-date-format'
import { trxIcon } from '../../../remotepass-cards/components/latest-transactions'
import { dateCell } from './cell'
import { FilterField } from './filter-field'
import { useFilters } from './use-filters'

function getBodyFromFilters(filters) {
  return { ...filters, page: filters.page - 1 }
}

const cashBackFormatter = (amount, currency) =>
  getCurrencyFormatter(currency).format(amount)

export default function AdminCardsTransactions() {
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    date_from: null,
    date_to: null,
    type: null,
    user_email: null,
  })

  function handleChangeWithClear({ value, name } = {}) {
    handleFiltersChange(name, value, { action: 'clear' })
  }

  const { isLoading, data } = useFetch(
    {
      action: adminCardsTransactions,
      withAdminAccess: true,
      autoFetch: true,
      body: getBodyFromFilters(filters),
    },
    [filters],
  )

  const items = data?.items ?? []

  const columns = useMemo(
    () => [
      { Header: 'Date', accessor: 'created_at', Cell: dateCell },
      {
        Header: 'Type',
        accessor: 'authorization.transaction_type',
        Cell: ({ cellData }) => {
          const icon = trxIcon[cellData]
          return (
            <div className='d-flex align-items-center gap-4'>
              <i
                className={`${icon} bg-slate-100 rounded-circle text-success`}
                style={{ padding: '0.125rem' }}
              />
              {cellData}
            </div>
          )
        },
      },
      { Header: 'Merchant name', accessor: 'merchant_details.name' },
      {
        Header: 'Card number',
        accessor: 'card.last_4_digits',
        Cell: ({ cellData }) => '**** **** **** ' + cellData,
      },
      { Header: 'Channel', accessor: 'payment_channel.channel_name' },
      { Header: 'Amount', accessor: 'authorization.transaction_amount' },
      { Header: 'Currency', accessor: 'authorization.transaction_currency' },
      {
        Header: 'USD value',
        Cell: ({ rowData }) =>
          usdFormatter.format(rowData.authorization.total_amount),
      },
      {
        Header: 'Cashback',
        Cell: ({ rowData }) =>
          cashBackFormatter(
            rowData?.cashback?.amount,
            rowData?.cashback?.currency_code,
          ),
      },
      { Header: 'First name', accessor: 'user.name.first' },
      { Header: 'Last name', accessor: 'user.name.last' },
      { Header: 'Email', accessor: 'user.email' },
    ],
    [],
  )
  return (
    <div className='page-content'>
      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0'>Card Transactions</h2>
      </div>
      <Card>
        <Container fluid>
          <Row className='p-3'>
            <FilterField
              colSize={3}
              type='date'
              placeholder='From Date'
              label='From'
              name='date_from'
              value={filters.date_from}
              onChange={(name, val) =>
                handleChangeWithClear({
                  name,
                  value: datePickerDateFormat(val),
                })
              }
              isClearable
              onClear={() =>
                handleChangeWithClear({ name: 'date_from', value: null })
              }
              maxDate={filters.date_to ? parseISO(filters.date_to) : null}
            />
            <FilterField
              colSize={3}
              type='date'
              placeholder='To Date'
              label='To'
              name='date_to'
              value={filters.date_to}
              onChange={(name, val) =>
                handleChangeWithClear({
                  name,
                  value: datePickerDateFormat(val),
                })
              }
              isClearable
              onClear={() =>
                handleChangeWithClear({ name: 'date_to', value: null })
              }
              minDate={filters.date_from ? parseISO(filters.date_from) : null}
            />
            <FilterField
              colSize={3}
              type='email'
              placeholder='example@remotepass.com'
              label='Email'
              name='user_email'
              onChange={(name, value) => handleChangeWithClear({ name, value })}
            />
            <FilterField
              colSize={3}
              name='type'
              label='Type'
              type='select'
              onChange={(name, option) => {
                handleChangeWithClear({ name, value: option.value })
              }}
              options={[
                { label: 'All', value: null },
                { label: 'Cashback', value: 'cashback' },
              ]}
            />
          </Row>
        </Container>

        {isLoading ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : items?.length <= 0 ? (
          <NoContent
            className='text-muted font-size-16'
            minHeight='max(50vh, 550px)'
          >
            No items found
          </NoContent>
        ) : (
          <>
            <DataTable responsive data={items ?? []} columns={columns} />

            <div className='d-flex justify-content-end px-3 mt-3'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={data?.limit ?? 10}
                totalItemsCount={data?.total_items_count ?? 0}
                onChange={(v) => handleFiltersChange('page', v)}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}
