import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FEATURE_FLAGS from '../../config/feature-flags'
import { BILL_MODULE_STATUS } from './list'

export function useBillModuleActive() {
  const history = useHistory()
  const billApproved = useSelector(
    (state) =>
      state?.userProfile?.userProfile?.company?.bill_status ===
      BILL_MODULE_STATUS.APPROVED,
  )

  const billModuleActive = billApproved || FEATURE_FLAGS.BILL_PAYMENTS

  useEffect(() => {
    if (!billModuleActive) {
      history.push('/')
    }
  }, [billModuleActive, history])
}
