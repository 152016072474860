import React, { useState } from 'react'
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../../../components/ui/button'
import { useFetch } from '../../../../../helpers/hooks'
import { addFormStep, updateFormStep } from '../../../../../services/api'
import { getHighestOrder } from '../helpers/getOrder'

const AddOrEditStep = ({ refetch, toggle, form, isOpen, step }) => {
  const [name, setName] = useState(step?.title)

  const { startFetch: addStep, isLoading: isAdding } = useFetch({
    action: addFormStep,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Step added successfully')
      setName('')
      toggle?.()
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const { startFetch: updateStep, isLoading: isUpdating } = useFetch({
    action: updateFormStep,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Step Updated successfully')
      setName('')
      toggle?.()
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Step</ModalHeader>
      <ModalBody className='tw-flex tw-w-full tw-shrink-0 tw-grow tw-flex-col tw-gap-2'>
        <Input
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          placeholder='Step Name'
          className='tw-shrink-0 tw-grow'
          label='Step Name'
        />
      </ModalBody>
      <ModalFooter className='tw-flex tw-w-full tw-items-center tw-gap-2'>
        <Button
          className='tw-h-10'
          color='danger'
          outline
          onClick={() => {
            toggle?.()
          }}
        >
          Cancel
        </Button>
        <Button
          className='tw-h-10'
          disabled={!name || isAdding || isUpdating}
          loading={isAdding || isUpdating}
          onClick={() => {
            if (step) {
              updateStep({
                form_id: step.form_id,
                title: name,
                order: step.order,
                form_step_id: step.id,
              })
            } else {
              addStep({
                form_id: form?.id,
                title: name,
                order: getHighestOrder(form.form_steps ?? []) + 1,
              })
            }
          }}
        >
          {step ? 'Update' : 'Add'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddOrEditStep
