import React from 'react'

import PageHeading from '../../components/ui/page-heading'
import { AddTimeOff } from './add-time-off'

export default function TimeOffPageHeader({
  updateTimeOff,
  timeOffs,
  isUserAssignedTimeOff,
}) {
  return (
    <PageHeading>
      <PageHeading.Title>Time off</PageHeading.Title>

      {isUserAssignedTimeOff && (
        <AddTimeOff onSubmit={updateTimeOff} timeOffs={timeOffs?.list} />
      )}
    </PageHeading>
  )
}
