import React, { useEffect, useState } from 'react'
import Paginations from 'react-js-pagination'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Badge,
  Card,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import toastr from 'toastr'
import { Avatar, cn } from 'ui'

import ActionCenter from '../../../../components/ActionCenter/index'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import SearchBar from '../../../../components/SearchBar'
import UserFlagging from '../../../../components/userFlag'
import { useFetch } from '../../../../helpers/hooks'
import {
  getEORAdminTodos,
  getFullTimeReqList,
  setEORCutoffDay,
  unsetEORCutoffDay,
} from '../../../../services/api'
import { mapCountryToOption } from '../../../../utils/map-to-option'
import ContractRef from '../../components/ContractRef'
import IconButton from '../../../../components/ui/icon-button'
import { Check, CircleNotch, X } from '@phosphor-icons/react'
import AdminTabNav from '../../../../components/admin-tab-nav'

const tabs = [
  'Pending Quotation',
  'Pending SOW',
  'Pending Deposit',
  'Pending SOW Provider',
  'Onboarding Active',
  'Ongoing',
  'Cancelled',
  'Terminated',
]

const getRequestStatus = (activeTab) => {
  switch (activeTab) {
    case '1':
      return 'pending_invite'
    case '2':
      return 'pending_client_signature'
    case '3':
      return 'pending_deposit'
    case '4':
      return 'pending_provider_signature'
    case '5':
      return 'onboarding_active'
    case '6':
      return 'ongoing'
    case '7':
      return 'cancelled'
    case '8':
      return 'terminated'
    default:
      return 'pending'
  }
}

const defaultFilters = {
  activeTab: '1',
  page: 1,
}

function getBodyFromFilters(filters) {
  return {
    ...(filters?.contract_ref && filters?.contract_ref !== ''
      ? { contract_ref: filters?.contract_ref }
      : {}),
    ...(filters?.employee_name && filters?.employee_name !== ''
      ? { employee_name: filters?.employee_name }
      : {}),
    ...(filters?.client_name && filters?.client_name !== ''
      ? { client_name: filters?.client_name }
      : {}),
    ...(filters?.provider_name && filters?.provider_name !== ''
      ? { provider_name: filters?.provider_name }
      : {}),
    ...(filters?.country_id ? { country_id: filters?.country_id?.id } : {}),
    status: getRequestStatus(filters.activeTab),
    page: filters.page,
  }
}

const getStatusColor = (status) => {
  switch (status) {
    case 4:
      return 'tw-text-systemGreen tw-bg-systemGreen/10'
    case 7:
      return 'tw-text-red tw-bg-red/10'
    default:
      return 'tw-text-systemGold tw-bg-systemGold/10'
  }
}

const FullTimeRequests = () => {
  const [pageFilters, setPageFilters] = useState(defaultFilters)
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const requests = useFetch(
    {
      action: getFullTimeReqList,
      withAdminAccess: true,
      autoFetch: true,
      body: getBodyFromFilters(pageFilters),
    },
    [pageFilters],
  )
  function handleChange(changeValue, options) {
    const name = options?.name ?? changeValue.target.name
    const value = options?.name ? changeValue : changeValue.target.value

    setPageFilters((prev) => ({
      ...prev,
      [name]: value,
      page: name === 'page' ? value : 1,
    }))
  }

  const { data: todos, isLoading: isTodosLoading } = useFetch({
    action: getEORAdminTodos,
    autoFetch: true,
    withAdminAccess: true,
  })

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <div>
          <ActionCenter data={todos} isAdmin isLoading={isTodosLoading} />
        </div>
        <h1 style={{ marginBottom: '2rem' }}>Full time contracts</h1>

        <Card>
          <div className='px-4 py-3'>
            <AdminTabNav
              items={tabs.map((tab, i) => ({
                label: tab,
                onClick: () => handleChange(`${i + 1}`, { name: 'activeTab' }),
                isActive: pageFilters.activeTab === `${i + 1}`,
              }))}
            />
          </div>
          <Container fluid>
            <Row>
              <Col md={6} className='pb-2 mb-3 mb-md-0 pr-md-2'>
                <SearchBar
                  query={pageFilters.client_name}
                  onQueryChanged={(e) =>
                    handleChange(e, { name: 'client_name' })
                  }
                  placeholder='Search by client name'
                  isClearable
                />
              </Col>
              <Col md={6} className='pl-md-2'>
                <SearchBar
                  query={pageFilters.provider_name}
                  onQueryChanged={(e) =>
                    handleChange(e, { name: 'provider_name' })
                  }
                  placeholder='Search by provider name'
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className='mb-3 mb-md-0 pr-md-2'>
                <SearchBar
                  query={pageFilters.contract_ref}
                  onQueryChanged={(e) =>
                    handleChange(e, { name: 'contract_ref' })
                  }
                  placeholder='Search by contract ref'
                  isClearable
                />
              </Col>
              <Col md={6} className='pl-md-2'>
                <SearchBar
                  query={pageFilters.employee_name}
                  onQueryChanged={(e) =>
                    handleChange(e, { name: 'employee_name' })
                  }
                  placeholder='Search by employee name'
                  isClearable
                />
              </Col>
              <Col md={6} className='mb-md-0 pr-md-2'>
                <CustomSelect
                  placeholder='Filter by country'
                  className='mt-2'
                  value={pageFilters.country_id}
                  onChange={(e) => handleChange(e, { name: 'country_id' })}
                  options={
                    countries ? countries.map((c) => mapCountryToOption(c)) : []
                  }
                  isClearable
                />
              </Col>
            </Row>
          </Container>

          {requests.isLoading ? (
            <div
              style={{ minHeight: '50vh' }}
              className='d-flex justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <>
              <div className='table-responsive'>
                <Table className='table table-centered table-nowrap text-muted'>
                  <thead className='thead-light'>
                    <tr className='text-muted'>
                      <th className='border-top-0 text-muted'>Contract ID</th>
                      <th className='border-top-0 text-muted'>Job Title</th>
                      <th className='border-top-0 text-muted'>Client</th>
                      <th className='border-top-0 text-muted'>Provider</th>
                      <th className='border-top-0 text-muted'>Employee</th>
                      <th className='border-top-0 text-muted'>Salary</th>
                      <th className='border-top-0 text-muted'>Company Name</th>
                      <th className='border-top-0 text-muted'>Country</th>
                      <th className='border-top-0 text-muted'>Start Date</th>
                      <th className='border-top-0 text-muted'>
                        Termination Date
                      </th>
                      <th className='border-top-0 text-muted'>Status</th>
                      <th className='border-top-0 text-muted'>
                        Custom cut off day of month
                      </th>
                      <th className='border-top-0 text-muted' />
                    </tr>
                  </thead>
                  <tbody>
                    {requests?.data?.contracts?.map((contract, i) => (
                      <ContractLine
                        key={`temp${i}`}
                        contract={contract}
                        onUpdate={() =>
                          requests.startFetch(
                            getBodyFromFilters(pageFilters),
                            false,
                          )
                        }
                      />
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className='d-flex justify-content-end pt-3 px-3'>
                <Paginations
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={pageFilters.page}
                  itemsCountPerPage={requests.paginator?.per_page}
                  totalItemsCount={requests.paginator?.total}
                  onChange={(e) => handleChange(e, { name: 'page' })}
                />
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  )
}

const InlineInputField = ({ onChange, loading, value }) => {
  const [showButtons, setShowButtons] = useState(false)
  const [days, setDays] = useState(value)
  const [hasFocus, setHasFocus] = useState(false)

  useEffect(() => {
    setDays(value)
  }, [value])
  return (
    <div
      onMouseLeave={() => setShowButtons(false)}
      onMouseOver={() => {
        setShowButtons(true)
      }}
      className='tw-relative'
    >
      <Input
        value={days}
        disabled={loading}
        type='number'
        onChange={(ev) => {
          setDays(ev.target.value)
        }}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        size='sm'
      />
      {(showButtons || hasFocus) && !loading && (
        <div className='tw-absolute tw-right-1 tw-top-0 tw-flex tw-h-full tw-items-center tw-gap-1'>
          <IconButton
            className='tw-shrink-0 hover:!tw-bg-transparent hover:!tw-text-green-100'
            size={20}
            circle
            disabled={!days || days === '0'}
            color='success'
            onClick={() => {
              setHasFocus(false)
              onChange(days)
            }}
            icon={<Check />}
          />
          <IconButton
            className='tw-shrink-0 hover:!tw-bg-transparent hover:!tw-text-red-100'
            size={20}
            color='danger'
            onClick={() => {
              setHasFocus(false)
              onChange('0')
              setDays('')
            }}
            circle
            icon={<X />}
          />
        </div>
      )}
      {loading && (
        <CircleNotch
          className='tw-absolute tw-right-1 tw-top-0 tw-flex tw-h-full tw-animate-spin tw-items-center'
          size={20}
        />
      )}
    </div>
  )
}

const ContractLine = ({ contract, onUpdate }) => {
  const isAdmin = location.pathname.startsWith('/admin')
  const { startFetch: setCutoffDay, isLoading: settingCutoffDay } = useFetch({
    action: setEORCutoffDay,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Cut off day was set successfully')
      onUpdate()
    },
    onError: (err) => {
      toastr.error(err.error ?? 'Something went wrong')
    },
  })

  const { startFetch: unsetCutoffDay, isLoading: unsettingCutoffDay } =
    useFetch({
      action: unsetEORCutoffDay,
      withAdminAccess: true,
      onComplete: () => {
        toastr.success('Cut off day was cleared successfully')
        onUpdate()
      },
      onError: (err) => {
        toastr.error(err.error ?? 'Something went wrong')
      },
    })

  const handleCutoffDayChange = (val) => {
    const newVal = parseInt(val)
    if (newVal < 0 || newVal > 31) {
      toastr.warning('Cut off day must be between 1 and 31')
      return
    }
    if (newVal) {
      setCutoffDay({
        contract_id: contract.id,
        cutoff_day_of_month: newVal,
      })
    } else {
      unsetCutoffDay({ contract_id: contract.id })
    }
  }

  return (
    <tr>
      <td>
        <ContractRef isAdmin={isAdmin} contractId={contract.ref} />
      </td>
      <td>{contract?.name}</td>
      <td>
        <Col>
          <Row className='align-items-center flex-nowrap'>
            <Avatar
              name={contract?.client?.first_name || contract?.client?.email}
              photo={contract?.client?.photo}
              flag={contract?.client?.flag}
            />

            <div
              className='d-flex font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '600',
                marginLeft: '10px',
              }}
            >
              {contract.client.is_flagged ? (
                <UserFlagging
                  user={contract?.client}
                  onCompleteAction={() => {
                    onUpdate()
                  }}
                />
              ) : null}
              {contract?.client?.first_name || contract?.client?.first_name
                ? `${contract?.client?.first_name || ''} ${
                    contract?.client?.last_name || ''
                  }`
                : contract?.client?.email}
            </div>
          </Row>
        </Col>
      </td>
      <td>
        {contract?.provider && contract?.provider !== 'N/A' ? (
          <Col>
            <Row className='align-items-center flex-nowrap'>
              <Avatar
                name={contract?.provider?.name}
                photo={contract?.provider?.logo}
                flag={contract?.provider?.flag}
              />

              <div
                className='font-size-14'
                style={{
                  color: '#4A4E5B',
                  fontWeight: '600',
                  marginLeft: '10px',
                }}
              >
                {contract?.provider?.name
                  ? contract?.provider?.name
                  : contract?.provider?.contract_email}
              </div>
            </Row>
          </Col>
        ) : (
          'Not selected yet'
        )}
      </td>
      <td>
        <Col>
          <Row className='align-items-center flex-nowrap'>
            <Avatar
              name={
                contract?.contractor?.first_name || contract?.contractor?.email
              }
              photo={contract?.contractor?.photo}
              flag={contract?.contractor?.flag}
            />

            <div
              className='font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '600',
                marginLeft: '10px',
              }}
            >
              {contract?.contractor?.first_name ||
              contract?.contractor?.first_name
                ? `${contract?.contractor?.first_name || ''} ${
                    contract?.contractor?.last_name || ''
                  }`
                : contract?.contractor?.email}
            </div>
          </Row>
        </Col>
      </td>
      <td>{contract?.salary}</td>
      <td>{contract?.company}</td>
      <td>{contract?.country}</td>
      <td>{contract?.start_date}</td>
      <td>{contract?.termination_date}</td>
      <td>
        <Badge
          className={cn(
            `tw-rounded !tw-text-xs md:!tw-text-sm`,
            getStatusColor(contract?.status?.id),
          )}
          color='white'
          pill
        >
          {contract?.status?.name}
        </Badge>
      </td>
      <td>
        <InlineInputField
          onChange={(val) => handleCutoffDayChange(val)}
          value={contract.cutoff_day_of_month}
          loading={settingCutoffDay || unsettingCutoffDay}
        />
      </td>
      <td>
        <Link target='_blank' to={`/admin-fulltime-details/${contract.id}`}>
          Review
        </Link>
      </td>
    </tr>
  )
}

export default FullTimeRequests
