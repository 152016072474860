import React from 'react'
import { useFormContext } from 'react-hook-form'
import { TRIGGER_FILTER, TRIGGER_OCCURRENCE } from '../tools/constants'

function getTriggerOccurrenceTitle(
  label,
  occurrenceDays,
  occurrence,
  typeOfOccurrence,
) {
  switch (occurrence) {
    case TRIGGER_OCCURRENCE.PENDING_DAYS:
      return occurrenceDays ? `${label} for ${occurrenceDays} days` : label
    case TRIGGER_OCCURRENCE.BEFORE_DAYS:
      return occurrenceDays ? `${occurrenceDays} days before ${label}` : label
    case TRIGGER_OCCURRENCE.AFTER_DAYS:
      return occurrenceDays ? `${occurrenceDays} days after ${label}` : label
    case TRIGGER_OCCURRENCE.RANGE:
      if (occurrenceDays && typeOfOccurrence) {
        return `${occurrenceDays} days ${typeOfOccurrence.toLowerCase()} ${label}`
      }
      return label
    default:
      return label
  }
}

function getTriggerFilterTitle(value, label, key) {
  switch (key) {
    case TRIGGER_FILTER.EXPENSE_AMOUNT:
    case TRIGGER_FILTER.PAYMENT_ADJUSTMENT_AMOUNT:
    case TRIGGER_FILTER.WORK_AMOUNT:
      return value ? `${label} amount more than ${value}` : label
    default:
      return label
  }
}

export function TriggerTitle({ index }) {
  const { watch } = useFormContext()
  const { event, occurrenceDays, typeOfOccurrence } = watch(`steps.${index}`)
  const { occurrence = {}, label, filter } = event ?? {}
  const triggerFilter = watch(`steps.${index}.filters.${filter?.key}`)

  if (!label) {
    return <span className='tw-text-text-80'>Choose trigger details</span>
  }

  let title
  if (filter) {
    title = getTriggerFilterTitle(triggerFilter, label, filter.key)
  } else {
    title = getTriggerOccurrenceTitle(
      label,
      occurrenceDays,
      occurrence?.type,
      typeOfOccurrence?.label,
    )
  }

  return <span className='tw-font-medium'>{title}</span>
}
