import React from 'react'
import {
  DetailsList,
  TenureRulesList,
} from '../pages/CompanySetting/components/time-off-policies-tab/view-policy'
import {
  CalendarBlank,
  ClockClockwise,
  Textbox,
  TreePalm,
} from '@phosphor-icons/react'
import WorkingDaysView from './working-days-view'
import PolicyAccrualInfo from './policy-accrual-info'
import PolicyRequestSettings from './policy-request-settings'
import { formatDays } from '../utils/formatters/format-days'

function TimeOffPolicyDetails({
  policy,
  isSettingsOpenByDefault,
  isAccrualInfoOpenByDefault,
  showWorkingDays,
}) {
  const isAccrued = policy?.type?.is_accrued === 1

  return (
    <>
      <DetailsList
        title='Basic details'
        items={[
          {
            icon: <Textbox size={24} />,
            label: 'Name',
            value: policy?.name,
          },
          {
            icon: <TreePalm size={24} />,
            label: 'Type',
            value: policy?.type?.name,
          },
          {
            icon: <CalendarBlank size={24} />,
            label: 'Time off amount',
            value: policy?.tenure_rules?.length
              ? null
              : policy?.accrual_days || policy?.max_annual_days
                ? formatDays(policy?.accrual_days ?? policy?.max_annual_days) +
                  ' / Year'
                : 'Unlimited',
            extraDetails: policy?.tenure_rules?.length ? (
              <TenureRulesList policy={policy} />
            ) : null,
          },
          !isAccrued && {
            icon: <ClockClockwise size={24} />,
            label: 'Accrual settings',
            value: 'Non-accrual',
          },
        ].filter(Boolean)}
      />

      {showWorkingDays ? (
        <>
          <hr className='tw-my-6' />
          <WorkingDaysView className='tw-mx-6' days={policy.working_days} />
        </>
      ) : null}

      {isAccrued && (
        <>
          <hr className='tw-my-6' />
          <PolicyAccrualInfo
            policy={policy}
            className='tw-mx-6'
            isOpenByDefault={isAccrualInfoOpenByDefault}
          />
        </>
      )}

      <PolicyRequestSettings
        policy={policy}
        isOpenByDefault={isSettingsOpenByDefault}
        className='tw-mx-6 tw-w-[stretch]'
      />
    </>
  )
}

export default TimeOffPolicyDetails
