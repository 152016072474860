import { ArrowLeft, Plus, Storefront } from '@phosphor-icons/react'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Head from '../../../../components/head'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import { LinkOut } from '../../../../components/ui/link-out'
import PageHeading from '../../../../components/ui/page-heading'
import Pagination from '../../../../components/ui/pagination'
import Shimmer from '../../../../components/ui/shimmer'
import { useSideMenuState } from '../../../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import { useUrlStateV2 } from '../../../../helpers/hooks/use-url-state'
import permissions from '../../../../helpers/permissions'
import {
  getBillCategories,
  getVendors,
} from '../../../../services/api-bill-payments'
import { mapListToOption } from '../../../../utils/map-to-option'
import { EmptyState } from '../../../review-center/empty-state'
import BillCategoryIcon from '../../bill-category-icon'
import { useBillModuleActive } from '../../bill-permission-hook'
import {
  AddressBadge,
  BankBadge,
  getAddressDetailsStatus,
  VENDOR_ADDRESS_STATUS,
  VENDOR_BANK_ACCOUNT_STATUS,
} from '../../detail-section-title'
import { AddBankSideMenu } from './add-bank-side-menu'
import { EditVendorDetailsSideMenu } from './edit-vendor-details'
import VendorDetailsButton from './vendor-details-button'

const PageHeader = () => {
  const { hasAccess } = usePermissions()
  return (
    <PageHeading className='tw-mb-6'>
      <div className='tw-flex tw-items-center tw-gap-4'>
        <PageHeading.Title
          action={
            <Button
              icon={<ArrowLeft size={24} />}
              color='transparent'
              className='!tw-p-0'
              tag={Link}
              to='/bills'
            />
          }
          className='tw-text-2xl'
          containerClassName='tw-items-center'
        >
          Vendors
        </PageHeading.Title>

        <LinkOut
          href='https://help.remotepass.com/en/articles/10226900-how-to-manage-vendors-for-bill-pay'
          title='Learn more about vendors for bill pay'
        />
      </div>

      {hasAccess(permissions.ManageBill) && (
        <Button icon={<Plus size={20} />} tag={Link} to='/bills/vendors/create'>
          Add Vendor
        </Button>
      )}
    </PageHeading>
  )
}

const vendorFiltersDefaultValues = {
  selectedItem: undefined,
  category: undefined,
  name: undefined,
  page: 1,
}

const BodyHeader = ({ isLoading, categories, selectedCategory }) => {
  const wrapperClassName = 'tw-grid tw-grid-rows-2 tw-gap-2 md:tw-grid-cols-2'
  const {
    updater: setUrlState,
    value: { name: query },
  } = useUrlStateV2(vendorFiltersDefaultValues, { replaceRoute: true })
  if (isLoading) {
    return (
      <span className={wrapperClassName}>
        <Shimmer width='1fr' />
        <Shimmer width='1fr' />
      </span>
    )
  }

  return (
    <span className={wrapperClassName}>
      <SearchBar
        placeholder='Search by name'
        onQueryChanged={(name) => setUrlState({ name: name || undefined })}
        query={query}
        inputClassName='tw-w-full !tw-h-11'
      />

      <CustomSelect
        placeholder='Category'
        isClearable
        options={categories?.map(mapListToOption)}
        onChange={(val) => setUrlState({ category: val?.label })}
        value={selectedCategory}
        selectStyles={{ control: () => ({ height: '44px' }) }}
      />
    </span>
  )
}
const extractAddressDetails = (item) => {
  const addressDetails = {
    city: item?.city,
    address: item?.address,
    country_id: item?.country?.id,
  }
  return addressDetails
}

export const Title = ({ details }) => {
  const addressDetails = extractAddressDetails(details)
  return (
    <span className='flex tw-gab-2 tw-items-center'>
      Details <AddressBadge details={addressDetails} />
    </span>
  )
}

const EditBankDetailsButton = ({ fetchVendors, item, children }) => {
  const [isAddBankOpen, toggleAddBank] = useSideMenuState()
  const hasBankAccount =
    item.bank_account &&
    item?.bank_account?.screening_status !==
      VENDOR_BANK_ACCOUNT_STATUS.MISSING_DETAILS

  return (
    <>
      {hasBankAccount ? (
        children
      ) : (
        <>
          <Button
            color='link'
            onClick={() => toggleAddBank()}
            className='!tw-px-0 tw-text-sm !tw-text-primary-100'
          >
            {children}
          </Button>
          <AddBankSideMenu
            isOpen={isAddBankOpen}
            toggle={toggleAddBank}
            updateVendors={() => fetchVendors(undefined, false)}
            vendor={item}
          />
        </>
      )}
    </>
  )
}

export const EditVendorLocationDetailsButton = ({ item, fetchVendors }) => {
  const [isEditOpen, toggleEdit] = useSideMenuState()
  const addressDetails = extractAddressDetails(item)
  return (
    <>
      {getAddressDetailsStatus({ details: addressDetails }).label ===
      VENDOR_ADDRESS_STATUS.MISSING_DETAILS ? (
        <>
          <Button
            color='link'
            className='!tw-px-0 tw-text-sm !tw-text-primary-100'
            onClick={toggleEdit}
          >
            <AddressBadge details={addressDetails} />
          </Button>
          <EditVendorDetailsSideMenu
            isOpen={isEditOpen}
            toggle={toggleEdit}
            updateVendors={() => fetchVendors(undefined, false)}
            vendor={item}
          />
        </>
      ) : (
        <AddressBadge details={addressDetails} />
      )}
    </>
  )
}

const Body = () => {
  const {
    value: {
      selectedItem: _selectedItem,
      category,
      name: query,
      page: currentPage,
    },
    updater: setUrlState,
  } = useUrlStateV2(vendorFiltersDefaultValues)

  function setSelectedItem(item) {
    setUrlState({ selectedItem: item })
  }

  const selectedItem = !_selectedItem ? null : Number(_selectedItem)
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)

  const { data: categories } = useFetch({
    action: getBillCategories,
    autoFetch: true,
    onError: toastr.error,
  })

  const { id: selectedCategoryId, name: selectedCategoryName } =
    categories?.find((cat) => cat.name === category) ?? {}
  const isFiltering = query || selectedCategoryId
  const hasIntegration = !!categories?.[0]?.integration_id

  const {
    data: vendors,
    isLoading,
    startFetch: fetchVendors,
    paginator,
  } = useFetch(
    {
      action: getVendors,
      autoFetch: true,
      body: {
        search_key: query,
        category_id: selectedCategoryId,
        page: currentPage,
      },
    },
    [query, selectedCategoryId, currentPage],
  )

  const columns = [
    { Header: 'Vendor Name', accessor: 'name' },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cellData }) => {
        if (!cellData.id) return 'N/A'
        return (
          <span className='tw-flex tw-gap-2'>
            <BillCategoryIcon category={cellData?.name} /> {cellData?.name}
          </span>
        )
      },
    },
    {
      Header: 'Bank Account Details',
      accessor: 'bank_account',
      Cell: ({ cellData, rowData }) => {
        return (
          <>
            <EditBankDetailsButton fetchVendors={fetchVendors} item={rowData}>
              <BankBadge
                status={cellData.screening_status}
                details={cellData}
              />
            </EditBankDetailsButton>
          </>
        )
      },
    },
    {
      Header: 'Address Details',
      accessor: 'bank_account',
      Cell: ({ rowData }) => {
        return (
          <>
            <EditVendorLocationDetailsButton
              fetchVendors={fetchVendors}
              item={rowData}
            />
          </>
        )
      },
    },
    {
      Cell: ({ rowData }) => (
        <VendorDetailsButton
          item={rowData}
          fetchVendors={fetchVendors}
          activeItem={selectedItem}
          showItem={() => setSelectedItem(rowData?.id)}
          closeItem={() => setSelectedItem(undefined)}
          hasIntegration={hasIntegration}
        />
      ),
    },
  ]
  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
      <BodyHeader
        isLoading={isLoading && !isFiltering}
        categories={categories}
        selectedCategory={
          selectedCategoryId
            ? { label: selectedCategoryName, value: selectedCategoryId }
            : undefined
        }
      />
      {isLoading ? (
        <span className='tw-flex tw-flex-col tw-gap-2'>
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
        </span>
      ) : vendors?.length === 0 ? (
        <EmptyState
          title='You have no vendor to show'
          textElement={
            !isFiltering
              ? 'You can click Add Vendor button and create one.'
              : ''
          }
          pictureElement={
            <Storefront
              weight='duotone'
              className='tw-mb-4 tw-fill-primary'
              size={250}
            />
          }
          className='tw-shadow-none'
        />
      ) : (
        <>
          <DataTable columns={columns} data={vendors} striped responsive />
          <span className='tw-flex tw-justify-end'>
            <Pagination
              activePage={Number(currentPage)}
              onChange={(page) => {
                searchParams.set('page', page)
                history.replace(
                  `${history.location.pathname}?${searchParams.toString()}`,
                )
              }}
              itemsCountPerPage={paginator?.per_page}
              totalItemsCount={paginator?.total ?? 0}
            />
          </span>
        </>
      )}
    </div>
  )
}

export default function Vendors() {
  useBillModuleActive()
  return (
    <div className='page-content'>
      <Head title='Vendors' />
      <PageHeader />
      <Body />
    </div>
  )
}
