import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  occurrenceDaysLabel,
  rangeOccurrences,
  TRIGGER_OCCURRENCE,
} from '../tools/constants'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import ControlledInput from '../../../../../components/ControlledInput'

export function TriggerOccurrences({ index }) {
  const { watch } = useFormContext()
  const occurrence = watch(`steps.${index}.event`)?.occurrence
  if (!occurrence) return null

  return (
    <div className='tw-flex tw-flex-row tw-gap-3.5'>
      <ControlledInput
        name={`steps.${index}.occurrenceDays`}
        type='number'
        wrapperClassName='tw-flex-1'
        postFix='Days'
        placeholder={occurrenceDaysLabel[occurrence.type]}
      />

      {occurrence?.type === TRIGGER_OCCURRENCE.RANGE ? (
        <Controller
          defaultValue={rangeOccurrences[0]}
          shouldUnregister={false}
          name={`steps.${index}.typeOfOccurrence`}
          render={({ field }) => (
            <CustomSelect
              value={field.value}
              options={rangeOccurrences}
              placeholder='Occurence'
              onChange={field.onChange}
              wrapperClassName='tw-flex-1'
            />
          )}
        />
      ) : null}
    </div>
  )
}
