import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Col,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

import { useFetch, usePermissions } from '../../../helpers/hooks'
import {
  connectFreshBooks,
  connectQuickbooks,
  connectXero,
  connectZoho,
  disconnectXero,
  enableOktaIntegration,
  disableOktaIntegration,
  updateDataToBamboo,
  updateDataToHibob,
  updateDataToSapling,
  updateOktaIntegration,
  validateOktaMetadataUrl,
} from '../../../services/api'
import { bambooRequest } from '../../../store/bamboo/actions'
import { HiBobRequest } from '../../../store/hiBob/actions'
import { SaplingRequest } from '../../../store/sapling/actions'

import xeroLogo from '../../../assets/images/xero.png'
import quickBooksLogo from '../../../assets/images/brands/quick-books.png'
import saplingLogo from '../../../assets/images/sapling.png'
import bobLogo from '../../../assets/images/bob.png'
import bambooLogo from '../../../assets/images/bamboo.png'
import freshbooksLogo from '../../../assets/images/freshbooks.png'
import zohoInvoiceLogo from '../../../assets/images/zoho_invoice.png'
import netSuiteLogo from '../../../assets/images/brands/net-suite-1.svg'
import oktaLogo from '../../../assets/images/okta.png'
import permissions from '../../../helpers/permissions'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { PERMISSION_GROUP } from '../manage-role'
import toastr from 'toastr'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import InputFeedback from '../../../components/ui/input-feedback'
import ControlledInput from '../../../components/ControlledInputNew'
import {
  ArrowSquareOut,
  Copy,
  Info,
  LinkSimple,
  Warning,
} from '@phosphor-icons/react'
import Button from '../../../components/ui/button'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'

function IntegrationCard({ onClick, item, onUpdate }) {
  const history = useHistory()
  const internalIntegration = item?.auth_type === 'internal'
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const HiRequest = useSelector((state) => state.hiBob.request)
  const request = useSelector((state) => state.bamboo.request)

  const [isEnableOktaFlowOpen, setIsEnableOktaFlowOpen] = useState(false)
  const [isDisableOktaFlowOpen, setIsDisableOktaFlowOpen] = useState(false)

  const dispatch = useDispatch()

  const getIcon = (e) => {
    switch (e?.code) {
      case 'xero':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={xeroLogo}
            height={63}
            width={63}
            alt='Xero logo'
          />
        )
      case 'quickbooks':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={quickBooksLogo}
            height={63}
            width={63}
            alt='Quickbook logo'
          />
        )
      case 'sapling':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={saplingLogo}
            height={63}
            width={63}
            alt='Sapling logo'
          />
        )
      case 'hibob':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={bobLogo}
            height={63}
            width={63}
            alt='Hibob logo'
          />
        )
      case 'bamboohr':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={bambooLogo}
            height={63}
            width={63}
            alt='BambooHR logo'
          />
        )
      case 'fresh':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={freshbooksLogo}
            height={63}
            width={63}
            alt='Fresh logo'
          />
        )
      case 'zoho':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={zohoInvoiceLogo}
            height={63}
            width={63}
            alt='Zoho logo'
          />
        )
      case 'netsuite':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={netSuiteLogo}
            height={63}
            width={63}
            alt='Netsuite logo'
          />
        )
      case 'okta':
        return (
          <img
            className='my-0 mx-auto mb-4 rounded-circle'
            src={oktaLogo}
            height={63}
            width={63}
            alt='Okta logo'
          />
        )
      default:
        return null
    }
  }

  function getDescription(e) {
    switch (e?.code) {
      case 'xero':
        return `The Xero integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${
          e?.organisation_name ?? e?.name
        } account.`
      case 'quickbooks':
        return `The Quickbooks integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${
          e?.organisation_name ?? e?.name
        } account.`
      case 'zoho':
        return `The Zoho Invoices integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${
          e?.organisation_name ?? e?.name
        } account.`
      case 'hibob':
        return 'One-click integration to automatically create new employees or contractors on Bob with all relevant data.'
      case 'bamboohr':
        return 'One-click integration to automatically create new employees or contractors on Bamboo HR with all relevant data.'
      case 'sapling':
        return 'One-click integration to automatically create new employees or contractors on Sapling HR with all relevant data.'
      case 'fresh':
        return `The FreshBooks integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${
          e?.organisation_name ?? e?.name
        } account.`
      case 'netsuite':
        return `The NetSuite integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${
          e?.organisation_name ?? e?.name
        } account.`
      case 'okta':
        return 'Set up Single Sign-on (SSO) for seamless and secure team access'
      default:
        return null
    }
  }

  const getConnectFunction = (e) => {
    switch (e?.code) {
      case 'xero':
        return connectXero
      case 'quickbooks':
        return connectQuickbooks
      case 'zoho':
        return connectZoho
      case 'hibob':
        return updateDataToHibob
      case 'fresh':
        return connectFreshBooks
      case 'sapling':
        return updateDataToSapling
      case 'bamboohr':
        return updateDataToBamboo
      case 'netsuite':
      default:
        return null
    }
  }

  const handleReview = (e) => {
    if (e?.code === 'sapling') {
      dispatch(
        SaplingRequest({ ...saplingRequest, session_id: item?.session_id }),
      )
      history.push('/integrations/update-sapling')
    } else if (e?.code === 'hibob') {
      dispatch(HiBobRequest({ ...HiRequest, session_id: item?.session_id }))
      history.push('/integrations/update-hibob')
    } else if (e?.code === 'bamboohr') {
      dispatch(bambooRequest({ ...request, session_id: item?.session_id }))
      history.push('/integrations/update-bamboo')
    } else if (e.code === 'okta') {
      setIsEnableOktaFlowOpen(true)
    }
  }

  const updateData = useFetch(
    {
      action: getConnectFunction(item),
      onComplete: onUpdate,
    },
    [],
  )

  const handleEnable = () => {
    if (item?.code === 'okta') {
      setIsEnableOktaFlowOpen(true)
    } else if (item.synched === 1 && item?.code !== 'netsuite') {
      updateData.startFetch({ session_id: item?.session_id, connected: 1 })
    } else {
      history.push(`/integrations/${item?.code}`)
    }
  }

  const connect = useFetch(
    {
      action: getConnectFunction(item),
      onComplete: (data) => {
        if (data?.redirect_url) {
          window.location.replace(data?.redirect_url)
        }
      },
    },
    [],
  )

  const disconnect = useFetch(
    {
      action: disconnectXero,
      onComplete: onUpdate,
    },
    [],
  )

  const handleSynced1 = (item) => {
    if (item.synched === 1) {
      history.push(
        `/integrations/update-${
          item?.code === 'fresh' ? 'freshbooks' : item?.code
        }`,
      )
    } else {
      history.push(
        `/integrations/${item?.code === 'fresh' ? 'freshbooks' : item?.code}`,
      )
    }
  }

  const { hasAccess } = usePermissions()
  const canManageIntegrations = hasAccess(permissions.manageCompanySettings)

  return (
    <>
      <Col className='p-0 mb-3' md={6}>
        <div
          style={{
            marginLeft: 12,
            marginRight: 12,
            borderRadius: 4,
            border: '1px solid #E7E8F2',
            height: '100%',
          }}
        >
          {!!item.connected && (
            <Row
              style={{ position: 'absolute', right: 30, top: 18 }}
              className='align-items-center p-0 m-0'
            >
              <span
                style={{
                  height: 10,
                  width: 10,
                  backgroundColor: 'lime',
                  borderRadius: '50%',
                }}
              />
            </Row>
          )}
          <a onClick={onClick} style={{ height: '100%' }}>
            <div className='rounded-sm m-0 p-0' style={{ height: '100%' }}>
              <Row
                className='p-0 m-0 p-4 flex-column'
                style={{ height: '100%' }}
              >
                {getIcon(item)}
                <Col
                  className='p-0 m-0 align-content-between'
                  style={{ height: '100%' }}
                >
                  <div>
                    <Row className='align-items-center p-0 m-0 mb-1'>
                      <h4 className='font-size-24'>{item?.name}</h4>
                    </Row>
                    <div>
                      <p className='text-muted font-size-14'>
                        {getDescription(item)}
                      </p>
                    </div>
                  </div>
                </Col>
                <div>
                  <Row
                    className='m-0 p-0 align-items-center'
                    style={{ height: '100%' }}
                  >
                    {/* eslint-disable-next-line no-constant-condition */}
                    <Row className='m-0 p-0 w-100 text-center'>
                      {item.connected ? (
                        <>
                          <Col className='m-0 p-0 pr-1'>
                            <PermissionTooltip
                              showing={!canManageIntegrations}
                              id={`add-user-btn-tooltip-${item?.id}`}
                              area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
                            >
                              {' '}
                              <button
                                onClick={() => {
                                  if (
                                    internalIntegration &&
                                    item?.code !== 'netsuite'
                                  ) {
                                    handleReview(item)
                                  } else {
                                    handleSynced1(item)
                                  }
                                }}
                                disabled={!canManageIntegrations}
                                className='btn btn-outline-primary btn-block rp-font-bold'
                              >
                                {item.synched === 1
                                  ? 'Review'
                                  : internalIntegration
                                    ? 'Review'
                                    : 'Complete'}
                              </button>
                            </PermissionTooltip>
                          </Col>
                          <Col className='m-0 p-0 pl-1'>
                            <PermissionTooltip
                              showing={!canManageIntegrations}
                              id={`add-user-btn-tooltip-${item?.id}`}
                              area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
                            >
                              <button
                                onClick={() => {
                                  if (item?.code === 'okta') {
                                    setIsDisableOktaFlowOpen((o) => !o)
                                    return
                                  }
                                  if (
                                    internalIntegration &&
                                    item?.code !== 'netsuite'
                                  ) {
                                    if (!updateData.isLoading) {
                                      updateData.startFetch({
                                        session_id: item?.session_id,
                                        connected: 0,
                                      })
                                    }
                                  } else {
                                    if (!disconnect.isLoading) {
                                      disconnect.startFetch({
                                        session_id: item?.session_id,
                                      })
                                    }
                                  }
                                }}
                                disabled={!canManageIntegrations}
                                className='btn btn-outline-danger btn-block rp-font-bold'
                              >
                                {(updateData?.isLoading ||
                                  disconnect.isLoading) && (
                                  <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
                                )}
                                {internalIntegration ? 'Disable' : 'Disconnect'}
                              </button>
                            </PermissionTooltip>
                          </Col>
                        </>
                      ) : (
                        <Col className='m-0 p-0'>
                          <PermissionTooltip
                            showing={!canManageIntegrations}
                            id={`add-user-btn-tooltip-${item?.id}`}
                            area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
                          >
                            <button
                              onClick={() => {
                                if (internalIntegration) {
                                  handleEnable()
                                } else {
                                  if (!connect.isLoading) {
                                    connect.startFetch()
                                  }
                                }
                              }}
                              disabled={!canManageIntegrations}
                              className='btn btn-primary btn-block'
                            >
                              {(updateData?.isLoading ||
                                connect.isLoading ||
                                disconnect.isLoading) && (
                                <i className='bx bx-loader bx-spin mr-2' />
                              )}
                              {internalIntegration ? 'Enable' : 'Connect'}
                            </button>
                          </PermissionTooltip>
                        </Col>
                      )}
                    </Row>
                    {item.synched & item.last_sync_date ? (
                      <p className='mt-3 m-0 text-muted font-size-12'>
                        Last sync:{' '}
                        {moment(item.last_sync_date * 1000).format(
                          'MM/DD/YYYY, h:mm a',
                        )}
                      </p>
                    ) : null}
                  </Row>
                </div>
              </Row>
            </div>
          </a>
        </div>
      </Col>

      {isEnableOktaFlowOpen && (
        <EnableOktaModal
          isOpen={isEnableOktaFlowOpen}
          toggle={() => setIsEnableOktaFlowOpen(false)}
          onEnable={() => {
            setIsEnableOktaFlowOpen(false)
            onUpdate?.()
          }}
          oktaData={item.code === 'okta' ? item : null}
        />
      )}
      {isDisableOktaFlowOpen && (
        <DisableOktaModal
          isOpen={isDisableOktaFlowOpen}
          toggle={() => setIsDisableOktaFlowOpen(false)}
          onDisable={() => {
            setIsDisableOktaFlowOpen(false)
            onUpdate?.()
          }}
        />
      )}
    </>
  )
}

function EnableOktaModal({ isOpen, toggle, onEnable, oktaData = null }) {
  const isOktaConnected = oktaData?.connected === 1

  const [copyButtonText, setCopyButtonText] = useState('Copy Link')
  const { startFetch: enableOktaInt, isLoading: isEnablingOkta } = useFetch({
    action: isOktaConnected ? updateOktaIntegration : enableOktaIntegration,
    onComplete: (data) => {
      if (data.connected === 1) {
        toastr.success(
          'You can now use Okta SSO services',
          'Integration enabled',
        )
        onEnable?.()
      }
    },
    onError: (err) => toastr.error(err),
  })

  const { startFetch: validateOktaUrl, isLoading: isValidatingUrl } = useFetch({
    action: validateOktaMetadataUrl,
    onComplete: (data) => {
      if (data.isValid) {
        enableOktaFlow()
      } else {
        toastr.error(data?.validationError)
      }
    },
    onError: (_, s) => {
      toastr.error(s.message?.[0], s.error)
    },
  })

  const schema = yup.object().shape({
    url: yup
      .string()
      .url('Please enter a valid url')
      .required('Metadata URL is required'),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      url: isOktaConnected ? oktaData?.metadata_url : '',
    },
  })

  const { url } = useWatch({ control })

  const callbackUrl = oktaData?.callback_url

  const enableOktaFlow = () => {
    enableOktaInt({
      metadata_url: url,
    })
  }

  const verifyMetadataUrl = () => {
    validateOktaUrl({ metadataUrl: url })
  }

  const copyOktaCallbackUrl = () => {
    navigator.clipboard.writeText(callbackUrl).then(
      () => {
        toastr.info('Okta Callback URL copied')

        setCopyButtonText('Copied')
        setTimeout(() => {
          setCopyButtonText('Copy Link')
        }, 3000)
      },
      () => {
        toastr.error('Failed to copy Okta Callback URL')
      },
    )
  }

  return (
    <Modal centered size='lg' isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        className='!tw-px-7 !tw-pt-7'
        close={<ModalCloseButton toggle={toggle} />}
      >
        <img src={oktaLogo} height={40} width={40} alt='' />
        <div className='tw-text-xl'>Okta integration</div>
        <div className='tw-text-sm tw-text-text-80'>
          Please fill in the following details to install single sign-on (SSO)
        </div>
      </ModalHeader>
      <ModalBody className='!tw-p-7'>
        <div className='tw-flex tw-flex-col'>
          <div className='bg-primary-10 tw-p-6'>
            <Info size={24} className='tw-text-primary-100' />
            <a
              href='https://www.remotepass.com/integrations'
              target='_blank'
              className='tw-mt-2 tw-flex tw-w-fit tw-items-center tw-text-xl tw-text-secondary-120'
              rel='noreferrer'
            >
              Add RemotePass to Okta <ArrowSquareOut size={20} />
            </a>
            <span className='tw-text-sm tw-text-text-80'>
              Start by integrating RemotePass into the Okta platform with this
              URL. This step is required to proceed with and finalize the
              integration details.
            </span>

            <div className='tw-mt-6 tw-flex tw-flex-wrap tw-gap-4 sm:tw-flex-nowrap'>
              <div className='tw-flex tw-flex-1 tw-items-center tw-gap-2 tw-rounded tw-bg-surface-30 tw-p-2'>
                <LinkSimple size={24} />
                <div className='tw-flex-1 tw-text-text-80'>
                  <span className='tw-text-[10px]'>Callback URL</span>
                  <div
                    className='tw-max-w-[200px] tw-truncate tw-text-sm sm:tw-max-w-[300px] md:tw-max-w-[475px]'
                    title={callbackUrl}
                  >
                    {callbackUrl}
                  </div>
                </div>
              </div>

              <Button
                onClick={copyOktaCallbackUrl}
                className='tw-flex-initial'
                icon={<Copy size={20} />}
              >
                {copyButtonText}
              </Button>
            </div>
          </div>

          <div className='tw-mb-3 tw-mt-6 tw-w-full tw-border-t tw-border-surface-30' />

          <div className='tw-py-4'>
            <InputGroup>
              <InputGroupText className='border-right-0 d-flex !tw-rounded-r-none'>
                <LinkSimple size={24} />
              </InputGroupText>

              <ControlledInput
                wrapperClassName='tw-flex-1'
                className='!tw-rounded-l-none'
                control={control}
                name='url'
                id='url'
                label='Metadata URL'
                placeholder='Metadata URL'
                showError={false}
              />
            </InputGroup>
            {errors?.url ? (
              <InputFeedback className='tw-mt-1'>
                {errors?.url?.message}
              </InputFeedback>
            ) : (
              <div className='tw-mt-2 tw-flex tw-items-start tw-gap-1 tw-text-xs tw-text-text-80'>
                <Info size={16} />
                <span>
                  The Okta metadata URL with the configuration details required
                  for Single Sign-On (SSO)
                </span>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='!tw-px-7 !tw-pb-7'>
        <Button type='button' onClick={toggle}>
          Cancel
        </Button>
        <Button
          disabled={!url || isEnablingOkta || isValidatingUrl}
          type='button'
          onClick={handleSubmit(verifyMetadataUrl)}
        >
          {isEnablingOkta || isValidatingUrl
            ? 'Verifying...'
            : isOktaConnected
              ? 'Save'
              : 'Enable'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function DisableOktaModal({ isOpen, toggle, onDisable }) {
  const { startFetch: disableOktaInt, isLoading: isDisablingOkta } = useFetch({
    action: disableOktaIntegration,
    onComplete: (data) => {
      if (data.connected === 0) {
        toastr.success('Okta Integration disabled')
        onDisable?.()
      }
    },
    onError: (err) => toastr.error(err),
  })

  return (
    <ConfirmationModal
      isOpen={isOpen}
      toggle={toggle}
      content={
        <>
          <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
            <Warning size={24} className='tw-fill-systemGold-100' />
            <ModalCloseButton toggle={toggle} />
          </div>
          <p className='tw-mb-2 tw-text-xl tw-text-secondary-120'>
            Are you sure you want to disable this Integration?
          </p>
          <p className='tw-text-sm tw-text-text-80'>
            You won&apos;t be able to use this integration. you will have to
            re-enable it to use it again
          </p>
        </>
      }
      negativeCaption='No, Close'
      caption='Yes, Disable'
      buttonColor='danger'
      onConfirm={() => {
        disableOktaInt()
      }}
      confirmLoading={isDisablingOkta}
    />
  )
}

export default IntegrationCard
