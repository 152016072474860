import React, { createContext, useCallback, useContext, useRef } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import { getPlaceholders } from '../../../../../services/api-automations'
import isNil from 'lodash/isNil'

export const PlaceholdersContext = createContext({
  isLoading: true,
  items: [],
  quillRef: null,
  insertPlaceholder: null,
})

export function PlaceholdersProvider(props) {
  const { children } = props
  const { isLoading, data: items = [] } = useFetch({
    autoFetch: true,
    isOpenApi: true,
    action: getPlaceholders,
  })

  const quillRef = useRef(null)
  const insertPlaceholder = useCallback((placeholder) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor()
      if (!quill.hasFocus()) quill.focus()
      const cursorPosition = quill.getSelection()?.index
      if (!isNil(cursorPosition)) {
        quill.insertText(cursorPosition, `{{${placeholder}}}`, 'user')
        quill.setSelection(cursorPosition + `{{${placeholder}}}`.length)
      }
    }
  }, [])

  return (
    <PlaceholdersContext.Provider
      value={{ isLoading, items, quillRef, insertPlaceholder }}
    >
      {children}
    </PlaceholdersContext.Provider>
  )
}

export function usePlaceholders() {
  return useContext(PlaceholdersContext)
}
