import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { WarningOctagon } from '@phosphor-icons/react'
import Button from '../../../../../components/ui/button'

export function UnpublishConfirmModal(props) {
  const { isOpen, toggle, onUnpulish } = props

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <WarningOctagon size={25} className='tw-text-systemRed-100' />
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <span className='tw-text-base tw-font-bold'>Unpublish automation</span>
        <span className='tw-text-s tw-text-secondary'>
          Are you sure you want to unpublish this automation? All pending
          actions will be canceled
        </span>
      </ModalBody>
      <ModalFooter>
        <Button
          className='tw-mb-2 !tw-bg-white'
          textClassName='!tw-text-black'
          color='surface-30'
          outline={true}
          onClick={toggle}
        >
          No, cancel
        </Button>
        <Button
          color='danger'
          onClick={() => {
            onUnpulish()
            toggle()
          }}
        >
          Yes, Unpublish
        </Button>
      </ModalFooter>
    </Modal>
  )
}
