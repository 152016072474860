import { yupResolver } from '@hookform/resolvers/yup'
import {
  ChatDots,
  FilePlus,
  ListBullets,
  UploadSimple,
  XCircle,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Card,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import ControlledDropzoneInput from '../../../components/controlled-dropzone-input'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { BE_CONTRACT_CATEGORY } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  addDocumentRequestClient,
  addDocumentRequestEmployee,
  getDocumentRequestTypes,
  requestDoc,
  uploadDocumentRequestClient,
} from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { JobTitleModal } from '../ContractDetail/SelectSignatoryDropdown'
import TabCardHeader from '../components/tab/tab-card-header'
import { FILE_SIZE_LIMITS_IN_BYTES } from '../utils/constants'
import DeDocumentsList from './de-documents-list'

function getBackToContractUrl(location) {
  return `back=${encodeURIComponent(location.pathname + location.search)}`
}

export default function DeDocumentsTab({
  contract,
  onUpdate,
  contractLoading,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const isDirectEmployee =
    userProfile?.contractor_type === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const isDirectEmployeeContract =
    contract.type === CONTRACT_TYPES.DIRECT_EMPLOYEE && isDirectEmployee

  const documents = contract?.documents
  const isEmpty = !documents || documents?.length === 0

  return (
    <Card>
      <TabCardHeader
        title='Documents'
        extra={
          isEmpty ? null : (
            <DocumentActions
              isDirectEmployee={isDirectEmployeeContract}
              refreshDocs={onUpdate}
              contract={contract}
            />
          )
        }
      />

      <DeDocumentsList
        loading={contractLoading}
        isEmpty={isEmpty}
        documents={documents}
        contract={contract}
        refreshDocs={onUpdate}
      />
    </Card>
  )
}

export function DocumentActions({
  isDirectEmployee = false,
  refreshDocs = () => {},
  contract,
}) {
  const { data: docTypes } = useFetch(
    {
      action: getDocumentRequestTypes,
      autoFetch: !!contract?.id,
      body: { contract_id: contract?.id },
    },
    [contract?.id],
  )

  const docTypeOptions = useMemo(() => {
    return (
      docTypes?.map((type) => {
        return { label: type.name, value: type.id }
      }) ?? []
    )
  }, [docTypes])
  return (
    <div className='d-flex flex-wrap gap-8'>
      {contract?.status?.id === 4 && (
        <RequestDocumentModal
          isDirectEmployee={isDirectEmployee}
          refreshDocs={refreshDocs}
          docTypeOptions={docTypeOptions}
          contract={contract}
        />
      )}

      {isDirectEmployee || !docTypeOptions?.length ? null : (
        <GenerateDocumentModal
          contract={contract}
          refreshDocs={refreshDocs}
          docTypeOptions={docTypeOptions}
        />
      )}
    </div>
  )
}

function getDocType(types, value) {
  return types.find((x) => x.value?.toString() === value?.toString())
}

function DocTypeField({ errors, control, docTypeOptions }) {
  return (
    <>
      <ControlledSelect
        label='Document type'
        name='type'
        inputId='doc-type-input-id'
        control={control}
        options={docTypeOptions}
        error={errors.type}
        wrapperClassName='mb-3'
      />

      <ControlledInput
        label='Recipient'
        name='recipient'
        id='recipient'
        control={control}
        error={errors.recipient}
        wrapperClassName='mb-3'
      />
    </>
  )
}

const requestDocumentFormId = 'request-document-form'
function RequestDocumentModal({
  isDirectEmployee = false,
  refreshDocs,
  contract,
  docTypeOptions,
}) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((open) => {
      if (!open) {
        reset()
      }

      return !open
    })
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        type: yup.string().when({
          is: () => isDirectEmployee && docTypeOptions?.length > 0,
          then: (schema) => schema.required('Document type is required'),
        }),
        title: yup.string().when({
          is: () => !isDirectEmployee || docTypeOptions?.length < 1,
          then: (schema) => schema.required('Document title is required'),
        }),
      }),
    ),
  })

  function onRequestedDocument() {
    toggle()
    refreshDocs?.()
  }

  const { startFetch: deRequestDoc, isLoading: requestingDocument } = useFetch({
    action: isDirectEmployee ? addDocumentRequestEmployee : requestDoc,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
      } else {
        toastr.success('Document requested')
        onRequestedDocument()
      }
    },
    onError: (err) => {
      const errorMessage =
        typeof err === 'string'
          ? err
          : typeof err?.message === 'string'
            ? err?.message
            : 'Something went wrong'

      toastr.error(errorMessage)
    },
  })

  function onSubmit(data) {
    const title =
      !isDirectEmployee || docTypeOptions.length < 1
        ? data?.title
        : getDocType(docTypeOptions, data.type)?.label

    const body = { title, description: data?.message }

    if (!isDirectEmployee) {
      body.contract_id = contract?.id
    }

    if (data?.recipient) {
      body.recipient = data?.recipient
    }

    deRequestDoc(body)
  }

  const title = 'Request a document'

  const btnLoading = requestingDocument

  const { hasAccess } = usePermissions()

  const canManageDocuments =
    hasAccess([
      permissions.RequestContractsDocuments,
      permissions.createDocument,
    ]) || isDirectEmployee

  const buttonProps = isDirectEmployee
    ? {}
    : {
        outline: true,
        color: isDirectEmployee || canManageDocuments ? 'light' : 'primary',
      }

  return (
    <>
      <PermissionTooltip
        showing={!canManageDocuments}
        area={PERMISSION_GROUP.DOCUMENTS.name}
        id='request-document-btn'
      >
        <Button
          disabled={!canManageDocuments}
          onClick={toggle}
          {...buttonProps}
        >
          {title}
        </Button>
      </PermissionTooltip>

      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id={requestDocumentFormId}>
            {isDirectEmployee && docTypeOptions.length ? (
              <DocTypeField
                errors={errors}
                control={control}
                docTypeOptions={docTypeOptions}
              />
            ) : (
              <ControlledInput
                label='Document title'
                placeholder='Document title'
                name='title'
                id='title'
                control={control}
                error={errors.title?.message}
                wrapperClassName='mb-3'
              />
            )}

            <ControlledInput
              label='Message (optional)'
              placeholder={`Message to your ${
                isDirectEmployee ? 'Employer' : 'Employee'
              } here...`}
              name='message'
              id='message-id'
              control={control}
              type='textarea'
              error={errors.message?.message}
              rows={5}
            />
          </form>
        </ModalBody>

        <ModalFooter>
          <Button color='light' outline onClick={toggle} disabled={btnLoading}>
            Cancel
          </Button>
          <Button
            type='submit'
            formId={requestDocumentFormId}
            disabled={btnLoading}
            loading={btnLoading}
          >
            Request
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const MODAL_TABS = { TEMPLATES: 1, CUSTOM_UPLOAD: 2 }
const modalTabs = [
  {
    label: 'Templates',
    id: MODAL_TABS.TEMPLATES,
    icon: <ListBullets size={20} />,
  },
  {
    label: 'Upload document',
    id: MODAL_TABS.CUSTOM_UPLOAD,
    icon: <UploadSimple size={20} />,
  },
]

const generateDocumentFormId = 'generate-document-form'
export function GenerateDocumentModal({
  contract,
  refreshDocs,
  buttonProps = {},
  docTypeOptions,
}) {
  const [activeTab, setActiveTab] = useState(MODAL_TABS.TEMPLATES)
  const [isOpen, setIsOpen] = useState(false)

  const [openJobTitleModal, setOpenJobTitleModal] = useState(false)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const clientHasJobTitle = !!userProfile?.job_title
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
  const jobTitleRequired = isDeContract

  function toggle() {
    if (jobTitleRequired && !clientHasJobTitle) {
      setOpenJobTitleModal(true)
      return null
    }

    setIsOpen((open) => {
      if (open) {
        reset()
        setActiveTab(MODAL_TABS.TEMPLATES)
      }

      return !open
    })
  }

  const history = useHistory()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(
      activeTab === MODAL_TABS.CUSTOM_UPLOAD
        ? yup.object().shape({
            file: yup.array().required('Document is required'),
          })
        : yup.object().shape({
            type: yup.string().required('Document type is required'),
          }),
    ),
  })

  const { startFetch: uploadDocument, isLoading: uploadingDocument } = useFetch(
    {
      action: uploadDocumentRequestClient,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Something went wrong')
        }

        toggle()
        refreshDocs?.()
      },
      onError: (err) => {
        toastr.error(getErrorMessage(err))
      },
    },
  )

  const { startFetch: deRequestDoc, isLoading: requestingDocument } = useFetch({
    action: addDocumentRequestClient,
    onComplete: (data, body) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
      }

      if (data?.id && activeTab === MODAL_TABS.TEMPLATES) {
        history.push(
          `/document/generate/${data?.id}?${getBackToContractUrl(
            history.location,
          )}`,
        )
        toggle()
        refreshDocs?.()
      } else {
        uploadDocument({ file: body?.file, request_id: data?.id })
      }
    },
    onError: (err) => {
      toastr.error(getErrorMessage(err))
    },
  })

  function onSubmit(data) {
    const title = getDocType(docTypeOptions, data.type)?.label

    const body = {
      contract_id: contract?.id,
      description: data?.message,
      title,
    }

    if (data?.recipient) {
      body.recipient = data?.recipient
    }

    if (activeTab === MODAL_TABS.CUSTOM_UPLOAD) {
      const file = data?.file?.[0]
      body.file = file
      body.title = file?.name
    }

    deRequestDoc(body)
  }
  const { hasAccess } = usePermissions()
  const canManageDocuments = hasAccess([
    permissions.RequestContractsDocuments,
    permissions.createDocument,
  ])

  const uploadedFileName = watch('file')?.length > 0 && watch('file')[0]?.name

  return (
    <>
      <PermissionTooltip
        showing={!canManageDocuments}
        area={PERMISSION_GROUP.DOCUMENTS.name}
        id='generate-document-btn'
      >
        <Button
          disabled={!canManageDocuments}
          onClick={toggle}
          {...buttonProps}
        >
          Generate a document
        </Button>
      </PermissionTooltip>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
          Generate a document
        </ModalHeader>

        <ModalTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id={generateDocumentFormId}>
            {activeTab === MODAL_TABS.TEMPLATES ? (
              <DocTypeField
                errors={errors}
                control={control}
                docTypeOptions={docTypeOptions}
              />
            ) : (
              <>
                <PrimaryAlert fade={false}>
                  Ensure that the document is signed before submission
                </PrimaryAlert>

                <FormGroup className='mt-3'>
                  <ControlledDropzoneInput
                    name='file'
                    maxSize={FILE_SIZE_LIMITS_IN_BYTES.TWO_MB}
                    control={control}
                    error={errors?.file}
                    unstyled
                    className={cx(
                      'd-flex gap-12 align-items-center flex-wrap font-size-14 rounded cursor-pointer rp-font-bold px-3 py-2 bg-primary-20',
                      errors?.file
                        ? 'text-danger bg-soft-danger'
                        : 'text-primary-100 bg-primary-20 border-primary-100',
                      { 'justify-content-between': !!uploadedFileName },
                    )}
                    style={{
                      minHeight: 54,
                      border: uploadedFileName
                        ? '2px dashed transparent'
                        : '2px dashed' + (errors?.file ? ' var(--danger)' : ''),
                    }}
                  >
                    {uploadedFileName ? (
                      <>
                        {uploadedFileName}

                        <button
                          className='p-0.5 rp-btn-nostyle text-red-90 d-flex'
                          type='button'
                          onClick={() => {
                            setValue('file', null)
                          }}
                        >
                          <XCircle size={20} weight='fill' />
                        </button>
                      </>
                    ) : (
                      <>
                        <FilePlus size={20} weight='duotone' />
                        <div className='d-none d-md-block'>
                          Drop files here or click to upload
                        </div>
                        <div className='d-md-none'>Click to upload</div>

                        <div className='text-secondary-80 rp-font-normal'>
                          Max file size 2MB
                        </div>
                      </>
                    )}
                  </ControlledDropzoneInput>
                </FormGroup>
              </>
            )}

            <ControlledInput
              label='Message (optional)'
              placeholder='Message to your employee here...'
              name='message'
              id='message-id'
              control={control}
              type='textarea'
              error={errors.message?.message}
              rows={5}
            />
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            color='light'
            outline
            onClick={toggle}
            disabled={requestingDocument || uploadingDocument}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            formId={generateDocumentFormId}
            loading={requestingDocument || uploadingDocument}
            disabled={requestingDocument || uploadingDocument}
          >
            Generate
          </Button>
        </ModalFooter>
      </Modal>

      <JobTitleModal
        open={openJobTitleModal}
        toggle={() => setOpenJobTitleModal((open) => !open)}
      />
    </>
  )
}

function ModalTabs({ activeTab, setActiveTab }) {
  return (
    <div className='border-bottom'>
      <div className='d-flex gap-32 justify-content-center align-items-center'>
        {modalTabs.map(({ label, id, icon }) => {
          const isActive = activeTab === id

          return (
            <button
              key={id}
              className={cx(
                'bg-transparent d-flex gap-8 justify-content-center align-items-center py-3 pl-1 pr-1.5',
                {
                  'border-primary text-primary rp-font-bold': isActive,
                  'border-transparent text-secondary-80': !isActive,
                },
              )}
              style={{
                border: 'none',
                borderBottom: '2px solid transparent',
              }}
              onClick={() => setActiveTab(id)}
            >
              {icon}
              <span>{label}</span>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export function DocumentMessage({ from, message }) {
  return (
    <div className='bg-surface-20 border border-surface-30 rounded p-3 font-size-14'>
      <div className='text-text-110 rp-font-semibold mb-2'>
        <ChatDots size={24} className='mb-2' />
        <div>From {from}</div>
      </div>
      <div>“{message}”</div>
    </div>
  )
}
