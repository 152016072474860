import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ActionEditor } from './action-editor'
import { usePlaceholders } from '../contexts/placeholders-context'
import { useSlackConnection } from '../contexts/slack-connection-context'
import Button from '../../../../../components/ui/button'
import { SlackLogo, WarningOctagon, Plus } from '@phosphor-icons/react'
import Loader from '../../../../../components/ui/loader'
import { ActionSchedule } from './action-schedule'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import ControlledInput from '../../../../../components/ControlledInput'
import {
  getSlackConnectionInputValue,
  getSlackRecipientInputValue,
  transformSlackConnection,
  transformSlackRecipients,
} from '../tools'

export function ActionSlackForm(props) {
  const { index, className } = props
  const { quillRef, ...placeholder } = usePlaceholders()
  const { watch, setValue } = useFormContext()
  const connection = watch(`steps.${index}.connection`)

  const {
    handleWorkspaceChange,
    workspaces,
    isLoadingUrl,
    recipients,
    isLoading: isLoadingSlack,
    connectSlack,
  } = useSlackConnection()

  useEffect(() => {
    handleWorkspaceChange(connection?.value)
    setValue('recipients', [])
  }, [connection, setValue])

  const slackConnections = useMemo(
    () => transformSlackConnection(workspaces),
    [workspaces],
  )

  const slackRecipients = useMemo(
    () => transformSlackRecipients(recipients),
    [recipients],
  )

  if (isLoadingSlack && !workspaces.length)
    return <Loader minHeight='20px' className='tw-my-2 tw-flex-1' />

  if (!workspaces?.length)
    return (
      <div className='tw-flex tw-flex-col tw-gap-2 tw-rounded tw-bg-systemRed-20 tw-p-5'>
        <div className='tw-flex tw-flex-row tw-items-center tw-gap-1'>
          <WarningOctagon size={20} className='tw-text-systemRed' />
          <span className='tw-leading-none tw-text-text'>
            Slack deactivated
          </span>
        </div>
        <span className='tw-text-text-60'>
          To continue setting up this action, please connect your Slack account.
        </span>
        <Button
          outline
          loading={isLoadingUrl}
          onClick={connectSlack}
          className='tw-self-start !tw-bg-transparent'
          textClassName='!tw-text-black'
          color='secondary-100'
          iconRight={<SlackLogo className='tw-text-text' />}
        >
          Connect slack
        </Button>
      </div>
    )

  return (
    <div className='tw-flex tw-flex-col tw-gap-3'>
      <Controller
        render={({ field, fieldState }) => (
          <CustomSelect
            header={
              <div className='tw-flex tw-justify-center tw-p-1'>
                <Button
                  icon={<Plus />}
                  onClick={() => connectSlack()}
                  className='tw-m-3 tw-w-[95%]'
                  outline={true}
                >
                  Connect another account
                </Button>
              </div>
            }
            value={getSlackConnectionInputValue(field.value, slackConnections)}
            options={slackConnections}
            error={fieldState.error}
            hasError={!!fieldState.error}
            onChange={field.onChange}
            placeholder='Slack connection'
          />
        )}
        name={`steps.${index}.connection`}
      />

      <ActionSchedule index={index} type='slack' />

      <div className='tw-flex tw-flex-row tw-items-center tw-gap-3'>
        <SlackLogo size={20} className='tw-text-primary' />
        <span className='tw-font-bold'>Customize your slack message</span>
      </div>

      <ControlledInput
        name={`steps.${index}.from`}
        disabled={true}
        defaultValue='From: RemotePass Bot'
      />

      <Controller
        render={({ field, fieldState }) => (
          <CustomSelect
            isMulti
            isDisabled={!recipients}
            value={getSlackRecipientInputValue(field.value, slackRecipients)}
            options={slackRecipients}
            error={fieldState.error}
            hasError={!!fieldState.error}
            onChange={field.onChange}
            isOptionDisabled={() => field?.value?.length >= 5}
            placeholder='Recipient(s)'
          />
        )}
        name={`steps.${index}.recipients`}
      />

      <Controller
        name={`steps.${index}.body`}
        render={({
          field: { value = '', onChange },
          fieldState: { error },
        }) => (
          <ActionEditor
            {...placeholder}
            ref={quillRef}
            value={value}
            error={error}
            className={className}
            onChange={onChange}
            placeholder='Message'
          />
        )}
      />
    </div>
  )
}
