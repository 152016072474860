import { WarningOctagon } from '@phosphor-icons/react'
import React, { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { assignBillApprovalFlow } from '../../../services/api-bill-payments'
import BillApprovalFlowSelector from '../../../components/bill-approval-flow-selector'

export default function BillApprovalActionButton({
  approvalFlows = [],
  fetchingApprovalFlows,
  approvalFlow,
  onApprovalFlowChange,
}) {
  const [showModal, setShowModal] = useState(false)
  const [selectedFlow, setSelectedFlow] = useState({
    label: approvalFlow?.name,
    value: approvalFlow?.id,
  })

  const { isLoading: assigningFlow, startFetch: assignBillFlow } = useFetch({
    action: assignBillApprovalFlow,
    onComplete: () => {
      toastr.success(`Successfully assigned "${selectedFlow.label}" for bills.`)
      setShowModal(false)
      onApprovalFlowChange()
    },
    onError: (error) => toastr.error(error),
  })

  return (
    <>
      <span id='flowPicker'>
        <Button
          color='link'
          className='!tw-p-0'
          onClick={() => setShowModal(true)}
          disabled={fetchingApprovalFlows || !approvalFlows.length}
          loading={fetchingApprovalFlows}
        >
          {approvalFlow?.id ? 'Manage' : 'Select'}
        </Button>
      </span>

      {!approvalFlows.length && (
        <UncontrolledTooltip target='flowPicker'>
          You need to add an approval flow to perform this action.
        </UncontrolledTooltip>
      )}

      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        centered
        className='[&>div>div>h5]:!tw-w-full'
      >
        <ModalHeader>
          <span className='tw-flex tw-w-full tw-items-start tw-justify-between'>
            <span>
              <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                Bill Approval
              </p>
              <span className='tw-text-sm tw-text-text-80'>
                Assign a default approval flow for bill payment
              </span>
            </span>

            <ModalCloseButton toggle={() => setShowModal(false)} />
          </span>
        </ModalHeader>

        <ModalBody className='!tw-p-6'>
          <BillApprovalFlowSelector
            approvalFlows={approvalFlows}
            value={selectedFlow.value}
            onChange={(val) => {
              setSelectedFlow({ label: val.name, value: val.id })
            }}
          />

          <Alert
            className='tw-mt-4 tw-gap-4 !tw-border-systemRed-20 !tw-bg-systemRed-20 !tw-p-4 tw-text-sm'
            customIcon={
              <WarningOctagon size={24} className='tw-fill-systemRed' />
            }
          >
            Assigning a different flow will reset the status of bills that are
            not yet approved to the initial stage of approval.
          </Alert>
        </ModalBody>
        <ModalFooter className='tw-justify-end tw-gap-2'>
          <Button
            outline
            color='light'
            disabled={assigningFlow}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              assignBillFlow({ approval_flow_id: selectedFlow.value })
            }
            loading={assigningFlow}
            disabled={assigningFlow || !selectedFlow}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
