import React from 'react'
import { Card } from 'reactstrap'
import { HourglassLow, Invoice } from '@phosphor-icons/react'
import Heading from '../../remotepass-perks/components/heading'

export default function PendingStatusPage() {
  return (
    <Card
      className='tw-mb-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-pb-5 tw-pt-5'
      style={{ minHeight: '75vh' }}
    >
      <div className='tw-flex tw-h-auto tw-max-w-[500px] tw-max-w-full tw-items-center tw-justify-between'>
        <div>
          <Invoice size={410} weight='duotone' color='#325AE7' />
        </div>
        <div>
          <HourglassLow size={90} color='#325AE7' />
        </div>
      </div>
      <Heading>Your access request to Bill Pay has been received</Heading>

      <p
        className='text-center text-muted font-size-16'
        style={{ lineHeight: '24px', maxWidth: 480 }}
      >
        You will be notified by email once your request is approved
      </p>
    </Card>
  )
}
