import cx from 'classnames'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import Paginations from 'react-js-pagination'
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from 'reactstrap'

import toastr from 'toastr'
import { Avatar } from 'ui'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import SearchBar from '../../../../components/SearchBar'
import BadgeX from '../../../../components/Table/BadgeX'
import { StyledH6 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import UserFlagging from '../../../../components/userFlag'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch, useResize } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  deactivateInsurance,
  deductAllPaymentInsurance,
  getAdminContractLessInsuranceList,
  getAdminInsuranceList,
  getDependentDetails,
  syncInsurancePlan,
} from '../../../../services/api'
import { BurgerDetails } from '../Users/users'
import BeneficiariesModal from './beneficiaries-modal'

const tabs = [
  { key: 'pending', label: 'Pending Requests' },
  { key: 'canceled', label: 'Cancelled Requests' },
  { key: 'active', label: 'Active' },
  { key: 'inactive', label: 'Deactivated' },
  { key: 'contract_less', label: 'Contract-less' },
  { key: 'processing', label: 'Processing' },
]

const InsuranceAdmin = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')

  const insurance = useFetch(
    {
      action:
        activeTab === 4
          ? getAdminContractLessInsuranceList
          : getAdminInsuranceList,
      withAdminAccess: true,
      autoFetch: true,
      body: {
        status: tabs[activeTab].key,
        search: searchQuery,
        page: activePage,
      },
      initResult: [],
    },
    [activeTab, searchQuery, activePage],
  )
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [show, setShow] = useState(false)
  const [selectedInsuranceId, setSelectedInsuranceId] = useState(null)
  const [showInsurance, setShowInsurance] = useState(false)
  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_INSURANCE_REQUESTS,
  )

  function handleSearch(query) {
    setActivePage(1)
    setSearchQuery(query)
  }

  return (
    <div className='page-content'>
      <BurgerDetails
        id={selectedUserId}
        hide={() => setShow(false)}
        show={show}
      />
      <DependentsDetails
        id={selectedInsuranceId}
        hide={() => setShowInsurance(false)}
        show={showInsurance}
      />
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Insurances</h1>

        <Card>
          <div
            className='d-md-flex d-none flex-column p-3'
            style={{ gap: '1rem' }}
          >
            <Nav tabs className='nav-tabs-custom'>
              {tabs.map((tab, i) => (
                <NavItem key={`tab-${i}`}>
                  <NavLink
                    tag='button'
                    style={{ cursor: 'pointer' }}
                    className={cx({ active: activeTab === i })}
                    onClick={() => setActiveTab(i)}
                  >
                    <span className='font-size-14'>{tab?.label}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <div>
              <SearchBar
                query={searchQuery}
                onQueryChanged={handleSearch}
                placeholder='Search by name'
                className='mr-md-2'
              />
            </div>
          </div>
          {insurance.isLoading ? (
            <Loader minHeight='70vh' />
          ) : (
            <div className='table-responsive' style={{ minHeight: '50vh' }}>
              <Table className='table table-centered table-nowrap text-muted'>
                <thead className='thead-light'>
                  <tr className='text-muted'>
                    <th className='border-top-0 text-muted'>Contractor</th>
                    {activeTab !== 4 && (
                      <th className='border-top-0 text-muted'>Client</th>
                    )}
                    <th className='border-top-0 text-muted'>Plan</th>
                    <th className='border-top-0 text-muted'>Created At</th>
                    <th className='border-top-0 text-muted'>Dependents</th>
                    {activeTab === 2 && (
                      <th className='border-top-0 text-muted'>Next Billing</th>
                    )}
                    <th className='border-top-0 text-muted'>Total</th>
                    <th className='border-top-0 text-muted'>Status</th>
                    {!hasEditPermission ? null : (
                      <th className='border-top-0 text-muted'>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {insurance.data?.map((e, i) => (
                    <InsuranceLine
                      onUserClick={(id) => {
                        setSelectedUserId(id)
                        setShow(true)
                      }}
                      onDetailsClick={(id) => {
                        setSelectedInsuranceId(id)
                        setShowInsurance(true)
                      }}
                      activeTab={activeTab}
                      key={`ins-${i}`}
                      item={e}
                      hasEditPermission={hasEditPermission}
                      onUpdate={() =>
                        insurance.startFetch(
                          { status: tabs[activeTab].key },
                          false,
                        )
                      }
                      dataLoading={insurance.isLoading}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          <div className='d-flex justify-content-end px-3'>
            <Paginations
              itemClass='page-item'
              linkClass='page-link'
              activePage={activePage}
              itemsCountPerPage={insurance?.paginator?.per_page}
              totalItemsCount={insurance?.paginator?.total}
              onChange={setActivePage}
            />
          </div>
        </Card>
      </Container>
    </div>
  )
}

const InsuranceLine = ({
  item,
  onUpdate,
  activeTab,
  onUserClick,
  onDetailsClick,
}) => {
  const deactivate = useFetch({
    action: deactivateInsurance,
    withAdminAccess: true,
    onComplete: (res) => {
      if (res?.success === false) {
        toastr.error(res?.message || 'Error deactivating insurance')
      } else {
        toastr.success(res?.message ?? 'Insurance deactivated successfully.')
        setCancellationDate(null)
        onUpdate()
      }
    },
    onError: (error) => {
      toastr.error(error?.message || 'Error deactivating insurance')
    },
  })
  const deductPayments = useFetch({
    action: deductAllPaymentInsurance,
    withAdminAccess: true,
    onComplete: (res) => {
      if (res?.success === false) {
        toastr.error(res?.message || 'Error deducting all insurance')
      } else {
        toastr.success('Payments deducted successfully.')
        onUpdate()
      }
    },
    onError: (error) => {
      toastr.error(error || 'Error deducting all insurance')
    },
  })
  const { startFetch: syncPlan, isLoading: isPlanSyncing } = useFetch({
    action: syncInsurancePlan,
    withAdminAccess: true,
    onComplete: () => {
      onUpdate()
    },
  })

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: 0,
    currency: item.currency?.code,
  })
  const [confirmDeactivate, setConfirmDeactivate] = useState(false)
  const [showBeneficiaries, setShowBeneficiaries] = useState(null)
  const [showDeductAllPayments, setShowDeductAllPayments] = useState(false)
  const [cancellationDate, setCancellationDate] = useState(null)

  const dateFormat = 'yyyy-MM-dd'

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'canceled':
        return 'danger'
      case 'active':
        return 'success'
      default:
        return 'primary'
    }
  }

  return (
    <>
      <tr>
        <td>
          <button
            className='rp-btn-nostyle text-primary'
            onClick={() => {
              onUserClick(item?.user?.id)
            }}
          >
            <Row className='align-items-center flex-nowrap m-0 p-0'>
              <Avatar
                name={item?.user?.first_name}
                photo={item?.user?.img}
                flag={item?.user?.flag}
              />
              <div
                style={{ fontWeight: '600', marginLeft: 10 }}
                className='d-flex text-primary'
              >
                {item.user.is_flagged ? (
                  <UserFlagging
                    user={item?.user}
                    onCompleteAction={() => {
                      onUpdate()
                    }}
                  />
                ) : null}
                {item?.user?.first_name} {item?.user?.last_name}
              </div>
            </Row>
          </button>
        </td>
        {activeTab !== 4 && (
          <td
            className='text-truncate'
            style={{ maxWidth: 200 }}
            title={item.clients}
          >
            {item.clients}
          </td>
        )}
        <td>{item?.plan?.name}</td>
        <td>{item?.created_at}</td>
        <td>{item?.beneficiaries}</td>
        {activeTab === 2 && <td>{item?.next_billing}</td>}
        <td>{formatter.format(item?.total)}</td>
        <td>
          <BadgeX
            status={getStatusColor(item.status)}
            name={item.status}
            textStatus={getStatusColor(item.status)}
          />
        </td>

        <td>
          <div className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
            {activeTab !== 4 && (
              <>
                <Button
                  size='sm'
                  outline
                  onClick={() => {
                    onDetailsClick(item?.id)
                  }}
                >
                  Details
                </Button>
                {activeTab === 2 ? (
                  <Button
                    size='sm'
                    color='danger'
                    outline
                    onClick={() => {
                      setConfirmDeactivate(true)
                    }}
                  >
                    Deactivate
                  </Button>
                ) : null}
              </>
            )}

            {activeTab === 4 && (
              <Button
                size='sm'
                color='danger'
                outline
                onClick={() => {
                  setShowDeductAllPayments(true)
                }}
              >
                Deduct all payments
              </Button>
            )}
            <Button
              onClick={() => {
                syncPlan({ insurance_id: item.id })
              }}
              outline
              size='sm'
              loading={isPlanSyncing}
            >
              Sync
            </Button>
          </div>
        </td>
      </tr>
      {showDeductAllPayments && (
        <ConfirmationModal
          toggle={() => setShowDeductAllPayments(false)}
          isOpen={showDeductAllPayments}
          title='Deduct all contract payments'
          message='Are you sure you want to deduct all payments for this contract'
          onConfirm={() => {
            deductPayments.startFetch({
              user_id: item?.user?.id,
              insurance_id: item.id,
            })
            setShowDeductAllPayments(false)
          }}
        />
      )}
      {confirmDeactivate && (
        <ConfirmationModal
          toggle={() => {
            setCancellationDate(null)
            setConfirmDeactivate(false)
          }}
          isOpen={confirmDeactivate}
          content={
            <FormGroup className='mb-0'>
              <Label>Deactivation date</Label>
              <CustomDatePicker
                placeholder='Select deactivation date'
                dateFormat={dateFormat}
                value={cancellationDate}
                minDate={new Date()}
                handleOnChange={setCancellationDate}
              />
            </FormGroup>
          }
          title='Deactivate Insurance'
          message='Are you sure you want to deactivate this insurance'
          onConfirm={() => {
            if (cancellationDate) {
              setConfirmDeactivate(false)
              deactivate.startFetch({
                insurance_id: item.id,
                cancellation_date: format(cancellationDate, dateFormat),
              })
            } else {
              toastr.info('Please select a date')
            }
          }}
          caption='Deactivate'
          buttonColor='danger'
        />
      )}

      {!showBeneficiaries ? null : (
        <BeneficiariesModal
          dependents={showBeneficiaries}
          isOpen={!!showBeneficiaries}
          toggle={() => setShowBeneficiaries(null)}
          currency={item?.currency?.code}
          item={item}
        />
      )}
    </>
  )
}

export function BxIcon({ loading, name, className }) {
  return (
    <i
      className={cx(
        `${loading ? 'bx bx-loader-circle bx-spin' : name}`,
        className,
      )}
    />
  )
}
export const DependentsDetails = ({ hide, show, id }) => {
  const isMobile = useResize()

  const getInsuranceDetails = useFetch(
    {
      action: getDependentDetails,
      withAdminAccess: true,
      autoFetch: !!id,
      body: { insurance_id: id },
    },
    [id],
  )

  return (
    <div
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <Menu
        onClose={hide}
        className='bg-white'
        isOpen={show}
        width={isMobile ? '100%' : '30%'}
        right
      >
        {getInsuranceDetails.isLoading ? (
          <Row
            style={{ minHeight: '30rem' }}
            className='d-flex p-0 m-0 justify-content-center align-items-center'
          >
            <Loader />
          </Row>
        ) : (
          <Row className='p-0 m-0'>
            <Col md={12} className='p-0 m-0'>
              <Card>
                <CardBody className='m-0 p-4'>
                  {getInsuranceDetails.data?.map((item, i) => {
                    return (
                      <div key={i}>
                        <StyledH6 min='20px' max='30px'>
                          {item?.title}
                        </StyledH6>
                        {item?.details?.map((v) => {
                          return (
                            <div key={v?.title}>
                              <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                                {v?.title}
                              </h5>
                              <p
                                className='p-2'
                                style={{
                                  border: '1px solid #E7E8F2',
                                  borderRadius: 4,
                                  color: '#777F9E',
                                }}
                              >
                                {v?.value || 'N/A'}
                              </p>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Menu>
    </div>
  )
}
export default InsuranceAdmin
