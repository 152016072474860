import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { StyledH5 } from '../../../../components/Typo'
import { updateNetSuiteRequest } from '../../../../store/ns/actions'
import ContactsList from '../ContactsList'
import { VendorCard } from '../vendor-card'
import Button from '../../../../components/ui/button'

const VendorsSettingTab = ({ loading, onSubmitted }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.ns.preData)
  const [syncContractors, setSyncContractors] = useState(
    data?.contractors_auto_sync,
  )

  const onSubmit = () => {
    dispatch(
      updateNetSuiteRequest({
        contractors_auto_sync: syncContractors,
        isUpdate: true,
      }),
    )
    onSubmitted()
  }

  return (
    <Col className='p-0 m-0'>
      <VendorCard className='p-4 m-0 mb-3'>
        <Row className='p-0 m-0 align-items-center'>
          <div className='mr-3'>
            <Toggle
              check={syncContractors}
              change={() => setSyncContractors((e) => !e)}
            />
          </div>
          <div>
            <StyledH5 className='font-size-16 rp-font-bold'>
              Vendors auto sync
            </StyledH5>
            <p className='text-muted m-0 p-0'>
              Automatically sync all new contractors with the integration.
            </p>
          </div>
        </Row>
      </VendorCard>
      <VendorCard className='p-0 m-0'>
        <ContactsList defaultValues={{}} platform='NetSuite' />
        <Row className='justify-content-end m-0 p-4 mt-4 border-top border-light'>
          <Button loading={loading} disabled={loading} onClick={onSubmit}>
            Save
          </Button>
        </Row>
      </VendorCard>
    </Col>
  )
}

export default VendorsSettingTab
