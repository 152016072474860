import React, { useState } from 'react'
import { Label } from 'reactstrap'

const ControlledInputLabel = ({
  disabled,
  required,
  name,
  onChange,
  value,
  defaultValue,
  ...prop
}) => {
  const [editable, setEditable] = useState(false)
  return editable ? (
    <input
      className='mb-2'
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      onBlur={() => {
        setEditable(false)
      }}
      {...prop}
    />
  ) : (
    <Label className='col-form-label pt-0' onClick={() => setEditable(true)}>
      {value}
      {required && <span className='text-danger font-size-16 ml-1'>*</span>}
    </Label>
  )
}

export default ControlledInputLabel
