import { CalendarBlank, Info, List } from '@phosphor-icons/react'
import React, { Suspense, useEffect } from 'react'
import { Button as RAButton, TooltipTrigger } from 'react-aria-components'
import { withTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import toastr from 'toastr'

import { useFetch } from '../../helpers/hooks'
import { leaveImpersonation } from '../../services/api'
import {
  changeSidebarType,
  loginUserSuccessful,
  showRightSidebarAction,
  toggleLeftMenu,
  updateAdminToken,
  updateShowCalender,
} from '../../store/actions'
import HeaderLogoLink from '../Common/header-logo'
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'
import Button from '../ui/button'
import { TooltipV2 } from './tooltip-v2'
import Loader from '../ui/loader'

const CalendarModal = React.lazy(() => import('../Calendar'))

const Header = ({
  changeSidebarType,
  toggleLeftMenu,
  leftMenu,
  leftSideBarType,
  location,
  history,
}) => {
  const showCalendar = useSelector((state) => state.Layout?.showCalendar)
  const dispatch = useDispatch()

  const user = useSelector((state) => state.Account?.user)
  const originalUser = useSelector((state) => state.Account?.originalUser)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const leave = useFetch({
    action: leaveImpersonation,
    withAdminAccess: true,
    onError: toastr.error,
    onComplete: () => {
      dispatch(loginUserSuccessful(originalUser))
      dispatch(updateAdminToken(user.admin_token))
      history.push('/admin/users')
    },
  })
  useEffect(() => {
    changeSidebarType('default', isMobile)
  }, [location, isMobile, changeSidebarType])

  function tToggle() {
    toggleLeftMenu(!leftMenu)
    if (leftSideBarType === 'default') {
      changeSidebarType('condensed', isMobile)
    } else if (leftSideBarType === 'condensed') {
      changeSidebarType('default', isMobile)
    }
  }

  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header pr-0 position-relative'>
          <div className='tw-flex'>
            <HeaderLogoLink />
          </div>

          {user?.impersonating && (
            <Button
              onClick={() => {
                leave.startFetch({
                  user_id: user.id,
                  token_id: user.user_token_id,
                })
              }}
              disabled={leave.isLoading}
              loading={leave.isLoading}
            >
              Leave impersonation
            </Button>
          )}

          <div className='d-flex h-100'>
            {/* <LanguageDropdown /> */}

            <TooltipTrigger delay={0}>
              <RAButton
                className='tw-hidden tw-p-4 tw-text-secondary-100 hover:tw-bg-surface-30 hover:tw-text-secondary-100 lg:tw-block'
                onPress={() => {
                  dispatch(updateShowCalender(true))
                }}
                type='button'
                aria-label='Toggle Calendar'
              >
                <CalendarBlank size={24} />
              </RAButton>
              <TooltipV2 className='tw-text-xs' placement='bottom'>
                Calendar
              </TooltipV2>
            </TooltipTrigger>

            <TooltipTrigger delay={0}>
              <RAButton
                onPress={() => {
                  window.analytics.track('Clicked Support', {
                    email_id: user?.email,
                  })
                }}
                className='[&>*]:tw-h-full'
              >
                <a
                  className='tw-hidden tw-items-center tw-p-4 tw-text-secondary-100 hover:tw-bg-surface-30 hover:tw-text-secondary-100 lg:tw-flex'
                  href='https://help.remotepass.com'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Help Center'
                >
                  <Info size={24} />
                </a>
              </RAButton>
              <TooltipV2 className='tw-text-xs' placement='bottom'>
                Help Center
              </TooltipV2>
            </TooltipTrigger>

            <NotificationDropdown />

            <button
              type='button'
              onClick={tToggle}
              className='tw-flex tw-items-center tw-p-4 tw-text-secondary-100 hover:tw-bg-surface-30 hover:tw-text-secondary-100 lg:tw-hidden'
              id='vertical-menu-btn'
              aria-label='Open side menu'
            >
              <List size={22} weight='bold' />
            </button>

            <div className='tw-hidden tw-items-center tw-border-l tw-border-surface-30 lg:tw-flex'>
              <ProfileMenu isMobile={isMobile} />
            </div>
          </div>
        </div>
      </header>
      <div className='d-none d-md-block'>
        <Suspense fallback={<Loader className='tw-absolute' />}>
          <CalendarModal
            show={showCalendar}
            hide={() => dispatch(updateShowCalender(false))}
          />
        </Suspense>
      </div>
    </React.Fragment>
  )
}
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftMenu,
    changeSidebarType,
  })(withTranslation()(Header)),
)
