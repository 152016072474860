import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import Button from 'reactstrap/lib/Button'
import Card from 'reactstrap/lib/Card'
import Spinner from 'reactstrap/lib/Spinner'
import Table from 'reactstrap/lib/Table'
import UncontrolledAlert from 'reactstrap/lib/UncontrolledAlert'
import toastr from 'toastr'

import DropzoneInput from '../../../../components/Common/dropzone-input'
import customStyles from '../../../../components/Common/react-select-custom-styles'
import { useFetch } from '../../../../helpers/hooks'
import {
  approvePartnerInvoice as approvePartnerInvoiceAction,
  downloadEorInvoice,
  getPartnerInvoiceEmployees,
  uploadPartnerInvoiceBreakdown,
} from '../../../../services/api'
import openFile from '../../../../utils/file/open'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { ShowTotal } from '../EORInvoices/InvoiceForm'

const currencyFormatter = getCurrencyFormatter()

const UI_STATES = {
  INITIAL: 'INITIAL',
  UPLOADING_BREAKDOWN: 'UPLOADING_BREAKDOWN',
  BREAKDOWN_UPLOADED: 'BREAKDOWN_UPLOADED',
  ERROR: 'ERROR',
}

const breakdownAcceptedFiles = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
}

const headerItems = ['Item', 'Amount', 'Employee Name']

function getNumber(value) {
  const number = Number(String(value).replaceAll(',', ''))
  return number
}

export default function PartnerInvoiceBreakdown() {
  const [uiState, setUiState] = useState(UI_STATES.INITIAL)
  const [items, setItems] = useState([])
  const [invoicePreview, setInvoicePreview] = useState(null)
  const history = useHistory()
  const { state } = history.location

  const { data: invoiceEmployees } = useFetch({
    action: getPartnerInvoiceEmployees,
    withAdminAccess: true,
    autoFetch: !!state?.invoice?.id,
    body: { partner_invoice_id: state.invoice.id },
  })

  const { startFetch: uploadBreakdown, data: breakdownData } = useFetch({
    action: uploadPartnerInvoiceBreakdown,
    withAdminAccess: true,
    onComplete: onUploadComplete,
    onError: () => {
      setUiState(UI_STATES.ERROR)
    },
  })

  const { startFetch: approvePartnerInvoice } = useFetch({
    action: approvePartnerInvoiceAction,
    withAdminAccess: true,
    onError: (e) => {
      toastr.error('An Error Occurred', 'Error')
      // eslint-disable-next-line no-console
      console.log(e)
    },
    onComplete: () => {
      toastr.success('Invoice approved', 'Success')
      history.push('/admin/partner-invoices')
    },
  })

  useFetch({
    action: downloadEorInvoice,
    autoFetch: !!state.invoice.id,
    withAdminAccess: true,
    body: { id: state.invoice.id },
    onComplete: setInvoicePreview,
  })

  function onUploadComplete(data) {
    const newItems = data.map((item) => {
      return {
        desc: item[0],
        amount: item[1],
        name: item[2],
        employee_id: null,
      }
    })

    setUiState(UI_STATES.BREAKDOWN_UPLOADED)
    setItems(newItems)
  }

  const allEmployeesSelected = useMemo(() => {
    if (!invoiceEmployees || items.length <= 0) return false

    return items.every((items) =>
      invoiceEmployees.map((i) => i.id).includes(items.employee_id),
    )
  }, [items, invoiceEmployees])

  const { breakdownTotal, totalDiff } = useMemo(() => {
    if (!breakdownData) return { breakdownTotal: 0, totalDiff: 0 }

    const breakdownTotal = breakdownData.reduce(
      (acc, curr) => acc + getNumber(curr[1]),
      0,
    )

    const totalDiff = Number(state.invoice.total) - breakdownTotal

    return { breakdownTotal, totalDiff }
  }, [breakdownData, state.invoice.total])

  const employeeOptions = useMemo(() => {
    if (!invoiceEmployees) return []

    return invoiceEmployees.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }, [invoiceEmployees])

  function handleBreakdownDropped(acceptedFiles) {
    setUiState(UI_STATES.UPLOADING_BREAKDOWN)

    const formData = new FormData()
    formData.append('file', acceptedFiles[0])

    uploadBreakdown(formData)
  }

  function handleEmployeeChange(index) {
    return function (employee) {
      setItems((prevItems) => {
        const changedItemName = prevItems[index].name
        const newItems = prevItems.map((item) => {
          if (item.name === changedItemName && !item.employee_id) {
            item.employee_id = employee.value
          }

          return { ...item }
        })

        newItems[index].employee_id = employee.value

        return newItems
      })
    }
  }

  function handleApprovePartnerRequest(partnerInvoiceId) {
    if (!allEmployeesSelected) {
      toastr.error('Please select an employee for all items')
      return
    }

    const itemsWithoutName = items.map((item) => {
      delete item.name
      return item
    })

    approvePartnerInvoice({
      partner_invoice_id: partnerInvoiceId,
      items: itemsWithoutName,
    })
  }

  function handlePreviewInvoice(type) {
    return function () {
      switch (type) {
        case 'in-browser': {
          const file = new Blob([invoicePreview], { type: 'application/pdf' })
          const url = URL.createObjectURL(file)
          window.open(url)

          break
        }
        case 'download': {
          openFile(invoicePreview, `partner-invoice-${state.invoice.id}.pdf`)

          break
        }
      }
    }
  }

  if (!state.invoice) {
    history.replace('/admin/partner-invoices')

    return null
  }

  return (
    <div className='page-content'>
      <h2 style={{ marginBottom: '2rem' }}>Partner Invoice Breakdown</h2>

      {uiState !== UI_STATES.ERROR ? null : (
        <UncontrolledAlert color='danger' fade>
          ERROR Occurred while uploading breakdown
        </UncontrolledAlert>
      )}
      {uiState !== UI_STATES.INITIAL && uiState !== UI_STATES.ERROR ? null : (
        <DropzoneInput
          onDropAccepted={handleBreakdownDropped}
          accept={breakdownAcceptedFiles}
          style={{ maxWidth: '50%', marginBottom: '1rem' }}
        >
          <div
            className='d-flex justify-content-center align-items-baseline font-size-16'
            style={{ gap: '1rem' }}
          >
            <i className='fas fa-clipboard-list font-size-24'></i>
            <p className='mb-0 text-dark'>Upload partner invoice breakdown</p>
          </div>
        </DropzoneInput>
      )}
      {uiState !== UI_STATES.UPLOADING_BREAKDOWN ? null : (
        <div
          className='d-flex justify-content-center align-items-center bg-soft-secondary rounded font-size-16 mb-3'
          style={{ minHeight: 80, maxWidth: '50%', gap: '1rem' }}
        >
          <Spinner type='grow' color='secondary' />
          <span>Uploading breakdown</span>
        </div>
      )}

      {!invoicePreview ? null : (
        <div className='mb-4'>
          <h3 className='h5'>Invoice preview</h3>
          <div className='d-flex' style={{ gap: '0.5rem' }}>
            <Button
              outline
              type='button'
              color='info'
              size='sm'
              className='rounded'
              onClick={handlePreviewInvoice('download')}
            >
              Download
            </Button>
            <Button
              outline
              type='button'
              color='info'
              size='sm'
              className='rounded'
              onClick={handlePreviewInvoice('in-browser')}
            >
              Preview in browser
            </Button>
          </div>
        </div>
      )}

      {uiState !== UI_STATES.BREAKDOWN_UPLOADED ? null : (
        <Card className='pb-4 align-items-start' style={{ gap: '1rem' }}>
          <Table className='table-nowrap table-centered'>
            <thead>
              <tr>
                {headerItems.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                <th>Employee</th>
              </tr>
            </thead>
            <tbody>
              {breakdownData.map((line, index) => {
                return (
                  <tr key={index}>
                    {line.map((item, i) => {
                      const value = !isNaN(getNumber(item))
                        ? currencyFormatter.format(getNumber(item))
                        : item

                      return <td key={i}>{value}</td>
                    })}
                    <td
                      className='employee-select'
                      style={{
                        '--col-width': '250px',
                        maxWidth: 'var(--col-width)',
                        width: 'var(--col-width)',
                      }}
                    >
                      <Select
                        options={employeeOptions}
                        styles={customStyles}
                        onChange={handleEmployeeChange(index)}
                        value={
                          employeeOptions.find(
                            (option) =>
                              option.value === items?.[index]?.employee_id,
                          ) ?? null
                        }
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

          <div className='d-flex flex-column mx-3' style={{ gap: '0.25rem' }}>
            <div>
              <ShowTotal
                label='Total:'
                total={currencyFormatter.format(breakdownTotal)}
              />
            </div>

            <div>
              <ShowTotal
                label='Total partner invoice:'
                total={currencyFormatter.format(state.invoice.total_usd)}
              />
            </div>

            <div>
              <ShowTotal
                label='Difference:'
                total={currencyFormatter.format(totalDiff)}
                textColor={
                  totalDiff === 0
                    ? 'text-info'
                    : totalDiff < 0
                      ? 'text-danger'
                      : 'text-success'
                }
              />
            </div>
          </div>

          <Button
            type='button'
            color='primary'
            className='mx-3'
            onClick={() => handleApprovePartnerRequest(state.invoice.id)}
            disabled={approvePartnerInvoice.isLoading || !allEmployeesSelected}
          >
            Approve invoice breakdown
          </Button>
        </Card>
      )}
    </div>
  )
}
