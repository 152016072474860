import { useFetch } from '../../../../../helpers/hooks'
import toastr from 'toastr'
import { getAutomations } from '../../../../../services/api-automations'
import { useState } from 'react'
import { transformParams } from '../tools'

export function useAutomations({ onlyTemplates = false, limit = 10 }) {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({})
  const {
    startFetch,
    paginator,
    data = [],
    isLoading,
  } = useFetch(
    {
      action: getAutomations,
      isOpenApi: true,
      body: {
        params: transformParams({
          page,
          limit,
          onlyTemplates,
          ...filters,
        }),
      },
      onError: (err) => toastr.error(err),
      autoFetch: true,
    },
    [page, onlyTemplates, limit, filters],
  )

  return {
    refetch: startFetch,
    isLoading,
    automations: data,
    filters,
    setFilters,
    pagination: {
      onPageChange: (page) => setPage(page),
      page,
      total: paginator?.totalItemsCount,
      perPage: limit,
    },
  }
}
