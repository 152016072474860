import {
  ArrowsClockwise,
  ArrowSquareOut,
  FlowArrow,
  ListChecks,
  Play,
} from '@phosphor-icons/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Card } from 'reactstrap'

import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { getClientInfo } from '../../../services/api'
import { requestBillFeature } from '../../../services/api-bill-payments'
import { updateUserProfileInfo } from '../../../store/profile/actions'
import upSellIllustration from './up-selling-illustration.png'

export function BillUpSelling() {
  const dispatch = useDispatch()

  const { startFetch: updateProfileInfo, isLoading: gettingCompanyInfo } =
    useFetch({
      action: getClientInfo,
      onComplete: (data) => {
        dispatch(
          updateUserProfileInfo({
            company: { ...data?.company },
          }),
        )
      },
    })

  const { startFetch: requestBill, isLoading: isRequesting } = useFetch({
    action: requestBillFeature,
    onComplete: () => {
      updateProfileInfo()
    },
  })

  const isLoading = isRequesting || gettingCompanyInfo

  return (
    <div className='tw-space-y-4'>
      <div className='tw-relative tw-flex tw-min-h-64 tw-flex-col tw-rounded tw-bg-white tw-bg-gradient-to-r tw-from-yellow-100/10 tw-via-yellow-100/5 tw-to-yellow-100/30 md:tw-flex-row md:tw-items-center md:*:tw-max-w-[50%]'>
        <div className='tw-relative tw-px-6 tw-py-8'>
          <Button
            tag='a'
            href='https://www.remotepass.com/bill-pay'
            target='_blank'
            rel='noreferrer'
            title='Learn more about bill pay'
            className='-tw-mt-2 !tw-px-0 !tw-py-2 !tw-text-text-80'
            color='link'
            iconRight={<ArrowSquareOut size={20} />}
          >
            Learn more
          </Button>

          <h2 className='tw-mb-2 tw-text-3xl tw-font-bold'>
            Streamline your Accounts Payable
          </h2>

          <p className='tw-mb-0 tw-max-w-prose tw-text-sm tw-font-medium tw-text-text-100'>
            Effortlessly track, manage, and pay all your invoices in one secure
            platform. Stay on top of your bills, avoid late fees.
          </p>

          <div className='tw-mt-4 tw-flex tw-gap-2'>
            <Button
              type='button'
              color='default'
              className='!tw-border-secondary-100 !tw-bg-secondary-100 !tw-py-4 !tw-text-white hover:!tw-bg-secondary-110'
              onClick={() => requestBill()}
              disabled={isLoading}
              loading={isLoading}
            >
              Get started
            </Button>
            <Button
              tag='a'
              href='https://youtu.be/xq4RaLuI1s8'
              target='_blank'
              rel='noreferrer'
              color='default'
              outline
              icon={<Play size={20} />}
              className='!tw-border-secondary-100 !tw-py-4 !tw-text-secondary-100 hover:!tw-bg-secondary-100 hover:!tw-text-white'
            >
              Watch demo
            </Button>
          </div>
        </div>

        <img
          src={upSellIllustration}
          className='tw-h-[250px] tw-w-auto tw-max-w-full tw-object-cover max-md:tw-shrink md:tw-absolute md:tw-right-0 md:tw-top-0'
        />
      </div>

      <div className='tw-grid tw-gap-4 md:tw-grid-cols-3'>
        <UpSellingCard
          icon={
            <FlowArrow
              className='tw-mb-4 tw-size-8 tw-text-cyan-100'
              weight='duotone'
            />
          }
          title='Customize Multi-Level Approvals'
          description='Effortlessly assign multi-level approval flows to keep everything on track'
        />
        <UpSellingCard
          icon={
            <ListChecks
              className='tw-mb-4 tw-size-8 tw-text-green-100'
              weight='duotone'
            />
          }
          title='Pay All Your Bills at Once'
          description='Pay all your bills in any currency with a single transaction'
        />
        <UpSellingCard
          icon={
            <ArrowsClockwise
              className='tw-mb-4 tw-size-8 tw-text-systemGold-100'
              weight='duotone'
            />
          }
          title='Automate the Reconciliation'
          description='All your bill details are captured and synchronized in real time with seamless accounting integrations'
        />
      </div>
    </div>
  )
}

function UpSellingCard({ icon, title, description }) {
  return (
    <Card className='!tw-mb-0 tw-p-4 !tw-shadow-sm'>
      {icon}
      <h4 className='tw-mb-0 tw-text-base tw-font-bold'>{title}</h4>
      <p className='tw-mb-0 tw-text-sm tw-text-text-80'>{description}</p>
    </Card>
  )
}
