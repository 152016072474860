import { yupResolver } from '@hookform/resolvers/yup'
import { AvForm } from 'availity-reactstrap-validation'
import classNames from 'classnames'
import React, {
  forwardRef,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { components } from 'react-select'
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import InputFeedback from '../../../components/ui/input-feedback'
import { useFetch } from '../../../helpers/hooks'
import { getLocalCurrencies, updateCountryTaxData } from '../../../services/api'
import {
  getOptionFromList,
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import { Quote } from '../components/Quote'
import Loader from '../../../components/ui/loader'

const ReactQuill = React.lazy(() => import('react-quill'))

const HalfCol = ({ children }) => (
  <div className='tw-basis-full md:tw-flex-1'>{children}</div>
)

const quoteSchema = yup.object().shape({
  country_code: yup.string().required('Country is required'),
  amount: yup
    .number('Yearly salary must be a number')
    .typeError('Yearly salary must be a number')
    .min(1, 'Yearly salary must be greater than 0')
    .required('Yearly salary is required'),
  currency_id: yup.number().required(),
})

const FullTimeForm = forwardRef(function FullTimeForm(
  { edit, onComplete, quote, quoteDetails, readOnly },
  ref,
) {
  const eorCountries = useSelector(
    (state) => state?.Layout?.staticData?.eor_countries,
  )

  const [show, setShow] = useState(false)
  const [updateQuote, setUpdateQuote] = useState(false)
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(quoteSchema),
    defaultValues: quote || { currency_id: 1 },
  })

  const onSubmit = (v) => {
    onComplete({ ...v, currency_code: customerCurrencyCode })
  }

  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })
  const currencyOptions = currencies?.data?.map((c) =>
    mapCurrencyToOption(c, 'id'),
  )

  useEffect(() => {
    // When I select a country, I want to set the currency
    // to the default currency of the country

    const currencyItem = getOptionFromList(
      currencies?.data,
      selectedCountry?.currency,
      'code',
    )

    if (currencyItem?.id) {
      setValue('currency_id', currencyItem.id)
    }
  }, [watch('country_code')])

  const selectedCountry = useMemo(
    () =>
      eorCountries?.length > 0
        ? getOptionFromList(eorCountries, watch('country_code'), 'iso2')
        : null,
    [watch('country_code'), eorCountries],
  )

  const customerCurrencyCode = useMemo(
    () =>
      currencies.data?.length > 0
        ? getOptionFromList(
            currencies?.data,
            watch('currency_id') ?? quote?.currency_id,
            'id',
          )?.code
        : null,
    [watch('currency_id'), currencies],
  )

  const showEditButton = edit && watch('country_code')

  return (
    <>
      {showEditButton && show ? (
        <TaxesEdit
          salaryAmountInCustomerCurrency={watch('amount')}
          customerCurrencyCode={customerCurrencyCode}
          countryCode={watch('country_code')}
          toggle={() => setShow(false)}
          show={show}
          onSubmitCompleted={() => setUpdateQuote(!updateQuote)}
        />
      ) : null}
      <div>
        <AvForm
          ref={ref}
          autoComplete='off'
          className='form-horizontal'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='d-flex flex-wrap' style={{ gap: '1.5rem' }}>
            <HalfCol>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  htmlFor='country_code'
                  className='col-form-label p-0 m-0 mb-2 font-size-14 rp-font-semibold'
                >
                  Country
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.country_code}
                  control={control}
                  name='country_code'
                  isDisabled={readOnly}
                  options={(eorCountries ?? []).map((c) =>
                    mapCountryToOption(c, 'iso2'),
                  )}
                />
              </FormGroup>
            </HalfCol>
            <HalfCol>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  className='col-form-label p-0 m-0 mb-2 font-size-14 rp-font-semibold'
                  htmlFor='amount'
                >
                  Yearly salary
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>

                <InputGroup style={{ zIndex: 6 }}>
                  <ControlledCurrencyInput
                    control={control}
                    name='amount'
                    error={errors.amount}
                    isDisabled={readOnly}
                    hideError
                    className='rounded-right-0'
                  />
                  <InputGroupAddon
                    style={{ width: '125px' }}
                    addonType='append'
                  >
                    <InputGroupText
                      className={classNames({
                        'p-0': true,
                        'border-danger': !!errors.currency_id,
                      })}
                      style={{ width: '100%', border: 0 }}
                    >
                      <ControlledSelect
                        error={errors.currency_id}
                        control={control}
                        name='currency_id'
                        isDisabled={readOnly}
                        options={currencyOptions}
                        classNamePrefix='RS-Addon'
                        customComponents={{
                          Option: customOption,
                          Value: customSingleValue,
                          SingleValue: customSingleValue,
                        }}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>

                {!errors.amount ? null : (
                  <InputFeedback className='tw-mt-1'>
                    {getInputErrorMessage(errors.amount)}
                  </InputFeedback>
                )}
              </FormGroup>
            </HalfCol>
            {!!watch('country_code') && !!customerCurrencyCode && !!quote && (
              <div style={{ flexBasis: '100%' }}>
                <Quote
                  quoteResults={(res) =>
                    quoteDetails ? quoteDetails(res) : null
                  }
                  updateQuote={updateQuote}
                  selectedCountry={selectedCountry}
                  localCountryCode={watch('country_code')}
                  salaryAmountInCustomerCurrency={watch('amount')}
                  noOfHires={1}
                  customerCurrencyCode={customerCurrencyCode}
                />
              </div>
            )}
          </div>
        </AvForm>
      </div>
    </>
  )
})

const { Option } = components
export const customOption = (props) => (
  <Option {...props}>
    <div className='input-select'>
      <div className='input-select__single-value d-flex flex-row align-items-center'>
        {props.data.flag && (
          <img
            src={props.data.flag}
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '0.5rem',
              marginRight: 10,
              objectFit: 'cover',
            }}
            alt={props.data.code}
          />
        )}
        {props.data.icon}
        <span className='font-weight-light text-dark rp-font-bold'>
          {props.data.code}
        </span>
        <span
          className='font-weight-light text-secondary ml-2'
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.data.name}
        </span>
      </div>
    </div>
  </Option>
)

export const customSingleValue = (props) => (
  <div className='input-select'>
    <div className='input-select__single-value d-flex flex-row align-items-center'>
      {props.data.flag && (
        <img
          src={props.data.flag}
          style={{
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
            marginRight: 10,
            objectFit: 'cover',
          }}
          alt={props.data.code}
        />
      )}
      {props.data.icon}
      <span
        className='font-weight-light text-secondary'
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {props.data.code}
      </span>
    </div>
  </div>
)

export const TaxesEdit = ({
  show,
  toggle,
  countryCode,
  onSubmitCompleted,
  config,
}) => {
  const TAX_TYPES = [
    {
      label: 'Absolute',
      value: 'absolute',
      description: 'Tax type is an absolute value',
    },
    {
      label: 'Tier',
      value: 'tier',
      description: 'Tax type is in tiers',
    },
    {
      label: 'Percentage',
      value: 'percentage',
      description: 'Tax type is a percentage of total salary',
    },
  ]

  const [notes, setNotes] = useState(config?.taxes?.employerTaxesNotes)

  const schema = yup.object({
    indirectTaxPercentage: yup
      .number('Indirect tax must be a number')
      .typeError('Indirect tax must be a number')
      .min(0, 'Indirect tax must be at least 0')
      .required('Indirect tax is required'),
    employerTaxes: yup.array().of(
      yup.object({
        name: yup.string().required('Name is required'),
        value: yup
          .number('Value must be a number')
          .typeError('Value must be a number')
          .when('type', ([_type], _schema) => {
            if (!_type || _type === 'tier') {
              // value doesn't exist for 'tier' tax type, or when tax type hasn't been selected
              return _schema.notRequired()
            } else {
              return _schema
                .min(0, 'Value must be at least 0')
                .required('Value is required')
            }
          }),
        taxNo: yup
          .number('Must be a number')
          .typeError('Must be a number')
          .required('Tax number is required'),
        type: yup
          .string()
          .oneOf(['percentage', 'tier', 'absolute'], 'Type is required')
          .required('Type is required'),
        tiers: yup.array().when(['type'], ([_type], tierSchema, tierValue) => {
          if (_type === 'tier') {
            return yup
              .array()
              .required('At least two tiers are required')
              .min(2, 'At least two tiers are required')
              .of(
                yup.object({
                  to: yup.string().when('value', ([_value]) => {
                    // The last 'to' field is not required
                    if (
                      tierValue.value[tierValue.value?.length - 1]?.value ===
                      _value
                    ) {
                      return yup.string().notRequired()
                    } else {
                      return yup.string().required('To is required')
                    }
                  }),
                  from: yup.string().required('From is required'),
                  value: yup.string().required('Value is required'),
                }),
              )
          } else {
            return yup.array().notRequired()
          }
        }),
        minTaxableBasis: yup
          .number('Min taxable basis must be a number')
          .typeError('Min taxable basis must be a number')
          .when(
            ['type', 'maxTaxableBasis'],
            (
              [_type, _maxTaxableBasis],
              minTaxableBasisSchema,
              minTaxableBasisValue,
            ) => {
              if (_type === 'percentage' && _maxTaxableBasis) {
                return minTaxableBasisSchema.lessThan(
                  _maxTaxableBasis,
                  'Min taxable basis must be less than max taxable basis',
                )
              } else if (minTaxableBasisValue === '') {
                // only validate if present
                return minTaxableBasisSchema.required(
                  'Min taxable basis is required',
                )
              } else {
                return minTaxableBasisSchema.notRequired()
              }
            },
          ),
        maxTaxableBasis: yup
          .number('Max taxable basis must be a number')
          .typeError('Max taxable basis must be a number'),
        contributionBasisCeiling: yup
          .number('Contribution basis ceiling must be a number')
          .typeError('Contribution basis ceiling must be a number'),
        contributionCeiling: yup
          .number('Contribution ceiling must be a number')
          .typeError('Contribution ceiling must be a number'),
        contributionBasisPercentage: yup
          .number('Percentage must be a number')
          .typeError('Percentage must be a number')
          .min(1)
          .max(100),
      }),
    ),
  })

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      indirectTaxPercentage: config?.taxes?.indirectTaxPercentage,
      employerTaxes: config?.taxes?.employerTaxes || [],
      employerTaxesNotes: config?.taxes?.employerTaxesNotes,
    },
  })

  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')

  const updateData = useFetch({
    action: updateCountryTaxData,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          typeof err === 'string'
            ? data
            : data?.message || 'Something went wrong updating tax data',
        )
      } else {
        toastr.success('Updated successfully')
        onSubmitCompleted()
      }
    },
    autoFetch: false,
    onError: (err) => {
      toastr.error(
        typeof err === 'string'
          ? err
          : err?.message || 'Something went wrong updating tax data',
      )
    },
  })

  const submitUpdate = () => {
    updateData.startFetch({
      region_type: config?.region_type,
      region_id: config?.region_id,
      employerTaxes: watch().employerTaxes.filter(Boolean),
      indirectTaxPercentage: watch().indirectTaxPercentage,
      countryIso2: countryCode,
      ...(notes && notes !== ' ' && { additionalNotes: notes }),
    })
  }

  function findFirstErrorMessage(errors) {
    for (const key in errors) {
      if (typeof errors[key] === 'string') {
        return errors[key]
      } else if (typeof errors[key] === 'object') {
        return findFirstErrorMessage(errors[key])
      }
    }
  }

  const removeTaxData = (i) => {
    const newEmployerTaxes = [...watch('employerTaxes')]
    newEmployerTaxes.splice(i, 1)
    setValue(`employerTaxes`, newEmployerTaxes.filter(Boolean))
  }

  const appendTaxFormData = () => {
    const employerTaxes = watch('employerTaxes')
    const newTax = {
      name: '',
      type: '',
      value: null,
      taxNo: employerTaxes?.length + 1,
    }
    setValue(`employerTaxes.${employerTaxes?.length + 1}`, newTax)
  }

  const removeTierData = (taxIndex, tierIndex) => {
    const newTierTaxes = [...watch(`employerTaxes.${taxIndex}.tiers`)]
    newTierTaxes.splice(tierIndex, 1)
    setValue(`employerTaxes.${taxIndex}.tiers`, newTierTaxes.filter(Boolean))
  }

  const addTierData = (taxIndex) => {
    const newTier = {
      from: '',
      value: '',
    }
    if (
      watch(`employerTaxes.${taxIndex}.tiers`) &&
      Array.isArray(watch(`employerTaxes.${taxIndex}.tiers`))
    ) {
      // to field is optional in the last tier.
      setValue(
        `employerTaxes.${taxIndex}.tiers.${
          watch(`employerTaxes.${taxIndex}.tiers`).length
        }`,
        newTier,
      )
    } else {
      setValue(`employerTaxes.${taxIndex}.tiers`, [newTier])
    }
  }

  // For all optional and mutually inclusive fields
  const handleTaxOptionChange = (taxIndex, isChecked, taxOptionKey) => {
    const tax = watch('employerTaxes')[taxIndex]
    if (isChecked) {
      tax[taxOptionKey] = ''
    } else {
      delete tax[taxOptionKey]
    }
    setValue(`employerTaxes.${taxIndex}`, { ...tax })
  }

  // For mutually exclusive max, ceiling and taxCeiling.
  const handlePercentageExtraOptions = (taxIndex, isChecked, option) => {
    const tax = { ...watch(`employerTaxes.${taxIndex}`) }
    delete tax.contributionCeiling
    delete tax.contributionBasisCeiling
    delete tax.maxTaxableBasis
    if (isChecked) {
      switch (option) {
        case 'max':
          tax.maxTaxableBasis = ''
          break
        case 'taxceiling':
          tax.contributionCeiling = ''
          break
        case 'ceiling':
          tax.contributionBasisCeiling = ''
          break
      }
    }
    setValue(`employerTaxes.${taxIndex}`, tax)
  }

  const editTaxesDataId = 'editTaxesDataId'

  return (
    <Modal
      countryIso2={3}
      isOpen={show}
      toggle={toggle}
      size='lg'
      scrollable
      unmountOnClose
    >
      <ModalHeader toggle={toggle}>Edit tax data</ModalHeader>
      <ModalBody className='!tw-p-0'>
        <form
          id={editTaxesDataId}
          onSubmit={handleSubmit(submitUpdate)}
          className='tw-p-6'
        >
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Label for='indirect-tax'>
                  Indirect tax (%) <span className='tw-text-red'>*</span>
                </Label>
                <InputGroup>
                  <ControlledInput
                    id='indirect-tax'
                    control={control}
                    placeholder='Indirect tax (%)'
                    error={errors?.indirectTaxPercentage}
                    name='indirectTaxPercentage'
                    wrapperStyles={{ flexGrow: 1 }}
                  />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          {watch()?.employerTaxes?.map((t, i) => (
            <>
              <Row key={`e-tax-${i}`}>
                <Col key={`e-tax-col-${i}`}>
                  <FormGroup>
                    <Label for={`tax-name-${i}`} className='font-size-14'>
                      Tax name <span className='tw-text-red'>*</span>
                    </Label>
                    <ControlledInput
                      type='text'
                      className='form-control'
                      id={`tax-name-${i}`}
                      control={control}
                      name={`employerTaxes.${i}.name`}
                    />
                  </FormGroup>

                  {/* Value shouldn't be changed. For Pre-existing value? */}
                  <FormGroup>
                    <Label>
                      Select tax type <span className='tw-text-red'>*</span>
                    </Label>
                    <ControlledSelect
                      name={`employerTaxes.${i}.type`}
                      containerClassName='btn text-primary'
                      control={control}
                      error={errors?.employerTaxes?.[i]?.type}
                      title='Tax type'
                      options={TAX_TYPES}
                    />
                  </FormGroup>

                  {/* Optional field for all tax types */}
                  {t?.contributionBasisPercentage !== undefined && (
                    <FormGroup>
                      <Label htmlFor={`contribution-basis-percentage-${i}`}>
                        Contribution Basis Percentage:
                      </Label>
                      <InputGroup>
                        <ControlledInput
                          name={`employerTaxes.${i}.contributionBasisPercentage`}
                          control={control}
                          wrapperStyles={{ flexGrow: 1 }}
                          id={`contribution-basis-percentage-${i}`}
                          placeholder='Contribution Basis Percentage Value'
                        />
                        <InputGroupText>%</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  )}

                  {t?.type === 'percentage' && (
                    <>
                      <FormGroup>
                        <Label htmlFor={`percentage-value-${i}`}>
                          Percentage value:
                        </Label>
                        <InputGroup>
                          <ControlledInput
                            name={`employerTaxes.${i}.value`}
                            id={`percentage-value-${i}`}
                            placeholder='Percentage value'
                            wrapperStyles={{ flexGrow: 1 }}
                            control={control}
                          />
                          <InputGroupText>%</InputGroupText>
                        </InputGroup>
                      </FormGroup>

                      {/* Optional field */}
                      {t?.contributionBasisCeiling !== undefined && (
                        <FormGroup>
                          <Label htmlFor={`contribution-basis-ceiling${i}`}>
                            Contribution Basis Ceiling:
                          </Label>
                          <ControlledInput
                            name={`employerTaxes.${i}.contributionBasisCeiling`}
                            id={`contribution-basis-ceiling${i}`}
                            control={control}
                            error={
                              errors?.employerTaxes?.[i]
                                ?.contributionBasisCeiling
                            }
                            placeholder='Contribution Basis Ceiling'
                          />
                        </FormGroup>
                      )}

                      {/* Optional field */}
                      {t?.minTaxableBasis !== undefined && (
                        <FormGroup>
                          <Label htmlFor={`min-taxable-basis-${i}`}>
                            Min. Taxable Basis:
                          </Label>
                          <ControlledInput
                            name={`employerTaxes.${i}.minTaxableBasis`}
                            id={`min-taxable-basis-${i}`}
                            placeholder='Min. Taxable Basis'
                            error={errors?.employerTaxes?.[i]?.minTaxableBasis}
                            control={control}
                          />
                        </FormGroup>
                      )}
                      {/* Optional field */}
                      {t?.maxTaxableBasis !== undefined && (
                        <FormGroup>
                          <Label
                            for={`max-taxable-basis-${i}`}
                            className='font-size-14'
                          >
                            Max. Taxable Basis:
                          </Label>
                          <ControlledInput
                            type='text'
                            className='form-control'
                            name={`employerTaxes.${i}.maxTaxableBasis`}
                            id={`max-taxable-basis-${i}`}
                            error={errors?.employerTaxes?.[i]?.maxTaxableBasis}
                            control={control}
                            placeholder='Max. Taxable Basis'
                          />
                        </FormGroup>
                      )}
                      {t?.contributionCeiling !== undefined && (
                        <FormGroup>
                          <Label
                            for={`contribution-ceiling-${i}`}
                            className='font-size-14'
                          >
                            Contribution Ceiling:
                          </Label>
                          <ControlledInput
                            type='text'
                            className='form-control'
                            placeholder='Contribution Ceiling'
                            id={`contribution-ceiling-${i}`}
                            name={`employerTaxes.${i}.contributionCeiling`}
                            error={
                              errors?.employerTaxes?.[i]?.contributionCeiling
                            }
                            control={control}
                          />
                        </FormGroup>
                      )}
                      <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-start'>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`contribution-basis-percentage-${i}`}
                            name={`employerTaxes.${i}.contributionBasisPercentage`}
                            onChange={(e) => {
                              handleTaxOptionChange(
                                i,
                                e.target.checked,
                                'contributionBasisPercentage',
                              )
                            }}
                            checked={
                              t?.contributionBasisPercentage !== undefined
                            }
                          />
                          <Label
                            check
                            htmlFor={`contribution-basis-percentage-${i}`}
                          >
                            With Contribution Basis Percentage
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`min-${i}`}
                            name={`min-${i}`}
                            onChange={(e) => {
                              handleTaxOptionChange(
                                i,
                                e.target.checked,
                                'minTaxableBasis',
                              )
                            }}
                            checked={t?.minTaxableBasis !== undefined}
                          />
                          <Label check htmlFor={`min-${i}`}>
                            With min
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`max-${i}`}
                            name={`max-${i}`}
                            onChange={(e) => {
                              handlePercentageExtraOptions(
                                i,
                                e.target.checked,
                                'max',
                              )
                            }}
                            checked={t?.maxTaxableBasis !== undefined}
                          />
                          <Label check htmlFor={`max-${i}`}>
                            With max
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            id={`ceiling-${i}`}
                            name={`employerTaxes.${i}.ceiling`}
                            className='cursor-pointer'
                            onChange={(e) => {
                              handlePercentageExtraOptions(
                                i,
                                e.target.checked,
                                'ceiling',
                              )
                            }}
                            checked={t?.contributionBasisCeiling !== undefined}
                          />
                          <Label check htmlFor={`ceiling-${i}`}>
                            With ceiling
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`tax-ceiling-${i}`}
                            name={`employerTaxes.${i}.taxceiling`}
                            onChange={(e) => {
                              handlePercentageExtraOptions(
                                i,
                                e.target.checked,
                                'taxceiling',
                              )
                            }}
                            checked={t?.contributionCeiling !== undefined}
                          />
                          <Label check htmlFor={`tax-ceiling-${i}`}>
                            With Tax Ceiling
                          </Label>
                        </FormGroup>
                      </Row>
                    </>
                  )}

                  {t?.type === 'absolute' && (
                    <>
                      <FormGroup>
                        <Label htmlFor={`absolute-value-${i}`}>
                          Absolute value:
                        </Label>
                        <ControlledInput
                          name={`employerTaxes.${i}.value`}
                          error={errors?.employerTaxes?.[i]?.value}
                          control={control}
                          id={`absolute-value-${i}`}
                          placeholder='Absolute value'
                        />
                      </FormGroup>
                      {/* Optional field */}
                      {t?.minTaxableBasis !== undefined && (
                        <FormGroup>
                          <Label htmlFor={`min-taxable-basis-${i}`}>
                            Min. Taxable Basis:
                          </Label>
                          <ControlledInput
                            name={`employerTaxes.${i}.minTaxableBasis`}
                            id={`min-taxable-basis-${i}`}
                            error={errors?.employerTaxes?.[i]?.minTaxableBasis}
                            control={control}
                            placeholder='Min. Taxable Basis'
                          />
                        </FormGroup>
                      )}
                      {/* Optional field */}
                      {t?.maxTaxableBasis !== undefined && (
                        <FormGroup>
                          <Label
                            for={`max-taxable-basis-${i}`}
                            className='font-size-14'
                          >
                            Max. Taxable Basis
                          </Label>
                          <ControlledInput
                            type='text'
                            className='form-control'
                            name={`employerTaxes.${i}.maxTaxableBasis`}
                            error={errors?.employerTaxes?.[i]?.maxTaxableBasis}
                            control={control}
                            id={`max-taxable-basis-${i}`}
                            placeholder='Min. Taxable Basis'
                          />
                        </FormGroup>
                      )}
                      <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-start'>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`contribution-basis-percentage-${i}`}
                            name={`contribution-basis-percentage-${i}`}
                            onChange={(e) => {
                              handleTaxOptionChange(
                                i,
                                e.target.checked,
                                'contributionBasisPercentage',
                              )
                            }}
                            checked={
                              t?.contributionBasisPercentage !== undefined
                            }
                          />
                          <Label
                            check
                            htmlFor={`contribution-basis-percentage-${i}`}
                          >
                            With Contribution Basis Percentage
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`min-${i}`}
                            name={`min-${i}`}
                            onChange={(e) => {
                              handleTaxOptionChange(
                                i,
                                e.target.checked,
                                'minTaxableBasis',
                              )
                            }}
                            checked={t?.minTaxableBasis !== undefined}
                          />
                          <Label check htmlFor={`min-${i}`}>
                            With min
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`max-${i}`}
                            name={`max-${i}`}
                            onChange={(e) => {
                              handlePercentageExtraOptions(
                                i,
                                e.target.checked,
                                'max',
                              )
                            }}
                            checked={t?.maxTaxableBasis !== undefined}
                          />
                          <Label check htmlFor={`max-${i}`}>
                            With max
                          </Label>
                        </FormGroup>
                      </Row>
                    </>
                  )}

                  {t?.type === 'tier' && (
                    <>
                      {t?.tiers?.map((ttt, ii) => (
                        <>
                          <FormGroup key={`tier-${ii}`}>
                            <div className='tw-flex'>
                              <label>
                                From <span className='tw-text-red'>*</span>
                                <ControlledInput
                                  control={control}
                                  error={
                                    errors?.employerTaxes?.[i]?.tiers?.[ii]
                                      ?.from
                                  }
                                  wrapperStyles={{ flexGrow: 1 }}
                                  name={`employerTaxes.${i}.tiers.${ii}.from`}
                                  placeholder='From'
                                />
                              </label>
                              <label>
                                To
                                <ControlledInput
                                  control={control}
                                  error={
                                    errors?.employerTaxes?.[i]?.tiers?.[ii]?.to
                                  }
                                  wrapperStyles={{ flexGrow: 1 }}
                                  name={`employerTaxes.${i}.tiers.${ii}.to`}
                                  placeholder='To'
                                />
                              </label>
                              <label className='tw-grow'>
                                Value <span className='tw-text-red'>*</span>
                                <InputGroup>
                                  <ControlledInput
                                    control={control}
                                    className={classNames({
                                      'tw-rounded tw-border !tw-border-red':
                                        errors?.employerTaxes?.[i]?.tiers?.[ii]
                                          ?.value,
                                    })}
                                    placeholder='Value'
                                    name={`employerTaxes.${i}.tiers.${ii}.value`}
                                    wrapperStyles={{ flexGrow: 1 }}
                                    showError={false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                                <span className='tw-ml-2 tw-mt-1 tw-block tw-text-xs tw-text-red'>
                                  {
                                    errors?.employerTaxes?.[i]?.tiers?.[ii]
                                      ?.value?.message
                                  }
                                </span>
                              </label>
                            </div>
                          </FormGroup>
                          <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-end'>
                            <a
                              className='text-danger font-size-14'
                              onClick={() => removeTierData(i, ii)}
                            >
                              Remove tier
                            </a>
                          </Row>
                        </>
                      ))}
                      <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-between'>
                        <FormGroup check inline>
                          <Input
                            type='checkbox'
                            className='cursor-pointer'
                            id={`contribution-basis-percentage-${i}`}
                            name={`contribution-basis-percentage-${i}`}
                            onChange={(e) => {
                              handleTaxOptionChange(
                                i,
                                e.target.checked,
                                'contributionBasisPercentage',
                              )
                            }}
                            checked={
                              t?.contributionBasisPercentage !== undefined
                            }
                          />
                          <Label
                            check
                            htmlFor={`contribution-basis-percentage-${i}`}
                          >
                            With Contribution Basis Percentage
                          </Label>
                        </FormGroup>
                        <a
                          className='font-size-14'
                          onClick={() => addTierData(i)}
                        >
                          Add tier
                        </a>
                      </Row>
                    </>
                  )}

                  <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-end'>
                    <a
                      className='text-danger font-size-14'
                      onClick={() => removeTaxData(i)}
                    >
                      Remove
                    </a>
                  </Row>
                </Col>
              </Row>
              <hr />
            </>
          ))}
          <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-end'>
            <a
              className='text-primary font-size-14'
              onClick={appendTaxFormData}
            >
              Add tax
            </a>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <FormGroup id='scope-contract-info'>
                <Label>Notes</Label>
                <Suspense fallback={<Loader />}>
                  <ReactQuill
                    formats={[
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'indent',
                      'image',
                      'video',
                    ]}
                    id='scopeEdit'
                    modules={{
                      toolbar: null,
                    }}
                    value={notes ?? ''}
                    onChange={(content, delta, source, editor) => {
                      setNotes(editor.getHTML())
                    }}
                    defaultValue=''
                    placeholder='Additional text'
                    style={{ whiteSpace: 'pre-line' }}
                  />
                </Suspense>
              </FormGroup>
              <FormText>
                You can use underlines, italics, and bold shortcut commands to
                edit text. Ctrl\⌘ + U, etc.
              </FormText>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-start'>
          <Button form={editTaxesDataId} color='primary' type='submit'>
            Update
          </Button>
          <span className='tw-ml-4 tw-text-sm tw-text-red'>
            {findFirstErrorMessage(errors?.employerTaxes)}
          </span>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default FullTimeForm
