import {
  ArrowRight,
  ListBullets,
  ListChecks,
  Warning,
  X,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import Button from '../../../../components/ui/button'
import IconButton from '../../../../components/ui/icon-button'
import InputFeedback from '../../../../components/ui/input-feedback'
import Loader from '../../../../components/ui/loader'
import { RadioButtonsV2 } from '../../../../components/ui/radio-button/radio-buttons-v2'
import { useFetch } from '../../../../helpers/hooks'
import {
  checkCOREligibilityStatus,
  getCOREligibilityQuestions,
} from '../../../../services/api'

// Constants for step numbers
const STEP_INTRO = 1
const STEP_QUESTIONS = 2
const STEP_LOADING = 3
const STEP_ELIGIBLE = 4
const STEP_REJECTED = 5
const STEP_ERROR = 7

const stepMinHeight = '460px'

function IntroStep({ onNext }) {
  return (
    <div
      className='d-flex flex-column align-items-center justify-content-center p-4'
      style={{ minHeight: stepMinHeight }}
    >
      <ListBullets size={138} color='var(--primary)' />
      <div className='font-size-24'>Check your eligibility</div>
      <div className='font-size-14 text-text-60 mt-2'>
        Two steps to know if the contract is eligible for this program
      </div>
      <Button onClick={onNext} className='mt-4'>
        Start
      </Button>
    </div>
  )
}

function Question({
  onNext,
  number,
  question,
  description,
  options,
  onBack,
  selectedOption,
  setSelectedOption,
}) {
  const [showError, setShowError] = useState()
  const handleOnNext = () => {
    setShowError(null)
    if (selectedOption) {
      onNext(selectedOption)
    } else {
      setShowError('You must choose an option')
    }
  }
  const handleOnBack = () => {
    setShowError(null)
    onBack()
  }
  return (
    <>
      <div className='d-flex flex-column border-bottom p-4'>
        <div className='font-size-14 text-text-60'>{number}/2</div>
        <div
          className='font-size-20 font-weight-medium my-1'
          style={{ lineHeight: '28px' }}
        >
          {question}
        </div>
        {!description ? null : (
          <div
            className='font-size-14 text-text-70'
            style={{ lineHeight: '22px' }}
          >
            * {description}
          </div>
        )}
      </div>
      <div className='tw-p-6'>
        <RadioButtonsV2
          value={selectedOption}
          onChange={(event) => {
            setShowError(null)
            setSelectedOption(event?.target?.value)
          }}
          options={options}
        />
        {!showError ? null : (
          <InputFeedback className='tw-mt-2'>{showError}</InputFeedback>
        )}
      </div>
      <div className='p-4 d-flex justify-content-between mt-auto'>
        {!onBack ? null : (
          <Button
            outline
            color='surface-30'
            textClassName='text-text-black'
            onClick={handleOnBack}
          >
            Back
          </Button>
        )}
        <div />
        <Button onClick={handleOnNext} disabled={!selectedOption}>
          Next
        </Button>
      </div>
    </>
  )
}

function QuestionsStep({ onNext, onBack }) {
  const [questions, setQuestions] = useState()
  const [currentQ, setCurrentQ] = useState(1)

  const { isLoading } = useFetch({
    action: getCOREligibilityQuestions,
    autoFetch: true,
    onComplete: (data) => {
      setQuestions(
        data?.map((question) => ({ ...question, selectedOption: null })),
      )
    },
  })

  return (
    <>
      {isLoading || !questions ? (
        <LoadingStep />
      ) : (
        <Question
          selectedOption={questions?.[currentQ - 1].selectedOption}
          setSelectedOption={(selectedOption) => {
            const updatedQuestions = [...questions]
            updatedQuestions[currentQ - 1].selectedOption = selectedOption
            setQuestions(updatedQuestions)
          }}
          onBack={
            currentQ === 1
              ? null
              : () => {
                  if (currentQ === 1) {
                    onBack()
                  } else {
                    setCurrentQ(currentQ - 1)
                  }
                }
          }
          onNext={() => {
            if (currentQ < questions?.length) {
              setCurrentQ(currentQ + 1)
            } else {
              onNext(questions)
            }
          }}
          options={questions?.[currentQ - 1]?.options?.map((option) => ({
            value: option,
            label: option,
          }))}
          question={questions?.[currentQ - 1]?.title}
          description={questions?.[currentQ - 1]?.description}
          number={currentQ}
        />
      )}
    </>
  )
}

function LoadingStep() {
  return (
    <Loader
      className='p-5 w-100'
      style={{ minHeight: stepMinHeight }}
      size='lg'
    />
  )
}

function StatusStep({
  onNext,
  title,
  icon,
  message,
  buttonText,
  buttonColor,
  buttonIcon,
  buttonOutline,
}) {
  return (
    <div
      className='d-flex flex-column align-items-center justify-content-center p-4'
      style={{ minHeight: stepMinHeight }}
    >
      {icon}
      <div className='mx-auto text-center' style={{ maxWidth: '264px' }}>
        <div className='font-size-24'>{title}</div>
        {!message ? null : (
          <div className='font-size-14 text-text-60 mt-2'>{message}</div>
        )}
      </div>
      <Button
        onClick={onNext}
        outline={buttonOutline}
        color={buttonColor}
        iconRight={buttonIcon}
        className='mt-4'
      >
        {buttonText}
      </Button>
    </div>
  )
}

function PremiumEligibilityModal({ isOpen, toggle, onEligible }) {
  const [step, setStep] = useState(STEP_INTRO)

  const { startFetch: fetchEligibility } = useFetch({
    action: checkCOREligibilityStatus,
    onComplete: (data, body) => {
      if (data.is_eligible) {
        setStep(STEP_ELIGIBLE)
        onEligible(body)
      } else {
        setStep(STEP_REJECTED)
      }
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err)
      setStep(STEP_ERROR)
    },
  })

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1)
  }

  const handleBackStep = () => {
    setStep(STEP_INTRO)
  }

  const checkIfEligible = (questions) => {
    const formattedQuestions = questions.map(
      ({ id, title, selectedOption: response }) => ({
        question_id: id,
        title,
        response,
      }),
    )

    fetchEligibility(formattedQuestions)
    setStep(STEP_LOADING)
  }

  let currentStep
  switch (step) {
    case STEP_INTRO:
      currentStep = <IntroStep onNext={handleNextStep} />
      break
    case STEP_QUESTIONS:
      currentStep = (
        <QuestionsStep
          onNext={(questions) => checkIfEligible(questions)}
          onBack={handleBackStep}
        />
      )
      break
    case STEP_LOADING:
      currentStep = <LoadingStep />
      break
    case STEP_ELIGIBLE:
      currentStep = (
        <StatusStep
          onNext={() => toggle(true)}
          title='This contract is eligible!'
          icon={<ListChecks size={138} color='var(--primary)' />}
          buttonIcon={<ArrowRight size={20} />}
          // message='Short message here'
          buttonText='Proceed'
          buttonColor='primary'
        />
      )
      break
    case STEP_REJECTED:
      currentStep = (
        <StatusStep
          onNext={() => toggle(false)}
          title='This contract is not eligible for RemotePass+!'
          icon={<X size={138} color='var(--primary)' />}
          message='You may retake the assessment and review your answers'
          buttonText='Close'
          buttonColor='secondary'
          buttonOutline
        />
      )
      break
    case STEP_ERROR:
      currentStep = (
        <StatusStep
          onNext={() => toggle(false)}
          title='Something went wrong'
          icon={<Warning size={138} color='var(--danger)' weight='duotone' />}
          message='Please try again, if this issue persists, please contact support'
          buttonText='Close'
          buttonColor='secondary'
          buttonOutline
        />
      )
      break
    default:
      currentStep = null
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody
        className='d-flex flex-column p-0'
        style={{ minHeight: stepMinHeight }}
      >
        <div className='position-absolute right-0 top-0'>
          <IconButton
            outline
            color='transparent'
            circle
            size={28}
            onClick={toggle}
            className='position-absolute top-0 right-0 mt-3 mr-3'
            icon={<X size={18} />}
          />
        </div>
        {currentStep}
      </ModalBody>
    </Modal>
  )
}

export default PremiumEligibilityModal
