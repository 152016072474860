import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { StyledH5 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import { qbUpdateRequest } from '../../../../store/qb/actions'
import ContactsList from '../ContactsList'
import { VendorCard } from '../vendor-card'

function VendorsSettingTab({ loading, onSubmitted }) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.qb.preData)
  const [syncContractors, setSyncContractors] = useState(
    data?.contractors_auto_sync,
  )

  function onSubmit() {
    dispatch(
      qbUpdateRequest({
        contractors_auto_sync: syncContractors,
        isUpdate: true,
      }),
    )
    onSubmitted()
  }

  return (
    <Col className='p-0 m-0'>
      <VendorCard className='p-4 m-0 mb-3'>
        <Row className='p-0 m-0 align-items-center'>
          <div className='mr-3'>
            <Toggle
              check={syncContractors}
              change={() => setSyncContractors((e) => !e)}
            />
          </div>
          <div>
            <StyledH5 className='font-size-16 rp-font-bold'>
              Vendors auto sync
            </StyledH5>
            <p className='text-muted m-0 p-0'>
              Automatically sync all new contractors with the integration.
            </p>
          </div>
        </Row>
      </VendorCard>
      <VendorCard className='tw-mb-0 tw-shadow-md'>
        <ContactsList defaultValues={{}} platform='Quickbooks' />

        <div className='tw-mt-6 tw-flex tw-justify-end tw-border-t tw-border-surface-30 tw-p-6'>
          <Button
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            type='button'
          >
            Save
          </Button>
        </div>
      </VendorCard>
    </Col>
  )
}

export default VendorsSettingTab
