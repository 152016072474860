import React from 'react'
import 'react-quill/dist/quill.snow.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ACTION_TYPE } from '../tools/constants'
import { ActionEmailForm } from './action-email-form'
import { ActionSlackForm } from './action-slack-form'

export function ActionMessageForm(props) {
  const { index, actionType } = props

  return (
    <div>
      {actionType === ACTION_TYPE.EMAIL ? (
        <ActionEmailForm index={index} />
      ) : (
        <ActionSlackForm index={index} />
      )}
    </div>
  )
}
