import React, { useState } from 'react'
import { Card, Container, Tooltip } from 'reactstrap'
import { useFetch } from '../../../../helpers/hooks'
import {
  approveAdjustment,
  getEORContractsAdjustments,
  getEORContractsAdjustmentsMonths,
  declineAdjustment,
} from '../../../../services/api'
import DataTable from '../../../../components/ui/data-table'
import ContractRef from '../../components/ContractRef'
import { getFullName } from '../../../../utils/get-full-name'
import Button from '../../../../components/ui/button'
import { ConfirmationModal } from '../../../remotepass-cards/components/active-credit-card-section'
import toastr from 'toastr'
import Pagination from '../../../../components/ui/pagination'
import Loader from '../../../../components/ui/loader'
import { format } from 'date-fns'
import BadgeX from '../../../../components/Table/BadgeX'
import { useFilters } from '../cards/use-filters'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Shimmer from '../../../../components/ui/shimmer'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { useLocation } from 'react-router-dom'

function AdjustmentType({ type }) {
  return <div className='text-capitalize'>{type.split('-').join(' ')}</div>
}

function AdjustmentStatus({ status }) {
  const color =
    status === 'approved'
      ? 'success'
      : status === 'pending'
        ? 'warning'
        : 'danger'

  return (
    <BadgeX
      style={{ minWidth: '100px' }}
      className='text-capitalize'
      status={color}
    >
      {status}
    </BadgeX>
  )
}

function AdjustmentsFilters({ handleFiltersChange, filters }) {
  const { data, isLoading } = useFetch({
    action: getEORContractsAdjustmentsMonths,
    withAdminAccess: true,
    autoFetch: true,
  })
  return (
    <div className='p-4 d-flex'>
      {isLoading ? (
        <div className='d-flex flex-column mr-3'>
          <Shimmer width={80} height={23} />
          <Shimmer width={200} height={43} className='mt-2' />
        </div>
      ) : (
        <CustomSelect
          label='Month'
          isClearable
          placeholder='Select Month...'
          defaultValue={filters.month}
          options={data}
          wrapperStyles={{ minWidth: 200 }}
          wrapperClassName='mr-3'
          onChange={(option) =>
            handleFiltersChange('month', option?.value, { action: 'clear' })
          }
        />
      )}

      <CustomSelect
        label='Status'
        placeholder='Select Status...'
        isClearable
        defaultValue={filters.status}
        options={[
          {
            value: 'pending',
            label: 'Pending',
          },
          {
            value: 'approved',
            label: 'Approved',
          },
          {
            value: 'declined',
            label: 'Declined',
          },
        ]}
        wrapperStyles={{ minWidth: 200 }}
        onChange={(option) =>
          handleFiltersChange('status', option, { action: 'clear' })
        }
      />
    </div>
  )
}

function Adjustments() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const showPending = searchParams.get('status') === 'pending'

  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    status: showPending
      ? {
          value: 'pending',
          label: 'Pending',
        }
      : null,
  })
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    type: '',
    title: null,
    component: null,
    withReason: null,
    content: null,
    onConfirm: null,
    onClose: null,
    confirmText: null,
    isLoading: null,
  })

  const setLoading = (loading) =>
    setConfirmationModal((prevState) => ({ ...prevState, isLoading: loading }))

  const closeConfirmationModal = () =>
    setConfirmationModal((prevState) => ({ ...prevState, isOpen: false }))

  const {
    data,
    paginator,
    isLoading,
    startFetch: refresh,
  } = useFetch(
    {
      action: getEORContractsAdjustments,
      withAdminAccess: true,
      autoFetch: true,
      body: { ...filters, status: filters.status?.value },
    },
    [filters],
  )

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const { startFetch: approve } = useFetch({
    action: approveAdjustment,
    withAdminAccess: true,
    onComplete: () => {
      setLoading(false)
      closeConfirmationModal()
      refresh({ page: 1 })
      toastr.success('Adjustment successfully approved!')
    },
    onError: (err) => {
      setLoading(false)
      toastr.error(err)
    },
  })

  const { startFetch: decline } = useFetch({
    action: declineAdjustment,
    withAdminAccess: true,
    onComplete: () => {
      setLoading(false)
      closeConfirmationModal()
      refresh({ page: 1 })
      toastr.info('Adjustment was successfully declined!')
    },
    onError: (err) => {
      setLoading(false)
      toastr.error(err)
    },
  })

  const handleApprove = ({ id }) => {
    setConfirmationModal({
      isOpen: true,
      title: 'Approve Adjustment',
      content: (
        <div className='text-center'>
          Are you sure you want to approve this adjustment?
        </div>
      ),
      confirmText: 'Approve',
      onConfirm: () => {
        setLoading(true)
        approve({
          id,
        })
      },
      onClose: () => {
        closeConfirmationModal()
      },
    })
  }
  const handleDecline = ({ id }) => {
    setConfirmationModal({
      isOpen: true,
      title: 'Decline adjustment',
      content: (
        <>
          <div className='text-center p-3'>
            Are you sure you want to decline this adjustment?
          </div>
        </>
      ),
      withReason: {
        placeholder: 'Reason of decline',
        isRequired: true,
      },
      confirmText: 'Decline',
      onConfirm: (ev, reason) => {
        setLoading(true)
        decline({
          reason,
          id,
        })
      },
      onClose: () => {
        closeConfirmationModal()
      },
    })
  }

  const columns = [
    {
      Header: 'Contract ID',
      accessor: 'contract.ref',
      Cell: ({ cellData }) => {
        return (
          <ContractRef contractId={cellData} isAdmin={true} target={null} />
        )
      },
    },
    {
      Header: 'Company',
      accessor: 'contract.company_name',
    },
    {
      Header: 'Client',
      accessor: 'contract.client',
      Cell: ({ cellData }) => {
        return getFullName(cellData)
      },
    },
    {
      Header: 'Employee',
      accessor: 'contract.employee',
      Cell: ({ cellData }) => {
        return getFullName(cellData)
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ cellData, rowData }) => {
        const formatter = getCurrencyFormatter(
          rowData.contract?.salary_currency?.code,
        )
        return formatter.format(cellData)
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cellData }) => {
        return <AdjustmentStatus status={cellData} />
      },
    },
    {
      Header: 'Note',
      accessor: 'note',
      Cell: ({ cellData, rowData }) => {
        const [tooltipOpen, setTooltipOpen] = useState(false)
        const toggle = () => setTooltipOpen((tooltipOpen) => !tooltipOpen)
        return (
          <>
            {rowData.note?.length > 30 ? (
              <Tooltip
                isOpen={tooltipOpen}
                target={`adj-note-${rowData.id}`}
                toggle={toggle}
                placement='right'
              >
                {cellData}
              </Tooltip>
            ) : null}
            <span
              id={`adj-note-${rowData.id}`}
              className='font-size-14 d-block'
              style={{
                maxWidth: '250px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {cellData}
            </span>
          </>
        )
      },
    },
    {
      Header: 'Kind',
      accessor: 'kind',
      className: 'text-capitalize',
    },
    {
      Header: 'type',
      accessor: 'type',
      Cell: ({ cellData }) => {
        return <AdjustmentType type={cellData} />
      },
    },
    {
      Header: 'Decline Reason',
      accessor: 'decline_reason',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        return format(new Date(cellData), 'MM/dd/yyyy')
      },
    },
    {
      Header: 'Effective Date',
      accessor: 'effective_date',
      Cell: ({ cellData }) => {
        return format(new Date(cellData), 'MM/dd/yyyy')
      },
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ cellData, rowData }) => {
        return (
          rowData.status === 'pending' && (
            <div className='d-flex gap-8'>
              <Button
                size='sm'
                outline
                onClick={() => {
                  handleApprove({ id: cellData })
                }}
              >
                Approve
              </Button>
              <Button
                size='sm'
                onClick={() => handleDecline({ id: cellData })}
                outline
                color='danger'
              >
                Decline
              </Button>
            </div>
          )
        )
      },
    },
  ]

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Adjustments</h1>

        <Card>
          <AdjustmentsFilters
            handleFiltersChange={handleFiltersChange}
            filters={filters}
          />
          {isLoading ? (
            <Loader style={{ minHeight: 300 }} className='w-100' />
          ) : data?.length === 0 ? (
            <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
              No Adjustments Pending
            </div>
          ) : (
            <DataTable data={data} responsive columns={columns} />
          )}
          {showPaginator && !isLoading && (
            <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={paginator?.per_page ?? 10}
                totalItemsCount={paginator?.total ?? 0}
                onChange={(newPage) => handleFiltersChange('page', newPage)}
              />
            </div>
          )}
        </Card>
      </Container>
      {confirmationModal.isOpen && (
        <ConfirmationModal
          isOpen={confirmationModal.isOpen}
          onCancel={confirmationModal.onClose}
          onConfirm={confirmationModal.onConfirm}
          title={confirmationModal.title}
          withReason={confirmationModal.withReason}
          loading={confirmationModal.isLoading}
          content={confirmationModal.content}
          toggle={() => closeConfirmationModal()}
          cancelColor='secondary'
          cancelText='Cancel'
          confirmText={confirmationModal.confirmText}
        />
      )}
    </div>
  )
}
export default Adjustments
