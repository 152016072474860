import React, { useState } from 'react'
import toastr from 'toastr'

import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { getScheduledTaskStatus } from '../../../services/api'
import { invitePendingContracts } from '../../../services/api-bulk-operations'
import { scheduledTaskStatus } from '../../payInvoices'

export function InvitePendingContracts({ onUpdate }) {
  const [isLoading, setIsLoading] = useState(false)

  const { startFetch: checkTaskStatus } = useFetch({
    action: getScheduledTaskStatus,
    onComplete: (data) => {
      if (data?.success === false) {
        setIsLoading(false)
        toastr.clear()
        toastr.error('Failed to invite contracts')
      } else {
        const stillHaveProcessingItems = data?.processes?.some(
          (process) => process?.status === scheduledTaskStatus.processing,
        )

        if (stillHaveProcessingItems) {
          // @todo: check BE always processing issue
          setTimeout(() => checkTaskStatus({ id: data?.id }), 800)
        } else {
          setIsLoading(false)
          toastr.clear()
          toastr.success('Contracts invited successfully')
          onUpdate?.()
        }
      }
    },
    onError: (error) => {
      setIsLoading(false)
      toastr.clear()
      toastr.error(error)
    },
  })

  const { startFetch: inviteContracts } = useFetch({
    action: invitePendingContracts,
    onComplete: (data) => {
      if (data?.success === false) {
        setIsLoading(false)
        toastr.error('Failed to invite contracts')
      } else {
        checkTaskStatus({ id: data?.id })
      }
    },
    onError: (err) => {
      setIsLoading(false)
      toastr.error(err)
    },
  })

  return (
    <Button
      onClick={() => {
        // @todo: add bulk invite with IDs -> BE in progress
        // const contractIds = contracts.map((order) => order.id)
        inviteContracts()
        setIsLoading(true)
      }}
      outline
      disabled={isLoading}
      loading={isLoading}
    >
      Invite selected
    </Button>
  )
}
