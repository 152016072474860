import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Row, Spinner } from 'reactstrap'
import toastr from 'toastr'

import NoContent from '../../components/NoContent'
import { useFetch } from '../../helpers/hooks'
import {
  getContractorActiveInsuranceAllowances,
  getContractorInfo,
  getInsuranceOverview,
  getInsurancePlans,
} from '../../services/api'
import Deductible from './../../assets/images/deductible.svg'
import Dental from './../../assets/images/dental.svg'
import Maternity from './../../assets/images/maternity.svg'
import Outpatient from './../../assets/images/outpatient.svg'
import Vision from './../../assets/images/vision.svg'
import AddOnCard from './components/AddOnCard'
import InsuranceForm from './components/InsuranceForm'
import InsurancePayments from './components/InsurancePayments'
import InsuranceStatus from './components/InsuranceStatus'
import { Plan } from './components/Plan'
import { QuestionItem } from './components/QuestionItem'

import { BookOpenUser, Globe, Hospital, Syringe } from '@phosphor-icons/react'
import remoteHealth from '../../assets/images/remote-health-safety-wing.png'
import welcomeImage from '../../assets/images/welcome.svg'

function Insurance() {
  const user = useSelector((state) => state?.userProfile?.userProfile)
  const [ages, setAges] = useState(null)
  const [contractorAllowances, setContractorAllowances] = useState(null)
  const [possiblePlan, setPossiblePlan] = useState(null)
  const [isValidSubmission, setIsValidSubmission] = useState(false)
  const data = useRef(null)
  const plans = useFetch({
    action: getInsurancePlans,
    onError: () => setIsValidSubmission(false),
    onComplete: () => setIsValidSubmission(true),
  })
  useFetch({
    action: getContractorInfo,
    autoFetch: true,
    onComplete: (data) => {
      if (data?.has_insurance_allowance === true) {
        insuranceAllowancePlans.startFetch()
      }
    },
  })
  const insuranceAllowancePlans = useFetch({
    action: getContractorActiveInsuranceAllowances,
    autoFetch: false,
  })
  const overView = useFetch({
    action: getInsuranceOverview,
    autoFetch: true,
  })

  useEffect(() => {
    if (insuranceAllowancePlans?.data?.length) {
      const total = insuranceAllowancePlans?.data?.reduce(
        (_total, currentValue) => _total + parseFloat(currentValue?.amount),
        0,
      )

      setContractorAllowances(total)

      for (let i = 0; i < plans?.data?.length; i++) {
        const plan = plans?.data?.[i]
        if (parseInt(plan.amount) >= total || i + 1 === plans?.data?.length) {
          setPossiblePlan(plan)
          break
        }
      }
    }
  }, [insuranceAllowancePlans?.data, plans?.data])

  const onSubmit = (v) => {
    let age = ''
    if (v?.age < 18) {
      age = '0-17'
    } else if (v?.age < 40) {
      age = '18-39'
    } else if (v?.age < 50) {
      age = '40-49'
    } else if (v?.age < 60) {
      age = '50-59'
    } else if (v?.age <= 74) {
      age = '60-74'
    } else {
      toastr.error(
        `Unfortunately, the age entered is outside the supported age range`,
      )
      return
    }

    const newAges = v?.ages?.length ? [age, ...(v?.ages ?? [])] : [age]
    setAges(newAges)
    plans.startFetch({ ages: newAges, country_id: v?.country_id })
    data.current = v
  }

  const onError = (errorMessage) => {
    setIsValidSubmission(false)
    toastr.error(errorMessage)
  }

  const getPlanCardWidth = (plansCount) => {
    switch (plansCount) {
      case 1:
        return 12
      case 2:
        return 6
      case 3:
      default:
        return 4
    }
  }

  return (
    <div className='page-content'>
      {user?.has_active_contract ||
      overView.data?.details?.status === 'active' ? (
        <Container fluid className='p-0 m-0' style={{ minHeight: '75vh' }}>
          {overView.isLoading ? (
            <Col style={{ minHeight: '90vh' }}>
              <Row
                style={{ minHeight: '90vh' }}
                className='justify-content-center align-items-center'
              >
                <Spinner type='grow' color='primary' />
              </Row>
            </Col>
          ) : (
            <>
              <Row className='p-0 m-0 justify-content-between align-items-center'>
                <h1
                  style={{ color: 'var(--gray-h)', marginBottom: 14 }}
                  className='font-size-32'
                >
                  Remote Health
                </h1>
                <img height={48} src={remoteHealth} alt='' />
              </Row>
              <h2
                style={{ marginBottom: 32, fontWeight: '400' }}
                className='text-secondary font-size-16'
              >
                Use Remote Health at any private or public hospital, or doctor,
                in the world - no matter where you live or travel.{' '}
              </h2>

              {overView.data?.details?.status === 'pending' ? (
                <NoContent
                  image={welcomeImage}
                  subtitle='You will receive an email invitation from our partner Remote Health by SafetyWing with a link to complete your registration and will be notified once your plan is activated.'
                  headline='You will get an invitation to complete your onboarding'
                  onAction={() => {}}
                />
              ) : (
                <>
                  {['active', 'inactive', 'processing'].includes(
                    overView.data?.details?.status,
                  ) ? (
                    <InsuranceStatus
                      status={overView.data?.details?.status}
                      number={overView.data?.details?.policy_number || '******'}
                      dependents={overView.data?.details?.dependents}
                      date={moment(
                        overView.data?.details?.start_date * 1000,
                      ).format('DD/MM/YYYY')}
                      data={overView.data}
                      refreshData={() => overView.startFetch(null, false)}
                    />
                  ) : (
                    <InsuranceForm
                      onSubmit={onSubmit}
                      onError={onError}
                      loading={plans.isLoading}
                    />
                  )}

                  {['active', 'inactive', 'processing'].includes(
                    overView.data?.details?.status,
                  ) ? (
                    <>
                      {overView.data?.payments &&
                        overView.data?.payments?.length > 0 && (
                          <InsurancePayments
                            payments={overView?.data?.payments}
                            planDetails={overView?.data?.details}
                            onUpdate={() => overView.startFetch(null, false)}
                            currency='USD'
                            price={130}
                            nextBilling={overView.data?.details?.next_billing}
                          />
                        )}
                    </>
                  ) : (
                    <>
                      {plans.data?.country_not_supported && (
                        <div className='bg-white rp-shadow-2 rounded p-3'>
                          <NoContent
                            image={welcomeImage}
                            subtitle={' '}
                            headline={`${plans?.data?.country_name} is not supported yet`}
                            onAction={() => {}}
                          />
                        </div>
                      )}
                      {isValidSubmission && !!plans.data?.length && (
                        <Row className='mb-5'>
                          {React.Children.toArray(
                            plans.data.map((e) => (
                              <Col
                                key={`plan-${e?.id}`}
                                md={getPlanCardWidth(plans.data?.length)}
                                sm={12}
                                className='pr-2'
                              >
                                <Plan
                                  ages={ages}
                                  plan={e}
                                  title={e.name}
                                  desc={e.desc}
                                  features={e.conditions}
                                  price={e.amount}
                                  dependents={ages}
                                  currency={e.currency?.code}
                                  onUpdate={() =>
                                    overView.startFetch(null, false)
                                  }
                                  insuranceAllowance={
                                    possiblePlan?.id === e.id
                                      ? contractorAllowances
                                      : null
                                  }
                                  data={data?.current}
                                />
                              </Col>
                            )),
                          )}
                        </Row>
                      )}
                    </>
                  )}
                </>
              )}

              <h1 className='text-dark mb-3 mt-5'>Remote Health Benefits</h1>
              <div className='bg-white rp-shadow-2 rounded p-3'>
                <div className='tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 xl:tw-grid-cols-3'>
                  {[
                    {
                      title: 'Worldwide Coverage',
                      icon: (
                        <div className='tw-basis-8'>
                          <Globe
                            size={32}
                            weight='bold'
                            color='var(--primary-100)'
                          />
                        </div>
                      ),
                      desc: 'Worldwide coverage for medical expenses that require hospital admission. $1.5M per year limit for the Standard and Premium plans and $2.5M per year limit for the Premium+ plan.',
                    },
                    {
                      title: 'Outpatient',
                      icon: (
                        <img
                          src={Outpatient}
                          alt=''
                          style={{ width: 32, height: 32 }}
                        />
                      ),
                      desc: 'Outpatient care is when you need medical services outside of hospital admission. $5,000 limit with a 10% co-pay for the Standard plan, so for a $100 appointment, you get reimbursed $90.',
                    },
                    {
                      title: 'Dental',
                      icon: (
                        <img
                          src={Dental}
                          alt=''
                          style={{ width: 32, height: 32 }}
                        />
                      ),
                      desc: 'USD 1,500 in dental coverage for when you need to see a dentist for routine care such as check-ups, fillings, etc. or more complex care like repairing or receiving new crowns, dentures, or inlays.',
                    },
                    {
                      title: '$0 deductible',
                      icon: (
                        <img
                          src={Deductible}
                          alt=''
                          style={{ width: 32, height: 32 }}
                        />
                      ),
                      desc: 'Your deductible determines how much you must pay out of pocket before receiving reimbursement for claims within one year.',
                    },
                    {
                      title: 'Vision',
                      icon: (
                        <img
                          src={Vision}
                          alt=''
                          style={{ width: 32, height: 32 }}
                        />
                      ),
                      desc: 'USD 500 for things like eye exams, frames, and contact lenses.',
                    },
                    {
                      title: 'Outpatient Psychiatric Treatment',
                      icon: (
                        <div className='tw-basis-8'>
                          <Hospital
                            size={32}
                            weight='bold'
                            color='var(--primary-100)'
                          />
                        </div>
                      ),
                      desc: 'Get ready for more flexibility and convenience! Now you can enjoy both in-person and virtual appointments. 100% UCR up to 10 visits per policy year.',
                    },
                    {
                      title: 'Complementary Therapies',
                      icon: (
                        <div className='tw-basis-8'>
                          <BookOpenUser
                            size={32}
                            weight='bold'
                            color='var(--primary-100)'
                          />
                        </div>
                      ),
                      desc: 'Massage therapy is now included! Experience the added relaxation and wellness benefits of massage as part of members complementary therapies. Up to $60 per visit, 10 visits per policy year.',
                    },
                    {
                      title: 'Screenings & vaccines',
                      icon: (
                        <div className='tw-basis-8'>
                          <Syringe
                            size={32}
                            weight='bold'
                            color='var(--primary-100)'
                          />
                        </div>
                      ),
                      desc: 'Covers up to USD 350 for the Standard Plan and USD 500 per year for the Premium and Premium+ Plan across two categories: Screenings (routine health checks) and vaccinations.',
                    },
                    {
                      title: 'Maternity',
                      icon: (
                        <img
                          src={Maternity}
                          alt=''
                          style={{ width: 32, height: 32 }}
                        />
                      ),
                      desc: 'Members have access to maternity coverage across all of our plans to cover the medically necessary costs incurred during pregnancy and childbirth up including pre and post-natal check-ups for up to 30 days following discharge. Coverage up to USD 2,500 for the Standard plan, USD 4,500 for the Premium plan and USD 7,500 for the Premium+ plan, with a 20% co-insurance. <span class="text-text-black tw-font-bold">A 10-month waiting period applies for the Standard and Premium plans</span>.',
                    },
                  ].map((e, index) => (
                    <AddOnCard
                      title={e.title}
                      desc={e.desc}
                      icon={e.icon}
                      key={index}
                    />
                  ))}
                </div>
              </div>

              <h1 className='text-dark mb-3 mt-5'>Questions and Answers</h1>
              {[
                {
                  title: 'How does payment work if I need to see a doctor?',
                  component: <PaymentWork />,
                },
                {
                  title: 'Are there any countries that can’t be covered?',
                  component: <CountriesCovered />,
                },
                {
                  title: 'Is maternity covered?',
                  component: <MaternityCovered />,
                },
                {
                  title: 'Can family members be covered?',
                  component: <FamilyCovered />,
                },
                { title: 'Is travel covered?', component: <TravelCovered /> },
                {
                  title: 'Is Dental covered?',
                  component: <DentalCovered />,
                },
                {
                  title: 'Is Vision covered?',
                  component: <VisionCovered />,
                },
                {
                  title: 'How long does it take to process claims?',
                  component: <HowLong />,
                },
                {
                  title: 'Billing frequency is monthly:',
                  component: <HowPaymentWorks />,
                },
                {
                  title: 'Is COVID-19 covered? ',
                  component: <COVID />,
                },
              ].map((e, index) => (
                <QuestionItem
                  title={e.title}
                  component={e.component}
                  key={index}
                />
              ))}
            </>
          )}
        </Container>
      ) : (
        <Container fluid className='p-0 m-0' style={{ minHeight: '75vh' }}>
          <NoContent
            image={welcomeImage}
            subtitle={
              <div style={{ maxWidth: '62ch', margin: '0 auto' }}>
                <div className='mb-3'>
                  You need to have an active contract to access Remote Health.
                </div>
                <div>
                  Remote Health offers a premium medical coverage with
                  0-deductible at any private or public hospital, or doctor, in
                  the world.
                </div>
              </div>
            }
            headline='Remote Health'
            onAction={() => {}}
          />
        </Container>
      )}
    </div>
  )
}

const PaymentWork = () => (
  <div className='p-4'>
    <p className='font-size-14 font-weight-bold'>
      You have two options for payment, based on your preference and situation:
    </p>

    <p className='font-size-14 font-weight-bold mb-2'>
      Option 1: Direct billing
    </p>
    <p className='mb-1'>
      We provide direct billing for in-patient and day-patient treatment
      (admitted to hospital). This means we handle all payments directly with
      the hospital, so our members don’t have to pay out of pocket. Direct
      billing is available for both emergency admissions and when you have an
      upcoming planned procedure.
    </p>

    <p className='font-size-14 font-weight-bold mb-2'>
      Option 2: Pay out of pocket and submit a claim
    </p>
    <p className='mb-2'>
      Members can pay out of pocket for their visit and then submit a claim for
      reimbursement online. Claims are paid within 7-10 days. For claims below
      $750, reimbursements are processed within 48 hours.
    </p>
    <p>
      Please note<strong> Remote Health does not have a network!</strong> You
      can choose a hospital or clinic (public or private) anywhere you live or
      travel.
    </p>
    <p>
      This{' '}
      <a
        className='text-primary'
        href='https://safetywing.notion.site/safetywing/Providers-We-ve-Worked-with-Before-0582fe590df346db893d076d0371a8b5'
      >
        list
      </a>{' '}
      contains info on providers we have worked with before.
    </p>
  </div>
)

function CountriesCovered() {
  return (
    <div className='p-4'>
      <p>
        Besides these countries, members are covered on long or short trips
        (except a 30-day limit in the US, SG, and HK). Singapore and Hong Kong
        residents must purchase an add-on, making the price 85% higher.
      </p>
      <Row>
        <Col md={4}>
          <ul>
            <li>Sudan</li>
            <li>South Sudan </li>
            <li>Syria</li>
          </ul>
        </Col>
        <Col md={4}>
          <ul>
            <li>Zimbabwe</li>
            <li>Iran</li>
            <li>Ireland</li>
          </ul>
        </Col>
        <Col md={4}>
          <ul>
            <li>North Korea </li>
            <li>Cuba</li>
          </ul>
        </Col>
      </Row>
      <p>
        Countries you cannot be a resident of, nor be covered if you spend more
        than 180 days in the country per year:
      </p>
      <Row>
        <Col md={4}>
          <ul>
            <li>American Samoa </li>
            <li>Belarus </li>
            <li>Canada </li>
            <li>Democratic Republic of Congo</li>
          </ul>
        </Col>
        <Col md={4}>
          <ul>
            <li>Palau</li>
            <li>Palestine (West Bank & Giza)</li>
            <li>Puerto Rico </li>
            <li>Saudi Arabia </li>
          </ul>
        </Col>
        <Col md={4}>
          <ul>
            <li>United Arab Emirates</li>
            <li>US Guam </li>
            <li>US Virgin Islands</li>
          </ul>
        </Col>
      </Row>
      <p>
        Besides these countries, members are covered when they go on long or
        short trips (with the exception of a 30-day limit in the US, SG, and
        HK). Singapore and Hong Kong residents must purchase an add-on, making
        the price 85% higher.
      </p>
    </div>
  )
}
function MaternityCovered() {
  return (
    <div className='p-4 py-2'>
      <p className='mb-0'>
        Yes. Members have access to maternity coverage across all of our plans
        to cover the medically necessary costs incurred during pregnancy and
        childbirth up including pre and post-natal check-ups for up to 30 days
        4,500 for the Premium plan and USD 7,500 for the Premium+ plan, with a
        20% co-insurance. A 10-month waiting period applies for the Standard and
        Premium plans.
      </p>
    </div>
  )
}
function FamilyCovered() {
  return (
    <div className='p-4'>
      <p>Yes! Dependents can be added under all plans.</p>
      <p>Dependents inclusion</p>
      <ul>
        <li>
          Dependents must be added simultaneously with the member’s enrollment
          or within a maximum of 30 days after their enrollment/start date.
        </li>
        <br />
        <li>
          Dependents include the member’s spouse, domestic partner, biological
          children, step-children, or legally adopted children.
        </li>
      </ul>
      <p>
        Exceptions to add your dependents post 30 days can be granted. To add a
        dependent to your policy after it has been submitted, please get in
        touch with our Support team.
      </p>
    </div>
  )
}
function TravelCovered() {
  return (
    <div className='p-4'>
      <p>
        Yes. Coverage applies to short or long-term travel to all supported
        countries (except for a 30-day limit in the US, SG, and HK).
      </p>
    </div>
  )
}
function DentalCovered() {
  return (
    <div className='p-4'>
      <p>
        Dental is covered under the premium plans. You benefit from $1,500
        coverage in dental coverage for when you need to see a dentist for
        routine care such as check-ups, fillings, etc.... or for more complex
        care like repairing or receiving new crowns, dentures, or inlays.
      </p>
    </div>
  )
}
function VisionCovered() {
  return (
    <div className='p-4'>
      <p>
        Vision is covered under the premium plans. You benefit from a $500
        coverage for eye exams, frames, and contact lenses.
      </p>
    </div>
  )
}
function HowLong() {
  return (
    <div className='p-4'>
      <p>
        When you file a claim, reimbursement takes 10 to 20 business days. For
        claims below $750, reimbursements are processed within 48 hours.
      </p>
    </div>
  )
}
function HowPaymentWorks() {
  return (
    <div className='p-4'>
      <ul>
        <li>
          Contractors receive a monthly invoice on their RemotePass account and
          can process payment using their RemotePass balance or by credit card.
        </li>
        <br />
        <li>
          Employees: The monthly premium is billed directly to the employer as
          part of the payroll summary.
        </li>
      </ul>
    </div>
  )
}
function COVID() {
  return (
    <div className='p-4'>
      <p>COVID-19 vaccinations are not covered. </p>
    </div>
  )
}

export default Insurance
