import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  CardBody,
  Col,
  Form,
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledInputLabel from '../../../components/ControlledInputLabel'
import ControlledSelect from '../../../components/ControlledSelect'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import Button from '../../../components/ui/button'
import InputFeedback from '../../../components/ui/input-feedback'
import { useFetch } from '../../../helpers/hooks'
import {
  convertAmount,
  getCurrencyExchangeRateAdmin,
  getEorQuotePrefill,
  getEorQuotePrefillForGenerator,
  getLocalCurrencies,
} from '../../../services/api'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import { MGT_FEES_STRING } from '../../AdminPanel/pages/EORInvoices/InvoiceForm'
import { customOption, customSingleValue } from './FullTimeForm'
import Loader from '../../../components/ui/loader'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'

const ReactQuill = React.lazy(() => import('react-quill'))

const QuotationForm = ({
  initialData,
  onSubmit,
  loading,
  currency,
  amount,
  salaryCurrency,
  forGenerator,
  readOnly = false,
  countryCode,
  contractId,
  allowances,
}) => {
  const oldManagementFeeLabel = 'Management fees'
  const managementFeeLabel = MGT_FEES_STRING
  const indirectTaxLabel = 'Indirect Tax'
  const usdHandlingFeeLabel = 'USD handling fee'
  const usdCurrencyCode = 'USD'

  const MANAGEMENT_FEE_LABELS = [oldManagementFeeLabel, managementFeeLabel]
  const USD_FEES_LABELS = [
    indirectTaxLabel,
    usdHandlingFeeLabel,
    ...MANAGEMENT_FEE_LABELS,
  ]

  const defaultArray = [
    {
      id: 1,
      first_month_payroll: {
        name: 'Gross Salary',
        value: !isNaN(amount) ? parseFloat(Number(amount) / 12).toFixed(2) : 0,
      },
      monthly_payroll: {
        name: 'Gross Salary',
        value: !isNaN(amount) ? parseFloat(Number(amount) / 12).toFixed(2) : 0,
      },
    },
    {
      id: 2,
      first_month_payroll: {
        name: managementFeeLabel,
        currency: usdCurrencyCode,
      },
      monthly_payroll: {
        name: managementFeeLabel,
        currency: usdCurrencyCode,
      },
    },
    {
      id: 3,
      first_month_payroll: {
        name: indirectTaxLabel,
        currency: usdCurrencyCode,
        required: false,
      },
      monthly_payroll: {
        name: indirectTaxLabel,
        currency: usdCurrencyCode,
        required: false,
      },
    },
  ]

  const schema = yup.object().shape({
    setup_fee: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .required('Setup Fee is required')
      .typeError('Setup Fee must be a number'),
    work_visa_cost: yup
      .number()
      .nullable()
      .typeError('Visa cost must be a number'),
    deposit_amount: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .required('Deposit Amount is required')
      .typeError('Deposit Amount must be a number')
      .min(0, 'Deposit Amount must be a positive number'),
    currency_code: yup.string().required(),
    yearly_gross: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .required('Yearly Gross is required')
      .typeError('Yearly Gross must be a number'),
    markup: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .required('Mark up is required')
      .typeError('Mark up must be a number')
      .min(0, 'Min value 0')
      .max(100, 'Max value 100'),
    country_id: forGenerator ? yup.string().required() : yup.number(),
  })

  const { handleSubmit, control, setValue, formState } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      payroll: defaultArray,
      deposit_amount: initialData?.deposit_amount || 0,
      setup_fee: initialData?.setup_fee || 0,
      work_visa_cost: Number(initialData?.work_visa_cost || 0),
      currency_code: initialData?.currency_code || salaryCurrency,
      yearly_gross: Number(initialData?.yearly_gross || amount || 0),
      markup: Number(initialData?.markup || 3),
    },
  })
  const { errors } = formState
  const {
    currency_code: currencyCode,
    yearly_gross: yearlyGross,
    country_id: countryId,
    work_visa_cost: workVisaCost,
    markup: markupPercentage,
  } = useWatch({ control })
  const orderFeesListing = useCallback((initialData) => {
    // Make management fee last fee. Check first if management fee is not last
    if (
      !MANAGEMENT_FEE_LABELS.includes(
        initialData?.first_month_payroll[
          initialData?.first_month_payroll?.length - 1
        ].name,
      )
    ) {
      const managementFeeIndex = initialData.first_month_payroll.findIndex(
        (q) => MANAGEMENT_FEE_LABELS.includes(q.name),
      )

      const newInitialData = [...initialData.first_month_payroll]
      newInitialData.splice(
        newInitialData.length - 1,
        0,
        newInitialData.splice(managementFeeIndex, 1)[0],
      )

      return newInitialData.map((e) => ({
        first_month_payroll: e,
        monthly_payroll: e,
      }))
    } else {
      const data = [...initialData.first_month_payroll]

      return data?.map((e, i) => ({
        first_month_payroll: e,
        monthly_payroll: initialData?.monthly_payroll[i],
      }))
    }
  }, [])

  const [array, setArray] = useState(
    initialData ? orderFeesListing(initialData) : defaultArray,
  )
  const [scope, setScope] = useState(initialData?.additional_text || '')
  const eorCountries = useSelector(
    (state) => state?.Layout?.staticData?.eor_countries ?? [],
  )
  const isSalaryCurrencyUSD = currencyCode === usdCurrencyCode

  const onAppend = () => {
    if (readOnly) {
      return
    }
    const newArray = [...array]
    const newValue = {
      id: uuidv4(),
      first_month_payroll: {
        name: 'Custom Field',
      },
      monthly_payroll: {
        name: 'Custom Field',
      },
    }
    newArray.splice(newArray.length - 2, 0, newValue)
    setArray(newArray)
  }

  const onChangeValue = (i, name, value) => {
    const newArray = array.map((f, index) => {
      const newF = { ...f }
      if (index === i) {
        newF[name] = { ...newF[name], value }
        if (name === 'first_month_payroll') {
          newF.monthly_payroll = { ...newF.monthly_payroll, value }
        }
      }
      return newF
    })
    setArray(newArray)
  }

  const onChangeName = (i, name, value) => {
    const newArray = array.map((f, index) => {
      const newF = { ...f }
      if (index === i) {
        newF[name] = { ...newF[name], name: value }
        if (name === 'first_month_payroll') {
          newF.monthly_payroll = { ...newF.monthly_payroll, name: value }
        }
      }
      return newF
    })
    setArray(newArray)
  }

  const prefillEorData = useCallback((data) => {
    let newArray = array.map((f) => {
      const newF = { ...f }
      // prefill/add management fee value if it's missing
      if (
        data?.management_fee &&
        MANAGEMENT_FEE_LABELS.includes(newF.monthly_payroll.name) &&
        !newF.monthly_payroll.value
      ) {
        newF.monthly_payroll = {
          ...newF.monthly_payroll,
          value: data?.management_fee?.monthly,
        }
        newF.first_month_payroll = {
          ...newF.first_month_payroll,
          value: data?.management_fee?.monthly,
        }
      }

      // prefill/add indirect tax value if it's missing
      if (
        data?.indirect_tax &&
        newF.monthly_payroll.name?.toLowerCase() ===
          indirectTaxLabel?.toLowerCase() &&
        !newF.monthly_payroll.value
      ) {
        newF.monthly_payroll = {
          ...newF.monthly_payroll,
          value: data?.indirect_tax?.monthly,
        }
        newF.first_month_payroll = {
          ...newF.first_month_payroll,
          value: data?.indirect_tax?.monthly,
        }
      }

      return newF
    })

    // update amount of existing taxes, and add missing taxes
    if (data?.taxes) {
      for (const key in data?.taxes) {
        const foundTaxIndex = newArray.findIndex(
          (e) => e.monthly_payroll?.name === key,
        )
        if (foundTaxIndex < 0) {
          newArray.splice(newArray.length - 2, 0, {
            id: uuidv4(),
            first_month_payroll: {
              name: key,
              value: data?.taxes[key]?.monthly,
            },
            monthly_payroll: {
              name: key,
              value: data?.taxes[key]?.monthly,
            },
          })
        } else {
          newArray[foundTaxIndex] = {
            id: uuidv4(),
            first_month_payroll: {
              name: key,
              value: data?.taxes[key]?.monthly,
            },
            monthly_payroll: {
              name: key,
              value: data?.taxes[key]?.monthly,
            },
          }
        }
      }
    }

    // update amount of existing allowances, and add missing allowances
    if (allowances && allowances?.length > 0) {
      newArray = newArray.filter((e) => !e.isAllowance)
      allowances?.forEach((p) => {
        const foundTaxIndex = newArray.findIndex(
          (e) => e.monthly_payroll?.name === p?.name,
        )
        if (foundTaxIndex < 0) {
          newArray?.splice(1, 0, {
            id: uuidv4(),
            isAllowance: true,
            first_month_payroll: {
              name: 'Allowance - ' + p?.name,
              value: p?.amount,
              disabled: true,
            },
            monthly_payroll: {
              name: 'Allowance - ' + p?.name,
              value: p?.amount,
              disabled: true,
            },
          })
        } else {
          newArray[foundTaxIndex] = {
            id: uuidv4(),
            first_month_payroll: {
              name: p?.name,
              value: p?.amount,
            },
            monthly_payroll: {
              name: p?.name,
              value: p?.amount,
            },
          }
        }
      })
    }

    const _usdHandlingFee = newArray.find(
      (e) =>
        e.monthly_payroll?.name?.toLowerCase() ===
        usdHandlingFeeLabel?.toLowerCase(),
    )
    if (data?.usd_handling_fee && _usdHandlingFee === undefined) {
      newArray.splice(newArray.length - 2, 0, {
        id: uuidv4(),
        first_month_payroll: {
          name: usdHandlingFeeLabel,
          value: data?.usd_handling_fee?.monthly,
        },
        monthly_payroll: {
          name: usdHandlingFeeLabel,
          value: data?.usd_handling_fee?.monthly,
        },
      })
    }
    setArray([...newArray])

    if (data?.additional_notes && !initialData) {
      setScope(data.additional_notes)
    }
    if (data?.work_visa_cost && !isNaN(data?.work_visa_cost)) {
      setValue('work_visa_cost', Number(data?.work_visa_cost))
    }
    if (
      (typeof data?.markup_percentage === 'number' &&
        data?.markup_percentage >= 0) ||
      !isNaN(data?.markup_percentage)
    ) {
      setValue('markup', Number(data?.markup_percentage))
    }
    if (data?.setup_fee) {
      setValue('setup_fee', data?.setup_fee)
    }
  }, [])

  const validateArray = () => {
    return !array.find(
      (e) =>
        e.first_month_payroll?.value === undefined ||
        e.monthly_payroll?.value === undefined,
    )
  }

  const onValidate = (v) => {
    const isArrayValid = validateArray()
    if (isArrayValid) {
      onSubmit({
        ...v,
        first_month_payroll: array.map((e) => e.first_month_payroll),
        monthly_payroll: array.map((e) => e.monthly_payroll),
        additional_text: scope,
      })
    }
  }

  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })

  const eorPrefill = useFetch({
    action: forGenerator ? getEorQuotePrefillForGenerator : getEorQuotePrefill,
    autoFetch: false,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong prefilling calculation breakdown')
      } else {
        prefillEorData(data)
        toastr.success('Prefilled data successfully')
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const exchangeRate = useFetch({
    action: getCurrencyExchangeRateAdmin,
    autoFetch: !isSalaryCurrencyUSD,
    body: { source_currency: currencyCode, target_currency: 'USD' },
    withAdminAccess: true,
  })

  const invertedExchangeRate = useFetch({
    action: getCurrencyExchangeRateAdmin,
    autoFetch: !isSalaryCurrencyUSD,
    body: { source_currency: 'USD', target_currency: currencyCode },
    withAdminAccess: true,
  })

  const exchange = useFetch({
    action: convertAmount,
    withAdminAccess: true,
    onComplete: (data) => {
      if (typeof data === 'number' || !isNaN(data)) {
        setValue('yearly_gross', Number(parseFloat(data).toFixed(2)))
      }
    },
  })

  // If salary not in USD, apply markup
  const MARKUP_RATE = useMemo(
    () => (!isSalaryCurrencyUSD ? (markupPercentage ?? 0) / 100 + 1 : 1),
    [markupPercentage, isSalaryCurrencyUSD],
  )

  const TO_USD_RATE = useMemo(
    () =>
      parseFloat(
        eorPrefill.data?.quote_currency_to_usd_rate ||
          exchangeRate?.data?.rate ||
          1,
      ),
    [eorPrefill.data?.quote_currency_to_usd_rate, exchangeRate?.data?.rate],
  )

  // For non USD (exchange rate * markup rate) // $quoteToUsdRate
  const RATE = useMemo(
    () => TO_USD_RATE * MARKUP_RATE,
    [TO_USD_RATE, MARKUP_RATE],
  )

  // For USD, used to convert (Management fee, Indirect taxes, or Salary currency in USD) to local currency
  const INVERTED_RATE = useMemo(() => {
    if (isSalaryCurrencyUSD) {
      return parseFloat(invertedExchangeRate?.data?.rate || 1)
    }

    return (
      parseFloat(invertedExchangeRate?.data?.rate || 1 / RATE) / MARKUP_RATE
    )
  }, [invertedExchangeRate?.data?.rate, isSalaryCurrencyUSD, MARKUP_RATE, RATE])

  useEffect(() => {
    if (
      !forGenerator &&
      currencyCode &&
      salaryCurrency &&
      currencyCode !== salaryCurrency &&
      yearlyGross
    ) {
      exchange.startFetch({
        amount: yearlyGross,
        source: salaryCurrency,
        target: currencyCode,
      })
    }
  }, [currencyCode])

  useEffect(() => {
    const newArray = [...array]
    newArray[0].first_month_payroll.value = (yearlyGross / 12).toFixed(2)
    newArray[0].monthly_payroll.value = (yearlyGross / 12).toFixed(2)
    setArray(newArray)
  }, [yearlyGross])

  useEffect(() => {
    if (forGenerator && countryId && yearlyGross && currencyCode) {
      const body = {
        country_code:
          countryCode ?? eorCountries?.find((c) => c.id === countryId)?.iso2,
        currency: currencyCode,
        annual_gross_salary: yearlyGross,
      }
      eorPrefill.startFetch(body)
    }
  }, [countryId, currencyCode])

  useEffect(() => {
    if (contractId && !readOnly) {
      const body = {
        contract_id: contractId,
      }
      eorPrefill.startFetch(body)
    }
  }, [])

  useEffect(() => {
    setValue('payroll', array)
  }, [array])

  const {
    totalMonthlyPayroll,
    totalFirstMonthPayroll,
    managementFees,
    indirectTaxes,
    usdHandlingFee,
  } = useMemo(() => {
    /**
     * totalMonthlyPayroll in local currency
     * Excludes Management Fee, Indirect Taxes, and USD Handling fee
     */
    const totalMonthlyPayroll = array
      .filter((n) => !USD_FEES_LABELS.includes(n?.monthly_payroll?.name))
      .reduce((a, n) => {
        return a + parseFloat(n?.monthly_payroll?.value || 0)
      }, 0)

    /**
     * totalFirstMonthPayroll is in local currency
     * Excludes Management Fee, Indirect Taxes, and USD Handling fee
     */
    const totalFirstMonthPayroll = array
      .filter((n) => !USD_FEES_LABELS.includes(n?.first_month_payroll?.name))
      .reduce((a, n) => {
        return a + parseFloat(n?.first_month_payroll?.value || 0)
      }, 0)

    const managementFees = parseFloat(
      array?.find((p) =>
        MANAGEMENT_FEE_LABELS.includes(p.monthly_payroll?.name),
      )?.monthly_payroll?.value ?? 0,
    )

    const indirectTaxes = parseFloat(
      array?.find((p) => p.monthly_payroll?.name === indirectTaxLabel)
        ?.monthly_payroll?.value ?? 0,
    )

    const usdHandlingFee = parseFloat(
      array?.find((p) => p.monthly_payroll?.name === usdHandlingFeeLabel)
        ?.monthly_payroll?.value ?? 0,
    )

    return {
      totalMonthlyPayroll,
      totalFirstMonthPayroll,
      managementFees,
      indirectTaxes,
      usdHandlingFee,
    }
  }, [array])

  /**
   * Total of Management Fee, Indirect Taxes, and USD Handling fee
   */
  const feesTotalInLocalCurrency = useMemo(() => {
    return parseFloat(
      (managementFees + indirectTaxes + usdHandlingFee) * INVERTED_RATE,
    )
  }, [managementFees, indirectTaxes, usdHandlingFee, INVERTED_RATE])

  /**
   * sets deposit_amount value.
   * Converts totalMonthlyPayroll to USD, then add Management Fee, Indirect Taxes, and USD Handling fee
   */
  useEffect(() => {
    const depositAmount =
      totalMonthlyPayroll * RATE +
      (managementFees + indirectTaxes + usdHandlingFee)

    setValue(
      'deposit_amount',
      Number(depositAmount?.toFixed(2)), // in USD.
    )
  }, [usdHandlingFee, managementFees, indirectTaxes, totalMonthlyPayroll, RATE])

  return (
    <div>
      <CardBody className='m-0 p-0'>
        <Form
          autoComplete='off'
          className='form-horizontal m-0 pt-3 pt-md-4'
          onSubmit={handleSubmit(onValidate, (err) => {
            // eslint-disable-next-line no-console
            console.log(err)
          })}
        >
          <fieldset disabled={readOnly || eorPrefill.isLoading}>
            <Row className='p-0 m-0 mb-4' style={{ gap: '1rem 0' }}>
              <Col md={6} sm={12}>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label pt-0'>
                    Gross salary
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 96 }}>
                    <ControlledCurrencyInput
                      containerClassName='tw-w-min'
                      control={control}
                      name='yearly_gross'
                      error={errors.yearly_gross}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon className='tw-w-fit' addonType='append'>
                      <InputGroupText
                        className={classnames({
                          'p-0': true,
                          'border-danger': !!errors.currency_code,
                        })}
                        style={{ width: '100%', border: 0 }}
                      >
                        <ControlledSelect
                          error={errors.currency_code}
                          control={control}
                          name='currency_code'
                          options={currencies?.data?.map((c) =>
                            mapCurrencyToOption(c),
                          )}
                          classNamePrefix='RS-Addon'
                          customComponents={{
                            Option: customOption,
                            Value: customSingleValue,
                            SingleValue: customSingleValue,
                          }}
                          isDisabled={readOnly}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              {!!forGenerator && (
                <Col md={6} sm={12}>
                  <FormGroup className='mb-0'>
                    <Label className='col-form-label pt-0'>
                      Country
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </Label>
                    <ControlledSelect
                      options={eorCountries.map((c) =>
                        mapCountryToOption(c, 'id'),
                      )}
                      control={control}
                      name='country_id'
                      error={errors.country_id}
                      isDisabled={readOnly}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    Markup
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledCurrencyInput
                    control={control}
                    name='markup'
                    error={errors.markup}
                    isDisabled={readOnly || isSalaryCurrencyUSD}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className='p-0 m-0' id='ww-00'>
              <Col md={6} sm={12}>
                <Row>
                  <Col md={12} className='mb-3'>
                    <Row className='m-0 align-items-center d-flex justify-content-between'>
                      <Label className='font-size-24'>Month 1 Payroll</Label>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col md={6} sm={12}>
                <Row>
                  <Col md={12} className='mb-3'>
                    <Row className='m-0 align-items-center d-flex justify-content-between'>
                      <Label className='font-size-24'>
                        General Monthly Payroll
                      </Label>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {array.map((f, i) => (
              <>
                {f?.first_month_payroll?.name === usdHandlingFeeLabel && (
                  <hr className='border-top' />
                )}
                <Row
                  id={`ww-${f.id}-${i}`}
                  key={`ww-${f?.first_month_payroll?.name}`}
                  className='p-0 m-0'
                >
                  <Col md={6}>
                    <FormGroup>
                      <ControlledInputLabel
                        required={f?.first_month_payroll?.required ?? true}
                        value={array[i]?.first_month_payroll?.name}
                        onChange={(v) =>
                          onChangeName(i, 'first_month_payroll', v)
                        }
                        name={`payroll.${i}.first_month_payroll.name`}
                      />
                      <InputGroup style={{ zIndex: 6 }}>
                        <CurrencyInput
                          className={classnames({
                            'form-control': true,
                            'border-danger':
                              !array[i]?.first_month_payroll?.value &&
                              formState.submitCount > 0,
                          })}
                          value={array[i]?.first_month_payroll?.value}
                          onValueChange={(v) =>
                            onChangeValue(i, 'first_month_payroll', v)
                          }
                          disabled={
                            readOnly || array[i]?.first_month_payroll?.disabled
                          }
                          // currency input props
                          allowDecimals={true}
                          decimalsLimit={2}
                          decimalSeparator='.'
                          groupSeparator=','
                        />
                        <InputGroupAddon addonType='append'>
                          {USD_FEES_LABELS.includes(
                            array[i]?.first_month_payroll?.name,
                          )
                            ? usdCurrencyCode
                            : currencyCode}
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlledInputLabel
                        required={f?.monthly_payroll?.required ?? true}
                        value={array[i]?.monthly_payroll?.name}
                        onChange={(v) => onChangeName(i, 'monthly_payroll', v)}
                        name={`payroll.${i}.monthly_payroll.name`}
                      />
                      <InputGroup style={{ zIndex: 6 }}>
                        <CurrencyInput
                          className={classnames({
                            'form-control': true,
                            'border-danger':
                              !array[i]?.monthly_payroll?.value &&
                              formState.submitCount > 0,
                          })}
                          value={array[i]?.monthly_payroll?.value}
                          onValueChange={(v) =>
                            onChangeValue(i, 'monthly_payroll', v)
                          }
                          disabled={
                            readOnly || array[i]?.monthly_payroll?.disabled
                          }
                          // currency input props
                          allowDecimals={true}
                          decimalsLimit={2}
                          decimalSeparator='.'
                          groupSeparator=','
                        />
                        <InputGroupAddon addonType='append'>
                          {USD_FEES_LABELS.includes(
                            array[i]?.first_month_payroll?.name,
                          )
                            ? usdCurrencyCode
                            : currencyCode}
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {i < 1 || readOnly
                  ? null
                  : !MANAGEMENT_FEE_LABELS.includes(
                      array[i]?.first_month_payroll?.name,
                    ) && (
                      <Col key={`col-${f?.first_month_payroll?.name}`}>
                        <Row
                          key={`ww-${f?.first_month_payroll?.name}`}
                          className='m-0 p-0 mb-2 align-items-center d-flex justify-content-end'
                        >
                          <a
                            className='text-danger font-size-14'
                            onClick={() => {
                              const newArray = [...array]
                              newArray.splice(i, 1)
                              setArray(newArray)
                            }}
                          >
                            Remove
                          </a>
                        </Row>
                      </Col>
                    )}
              </>
            ))}
            <Col>
              <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-end'>
                {!!readOnly && (
                  <UncontrolledTooltip
                    placement='top'
                    target='add-custom-field'
                  >
                    You do not have permission to manage this contract or this
                    contract has been signed.
                  </UncontrolledTooltip>
                )}
                <a
                  id='add-custom-field'
                  className='text-primary font-size-14'
                  onClick={onAppend}
                >
                  Add field
                </a>
              </Row>
            </Col>
            <Row className='p-0 m-0 border-top py-4'>
              <Col>
                <span className='rp-font-bold font-size-14'>Total:</span>{' '}
                <span className='font-size-14 text-muted'>
                  {currencyCode
                    ? getCurrencyFormatter(currencyCode).format(
                        totalFirstMonthPayroll + feesTotalInLocalCurrency,
                      )
                    : parseFloat(
                        totalFirstMonthPayroll + feesTotalInLocalCurrency,
                      ).toFixed(2)}
                </span>
              </Col>
              <Col>
                <span className='rp-font-bold font-size-14'>Total:</span>{' '}
                <span className='font-size-14 text-muted'>
                  {currencyCode
                    ? getCurrencyFormatter(currencyCode).format(
                        totalMonthlyPayroll + feesTotalInLocalCurrency,
                      )
                    : parseFloat(
                        totalMonthlyPayroll + feesTotalInLocalCurrency,
                      ).toFixed(2)}
                </span>
              </Col>
            </Row>
            <Row className='p-0 m-0'>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    Deposit
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 6 }}>
                    <ControlledCurrencyInput
                      containerClassName='tw-w-min'
                      control={control}
                      name='deposit_amount'
                      error={errors.deposit_amount}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon addonType='append'>
                      {currency || usdCurrencyCode}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    Setup fee
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 6 }}>
                    <ControlledCurrencyInput
                      containerClassName='tw-w-min'
                      control={control}
                      name='setup_fee'
                      error={errors.setup_fee}
                      isDisabled={readOnly}
                      hideError
                    />
                    <InputGroupAddon addonType='append'>
                      {currency || usdCurrencyCode}
                    </InputGroupAddon>
                  </InputGroup>
                  {!errors.setup_fee ? null : (
                    <InputFeedback className='tw-mt-1'>
                      {getInputErrorMessage(errors.setup_fee)}
                    </InputFeedback>
                  )}
                </FormGroup>
              </Col>

              {workVisaCost ? (
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label className='col-form-label pt-0'>
                      Visa cost
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </Label>
                    <InputGroup style={{ zIndex: 6 }}>
                      <ControlledCurrencyInput
                        containerClassName='tw-w-min'
                        control={control}
                        name='work_visa_cost'
                        error={errors.work_visa_cost}
                        isDisabled={readOnly}
                      />
                      <InputGroupAddon addonType='append'>
                        {currency || usdCurrencyCode}
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}
              <Col sm={12} md={12}>
                <FormGroup id='scope-contract-info'>
                  <Label>
                    Additional Text
                    <span className='text-danger font-size-16 mx-1' />
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <Suspense fallback={<Loader />}>
                    <ReactQuill
                      formats={[
                        'font',
                        'size',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'indent',
                        'image',
                        'video',
                      ]}
                      readOnly={readOnly}
                      id='scopeEdit'
                      modules={{
                        toolbar: null,
                      }}
                      defaultValue=''
                      value={scope}
                      onChange={(content, delta, source, editor) => {
                        setScope(editor.getHTML())
                      }}
                      placeholder='Additional text'
                      style={{ whiteSpace: 'pre-line' }}
                    />
                  </Suspense>
                </FormGroup>
                <FormText>
                  You can use underlines, italics, and bold shortcut commands to
                  edit text. Ctrl\⌘ + U, etc.
                </FormText>
              </Col>
            </Row>
            <Row className='justify-content-end p-0 m-0 p-4'>
              {readOnly ? null : (
                <Button
                  type='submit'
                  disabled={readOnly || loading}
                  loading={loading}
                >
                  {initialData ? 'Update' : 'Create Quote'}
                </Button>
              )}
            </Row>
          </fieldset>
        </Form>
      </CardBody>
    </div>
  )
}

export default QuotationForm
