import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
} from '../../../../store/actions'

// Layout Related Components
import AdminPanelLayoutContent from './admin-panel-layout-content'

function capitalizeFirstLetter(string) {
  return string.charAt(1).toUpperCase() + string.slice(2)
}

class Layout extends Component {
  componentDidMount() {
    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(function () {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }

    // Scroll Top to 0
    window.scrollTo(0, 0)
    const currentPage = capitalizeFirstLetter(this.props.location.pathname)

    document.title =
      currentPage +
      ' | RemotePass - Compliance & Payment for remote contractors'

    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme)
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth)
    }
    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType)
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id='preloader'>
          <div id='status'>
            <div className='spinner-chase'>
              <div className='chase-dot'></div>
              <div className='chase-dot'></div>
              <div className='chase-dot'></div>
              <div className='chase-dot'></div>
              <div className='chase-dot'></div>
              <div className='chase-dot'></div>
            </div>
          </div>
        </div>

        <AdminPanelLayoutContent
          leftSideBarTheme={this.props.leftSideBarTheme}
          leftSideBarType={this.props.leftSideBarType}
          routePermissions={this.props.routePermissions}
        >
          {this.props.children}
        </AdminPanelLayoutContent>

        {/* {this.props.showRightSidebar ? <RightBar /> : null} */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { ...state.Layout }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout))
