import React, { useRef, useState } from 'react'
import { Col, Container, Row, TabPane } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import StepContainer from '../../../../components/Steps/StepContainer'
import ModalHeader from '../../../../components/ModalHeader'
import Steps from '../../../../components/Steps'
import { useHistory } from 'react-router'
import {
  bambooRequest,
  bambooUpdateContractors,
} from '../../../../store/bamboo/actions'
import { useFetch } from '../../../../helpers/hooks'
import {
  getBambooContractors,
  saveBambooContractors,
  saveDataToBamboo,
} from '../../../../services/api'
import toastr from 'toastr'
import Tab1 from '../HibobIntegration/Tab1'
import TabX from '../HibobIntegration/TabX'
import Tab4 from '../HibobIntegration/Tab4'
import TabContent from '../../../../components/ui/tabs'

const Index = () => {
  const tab1Ref = useRef()
  const bambooReq = useSelector((state) => state.bamboo.request)

  const [activePage, setActivePage] = useState(0)
  const history = useHistory()
  const dispatch = useDispatch()

  const steps = [
    'Bamboo Integration',
    'Information to sync',

    'Auto create future contracts',
    'Existing contracts',
  ]

  const contractors = useSelector((state) => state.bamboo.contractors)

  const saveContractors = useFetch({
    action: saveBambooContractors,
    onComplete: () => {
      history.push('/settings/integrations')
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const saveData = useFetch({
    action: saveDataToBamboo,
    onComplete: (data) => {
      if (
        Object.prototype.hasOwnProperty.call(data, 'success') &&
        !data?.success
      ) {
        toastr.error(data?.data?.contract_types)
      }
      if (data?.connected === 1) {
        callGetBambooContractors.startFetch()

        setActivePage(activePage + 1)
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const callGetBambooContractors = useFetch({
    action: getBambooContractors,
    onComplete: (data) => {
      dispatch(bambooUpdateContractors(data))
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const handleSave = () => {
    const contractTypes = bambooReq?.contract_types
      ?.filter((type) => type.status === true)
      .map((type) => type.name)
    const fields = bambooReq?.fields
      ?.filter((type) => type.status === true)
      .map((type) => type.name)
    saveData.startFetch({ ...bambooReq, contract_types: contractTypes, fields })
  }

  return (
    <div>
      <Container fluid className='!tw-px-0'>
        <ModalHeader
          action={() => {
            history.push('/settings/integrations')
          }}
        >
          <Steps activeTab={activePage} data={steps} />
        </ModalHeader>

        <Row className='justify-content-center'>
          <Col>
            <TabContent
              activeTab={activePage}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  title='Bamboo Integration'
                  index={1}
                  total={steps.length}
                  onNext={() => {
                    tab1Ref.current.submit()
                  }}
                  loading={false}
                  isFlat
                  noBack
                >
                  <Tab1
                    platform='bamboo'
                    ref={tab1Ref}
                    onComplete={(data) => {
                      dispatch(bambooRequest({ ...bambooReq, ...data }))
                      setActivePage(activePage + 1)
                    }}
                  />
                </StepContainer>
              </TabPane>
              <TabPane tabId={1}>
                <StepContainer
                  title='Information to sync'
                  index={2}
                  total={steps.length}
                  onNext={() => {
                    setActivePage(activePage + 1)
                  }}
                  loading={false}
                  isFlat
                  disableNext={
                    !bambooReq?.fields.filter((f) => f?.status).length > 0
                  }
                  onBack={() => setActivePage(activePage - 1)}
                >
                  <TabX
                    platform='bamboo'
                    context='tab3'
                    onComplete={(data) => {
                      dispatch(bambooRequest({ ...bambooReq, fields: data }))
                    }}
                  />
                </StepContainer>
              </TabPane>
              <TabPane tabId={2}>
                <StepContainer
                  title='Auto create future contracts'
                  index={3}
                  total={steps.length}
                  onNext={handleSave}
                  loading={saveData.isLoading}
                  isFlat
                  disableNext={
                    !bambooReq?.contract_types.filter((f) => f?.status).length >
                    0
                  }
                  onBack={() => setActivePage(activePage - 1)}
                >
                  <TabX
                    platform='bamboo'
                    context='tab2'
                    onComplete={(data) => {
                      dispatch(
                        bambooRequest({ ...bambooReq, contract_types: data }),
                      )
                    }}
                  />
                </StepContainer>
              </TabPane>
              <TabPane tabId={3}>
                <StepContainer
                  title='Existing contracts'
                  index={4}
                  total={steps.length}
                  onNext={() =>
                    saveContractors.startFetch({
                      contractors: contractors
                        .filter((c) => c.checked)
                        .map((c) => c.id),
                    })
                  }
                  noBack
                  loading={saveContractors.isLoading}
                  isFlat
                >
                  <Tab4 platform='bamboo' />
                </StepContainer>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export const loadingTab = () => {
  return <div className='loading-tab'></div>
}

export default Index
