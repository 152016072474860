import React, { useState } from 'react'
import { Modal, ModalFooter, ModalHeader } from 'reactstrap'
import { deleteApiKey } from '../../../services/openapi'
import toastr from 'toastr'
import Button from '../../../components/ui/button'
import { Warning } from '@phosphor-icons/react'
import { format } from 'date-fns'
import DataTable from '../../../components/ui/data-table'
import { useFetch } from '../../../helpers/hooks'

function DeleteKeyModal({
  name,
  deleteApiKey,
  isOpen,
  toggle,
  isLoading,
  onCancelClicked,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <div>
          <Warning className='tw-text-2xl tw-font-bold tw-text-red-100' />
          <div className='tw-mt-2 tw-text-lg tw-font-medium'>
            Are you sure you want to delete this key, {name}?
          </div>
          <div className='tw-mt-2 tw-text-sm tw-font-extralight tw-text-text-80'>
            This action cannot be undone and will immediately disable access for
            any services using this key.
          </div>
        </div>
      </ModalHeader>

      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={onCancelClicked}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type='button'
          color='danger'
          onClick={deleteApiKey}
          disabled={isLoading}
          loading={isLoading}
          className='tw-capitalize'
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function RowDelete({ keyId, keyName, deleteApiKey }) {
  return (
    <div>
      <Button
        color='link'
        textClassName='tw-text-red-100'
        onClick={() => deleteApiKey(keyId, keyName)}
      >
        Delete
      </Button>
    </div>
  )
}

function PaginatedTable({ data, onDeleteClicked }) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Prefix',
      accessor: 'prefix_id',
    },
    {
      Header: 'Generated on',
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        return format(new Date(cellData), 'MM/dd/yyyy')
      },
    },
    {
      Header: '',
      Cell: ({ rowData }) => {
        return (
          <RowDelete
            keyId={rowData._id}
            keyName={rowData.name}
            deleteApiKey={(id, name) => onDeleteClicked(id, name)}
          />
        )
      },
    },
  ]

  return (
    <DataTable columns={columns} condensed data={data} expandable={false} />
  )
}

export function ApiKeysPaginatedTable({ listItems, onItemDeleted }) {
  const [selectedKey, setSelectedKey] = useState(null)

  const { isLoading: isDeleting, startFetch: deleteKey } = useFetch({
    isOpenApi: true,
    action: deleteApiKey,
    onError: (error) => {
      setSelectedKey(null)
      toastr.error(error)
    },
    onComplete: () => {
      onItemDeleted(selectedKey)
      setSelectedKey(null)
    },
  })

  function onDeleteClicked(id, name) {
    setSelectedKey({
      id,
      name,
    })
  }

  return (
    <div className='tw-w-full tw-px-8'>
      <DeleteKeyModal
        deleteApiKey={() => deleteKey({ keyId: selectedKey?.id })}
        isLoading={isDeleting}
        isOpen={!!selectedKey}
        name={selectedKey?.name}
        onCancelClicked={() => setSelectedKey(null)}
        toggle={() => setSelectedKey(null)}
      />
      <PaginatedTable
        data={listItems}
        onDeleteClicked={(id, name) => onDeleteClicked(id, name)}
      />
    </div>
  )
}
