import { Invoice, Money, Plus, Storefront } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'
import { useReadLocalStorage } from 'usehooks-ts'

import { Avatar, cn } from 'ui'
import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Head from '../../../components/head'
import { PageNav, useActiveTab } from '../../../components/page-nav'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import SearchBar from '../../../components/SearchBar'
import Button from '../../../components/ui/button'
import { CheckItem } from '../../../components/ui/check-item'
import DataTable from '../../../components/ui/data-table'
import { LinkOut } from '../../../components/ui/link-out'
import PageHeading from '../../../components/ui/page-heading'
import Pagination from '../../../components/ui/pagination'
import { Result } from '../../../components/ui/result'
import Shimmer from '../../../components/ui/shimmer'
import { useSideMenuState } from '../../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import { useUrlStateV2 } from '../../../helpers/hooks/use-url-state'
import permissions from '../../../helpers/permissions'
import {
  getBillCategories,
  getBillCount,
  getBills,
  getDefaultBillApprovalFlow,
  getVendors,
} from '../../../services/api-bill-payments'
import { updateToPayList } from '../../../store/payment/actions'
import { track } from '../../../utils/analytics'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { mapListToOption } from '../../../utils/map-to-option'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import BillCategoryIcon from '../bill-category-icon'
import { useBillModuleActive } from '../bill-permission-hook'
import { AddBankSideMenu } from '../vendors/list/add-bank-side-menu'
import { EditVendorDetailsSideMenu } from '../vendors/list/edit-vendor-details'
import BillDetailsButton, {
  BillDueDate,
  BillStatusBadge,
  PAYMENT_STATUS,
} from './bill-details-button'
import {
  BillOnboardingModal,
  lsBillOnboardingShown,
} from './bill-onboarding-modal'
import { BillUpSelling } from './bill-up-selling'
import PendingStatusPage from './pending-status-page'

export const BILL_STATUS = {
  DRAFT: 'draft',
  PENDING: 'pending_approval',
  READY: 'pending_payment',
  COMPLETED: 'completed',
}

export const BILL_MODULE_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECTED: 'rejected',
}

const BILL_PAY_STATUS_OPTIONS = [
  { label: 'Waiting for funds', value: 'waiting_for_funds' },
  { label: 'Processing', value: 'processing' },
  { label: 'Paid', value: 'paid' },
  { label: 'Declined', value: 'declined' },
]

const PageHeader = ({ actionsDisabled }) => {
  const { hasAccess } = usePermissions()
  const canManageVendors = hasAccess(permissions.ManageVendors)
  return (
    <PageHeading className='tw-mb-4'>
      <PageHeading.Title className='tw-flex tw-items-baseline tw-gap-2'>
        Bill pay
        <LinkOut
          href='https://help.remotepass.com/en/articles/10226893-how-to-manage-bills'
          title='Learn more about bill pay'
        />
      </PageHeading.Title>

      <PageHeading.ActionGroup className='tw-flex-wrap tw-gap-x-2'>
        <PermissionTooltip
          showing={!canManageVendors}
          id='manage-vendors-btn'
          area={PERMISSION_GROUP.MANAGE_VENDORS.name}
          action=''
        >
          <Button
            className='!tw-border-0 !tw-bg-white !tw-text-black'
            icon={<Storefront size={20} />}
            to='/bills/vendors'
            tag={Link}
            disabled={!canManageVendors || actionsDisabled}
          >
            Manage Vendors
          </Button>
        </PermissionTooltip>

        <AddBillInvoiceButton actionsDisabled={actionsDisabled} />
      </PageHeading.ActionGroup>
    </PageHeading>
  )
}

function AddBillInvoiceButton({ actionsDisabled }) {
  const { hasAccess } = usePermissions()
  const { id: userID } = useSelector((state) => state.Account?.user)
  const canManageBills = hasAccess([
    permissions.ManageAllBills,
    permissions.ManageOwnBills,
  ])
  const trackAddInvoice = () => {
    track('Clicked Add Invoice', { userID })
  }
  return (
    <PermissionTooltip
      showing={!canManageBills}
      id='manage-bills-btn'
      area={PERMISSION_GROUP.CREATE_BILL.name}
      action=''
    >
      <Button
        icon={<Plus size={20} />}
        tag={Link}
        to='/bills/create'
        disabled={!canManageBills || actionsDisabled}
        onClick={trackAddInvoice}
      >
        Add Invoice
      </Button>
    </PermissionTooltip>
  )
}

const tabsData = [
  {
    label: 'Draft',
    key: BILL_STATUS.DRAFT,
  },
  { label: 'For Approval', key: BILL_STATUS.PENDING },
  {
    label: 'Ready To Pay',
    key: BILL_STATUS.READY,
  },
  { label: 'Completed', key: BILL_STATUS.COMPLETED },
]

function TabHeaderWrapper({ children, hideTabsAndActions }) {
  return (
    <PageNav className='!tw-mb-4 !tw-flex-wrap tw-rounded tw-bg-white'>
      {hideTabsAndActions ? <div className='tw-h-[72px]'></div> : children}
    </PageNav>
  )
}
function HeaderTabs({ activeTab, billCountData }) {
  return tabsData.map((data) => {
    const count = billCountData?.[data.key] ?? ''
    const isActive = activeTab === data.key

    return (
      <PageNav.Item key={data.key}>
        <PageNav.Link
          to={`/bills?tab=${data.key}`}
          tag={Link}
          isActive={isActive}
          className='!tw-flex tw-items-center tw-gap-2'
        >
          {data.label}
          {!count ? null : (
            <span
              className={cn(
                'tw-flex tw-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded tw-border tw-p-0.5 tw-text-xs',
                isActive
                  ? 'tw-border-primary-100 tw-bg-primary-100 tw-text-white'
                  : 'tw-border-surface-30',
              )}
            >
              {count}
            </span>
          )}
        </PageNav.Link>
      </PageNav.Item>
    )
  })
}

const billFiltersDefaultValues = {
  date_range: undefined,
  min_amount: undefined,
  max_amount: undefined,
  vendor: undefined,
  category: undefined,
  selectedItem: undefined,
  status: undefined,
}

export function BillInfoText({ title, subTitle, className }) {
  return (
    <span
      className={cn(
        'tw-text-right tw-text-sm tw-font-normal tw-text-text-60',
        className,
      )}
    >
      <div className='tw-text-text'>{title}</div>
      {subTitle}
    </span>
  )
}
export const StatusButton = ({ cellData, rowData, fetchBills }) => {
  const [isEditVendorAddressOpen, toggleEditVendorAddress] = useSideMenuState()
  const [isAddBankOpen, toggleAddBank] = useSideMenuState()
  const handleBadgeClick = () => {
    if (cellData.toLowerCase() === PAYMENT_STATUS.MISSING_BANK_DETAILS) {
      toggleAddBank()
    }
    if (cellData.toLowerCase() === PAYMENT_STATUS.MISSING_VENDOR_DETAILS) {
      toggleEditVendorAddress()
    }
  }
  const Component = [
    PAYMENT_STATUS.MISSING_BANK_DETAILS,
    PAYMENT_STATUS.MISSING_VENDOR_DETAILS,
  ].includes(cellData.toLowerCase())
    ? 'button'
    : 'div'

  return (
    <>
      <Component onClick={handleBadgeClick}>
        <BillStatusBadge status={cellData} />
      </Component>

      <EditVendorDetailsSideMenu
        isOpen={isEditVendorAddressOpen}
        toggle={toggleEditVendorAddress}
        updateVendors={() => fetchBills(undefined, false)}
        vendor={rowData?.vendor}
        source='bill_list'
      />
      <AddBankSideMenu
        isOpen={isAddBankOpen}
        toggle={toggleAddBank}
        updateVendors={() => fetchBills(undefined, false)}
        vendor={{
          ...rowData?.vendor,
          latest_bill: { pdf: rowData?.file?.pdf },
        }}
        source='bill_list'
      />
    </>
  )
}

const ApprovalComponent = ({ billApprovalFlow, id }) => {
  const itemId = `next-approver-id-${id}`
  const remainingApprovers = billApprovalFlow?.steps?.slice(
    billApprovalFlow?.steps?.findIndex((v) => v.user_id === id) + 1,
  )
  const billApprovalFlowLength = remainingApprovers?.length

  return billApprovalFlowLength >= 1 ? (
    <>
      <span
        id={itemId}
        className='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-secondary-20 tw-px-3 tw-py-3 tw-text-sm tw-font-bold tw-text-black'
      >
        {`+${billApprovalFlowLength}`}
      </span>
      <UncontrolledTooltip
        placement='top'
        target={itemId}
        innerClassName='!tw-text-start'
      >
        {remainingApprovers.map((step, index) => {
          return <div key={index}>{step.user_name}</div>
        })}
      </UncontrolledTooltip>
    </>
  ) : null
}

const Body = ({ activeTab }) => {
  const { hasAccess } = usePermissions()
  const canManageBills = hasAccess([
    permissions.ManageBill,
    permissions.ManageOwnBills,
  ])
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const isBillEnabled =
    userProfile.company.bill_status === BILL_MODULE_STATUS.APPROVED

  const isBillPending =
    userProfile.company.bill_status === BILL_MODULE_STATUS.PENDING

  const {
    value: {
      selectedItem,
      date_range: dateRange,
      vendor,
      category,
      min_amount: minAmount,
      max_amount: maxAmount,
      status,
    },
    updater: setUrlState,
  } = useUrlStateV2(billFiltersDefaultValues)
  function setSelectedItem(item) {
    setUrlState({ selectedItem: item })
  }

  const [selectedItems, setSelectedItems] = useState([])
  const history = useHistory()
  const location = history.location
  const searchParams = new URLSearchParams(location.search)
  const currentPage = searchParams.get('page') ?? 1

  const { data: categories, isLoading: fetchingCategories } = useFetch(
    {
      action: getBillCategories,
      autoFetch: !!isBillEnabled,
      onError: toastr.error,
    },
    [isBillEnabled],
  )

  const { id: selectedCategoryId, name: selectedCategoryName } =
    categories?.find((cat) => cat.name === category) ?? {}
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()

  const [startDate, endDate] = dateRange ? dateRange.split(',') : [null, null]

  const {
    data: bills,
    isLoading: isLoadingBills,
    startFetch: fetchBills,
    paginator,
  } = useFetch(
    {
      action: getBills,
      autoFetch: !!isBillEnabled,
      body: {
        vendor_id: vendor,
        due_date_from: startDate,
        due_date_to: endDate,
        min_amount: minAmount,
        max_amount: maxAmount,
        category_id: selectedCategoryId,
        status:
          activeTab === BILL_STATUS.COMPLETED
            ? (status ?? activeTab)
            : activeTab,
        page: currentPage,
      },
      onError: (error) => toastr.error(error),
    },
    [
      vendor,
      startDate,
      endDate,
      minAmount,
      maxAmount,
      selectedCategoryId,
      activeTab,
      currentPage,
      isBillEnabled,
      status,
    ],
  )
  const isAllSelected = selectedItems.length === bills?.length
  const isFiltering =
    vendor ||
    dateRange ||
    selectedCategoryId ||
    maxAmount ||
    minAmount ||
    status
  const hasIntegration = !!categories?.[0]?.integration_id

  const { data: approvalFlow } = useFetch(
    {
      action: getDefaultBillApprovalFlow,
      autoFetch: !!isBillEnabled,
    },
    [isBillEnabled],
  )

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([])
    } else {
      const selectedBills = []
      bills?.forEach((bill) => selectedBills.push(bill))
      setSelectedItems(selectedBills)
    }
  }

  const columns = [
    {
      Header: hasIntegration ? 'GL Account' : 'Category',
      accessor: 'category',
      Cell: ({ cellData }) =>
        !cellData ? (
          'N/A'
        ) : (
          <div className='tw-flex tw-gap-2'>
            {!hasIntegration && <BillCategoryIcon category={cellData?.name} />}
            {cellData?.name}
          </div>
        ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>Created on</span>
      ),
      accessor: 'created_at',
      Cell: ({ cellData, rowData }) => (
        <BillInfoText
          title={format(new Date(cellData), 'dd/MM/yyyy')}
          subTitle={
            rowData.creator_name &&
            `By ${rowData.creator_name} ${user.id === rowData.creator_id ? '(You)' : ''}`
          }
          className='tw-text-left'
        />
      ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>Due date</span>
      ),
      accessor: 'due_date',
      Cell: ({ cellData }) => <BillDueDate date={cellData} />,
    },
    { Header: 'Vendor', accessor: 'vendor.name' },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>Amount</span>
      ),
      accessor: 'amount',
      Cell: ({ rowData }) => {
        const formatter = getCurrencyFormatter(rowData.currency.code)
        return <span>{formatter.format(rowData.amount)}</span>
      },
    },
    { Header: 'Invoice Number', accessor: 'details' },
    activeTab === BILL_STATUS.PENDING
      ? {
          Header: 'Next Approver',
          accessor: 'next_approver',
          Cell: ({ cellData }) => {
            return (
              <span className='tw-flex tw-gap-2'>
                <span className='tw-flex tw-items-center tw-gap-1 tw-rounded-[32px] tw-bg-secondary-20 tw-px-2 tw-py-1'>
                  <Avatar photo={cellData.photo} name={cellData.name} />
                  <span className='tw-text-sm tw-font-semibold tw-text-black'>
                    {cellData.name}
                  </span>
                </span>
                <ApprovalComponent
                  billApprovalFlow={billApprovalFlow}
                  id={cellData.id}
                />
              </span>
            )
          },
        }
      : {
          Header: 'Status',
          accessor: 'status.name',
          Cell: ({ cellData, rowData }) => (
            <StatusButton
              cellData={cellData}
              rowData={rowData}
              fetchBills={fetchBills}
            />
          ),
        },
    {
      Cell: ({ rowData }) => (
        <BillDetailsButton
          bill={rowData}
          fetchBills={fetchBills}
          activeTab={tabsData.find((tab) => tab.key === activeTab)}
          onPayClick={() => {
            onPayClick({
              paymentIds: [rowData.payment_item_id],
              location,
              dispatch,
              history,
            })
          }}
          activeItem={selectedItem || undefined}
          showItem={() => setSelectedItem(rowData?.name)}
          closeItem={() => setSelectedItem(undefined)}
          approvalFlow={approvalFlow}
          hasIntegration={hasIntegration}
        />
      ),
    },
    activeTab === BILL_STATUS.READY &&
      hasAccess(permissions.PrepareTransactions) &&
      canManageBills && {
        Header: (
          <Button color='link' onClick={onSelectAll} className='tw-w-28'>
            {isAllSelected ? `Deselect ` : 'Select '} All
          </Button>
        ),
        Cell: ({ rowData }) => {
          const isItemSelected = selectedItems.find(
            (item) => item.id === rowData.id,
          )
          return (
            <span className='tw-flex tw-justify-center'>
              <CheckItem
                className='tw-w-fit'
                checked={isAllSelected || isItemSelected}
                onChange={() => {
                  setSelectedItems(
                    isItemSelected
                      ? [
                          ...selectedItems.filter(
                            (item) => item.id !== rowData.id,
                          ),
                        ]
                      : [...selectedItems, rowData],
                  )
                }}
              />
            </span>
          )
        },
      },
  ].filter(Boolean)

  const companyId = useSelector(
    (state) => state.userProfile?.userProfile?.company?.id,
  )

  const billOnboardingLsValue = useReadLocalStorage(lsBillOnboardingShown)
  const billOnboardingShown = Array.isArray(billOnboardingLsValue)
    ? billOnboardingLsValue.includes(companyId)
    : false
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)
  const canOnboard = hasAccess([permissions.manageCompanySettings])

  const {
    data: billApprovalFlow,
    isLoading: isLoadingBillApprovalFlow,
    startFetch: fetchBillApprovalFlow,
  } = useFetch(
    {
      action: getDefaultBillApprovalFlow,
      autoFetch: !!isBillEnabled,
      onError: (error) => toastr.error(error),
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error('Something went wrong while fetching the approval flow')
          return
        }
        if (!isOnboardingOpen) {
          const billsAreSetup = !!data?.id
          setIsOnboardingOpen(!billsAreSetup)
        }
      },
    },
    [isBillEnabled],
  )

  const billsAreSetup = !!billApprovalFlow?.id
  const loadingData = isLoadingBills || isLoadingBillApprovalFlow
  const hideTabsAndActions = (loadingData || !billsAreSetup) && !isFiltering

  const { data: billCountData } = useFetch(
    {
      action: getBillCount,
      autoFetch: !!billsAreSetup,
    },
    [billsAreSetup],
  )

  const showOnboardingModal =
    isOnboardingOpen && !loadingData && !billOnboardingShown

  const shouldBillBeSetupButCannot = showOnboardingModal && !canOnboard

  const showAddInvoiceButton = !isFiltering && activeTab === BILL_STATUS.DRAFT
  const noBills = bills?.length === 0

  if (!isBillEnabled && !isBillPending) {
    return (
      <>
        <PageHeading className='tw-mb-4'>
          <PageHeading.Title className='tw-flex tw-items-baseline tw-gap-2'>
            Bill pay
          </PageHeading.Title>
        </PageHeading>

        <BillUpSelling />
      </>
    )
  }

  if (isBillPending) {
    return <PendingStatusPage />
  }

  return (
    <>
      <PageHeader actionsDisabled={hideTabsAndActions} />

      <TabHeaderWrapper hideTabsAndActions={hideTabsAndActions}>
        <HeaderTabs activeTab={activeTab} billCountData={billCountData} />
      </TabHeaderWrapper>

      <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
        {showOnboardingModal || !billsAreSetup ? null : (
          <BodyHeader
            isLoading={isLoadingBills}
            isFiltering={isFiltering}
            location={location}
            history={history}
            dispatch={dispatch}
            itemsToPay={selectedItems}
            noBills={noBills}
          />
        )}
        {!shouldBillBeSetupButCannot ? null : (
          <Result
            IconComponent={(props) => (
              <Invoice
                {...props}
                weight='duotone'
                className={cn(
                  props.className,
                  'tw-size-64 tw-text-primary-100',
                )}
              />
            )}
            title='You can’t start processing bills'
            description='Contact an admin user to first setup an approval flow for bill payments. '
            className='tw-mx-auto tw-min-h-[550px] tw-justify-center'
          />
        )}
        {!(noBills && !isFiltering) && (
          <FilterRow
            isLoading={(isLoadingBills && !isFiltering) || fetchingCategories}
            categories={categories}
            isBillEnabled={isBillEnabled}
            selectedCategory={
              selectedCategoryId
                ? { label: selectedCategoryName, value: selectedCategoryId }
                : undefined
            }
            hasIntegration={hasIntegration}
            activeTab={activeTab}
          />
        )}
        {isLoadingBills ? (
          <span className='tw-flex tw-flex-col tw-gap-2'>
            <Shimmer width='100%' height={50} />
            <Shimmer width='100%' height={50} />
            <Shimmer width='100%' height={50} />
          </span>
        ) : shouldBillBeSetupButCannot ? null : noBills ? (
          <Result
            IconComponent={(props) => (
              <Invoice
                {...props}
                weight='duotone'
                className={cn(
                  props.className,
                  'tw-size-64 tw-text-primary-100',
                )}
              />
            )}
            title='You have no bill to show'
            description={
              !billsAreSetup
                ? 'To get started with bills, please follow the onboarding process.'
                : showAddInvoiceButton
                  ? 'You can click Add Invoice button and create one.'
                  : ''
            }
            actions={
              !billsAreSetup ? (
                <Button type='button' onClick={() => setIsOnboardingOpen(true)}>
                  Get Started
                </Button>
              ) : !showAddInvoiceButton ? null : (
                <AddBillInvoiceButton actionsDisabled={hideTabsAndActions} />
              )
            }
            className='tw-mx-auto'
          />
        ) : (
          <>
            <DataTable columns={columns} data={bills} striped responsive />
            <span className='tw-flex tw-justify-end'>
              <Pagination
                activePage={Number(currentPage)}
                onChange={(page) => {
                  searchParams.set('page', page)
                  history.replace(
                    `${history.location.pathname}?${searchParams.toString()}`,
                  )
                }}
                itemsCountPerPage={paginator?.per_page}
                totalItemsCount={paginator?.total ?? 0}
              />
            </span>
          </>
        )}
      </div>

      {!showOnboardingModal || !canOnboard ? null : (
        <BillOnboardingModal
          isOpen={isOnboardingOpen}
          toggle={() => {
            setIsOnboardingOpen((open) => !open)
          }}
          onApprovalFlowUpdated={fetchBillApprovalFlow}
          billApprovalFlow={billApprovalFlow}
        />
      )}
    </>
  )
}

const onPayClick = ({ dispatch, history, paymentIds, location }) => {
  dispatch(updateToPayList(paymentIds))
  history.push(
    `/pay-invoices?from=${encodeURIComponent(location.pathname + location.search)}&only_bank=true`,
  )
}

const BodyHeader = ({
  isLoading,
  isFiltering,
  location,
  dispatch,
  history,
  itemsToPay = [],
  noBills,
}) => {
  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })
  const companyCurrency = useSelector(
    (state) => state.userProfile?.userProfile?.company?.currency?.code,
  )
  const formatter = getCurrencyFormatter(companyCurrency)
  const { hasAccess } = usePermissions()
  const paymentIds = itemsToPay.map((item) => item.payment_item_id)
  const canManageBills = hasAccess([
    permissions.ManageAllBills,
    permissions.ManageOwnBills,
  ])
  return (
    <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-2'>
      {!isLoading || isFiltering ? (
        <div className='tw-text-2xl'>
          {tabsData.find((data) => data.key === activeTab).label}
        </div>
      ) : (
        <Shimmer width={130} height={32} />
      )}

      {activeTab === BILL_STATUS.READY &&
        hasAccess(permissions.PrepareTransactions) &&
        canManageBills &&
        !noBills && (
          <Button
            disabled={isLoading || itemsToPay.length === 0}
            icon={<Money />}
            onClick={() =>
              onPayClick({ paymentIds, location, dispatch, history })
            }
          >
            {itemsToPay.length === 0
              ? 'Select To Pay'
              : 'Pay ' +
                formatter.format(
                  itemsToPay.reduce((acc, item) => acc + item.trans_amount, 0),
                )}
          </Button>
        )}
    </div>
  )
}

const FilterRowWrapper = ({ children, className }) => (
  <div
    className={cn(
      'tw-grid tw-gap-x-2 tw-gap-y-4 *:!tw-w-full sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-5',
      className,
    )}
  >
    {children}
  </div>
)

const FilterRow = ({
  isLoading,
  isBillEnabled,
  categories,
  selectedCategory,
  hasIntegration,
  activeTab,
}) => {
  const { data: vendors, isLoading: fetchingVendors } = useFetch(
    {
      action: getVendors,
      autoFetch: !!isBillEnabled,
      onError: (error) => toastr.error(error),
    },
    [isBillEnabled],
  )
  const {
    updater: setUrlState,
    value: {
      min_amount: minAmount,
      max_amount: maxAmount,
      date_range: dateRange,
      vendor,
      status,
    },
  } = useUrlStateV2(billFiltersDefaultValues, { replaceRoute: true })

  const [startDate, endDate] = dateRange ? dateRange.split(',') : [null, null]

  function handleDateRangeChange(dates) {
    const [start, end] = dates
    const formattedDateStart = start ? format(start, 'yyyy-MM-dd') : null
    const formattedDateEnd = end ? format(end, 'yyyy-MM-dd') : null
    const range = [formattedDateStart, formattedDateEnd]
      .filter(Boolean)
      .join(',')
    setUrlState({ date_range: range })
    return dates
  }

  if (isLoading) {
    return (
      <FilterRowWrapper>
        {new Array(5).fill(0).map((_, index) => (
          <Shimmer key={index} height={56} />
        ))}
      </FilterRowWrapper>
    )
  }

  return (
    <FilterRowWrapper>
      <CustomDatePicker
        placeholder='Due Date'
        clearable
        handleClear={() => setUrlState({ date_range: null })}
        inputClassName='!tw-h-[56px] !tw-flex tw-items-center'
        selected={!startDate ? null : new Date(startDate)}
        startDate={!startDate ? null : new Date(startDate)}
        endDate={!endDate ? null : new Date(endDate)}
        selectsRange
        handleOnChange={handleDateRangeChange}
      />

      <CustomSelect
        placeholder={hasIntegration ? 'GL Account' : 'Category'}
        isClearable
        options={categories?.map((category) => mapListToOption(category))}
        onChange={(val) => setUrlState({ category: val?.label })}
        value={selectedCategory}
        selectStyles={{ control: () => ({ height: '56px' }) }}
      />

      <CustomSelect
        placeholder='Vendor'
        isClearable
        options={vendors?.map(mapListToOption)}
        onChange={(val) => setUrlState({ vendor: val?.value })}
        value={
          vendor
            ? mapListToOption(
                vendors?.find((_vendor) => _vendor.id === Number(vendor)),
              )
            : undefined
        }
        selectStyles={{ control: () => ({ height: '56px' }) }}
        isDisabled={fetchingVendors}
        isLoading={fetchingVendors}
      />

      {activeTab === BILL_STATUS.COMPLETED && (
        <CustomSelect
          placeholder='Status'
          isClearable
          options={BILL_PAY_STATUS_OPTIONS}
          onChange={(val) => setUrlState({ status: val?.value })}
          value={BILL_PAY_STATUS_OPTIONS.find(
            (option) => option.value === status,
          )}
          selectStyles={{ control: () => ({ height: '56px' }) }}
        />
      )}

      <SearchBar
        type='number'
        placeholder='Min Amount'
        inputClassName='!tw-h-14'
        customIcon={<InputPrefix>$</InputPrefix>}
        query={minAmount}
        onQueryChanged={(val) => setUrlState({ min_amount: val || undefined })}
        min={0}
      />

      <SearchBar
        type='number'
        placeholder='Max Amount'
        inputClassName='!tw-h-14'
        customIcon={<InputPrefix>$</InputPrefix>}
        query={maxAmount}
        onQueryChanged={(val) => setUrlState({ max_amount: val || undefined })}
        min={0}
      />
    </FilterRowWrapper>
  )
}

function InputPrefix({ children }) {
  return (
    <div className='tw-pointer-events-none tw-absolute tw-left-3 tw-top-1/2 tw-flex tw-w-4 -tw-translate-y-1/2 tw-items-center tw-justify-center tw-font-semibold tw-text-text-80'>
      {children}
    </div>
  )
}

export default function Bills() {
  useBillModuleActive()

  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })

  return (
    <div className='page-content'>
      <Head title='Bill pay' />
      <Body activeTab={activeTab} />
    </div>
  )
}
