import {
  ArrowLeft,
  CalendarCheck,
  CalendarPlus,
  GridFour,
  IdentificationBadge,
  IdentificationCard,
  Info,
  Medal,
  TreePalm,
  SuitcaseSimple,
  Tag,
  TreeStructure,
  UserCircleGear,
  UserList,
  Users,
  Warning,
} from '@phosphor-icons/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import {
  assignContractsToPolicy,
  getUnassignedContracts,
  unassignContractsToPolicy,
} from '../../../../services/api-time-off-policies'
import { getFullName } from '../../../../utils/get-full-name'
import { UserProfile } from '../../../org-chart/organization-chart'
import { ConfirmFormField } from '../../../Contract/CreateContract/components/confirm-field'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { boolean, date, number, object, string } from 'yup'
import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import { format } from 'date-fns'
import { PrimaryAlert } from '../../../../components/ui/alert'
import { TimeOffPolicyTypes } from '../../../Contract/utils/constants'
import { getContractDetail } from '../../../../services/api'
import { DetailsList } from './view-policy'
import { ContractBadge } from '../../../Contract/ContractDetail/contract-timeline'
import { Link } from 'react-router-dom'
import FEATURE_FLAGS from '../../../../config/feature-flags'
import permissions from '../../../../helpers/permissions'
import { track } from '../../../../utils/analytics'
import { TIMEOFF_EVENTS } from '../../../new-time-off-policy/events'
import ControlledInput from '../../../../components/ControlledInput'

export function labelFromType(type) {
  if (!type) {
    return ''
  }

  if (type === 'bi_weekly') {
    return 'Bi-Weekly'
  }

  const label = String(type).trim().replaceAll('_', ' ').replaceAll('-', ' ')
  const capitalized = label.charAt(0).toUpperCase() + label.slice(1)

  return capitalized
}

function formatContract(contract) {
  return {
    id: contract?.id,
    name: getFullName(contract?.contractor),
    role: contract?.name,
    flag: contract?.contractor?.flag,
    photo: contract?.contractor?.photo,
    contractorType: labelFromType(contract?.contractor?.contractor_type),
    contractType: contract?.type,
    companyName: contract?.entity_name,
    isFormatted: true,
    ref: contract?.ref,
  }
}

function getAssignmentChanges({ policy, assignedWorkers }) {
  const defaultSelectedWorkersIds = (policy?.contracts ?? [])?.map((c) => c.id)
  const newAssignedWorkers = assignedWorkers.map((w) => w.id)

  const workersToAssign = []
  const workersToUnassign = []

  const all = new Set([...newAssignedWorkers, ...defaultSelectedWorkersIds])

  all.forEach((workerId) => {
    if (
      defaultSelectedWorkersIds.includes(workerId) &&
      !newAssignedWorkers.includes(workerId)
    ) {
      workersToUnassign.push(workerId)
    } else if (
      !defaultSelectedWorkersIds.includes(workerId) &&
      newAssignedWorkers.includes(workerId)
    ) {
      workersToAssign.push(workerId)
    }
  })

  return { workersToAssign, workersToUnassign }
}

const workerTabs = {
  assignedWorkers: 'assigned-workers',
  unassignedWorkers: 'add-workers',
}

export const EARNING_DATE_TYPE = {
  CUSTOM: 'custom_date',
  EARNING_START_DAYS: 'earning_start_days',
}

const WORKER_ACTION_TEXT = { REMOVE: 'Remove', ASSIGN: 'Assign' }

const schema = object().shape({
  earning_start_date: string()
    .label('Earning start date')
    .oneOf([EARNING_DATE_TYPE.CUSTOM, EARNING_DATE_TYPE.EARNING_START_DAYS])
    .required(),
  [EARNING_DATE_TYPE.EARNING_START_DAYS]: boolean().notRequired(),
  [EARNING_DATE_TYPE.CUSTOM]: date().when('earning_start_date', {
    is: EARNING_DATE_TYPE.CUSTOM,
    then: (schema) => schema.required('Please select a custom date'),
    otherwise: (schema) => schema.notRequired(),
  }),
  [EARNING_DATE_TYPE.EARNING_START_DAYS]: number()
    .typeError('Please insert a number')
    .when('earning_start_date', {
      is: EARNING_DATE_TYPE.EARNING_START_DAYS,
      then: (schema) => schema.required('Please insert amount of days'),
      otherwise: (schema) => schema.notRequired(),
    }),
})

function getTrackingData(policy) {
  return {
    type: policy.type,
    accrual_frequency: policy.accrual_frequency ?? null,
    carryover_used: policy.carryover_type.toLowerCase() === 'limited',
    source: 'Company settings',
  }
}

export function ManagePolicyWorkers({ policy, onSuccess, companyPolicies }) {
  const [assignedWorkers, setAssignedWorkers] = useState(
    policy?.contracts?.map(formatContract) ?? [],
  )
  const [showEarningDateModal, setShowEarningDateModal] = useState(false)
  const [selectedContract, setSelectedContract] = useState()
  const [activeTab, setActiveTab] = useState(workerTabs.assignedWorkers)
  const [isCloseConfirmOpen, setIsCloseConfirmOpen] = useState()
  const [isOpen, setIsOpen] = useState()
  const { hasAccess } = usePermissions()

  function toggleCloseConfirmModal() {
    setIsCloseConfirmOpen((open) => !open)
  }

  function toggleMenu() {
    setIsOpen((open) => {
      if (open) {
        setAssignedWorkers(policy?.contracts?.map(formatContract) ?? [])
        setActiveTab(workerTabs.assignedWorkers)
      }
      return !open
    })
  }

  function handleClose() {
    if (unsavedChanges) {
      toggleCloseConfirmModal()
    } else {
      toggleMenu?.()
      setSelectedContract(undefined)
    }
  }

  // Calls the api
  const [unAssignedWorkersSearch, setUnAssignedWorkersSearch] = useState('')
  // Search in place
  const [assignedWorkersSearch, setAssignedWorkersSearch] = useState('')

  const userToken = useSelector((state) => state.Account?.user?.token)

  const { data: contractDetails, isLoading: fetchingContractDetails } =
    useFetch(
      {
        action: getContractDetail,
        autoFetch: selectedContract,
        body: { id: selectedContract },
      },
      [selectedContract],
    )

  const {
    fetchNextPage,
    isFetchingNextPage,
    data: unassignedContractsData,
    status,
  } = useInfiniteQuery({
    queryKey: [
      userToken,
      'unassigned_contracts',
      policy?.id,
      unAssignedWorkersSearch,
    ],
    queryFn: ({ pageParam, signal, queryKey }) => {
      // allow users to search workers with or without #
      const searchFromQueryKey =
        queryKey[3].charAt(0) === '#'
          ? queryKey[3].substring(1).toLowerCase()
          : queryKey[3].toLowerCase()

      const policyId = queryKey[2]

      return getUnassignedContracts(
        userToken,
        { policyId, page: pageParam, search_key: searchFromQueryKey },
        { signal },
      )
    },
    select: (data) => {
      return {
        pages: data?.pages.map((page) => page.data),
        pageParams: data?.pageParams,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.paginator.current_page
      const hasMorePages = lastPage.data.paginator.has_more_pages

      const nextPage = hasMorePages ? currentPage + 1 : undefined
      return nextPage
    },
    enabled: !!isOpen,
  })

  const unassignedFlatItems = unassignedContractsData?.pages
    ?.map((item) => item.data)
    ?.flat()

  const totalUnassignedWorkers =
    unassignedContractsData?.pages?.[0]?.paginator?.total

  const unassignedWorkers = (unassignedFlatItems ?? [])
    ?.map((contract) => {
      if (contract?.isFormatted) {
        return contract
      } else {
        return formatContract(contract)
      }
    })
    .filter((contract) => {
      return assignedWorkers.length <= 0
        ? true
        : !assignedWorkers.map((w) => w.id).includes(contract.id)
    })

  const filteredWorkers = useCallback(
    function (value) {
      // Unassigned worker are filtered from the BE
      if (value === workerTabs.unassignedWorkers) {
        return unassignedWorkers
      }

      // Assigned workers are filtered here
      return assignedWorkers.filter(({ name, ref }) => {
        if (!assignedWorkersSearch) {
          return true
        } else if (assignedWorkersSearch.length > 0) {
          if (name?.length <= 0) {
            return false
          } else {
            // allowing the user to search with or without '#'
            const filteredTagSearch =
              assignedWorkersSearch.charAt(0) === '#'
                ? assignedWorkersSearch.substring(1).toLowerCase()
                : assignedWorkersSearch.toLowerCase()
            return (
              name?.toLowerCase().includes(filteredTagSearch) ||
              ref?.toLowerCase().includes(filteredTagSearch)
            )
          }
        }
        return true
      })
    },
    [assignedWorkers, assignedWorkersSearch, unassignedWorkers],
  )

  function assignWorker(worker) {
    setAssignedWorkers((workers) => [...workers, worker])
  }
  function removeWorker(worker) {
    setAssignedWorkers((workers) =>
      workers.filter(({ id }) => id !== worker.id),
    )
  }

  const tabs = [
    {
      label: 'Assigned workers',
      count: assignedWorkers.length,
      value: workerTabs.assignedWorkers,
    },
    {
      label: 'Add workers',
      value: workerTabs.unassignedWorkers,
    },
  ]

  const { startFetch: assignContracts, isLoading: assigningContracts } =
    useFetch({
      action: assignContractsToPolicy,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.error || 'Failed to assign contracts to policy')
        } else {
          toastr.success(
            'Workers successfully updated',
            'Time off policy saved',
          )
          onSuccess?.()
          track(TIMEOFF_EVENTS.ASSIGNED_WORKER, {
            ...getTrackingData(policy),
            custom_accrual_date:
              watch('earning_start_date') === EARNING_DATE_TYPE.CUSTOM,
          })
        }
      },
      onError: (error) => toastr.error(error),
    })

  const { startFetch: unassignContracts, isLoading: unassigningContracts } =
    useFetch({
      action: unassignContractsToPolicy,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error('Failed to unassign contracts to policy')
        } else {
          toastr.success(
            'Workers successfully updated',
            'Time off policy saved',
          )
          onSuccess?.()
          track(TIMEOFF_EVENTS.UNASSIGNED_WORKER, getTrackingData(policy))
        }
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  const loading = assigningContracts || unassigningContracts

  const { workersToAssign, workersToUnassign } = getAssignmentChanges({
    policy,
    assignedWorkers,
  })

  const unsavedChanges =
    workersToAssign.length > 0 || workersToUnassign.length > 0

  function handleSave() {
    if (!unsavedChanges) {
      toggleMenu()
      return
    }

    if (workersToAssign.length > 0) {
      if (policy.type.is_accrued) {
        setShowEarningDateModal(true)
        return
      }

      assignContracts({
        policyId: policy?.id,
        contracts: workersToAssign,
      })
    }
    if (workersToUnassign.length > 0) {
      unassignContracts({
        policyId: policy?.id,
        contracts: workersToUnassign,
      })
    }
  }

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      earning_start_date: EARNING_DATE_TYPE.EARNING_START_DAYS,
      earning_start_days: policy.earning_start_days,
    },
  })

  const onSubmit = (data) => {
    let startDate
    let days

    if (data.earning_start_date !== EARNING_DATE_TYPE.EARNING_START_DAYS) {
      startDate = format(new Date(data[EARNING_DATE_TYPE.CUSTOM]), 'yyyy-MM-dd')
    } else {
      days = data[EARNING_DATE_TYPE.EARNING_START_DAYS]
    }

    assignContracts({
      policyId: policy?.id,
      contracts: workersToAssign,
      start_date: startDate,
      [EARNING_DATE_TYPE.EARNING_START_DAYS]: days,
    })
  }

  return (
    <>
      <Button
        type='button'
        onClick={toggleMenu}
        className='tw-mt-4'
        block
        icon={<UserCircleGear size={20} className='tw-flex-shrink-0' />}
        outline
      >
        Manage workers
      </Button>

      <SideMenu
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
        isOpen={isOpen}
        onClose={handleClose}
      >
        <SideMenuHeader className='tw-block tw-p-0'>
          <div className='tw-flex tw-flex-col tw-p-4'>
            <div className='tw-flex tw-items-center'>
              {selectedContract && (
                <button
                  type='button'
                  onClick={() => setSelectedContract(undefined)}
                >
                  <ArrowLeft className='tw-mr-2' />
                </button>
              )}
              <div className='tw-flex-1 tw-font-semibold'>
                {selectedContract
                  ? contractDetails?.status?.name
                  : 'Manage workers'}
              </div>
              <ModalCloseButton
                toggle={handleClose}
                className='!tw-text-black'
                size={24}
              />
            </div>
            <span
              className={cn(
                'tw-text-sm tw-text-text-60',
                !!selectedContract && 'tw-ml-[28px]',
              )}
            >
              {!selectedContract
                ? `Assign “${policy?.name}” policy to your workers`
                : `#${selectedContract}`}
            </span>
          </div>
          {!selectedContract && (
            <Nav
              tabs
              justified
              className='tw-items-stretch tw-overflow-hidden tw-rounded !tw-border-b-0 !tw-border-t !tw-border-t-surface-30'
            >
              {tabs.map(({ label, value, count }) => {
                const isActive = value === activeTab

                return (
                  <NavItem key={value}>
                    <NavLink
                      tag='button'
                      type='button'
                      className={cn(
                        '!tw-mb-0 !tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-gap-2 !tw-border-x-0 !tw-border-b-2 !tw-border-t-0 !tw-px-4 !tw-py-6 tw-text-sm tw-font-medium',
                        isActive
                          ? '!tw-border-b-primary-100 !tw-bg-primary-10 tw-text-primary-100'
                          : 'tw-text-text-80 hover:!tw-border-transparent',
                      )}
                      onClick={() => {
                        setActiveTab(value)
                        setUnAssignedWorkersSearch('')
                      }}
                    >
                      <div>{label}</div>
                      {!count &&
                      count !== 0 &&
                      value !== workerTabs.unassignedWorkers ? null : (
                        <div
                          className={cn(
                            'tw-min-h-5 tw-min-w-5 tw-rounded tw-border tw-p-0.5 tw-text-xs',
                            isActive
                              ? 'tw-border-primary-100 tw-bg-primary-100 tw-font-bold tw-text-white'
                              : 'tw-border-surface-30 tw-bg-transparent tw-font-semibold',
                          )}
                        >
                          {count}
                          {value === workerTabs.unassignedWorkers &&
                            totalUnassignedWorkers}
                        </div>
                      )}
                    </NavLink>
                  </NavItem>
                )
              })}
            </Nav>
          )}
        </SideMenuHeader>
        <SideMenuBody className='tw-flex-grow !tw-p-0'>
          {!selectedContract ? (
            <TabContent activeTab={activeTab}>
              {tabs.map(({ value }) => {
                const workers = filteredWorkers(value)
                return (
                  <TabPane tabId={value} key={value}>
                    <Workers
                      search={
                        value === workerTabs.assignedWorkers
                          ? assignedWorkersSearch
                          : unAssignedWorkersSearch
                      }
                      setSearch={
                        value === workerTabs.assignedWorkers
                          ? setAssignedWorkersSearch
                          : setUnAssignedWorkersSearch
                      }
                      onIdBadgeClick={(worker) =>
                        setSelectedContract(worker.ref)
                      }
                      loading={
                        value === workerTabs.assignedWorkers
                          ? false
                          : status === 'pending' && !unAssignedWorkersSearch
                      }
                      loadingSearch={
                        value === workerTabs.assignedWorkers
                          ? null
                          : status === 'pending'
                      }
                      isFetchingNextPage={
                        value === workerTabs.assignedWorkers
                          ? null
                          : isFetchingNextPage
                      }
                      fetchNextPage={
                        value === workerTabs.assignedWorkers
                          ? null
                          : fetchNextPage
                      }
                      workers={workers}
                      workerActionText={
                        value === workerTabs.assignedWorkers
                          ? WORKER_ACTION_TEXT.REMOVE
                          : WORKER_ACTION_TEXT.ASSIGN
                      }
                      onWorkerClick={(worker) => {
                        if (value === workerTabs.assignedWorkers) {
                          removeWorker(worker)
                        } else {
                          assignWorker(worker)
                        }

                        setUnAssignedWorkersSearch('')
                      }}
                      policy={policy}
                      companyPolicies={companyPolicies}
                    />
                  </TabPane>
                )
              })}
            </TabContent>
          ) : fetchingContractDetails ? (
            <Loader minHeight='max(50vh, 550px)' />
          ) : (
            <div>
              <DetailsList
                title='Contract details'
                items={[
                  {
                    icon: <IdentificationBadge size={24} />,
                    label: 'Contract ID',
                    value: '#' + selectedContract,
                  },
                  {
                    icon: <Tag size={24} />,
                    label: 'Status',
                    value: <ContractBadge status={contractDetails?.status} />,
                  },
                  contractDetails?.name && {
                    icon: <SuitcaseSimple size={24} />,
                    label: 'Role',
                    value: contractDetails?.name,
                  },
                  contractDetails?.start_date && {
                    icon: <CalendarCheck size={24} />,
                    label: 'Start date',
                    value: contractDetails?.start_date,
                  },
                  contractDetails?.employment_term && {
                    icon: <Medal size={24} />,
                    label: 'Tenure',
                    value: contractDetails?.employment_term,
                  },
                  contractDetails?.type && {
                    icon: <IdentificationCard size={24} />,
                    label: 'Hire type',
                    value: contractDetails?.type,
                  },
                ].filter(Boolean)}
              />

              <hr className='tw-my-8' />

              <DetailsList
                title='Organizational information'
                items={[
                  contractDetails?.department && {
                    icon: <GridFour size={24} />,
                    label: 'Department',
                    value: contractDetails?.department,
                  },
                  {
                    icon: <TreeStructure size={24} />,
                    label: 'Head of department',
                    value: contractDetails?.is_head_of_department
                      ? 'Yes'
                      : 'No',
                  },
                  contractDetails?.reporting_to && {
                    icon: <Users size={24} />,
                    label: 'Reporting to',
                    value: contractDetails?.reporting_to,
                  },
                  {
                    icon: <UserList size={24} />,
                    label: 'Direct reports',
                    value: contractDetails?.direct_reports?.length || 'None',
                  },
                ].filter(Boolean)}
              />
            </div>
          )}
        </SideMenuBody>
        <SideMenuFooter
          className={cn(
            'tw-items-center',
            !!selectedContract && 'tw-justify-between',
          )}
        >
          {!selectedContract ? (
            <>
              <div className='tw-text-xs tw-text-text-60'>
                {unsavedChanges ? 'You have unsaved changes' : null}
              </div>

              <CancelAction
                closeMenu={toggleMenu}
                loading={loading}
                handleClose={handleClose}
                isCloseConfirmOpen={isCloseConfirmOpen}
                toggleCloseConfirmModal={toggleCloseConfirmModal}
              />

              <Button
                type='button'
                disabled={loading}
                loading={loading}
                onClick={handleSave}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                type='button'
                color='light'
                outline
                icon={<TreePalm size={20} />}
                className='tw-flex-1 !tw-px-4'
                tag={Link}
                to={`/contract/detail?id=${selectedContract}&tab=timeOff`}
              >
                View Time off
              </Button>

              {FEATURE_FLAGS.ORGANIZATION_CHART &&
                hasAccess([
                  permissions.ViewOrgChart,
                  permissions.ManageOrgChart,
                ]) && (
                  <Button
                    type='button'
                    color='light'
                    outline
                    icon={<TreeStructure size={20} />}
                    className='tw-flex-1 !tw-px-4'
                    tag={Link}
                    to='/org-chart'
                  >
                    View OrgChart
                  </Button>
                )}
            </>
          )}
        </SideMenuFooter>
      </SideMenu>

      <ConfirmationModal
        isOpen={showEarningDateModal}
        toggle={() => setShowEarningDateModal(false)}
        caption='Save'
        onConfirm={handleSubmit(onSubmit)}
        confirmLoading={assigningContracts}
        content={
          <EarningDate
            watch={watch}
            control={control}
            setValue={setValue}
            onCloseClick={() => setShowEarningDateModal(false)}
            policyDefaultEarningDays={policy.earning_start_days}
          />
        }
      />
    </>
  )
}

function EarningDate({
  watch,
  control,
  setValue,
  onCloseClick,
  policyDefaultEarningDays = 0,
}) {
  return (
    <>
      <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
        <CalendarPlus size={24} className='tw-fill-black' />
        <ModalCloseButton
          toggle={onCloseClick}
          className='!tw-text-secondary-100'
          size={24}
        />
      </div>
      <div className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
        Earning start date
      </div>
      <div className='tw-text-sm tw-font-medium tw-text-text-80'>
        When does the worker start earning time off balance?
      </div>
      <hr className='-tw-mx-6 tw-my-6' />

      <ConfirmFormField
        control={control}
        name='earning_start_date'
        fieldOptions={[
          {
            label: 'Days after contract start',
            value: EARNING_DATE_TYPE.EARNING_START_DAYS,
          },
          { label: 'Custom date', value: EARNING_DATE_TYPE.CUSTOM },
        ]}
        innerClassName='tw-rounded-b-none'
        transform={{
          output: (event) => {
            setValue('custom_earning_date', null)
            setValue('earning_start_days', policyDefaultEarningDays)
            return event
          },
        }}
      />
      <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
        {watch('earning_start_date') === EARNING_DATE_TYPE.CUSTOM && (
          <ControlledDatePicker
            control={control}
            name={EARNING_DATE_TYPE.CUSTOM}
            id='custom_earning_date'
            placeholder='Earning date'
            label='Earning date'
          />
        )}
        {watch('earning_start_date') ===
          EARNING_DATE_TYPE.EARNING_START_DAYS && (
          <>
            <ControlledInput
              control={control}
              name={EARNING_DATE_TYPE.EARNING_START_DAYS}
              postFix='Calendar days'
              type='number'
              placeholder='Insert amount'
            />
            <div className='tw-mt-2 tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-text-80'>
              <Info size={16} />
              <span>
                Policy setting: {policyDefaultEarningDays} days after the
                contract start date
              </span>
            </div>
          </>
        )}
      </div>
    </>
  )
}

function Workers({
  workers = [],
  onWorkerClick,
  workerActionText,
  loading,
  loadingSearch,
  fetchNextPage,
  search,
  setSearch,
  isFetchingNextPage,
  policy,
  companyPolicies,
  onIdBadgeClick,
}) {
  const isDefaultPolicy =
    policy.type.id === TimeOffPolicyTypes.DEFAULT_POLICY_TYPE
  const hasDefaultPolicy = companyPolicies.find(
    (policy) => policy.type.id === TimeOffPolicyTypes.DEFAULT_POLICY_TYPE,
  )
  const companyAccruedPolicy = companyPolicies.find(
    (policy) => policy.type.is_accrued === 1,
  )

  function getInfo(type) {
    return type.toLowerCase() === 'assign'
      ? `This policy will replace the current ${
          isDefaultPolicy
            ? companyAccruedPolicy?.name
            : 'Default Time Off policy'
        }. Past time off taken will be included in the new policy.`
      : `Workers can't be unassigned from this core time off policy but can be changed to a different vacation type ${isDefaultPolicy ? '' : 'or Default time off'} policy.`
  }

  if (loading) {
    return <Loader minHeight='max(50vh, 550px)' />
  }

  const conditionForWarning =
    (policy.type.is_accrued === 1 && hasDefaultPolicy) ||
    (isDefaultPolicy && companyAccruedPolicy && !!workers.length) ||
    isDefaultPolicy

  return (
    <>
      <div className='tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-px-6 tw-pb-3 tw-pt-6'>
        <SearchBar
          placeholder='Search by name, contract ID'
          query={search}
          onQueryChanged={setSearch}
          isClearable
        />
      </div>

      {conditionForWarning && (
        <PrimaryAlert color='warning' className='tw-mx-6 tw-mb-6'>
          {getInfo(workerActionText)}
        </PrimaryAlert>
      )}

      <div className='tw-mt-3 tw-flex tw-flex-col tw-gap-4 tw-px-6 tw-pb-6'>
        {loadingSearch ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : workers.length <= 0 || workers.length <= 0 ? (
          <div className='tw-text-text-80'>No workers</div>
        ) : (
          workers.map((worker) => {
            const { id, name, role, flag, photo } = worker
            const props = { name, role, flag, photo, id }
            return (
              <div key={id} className='tw-group hover:tw-bg-surface-10'>
                <div className='tw-relative'>
                  <UserProfile
                    data={{
                      ...props,
                      handless: true,
                      linkToContract: true,
                      linkToContractHash: '&tab=timeOff',
                      className:
                        'tw-w-auto tw-bg-transparent tw-rounded-b-none',
                    }}
                    onIdBadgeClick={() => onIdBadgeClick(worker)}
                  />

                  {workerActionText === WORKER_ACTION_TEXT.REMOVE &&
                  conditionForWarning ? null : (
                    <Button
                      className='tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2 !tw-text-secondary-100 !tw-transition-all md:tw-opacity-0 md:group-hover:tw-opacity-100'
                      size='sm'
                      color='link'
                      onClick={() => onWorkerClick(worker)}
                    >
                      {workerActionText}
                    </Button>
                  )}
                </div>

                <div className='tw-rounded-b tw-border-x tw-border-b tw-border-surface-30 tw-bg-surface-10 tw-p-4 tw-text-xs tw-text-text-80'>
                  {[
                    worker?.contractorType,
                    worker?.companyName,
                    worker?.contractType,
                  ]
                    .filter(Boolean)
                    .join(' | ')}
                </div>
              </div>
            )
          })
        )}

        {!fetchNextPage || typeof fetchNextPage !== 'function' ? null : (
          <InView
            onChange={(isView) => {
              if (isView) {
                fetchNextPage()
              }
            }}
            as='div'
            rootMargin='400px 0px 0px 0px'
          >
            {!isFetchingNextPage ? null : (
              <div className='tw-rounded tw-border tw-border-surface-30'>
                <div className='tw-flex tw-items-center tw-gap-4 tw-p-4'>
                  <div className='tw-h-12 tw-w-12 tw-animate-pulse tw-rounded-full tw-bg-surface-20' />
                  <div>
                    <div className='tw-mt-2 tw-h-4 tw-w-32 tw-animate-pulse tw-rounded tw-bg-surface-20' />
                    <div className='tw-mt-1 tw-h-4 tw-w-16 tw-animate-pulse tw-rounded tw-bg-surface-20' />
                  </div>
                </div>
                <div className='tw-border-t tw-border-t-surface-30 tw-bg-surface-10 tw-p-4'>
                  <div className='tw-mt-1 tw-h-4 tw-w-32 tw-animate-pulse tw-rounded tw-bg-surface-20' />
                </div>
              </div>
            )}
          </InView>
        )}
      </div>
    </>
  )
}

function CancelAction({
  closeMenu,
  handleClose,
  loading,
  isCloseConfirmOpen,
  toggleCloseConfirmModal,
}) {
  return (
    <>
      <Button
        type='button'
        onClick={handleClose}
        color='light'
        outline
        disabled={loading}
      >
        Cancel
      </Button>

      <ConfirmationModal
        isOpen={isCloseConfirmOpen}
        toggle={toggleCloseConfirmModal}
        negativeCaption='No, keep editing'
        caption='Yes, discard changes'
        buttonColor='danger'
        onConfirm={() => {
          closeMenu?.()
          toggleCloseConfirmModal()
        }}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemRed-100' />
              <ModalCloseButton toggle={toggleCloseConfirmModal} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Are you sure you want to close and discard the changes?
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              All the changes you made now will be lost
            </p>
          </>
        }
      />
    </>
  )
}
