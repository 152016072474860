import {
  CalendarBlank,
  CirclesThree,
  ClockClockwise,
  CoinVertical,
  CurrencyCircleDollar,
  Gear,
  NotePencil,
  WarningOctagon,
  X,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  Badge,
  Card,
  CardBody,
  Carousel,
  CarouselItem,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import healthIllustration from '../../../assets/images/health-illustration.svg'
import remoteHealth from '../../../assets/images/remote-health-safety-wing.png'
import welcomeImage from '../../../assets/images/welcome.svg'
import NoContent from '../../../components/NoContent'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import { StyledH1 } from '../../../components/Typo'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  deactivateAllowanceInsuranceClient,
  getContractorContractAllowances,
} from '../../../services/api'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { AllowanceStatuses } from '../utils/constants'
import InsurancePlans from './InsurancePlans'
import './nav-dots.scss'
import TabEmpty from '../components/tab/tab-empty'

export const Allowance = ({
  contractUser,
  userInfo,
  contract,
  showOnboardingModal,
  insurancePlans,
  contractUserHasBirthDateAndCountry,
}) => {
  const [openBoostModal, setOpenBoostModal] = useState(false)
  const [
    openConfirmAllowanceDeactivationModal,
    setOpenConfirmAllowanceDeactivationModal,
  ] = useState(false)

  const contractorContractAllowances = useFetch({
    action: getContractorContractAllowances,
    body: { contract_id: contract?.id },
    autoFetch: true,
    onComplete: () => {
      setOpenBoostModal(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const contractorContractAllowancesLoading =
    !contractorContractAllowances.completed ||
    contractorContractAllowances?.isLoading

  const showAllowances =
    Array.isArray(contractorContractAllowances?.data) &&
    contractorContractAllowances?.data?.length &&
    contractorContractAllowances?.data.some(
      (a) => a?.status === AllowanceStatuses.ACTIVE,
    )

  useEffect(() => {
    if (
      showOnboardingModal &&
      contractorContractAllowances.completed &&
      !showAllowances
    ) {
      setOpenBoostModal(true)
    }
  }, [
    showOnboardingModal,
    contractorContractAllowances.completed,
    showAllowances,
  ])

  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'success'
      case 'inactive':
        return 'danger'
      default:
        return 'primary'
    }
  }

  const dateFormat = 'do MMM yyyy'

  const { hasAccess } = usePermissions()
  const hasAddAllowancePermission = hasAccess(permissions.addExpense)
  const hasDeleteAllowancePermission = hasAccess(permissions.deleteExpense)

  return userInfo?.type === userTypes.COMPANY ? (
    <>
      {insurancePlans?.isLoading || contractorContractAllowancesLoading ? (
        <Card style={{ minHeight: '80vh' }} className='bg-white rounded p-3'>
          <Loader minHeight='60vh' />
        </Card>
      ) : showAllowances ? (
        <Card className='p-0 m-0'>
          <CardBody className='p-0 m-0'>
            <TableH title='Allowances' />
            <TableComp className='table-centered'>
              <thead className='thead-light'>
                <StyledTh>
                  <div className='d-flex gap-4'>
                    <NotePencil fontSize={22} />
                    <span>Status</span>
                  </div>
                </StyledTh>
                <StyledTh>
                  <div className='d-flex gap-4'>
                    <CalendarBlank fontSize={22} />
                    <span>Start date</span>
                  </div>
                </StyledTh>
                <StyledTh>
                  <div className='d-flex gap-4'>
                    <ClockClockwise fontSize={22} />
                    <span>Occurrence</span>
                  </div>
                </StyledTh>
                <StyledTh>
                  <div className='d-flex gap-4'>
                    <CurrencyCircleDollar fontSize={22} />
                    <span>Cost</span>
                  </div>
                </StyledTh>
              </thead>
              <tbody>
                {contractorContractAllowances?.data?.map((allowance, i) => {
                  return (
                    <tr key={i}>
                      <StyledTd>
                        <BadgeX
                          status={getStatusColor(allowance.status)}
                          name={allowance.status}
                          textStatus={getStatusColor(allowance.status)}
                          className='text-capitalize'
                        >
                          {allowance?.status}
                        </BadgeX>
                      </StyledTd>
                      <StyledTd>
                        {format(new Date(allowance?.created_at), dateFormat)}
                      </StyledTd>
                      <StyledTd>{allowance?.occurrence?.name}</StyledTd>
                      <StyledTd>
                        {allowance?.currency?.symbol} {allowance?.amount}
                      </StyledTd>
                      <StyledTd>
                        {allowance.status === AllowanceStatuses.ACTIVE && (
                          <PermissionTooltip
                            id='delete-allowance-plan'
                            area={PERMISSION_GROUP.EXPENSES.name}
                            showing={!hasDeleteAllowancePermission}
                            className='d-inline-block'
                          >
                            <Button
                              size='md'
                              color='danger'
                              outline
                              onClick={() => {
                                setOpenConfirmAllowanceDeactivationModal(
                                  allowance,
                                )
                              }}
                              disabled={
                                allowance.status ===
                                  AllowanceStatuses.INACTIVE ||
                                !hasDeleteAllowancePermission
                              }
                              className='px-3'
                              icon={<X size={20} />}
                            >
                              Deactivate
                            </Button>
                          </PermissionTooltip>
                        )}
                      </StyledTd>
                    </tr>
                  )
                })}
              </tbody>
            </TableComp>
          </CardBody>
        </Card>
      ) : (
        <>
          {insurancePlans?.data?.country_not_supported ? (
            <Card className='bg-white rounded p-3'>
              <NoContent
                image={welcomeImage}
                subtitle={' '}
                headline={`${insurancePlans?.data?.country_name} is not supported yet`}
                onAction={() => {}}
              />
            </Card>
          ) : Array.isArray(insurancePlans?.data) &&
            insurancePlans?.data?.length ? (
            <>
              <Card className='p-0 m-0'>
                <CardBody className='p-0 m-0'>
                  <div className='tw-flex tw-items-center tw-justify-between'>
                    <TableH title='Allowance plans' />

                    <img
                      src={remoteHealth}
                      alt='Remote Health Logo'
                      className='tw-h-12 tw-pr-6'
                    />
                  </div>
                  <Row className='m-0 px-2'>
                    {insurancePlans?.data?.map((e, i) => (
                      <Col
                        key={`insurance-plan-${i}`}
                        md={4}
                        sm={12}
                        className='px-3'
                      >
                        <InsurancePlans
                          plan={e}
                          title={e.name}
                          desc={e.description}
                          features={e.conditions}
                          price={e.amount}
                          currency={e.currency}
                          contractUser={contractUser}
                          contract={contract}
                          ctaText='Add Allowance'
                          onAddAllowanceUpdate={contractorContractAllowances}
                          hasAddPermission={hasAddAllowancePermission}
                        />
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </>
          ) : (
            <TabEmpty
              title='No Allowance plans available'
              subtitle={
                contractUserHasBirthDateAndCountry
                  ? 'Available allowance plans will be shown here'
                  : `The ${contract?.contractor_name} has not provided their birth date and country`
              }
              icon={
                <CoinVertical
                  size={250}
                  color='var(--primary)'
                  weight='duotone'
                />
              }
            ></TabEmpty>
          )}
        </>
      )}

      <BoostModal
        isOpen={openBoostModal}
        toggle={() => setOpenBoostModal(false)}
      />

      {openConfirmAllowanceDeactivationModal !== null && (
        <ConfirmAllowanceDeactivationModal
          isOpen={!!openConfirmAllowanceDeactivationModal}
          toggle={() => setOpenConfirmAllowanceDeactivationModal(null)}
          allowance={openConfirmAllowanceDeactivationModal}
          contract={contract}
          onUpdate={contractorContractAllowances}
        />
      )}
    </>
  ) : (
    <></>
  )
}

const BoostModal = ({ isOpen, toggle }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const slides = [
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={2}
    >
      <ModalBody style={{ height: '615px', padding: '0px' }}>
        <img className='' src={healthIllustration} />
        <div className='p-3'>
          <Badge className='p-2 mb-2 font-size-10' color='primary' pill>
            New
          </Badge>
          <StyledH1 className='mb-2 rp-font-bold' style={{ marginBottom: 24 }}>
            Boost Talent, Productivity & Satisfaction!
          </StyledH1>
          <div className='text-muted'>
            Contractors now have the option to purchase health insurance on our
            platform themselves. This can help with attracting top talent,
            increase productivity and job satisfaction. and ultimately save
            costs in the long run.
          </div>
        </div>
      </ModalBody>
    </CarouselItem>,
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={1}
    >
      <ModalBody style={{ height: '615px' }}>
        <div className='m-3 py-2'>
          <StyledH1 className='my-3 pb-2 rp-font-bold'>
            It has never been simpler!
          </StyledH1>
          <Card className='p-4 mb-4 border border-1'>
            <div className='d-flex justify-content-between'>
              <CirclesThree
                className='mr-3'
                size={40}
                color='var(--primary)'
                weight='duotone'
              />
              <div>
                <p className='rp-font-bold font-size-16 mb-2'>
                  Multiple Options
                </p>
                <span className='font-size-14 text-muted'>
                  Contractors have access to multiple options for different
                  types of health insurance coverage to choose from.
                </span>
              </div>
            </div>
          </Card>
          <Card className='p-4 mb-4 border border-1'>
            <div className='d-flex justify-content-between'>
              <ClockClockwise
                className='mr-3'
                size={40}
                color='var(--primary)'
                weight='duotone'
              />
              <div>
                <p className='rp-font-bold font-size-16 mb-2'>Automated</p>
                <span className='font-size-14 text-muted'>
                  Create a recurring monthly allowance to be added to your
                  payroll every month.
                </span>
              </div>
            </div>
          </Card>
          <Card className='p-4 mb-4 border border-1'>
            <div className='d-flex justify-content-between'>
              <Gear
                className='mr-3'
                size={80}
                color='var(--primary)'
                weight='duotone'
              />
              <div>
                <p className='rp-font-bold font-size-16 mb-2'>Full Control</p>
                <span className='font-size-14 text-muted'>
                  Contractors have the right to use this allowance for whatever
                  purpose they wish. This includes, but is not limited to health
                  insurance.
                </span>
              </div>
            </div>
          </Card>
        </div>
      </ModalBody>
    </CarouselItem>,
  ]

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <button
        className='position-absolute right-0 z-1000 btn font-size-24 p-0 mr-2 rp-font-normal'
        onClick={toggle}
        type='button'
      >
        &times;
      </button>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
      >
        {slides}
        <ModalFooter className='justify-content-between'>
          <Button
            disabled={activeIndex === 0}
            color='light'
            type='button'
            outline
            onClick={() => {
              previous()
            }}
          >
            Back
          </Button>
          <div className='nav-dots'>
            <span
              className={activeIndex === 0 ? 'active' : ''}
              onClick={() => {
                goToIndex(0)
              }}
            />
            <span
              className={activeIndex === 1 ? 'active' : ''}
              onClick={() => {
                goToIndex(1)
              }}
            />
          </div>
          <Button
            color='primary'
            type='button'
            onClick={() => {
              if (activeIndex === 1) {
                toggle()
              } else {
                next()
              }
            }}
          >
            {activeIndex === 1 ? 'View Options' : 'Next'}
          </Button>
        </ModalFooter>
      </Carousel>
    </Modal>
  )
}

const ConfirmAllowanceDeactivationModal = ({
  isOpen,
  toggle,
  allowance,
  contract,
  onUpdate,
}) => {
  const deactivateAllowanceInsurance = useFetch({
    action: deactivateAllowanceInsuranceClient,
    body: { contract_id: contract?.id, allowance_id: allowance?.id },
    onComplete: () => {
      onUpdate?.startFetch()
    },
  })

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
      style={{
        width: '450px',
      }}
    >
      <ModalHeader toggle={toggle}>Confirm deactivation</ModalHeader>
      <ModalBody className='text-center align-items-center d-flex flex-column'>
        <WarningOctagon color='red' size={32} weight='duotone' />
        <div className='m-3'>
          Are you sure you want to deactivate this allowance?
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color='light' type='button' outline onClick={toggle}>
          Cancel
        </Button>
        <Button
          color='danger'
          type='button'
          onClick={() => {
            deactivateAllowanceInsurance.startFetch()
            toggle()
          }}
        >
          Deactivate
        </Button>
      </ModalFooter>
    </Modal>
  )
}
