import { Info } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'
import { Input, UncontrolledTooltip } from 'reactstrap'

import { cn } from 'ui'
import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

export default function ControlledCheckbox({
  name,
  id,
  control,
  label,
  leftLabel,
  defaultChecked,
  transform,
  className,
  containerClassName,
  leftLabelClassName,
  tooltipText,
  error,
  style,
  disabled,
}) {
  const tooltipId = `${id ?? name}-tooltip`

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        function handleChange(e) {
          onChange(e.target.checked)
        }

        return (
          <div className={containerClassName}>
            <label
              className={cn(
                'custom-control custom-switch tw-mb-0 !tw-flex',
                className,
              )}
              style={style}
            >
              {!leftLabel ? null : (
                <div
                  className={cn(
                    '-tw-ml-8 tw-select-none tw-pr-12',
                    leftLabelClassName,
                  )}
                  htmlFor={id}
                >
                  {leftLabel}
                </div>
              )}

              <Input
                type='checkbox'
                name={name}
                id={id}
                defaultChecked={defaultChecked}
                checked={transform?.input ? transform.input(value) : value}
                onChange={(e) =>
                  transform?.output
                    ? handleChange(transform.output(e))
                    : handleChange(e)
                }
                onBlur={onBlur}
                className='custom-control-input tw-sr-only'
                disabled={disabled}
              />

              {!label ? (
                <div className='custom-control-label tw-pointer-events-none tw-select-none before:!tw-pointer-events-none after:!tw-pointer-events-none' />
              ) : (
                <div
                  className='custom-control-label tw-flex tw-flex-grow tw-select-none tw-items-center'
                  htmlFor={id}
                >
                  {label}
                </div>
              )}

              {!tooltipText ? null : (
                <>
                  <Info
                    size={18}
                    id={tooltipId}
                    className='tw-ml-1 tw-flex-shrink-0 tw-text-text-80'
                  />

                  <UncontrolledTooltip placement='top' target={tooltipId}>
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </label>

            {!error ? null : (
              <InputFeedback className='tw-mt-1'>
                {getInputErrorMessage(error)}
              </InputFeedback>
            )}
          </div>
        )
      }}
    />
  )
}

export function ControlledCheckbox2({
  name,
  id,
  control,
  label,
  leftLabel,
  value,
  transform,
  className,
  containerClassName,
  containerId,
  tooltipText,
  error,
  style,
  disabled,
}) {
  const tooltipId = `${id ?? name}-tooltip`

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value: checkedValue, ...props } }) => {
        function handleChange(event) {
          if (typeof transform?.output === 'function') {
            onChange(transform.output(event))
          } else {
            const newValue = value ? event.target.value : event.target.checked
            onChange(newValue)
          }
        }

        const checked =
          typeof transform?.input === 'function'
            ? transform.input(checkedValue)
            : value
              ? checkedValue === value
              : checkedValue

        return (
          <div className={containerClassName} id={containerId}>
            <div className={cx('d-flex tw-gap-2.5', className)} style={style}>
              {!leftLabel ? null : (
                <label className='user-select-none mb-0' htmlFor={id}>
                  {leftLabel}
                </label>
              )}

              <Checkbox2
                id={id}
                value={value}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                {...props}
              />

              {!label ? null : (
                <label
                  className='user-select-none flex-grow-1 d-flex align-items-center tw-mb-0'
                  htmlFor={id}
                >
                  {label}
                </label>
              )}

              {!tooltipText ? null : (
                <>
                  <Info
                    size={18}
                    id={tooltipId}
                    className='ml-1 flex-shrink-0 text-text-80'
                  />

                  <UncontrolledTooltip placement='top' target={tooltipId}>
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </div>

            {!error ? null : (
              <InputFeedback className='tw-mt-1'>
                {getInputErrorMessage(error)}
              </InputFeedback>
            )}
          </div>
        )
      }}
    />
  )
}

export function Checkbox2({
  id,
  value,
  checked,
  onChange,
  disabled,
  ...props
}) {
  return (
    <input
      type='checkbox'
      className={cx(
        'rp-checkbox-input tw-relative tw-shrink-0',

        !disabled && '!tw-border-[1.5px] !tw-border-primary-100',

        !checked && '!tw-bg-white/50',

        // before element styles
        'before:tw-absolute before:tw-z-[-1] before:-tw-m-2 before:tw-box-content before:tw-block before:tw-h-full before:tw-w-full before:tw-rounded-full before:tw-p-2 before:tw-transition-colors before:tw-duration-75 hover:before:tw-bg-primary-20 disabled:before:tw-hidden',
      )}
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
      {...props}
      disabled={disabled}
    />
  )
}
