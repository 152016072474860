import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import CardButton from './card-button'
import { cn } from 'ui'

export default function CardButtons({
  value,
  onChange,
  options,
  className,
  style,
  horizontal,
  buttonNoPadding,
  buttonClassName,
  spaced = true,
  labelClassName,
  descriptionClassName,
}) {
  return (
    <div className={className} style={style}>
      {options.map((option, i) => {
        const {
          label,
          description,
          icon,
          value: optionValue,
          isNew,
          isDisabled,
          id,
          tip,
          rightItem,
        } = option

        return (
          <span key={optionValue} id={`button-${optionValue}`}>
            <CardButton
              label={label}
              id={id}
              description={description}
              icon={icon?.(optionValue === value)}
              isActive={optionValue === value}
              onClick={() => onChange(option)}
              horizontal={horizontal}
              isNew={isNew}
              isDisabled={isDisabled}
              noPadding={buttonNoPadding}
              className={cn('tw-h-full', buttonClassName)}
              optionIndex={i}
              lastOptionIndex={options.length - 1}
              spaced={spaced}
              labelClassName={labelClassName}
              descriptionClassName={descriptionClassName}
              rightItem={rightItem?.(optionValue === value)}
            />
            {isDisabled && tip && (
              <UncontrolledTooltip target={`button-${optionValue}`}>
                {tip}
              </UncontrolledTooltip>
            )}
          </span>
        )
      })}
    </div>
  )
}
