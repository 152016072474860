import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'

import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { StyledH5 } from '../../../../components/Typo'
import { Checkbox2 } from '../../../../components/controlled-checkbox'

const TabX = ({ platform, onComplete, context, mode }) => {
  const isTab2 = context === 'tab2'
  const HiRequest = useSelector((state) => state.hiBob.request)
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const bambooReq = useSelector((state) => state.bamboo.request)

  const getState = () => {
    switch (platform) {
      case 'sapling':
        return saplingRequest
      case 'bamboo':
        return bambooReq
      default:
        return HiRequest
    }
  }

  const handleCheckChange = (value, index) => {
    const newData = isTab2
      ? getState()?.contract_types?.map((type, i) => {
          return index === i ? { ...type, status: value } : type
        })
      : getState()?.fields?.map((type, i) => {
          return index === i ? { ...type, status: value } : type
        })
    onComplete(newData)
  }

  const getLabel = () => {
    switch (platform) {
      case 'sapling':
        return 'Sapling'
      case 'bamboo':
        return 'Bamboo'
      default:
        return 'HiBob'
    }
  }

  return (
    <div>
      <div className={`${mode === 'edit' ? 'pt-4 px-4' : 'p-4 border-bottom'}`}>
        <StyledH5 className='font-size-16 mb-0 rp-font-bold'>
          {mode === 'edit'
            ? isTab2
              ? 'Select which contract types you wish to automatically create'
              : 'Select which information to sync'
            : isTab2
              ? 'Select which contract types you wish to automatically create'
              : `Select which information you wish to sync when creating a new hire on ${getLabel()}`}
        </StyledH5>
      </div>
      <div>
        <Row className='m-0 p-0'>
          <Col md={12} className='p-0 m-0'>
            {isTab2
              ? getState()?.contract_types?.map((type, i) => {
                  return (
                    <div
                      key={i}
                      className={`${
                        i === 3
                          ? mode === 'edit'
                            ? 'border-bottom'
                            : ''
                          : 'border-bottom'
                      } d-flex align-items-center justify-content-between p-4`}
                    >
                      <div className='d-flex flex-column ml-2'>
                        <label className='mb-2 rp-font-bold font-size-16'>
                          {type.label}
                        </label>
                        <span className='font-size-14 text-secondary'>
                          {type?.note ||
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
                        </span>
                      </div>

                      <Toggle
                        id={0}
                        check={type.status}
                        change={(event) =>
                          handleCheckChange(event.target.checked, i)
                        }
                      />
                    </div>
                  )
                })
              : getState()?.fields?.map((type, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        i === 2
                          ? mode === 'edit'
                            ? 'border-bottom p-4'
                            : 'p-4'
                          : 'border-bottom p-4'
                      }
                    >
                      <div
                        key={i}
                        className='d-flex align-items-center'
                        style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
                      >
                        <Checkbox2
                          checked={type?.status}
                          onChange={(ev) =>
                            handleCheckChange(ev.target.checked, i)
                          }
                        />
                        <div className='d-flex flex-column ml-2'>
                          <label className='mb-2 d-block rp-font-bold font-size-16'>
                            {type.label}
                          </label>
                          <span className='font-size-1 text-secondary'>
                            {type.note}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TabX
