import React from 'react'
import { Controller } from 'react-hook-form'
import CustomPhoneInput from '../Forms/phoneInput/CustomPhoneInput'

const ControlledPhoneInput = ({ name, control, label, defaultCountry }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
      <CustomPhoneInput
        label={label}
        value={value || ''}
        error={error}
        country={defaultCountry || 'ae'}
        enableAreaCodes
        onBlur={onBlur}
        onChange={onChange}
        name={name}
      />
    )}
  />
)

export default ControlledPhoneInput
