import {
  Archive,
  ChatText,
  CircleNotch,
  PaperPlaneTilt,
  X,
} from '@phosphor-icons/react'
import { format, formatDistanceToNow } from 'date-fns'
import _uniqueId from 'lodash/uniqueId'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Popover, PopoverBody } from 'reactstrap'
import toastr from 'toastr'

import { useFetch } from '../../helpers/hooks'
import {
  addComment as addCommentAction,
  archiveComment as archiveCommentAction,
} from '../../services/api'

export function UserComments({
  comments = [],
  userId,
  onCompleteAction,
  prefillComment,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [allComments, setAllComments] = useState(comments)
  const [id] = useState(_uniqueId('comment-'))

  useEffect(() => {
    setAllComments(comments)
  }, [comments])

  const { startFetch: addComment, isLoading } = useFetch({
    action: addCommentAction,
    withAdminAccess: true,
    onComplete: (data) => {
      setAllComments(data.comments)
      reset()

      if (typeof onCompleteAction === 'function') {
        onCompleteAction()
      }
    },
    onError: () => {
      toastr.error('Error adding your comment')
    },
  })

  const { startFetch: archiveComment, isLoading: isArchivingComment } =
    useFetch({
      action: archiveCommentAction,
      withAdminAccess: true,
      onComplete: (data) => {
        setAllComments(data.comments)

        if (typeof onCompleteAction === 'function') {
          onCompleteAction()
        }
      },
      onError: () => {
        toastr.error('Error archiving the comment')
      },
    })

  function toggle() {
    setIsOpen((isOpen) => !isOpen)
  }

  function handleShowComments() {
    toggle()
  }

  const { handleSubmit, register, reset } = useForm({
    defaultValues: { comment: prefillComment ?? '' },
  })

  useEffect(() => {
    reset({ comment: prefillComment })
  }, [prefillComment, reset])

  function onSubmit(values) {
    const body = {
      id: userId,
      model: 'user',
      comment: values.comment,
    }
    addComment(body)
  }

  function handleArchiveComment(id) {
    archiveComment({ comment_id: id })
  }

  const count = useMemo(() => allComments.length, [allComments.length])

  return (
    <>
      <button
        onClick={handleShowComments}
        aria-label='Show comments'
        className='tw-flex tw-items-center tw-gap-x-1 tw-rounded tw-border-0 tw-bg-transparent tw-px-1 tw-py-0.5 tw-text-text-60 hover:tw-bg-surface-20'
        type='button'
        id={id}
      >
        <ChatText size={16} weight='bold' aria-hidden />
        {count <= 0 ? null : (
          <span className='tw-text-xs' aria-label='Comments count'>
            {' '}
            {count}
          </span>
        )}
      </button>
      <Popover
        flip
        target={id}
        toggle={toggle}
        isOpen={isOpen}
        placement='bottom'
        className='tw-duration-200 [--popper-width:290px] [&>*]:!tw-z-[1010] [&>*]:!tw-w-[--popper-width] [&>*]:!tw-max-w-[--popper-width] [&>*]:!tw-rounded-md [&>*]:!tw-border-0 [&>*]:!tw-shadow-md [&>*]:!tw-ring-1 [&>*]:!tw-ring-surface-30'
      >
        <PopoverBody className='tw-relative tw-flex tw-flex-col tw-gap-2 !tw-px-2 !tw-pt-6'>
          {count <= 0 ? null : (
            <div className='tw-flex tw-flex-col tw-gap-0.5'>
              {allComments.map((comment) => {
                return (
                  <Comment
                    key={comment.id}
                    {...comment}
                    archiveComment={handleArchiveComment}
                    isArchivingComment={isArchivingComment}
                  />
                )
              })}
            </div>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='tw-flex tw-items-center tw-gap-1.5'
          >
            <input
              {...register('comment')}
              placeholder={count <= 0 ? 'Comment ...' : 'Reply ...'}
              className='tw-h-8 tw-w-full tw-rounded tw-border tw-border-surface-30 tw-px-2 tw-py-1 focus:tw-border-primary-80 focus-visible:tw-outline-2 focus-visible:tw-outline-offset-4 focus-visible:tw-outline-primary-60'
              autoComplete='off'
              disabled={isLoading}
            />

            <button
              type='submit'
              className='tw-rounded tw-border-surface-30 tw-p-1 tw-text-text-100 hover:tw-bg-surface-30 focus-visible:tw-bg-surface-30'
            >
              <PaperPlaneTilt className='tw-size-5' />
            </button>
          </form>

          <button
            className='tw-absolute tw-right-1.5 tw-top-1.5 tw-ml-auto tw-flex tw-rounded tw-p-0.5 tw-text-text-80 hover:tw-bg-surface-30 focus-visible:tw-bg-surface-30'
            onClick={toggle}
            type='button'
            aria-label='Close user comments'
          >
            <X className='tw-size-4' weight='bold' />
          </button>
        </PopoverBody>
      </Popover>
    </>
  )
}

function Comment({
  created_by: createdBy,
  created_at: createdAt,
  comment,
  archiveComment,
  id,
  isArchivingComment,
}) {
  return (
    <div className='tw-group tw-relative tw-rounded-sm tw-px-3 tw-py-2 hover:tw-bg-surface-20'>
      <div className='tw-flex tw-items-baseline tw-gap-2'>
        <h3 className='tw-mb-0 tw-whitespace-nowrap tw-text-sm tw-font-semibold'>
          {createdBy}
        </h3>
        <p
          className='tw-text-capitalize tw-mb-0 tw-truncate tw-text-text-80'
          title={format(new Date(createdAt), 'dd/MM/yyyy hh:mm:ss')}
        >
          {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
        </p>
        <button
          type='button'
          onClick={() => archiveComment(id)}
          className='tw-ml-auto tw-flex tw-items-center tw-gap-x-0.5 tw-rounded tw-border tw-border-surface-10 tw-bg-white tw-p-1.5 tw-text-sm tw-text-text-60 tw-opacity-0 tw-transition-colors hover:tw-bg-surface-30 group-hover:tw-opacity-100'
          title='Archive comments'
          disabled={isArchivingComment}
        >
          {isArchivingComment ? (
            <CircleNotch className='tw-size-4 tw-animate-spin' />
          ) : (
            <Archive className='tw-size-4' />
          )}
        </button>
      </div>
      <p className='tw-mb-0 tw-text-sm'>{comment}</p>
    </div>
  )
}
