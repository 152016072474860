import { CheckCircle, Circle, FlowArrow } from '@phosphor-icons/react'
import React from 'react'

import { cn } from 'ui'
import ApprovalFlowNameList from './approval-flow-name-list'
import ApprovalFlowSummary from './approval-flow-summary'
import { BoxIcon } from './ui/box'

export default function BillApprovalFlowSelector({
  approvalFlows = [],
  value,
  onChange,
}) {
  const customFlows = approvalFlows.map((flow) => ({
    label: flow.name,
    value: flow.id,
    description: `${flow.steps.length ?? 1}-level sequential approval flow`,
    icon: (active) => (
      <BoxIcon className={active && 'tw-bg-primary-20 tw-text-primary-100'}>
        <FlowArrow size={24} />
      </BoxIcon>
    ),
    rightItem: (active) =>
      active ? (
        <CheckCircle size={24} weight='fill' className='tw-fill-primary' />
      ) : (
        <Circle size={24} className='tw-fill-surface-40' />
      ),
  }))

  return (
    <div className='tw-flex tw-flex-col tw-gap-4'>
      {customFlows.map((flow, index) => {
        const isActive = value === flow.value
        return (
          <label htmlFor={flow.value} className='tw-mb-0' key={flow.value}>
            <ApprovalFlowSummary
              icon={flow.icon(isActive)}
              className={cn('tw-border-2', {
                'tw-border-primary-100': isActive,
              })}
              title={flow.label}
              description={flow.description}
              isActive={isActive}
              rightItem={flow.rightItem(isActive)}
              footer={
                <ApprovalFlowNameList
                  nameList={approvalFlows[index].steps?.map(
                    (step) => step?.user_name,
                  )}
                />
              }
            />
            <input
              name='billApprovalFlow'
              type='radio'
              id={flow.value}
              className='tw-absolute tw-hidden'
              value={flow.value}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange(
                    approvalFlows.find(
                      (flow) => flow.id === Number(e.target.value),
                    ),
                  )
                }
              }}
            />
          </label>
        )
      })}
    </div>
  )
}
