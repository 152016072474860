import React, { useState } from 'react'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../settings-section-heading'
import { AutomationTable } from './table/automation-table'
import { Link } from 'react-router-dom'
import { Sparkle } from '@phosphor-icons/react'
import Button from '../../../../components/ui/button'
import { AutomationsTemplatesModal } from './modals/automations-templates-modal'

export function AutomationsTab() {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className='tw-p-6'>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-3 md:tw-flex-nowrap'>
        <div className='tw-flex-1'>
          <SettingSectionHeading learnMoreLink='https://help.remotepass.com/en/articles/10021964-Automations'>
            My automations
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            Create automated alerts
          </SettingSectionSubHeading>
        </div>

        <button
          onClick={() => setIsOpen(true)}
          className='tw-flex tw-flex-row tw-items-center tw-gap-1'
        >
          <Sparkle size={20} />
          <span className='tw-font-bold'>Featured templates</span>
        </button>
        <Button
          to='/automations/add'
          tag={Link}
          color='link'
          className='tw-align-self-center tw-mr-2 !tw-px-0'
        >
          Add New
        </Button>
      </div>

      <AutomationTable onShowAll={() => setIsOpen(true)} />

      <AutomationsTemplatesModal
        isOpen={isOpen}
        toggle={() => setIsOpen((isOpen) => !isOpen)}
      />
    </div>
  )
}
