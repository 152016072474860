import { CaretDown, X } from '@phosphor-icons/react'
import React from 'react'
import Select, { components } from 'react-select'

import InputFeedback from '../../ui/input-feedback'
import { getInputErrorMessage } from '../get-input-error-message'

const CustomSelect = ({
  isDisabled,
  leftRounded,
  value,
  options,
  onChange,
  label,
  defaultValue,
  classNamePrefix = 'RS-Control',
  searchable = true,
  hasError,
  wrapperClassName,
  labelClassName,
  wrapperStyles,
  selectStyles,
  inputProps,
  error,
  showError = true,
  selectRef,
  clearIcon,
  ...props
}) => {
  const { Option, SingleValue } = components
  const IconOption = (props) => (
    <Option {...props}>
      <div className='d-flex flex-row align-items-center text-left'>
        {!!props.data.flag && (
          <img
            src={props.data.flag}
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '0.5rem',
              marginRight: 10,
              objectFit: 'cover',
            }}
            alt={props.data.label}
          />
        )}
        <div className={props.isDisabled ? 'opacity-40' : undefined}>
          {props.data.icon}
        </div>

        <div className='d-flex flex-column align-items-start'>
          <div>
            {props.data?.isChild && (
              <i className='bx bx-subdirectory-right ml-3' />
            )}{' '}
            {props.data.label}
          </div>
          <div className='font-size-10 text-left'>{props.data.description}</div>
        </div>
      </div>
    </Option>
  )

  const CustomSingleValue = ({ data, ...props }) => {
    return (
      <SingleValue style={{ maxWidth: '94%' }} {...props}>
        <div className='d-flex flex-row align-items-center'>
          {data.flag && (
            <img
              src={data.flag}
              style={{
                width: '2rem',
                height: '2rem',
                borderRadius: '1rem',
                marginRight: 10,
                objectFit: 'cover',
              }}
              alt={data.label}
            />
          )}
          {data.icon}
          <span
            className='text-secondary d-flex flex-column text-text-80 font-size-14'
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {!label || !data.label ? null : (
              <label className={`${labelClassName} mb-0 font-size-10`}>
                {label}
              </label>
            )}
            {data.label}
          </span>
        </div>
      </SingleValue>
    )
  }

  const Input = ({ ...rest }) => {
    return (
      <>
        <components.Input {...rest} {...inputProps} className='py-2.5' />
      </>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='px-2 py-1'>
        <CaretDown size={20} color='var(--secondary)' />
      </components.DropdownIndicator>
    )
  }

  function ClearIndicator(props) {
    return (
      <button
        className='tw-rounded tw-p-1 hover:tw-bg-surface-20 hover:tw-text-text-80'
        {...props.innerProps}
      >
        {clearIcon ?? <X size={18} />}
      </button>
    )
  }

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: 'var(--text-40)',
    }),

    menu: (provided) => ({
      ...provided,
      fontSize: '14px',
      color: '#777F9E',
      minWidth: 200,
      textAlign: 'right',
      zIndex: 999,
      width: props.transparent ? 300 : provided.width,
      right: props.transparent ? '0%' : provided?.right,
      marginLeft: props.leftDir ? -100 : 0,
    }),

    multiValue: () => ({
      margin: 2,
      paddingLeft: 6,
      backgroundColor: 'rgb(59, 124, 255,0.1)',
      color: '#3b7cff',
      display: 'flex',
      alignItems: 'stretch',
    }),

    multiValueLabel: () => ({
      fontSize: '14px',
    }),

    singleValue: (provided) => {
      return { ...provided, color: '#777F9E' }
    },
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: hasError
        ? 'var(--red) !important'
        : state.isFocused && props.transparent
          ? '#325AE7 !important'
          : 'var(--gray-b)',
      borderTopRightRadius: leftRounded
        ? '0px'
        : provided?.borderTopRightRadius,
      borderBottomRightRadius: leftRounded
        ? '0px'
        : provided?.borderBottomRightRadius,
      height: props.transparent
        ? 'calc(1.5em + 1.3rem + 2px)'
        : provided.height,
      ...selectStyles?.control(provided, state),
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: props.transparent
        ? 'transparent'
        : provided.backgroundColor,
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        ...selectStyles?.valueContainer(provided, state),
      }
    },
  }

  return (
    <div className={wrapperClassName} style={wrapperStyles}>
      <Select
        isDisabled={isDisabled}
        components={{
          DropdownIndicator,
          Option: IconOption,
          SingleValue: CustomSingleValue,
          value: CustomSingleValue,
          IndicatorSeparator: null,
          ClearIndicator,
          Input,
          ...props.customComponents,
        }}
        value={value}
        classNamePrefix={classNamePrefix}
        onChange={onChange}
        options={options}
        isSearchable={searchable}
        defaultValue={defaultValue}
        styles={customStyles}
        placeholder={label}
        ref={selectRef}
        {...props}
      />

      {!error || !showError ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

export default CustomSelect
