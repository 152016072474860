import { ArrowSquareOut } from '@phosphor-icons/react'
import React from 'react'
import { cn } from 'ui'

export function LinkOut({ href, title, className }) {
  return (
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      className={cn('tw-text-current', className)}
      title={title}
    >
      <ArrowSquareOut size={20} />
    </a>
  )
}
