import { Download } from '@phosphor-icons/react'
import axios from 'axios'
import classnames from 'classnames'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row, Spinner, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import NoContent from '../../../../components/NoContent'
import { SimpleCard } from '../../../../components/simple-card'
import StyledTh from '../../../../components/Table/StyledTh'
import TableComp from '../../../../components/Table/TableComp'
import { StyledH5 } from '../../../../components/Typo'
import { useFetch } from '../../../../helpers/hooks'
import {
  downloadInvoice,
  getQBHistory,
  qbSyncAllInvoices,
} from '../../../../services/api'
import { qbUpdateRequest } from '../../../../store/qb/actions'
import ContractRef from '../../../AdminPanel/components/ContractRef'
import XeroStatus from '../XeroStatus'
import noInvoicesImage from './../../../../assets/images/noinvoices.svg'
import VendorAutoSyncToggle from '../vendor-auto-sync-toggle'

const XeroLogs = ({ onSubmitted }) => {
  const data = useSelector((state) => state.qb.preData)
  const [syncInvoice, setSyncInvoice] = useState(data?.invoices_auto_sync)
  const [syncBills, setSyncBills] = useState(data?.bills_auto_sync)
  const [syncing, setSyncing] = useState(false)
  const dispatch = useDispatch()

  const invoices = useFetch(
    {
      action: getQBHistory,
      autoFetch: true,
    },
    [],
  )

  const syncAll = useFetch(
    {
      action: qbSyncAllInvoices,
      onError: toastr.error,
      onComplete: () => {
        invoices.startFetch(null, false)
      },
    },
    [],
  )

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (invoices.data?.queued > 0) {
        // eslint-disable-next-line no-return-assign,no-unused-vars
        invoices.startFetch(
          null,
          false,
          new axios.CancelToken((c) => (cancel = c)),
        )
      }
    },
    [syncing, invoices.data],
  )

  useEffect(() => {
    let cancel
    const interval = setInterval(() => refresh(cancel), 800)
    return () => {
      clearInterval(interval)
    }
  }, [invoices.data, syncing])

  return invoices.isLoading ? (
    <Container style={{ minHeight: '100vh' }}>
      <Col style={{ minHeight: '100vh' }}>
        <Row
          style={{ minHeight: '100vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' color='primary' />
        </Row>
      </Col>
    </Container>
  ) : (
    <>
      <VendorAutoSyncToggle
        itemToSync='Invoices'
        whenToSync='Automatically synchronize with Quickbooks every time I run payroll'
        onToggle={() => {
          setSyncInvoice((e) => !e)
          dispatch(
            qbUpdateRequest({
              invoices_auto_sync: !syncInvoice,
              isUpdate: true,
            }),
          )
          setTimeout(() => {
            invoices.startFetch(null, false)
          }, 1500)
          onSubmitted()
        }}
        toggleCheck={syncInvoice}
      />

      <VendorAutoSyncToggle
        itemToSync='Bills'
        whenToSync='Automatically synchronize with Quickbooks every time I add bills'
        toggleCheck={syncBills}
        onToggle={() => {
          setSyncBills((syncBills) => !syncBills)
          dispatch(
            qbUpdateRequest({
              bills_auto_sync: !syncBills,
              isUpdate: true,
            }),
          )
          setTimeout(() => {
            invoices.startFetch(null, false)
          }, 1500)
          onSubmitted()
        }}
      />

      {invoices.data?.list?.length === 0 ? (
        <NoContent
          headline='No Invoices'
          subtitle='All your invoices will be shown here after each payment'
          image={noInvoicesImage}
        />
      ) : (
        <SimpleCard className='mt-3'>
          <Row className='justify-content-between p-4 m-0 align-items-center'>
            <StyledH5 style={{ fontSize: 24 }}>Invoices</StyledH5>
            <button
              disabled={syncAll.isLoading || invoices.data?.queued > 0}
              onClick={() => {
                setSyncing(true)
                syncAll.startFetch()
              }}
              className='btn btn-primary'
            >
              <i
                className={classnames({
                  'fas fa-sync-alt mr-2': true,
                  'bx-spin': syncAll.isLoading || invoices.data?.queued > 0,
                })}
              />{' '}
              Sync All
            </button>
          </Row>
          <TableComp>
            <thead className='thead-light'>
              <tr>
                <StyledTh>Date</StyledTh>
                <StyledTh>Contract</StyledTh>
                <StyledTh>Contractor</StyledTh>
                <StyledTh>Ref</StyledTh>
                <StyledTh>Status</StyledTh>
                <StyledTh>Download</StyledTh>
              </tr>
            </thead>
            <tbody>
              {invoices.data?.list?.map((e, i) => (
                <LogLine key={`milestone-${i}`} item={e} />
              ))}
            </tbody>
          </TableComp>
        </SimpleCard>
      )}
    </>
  )
}

const LogLine = ({ item }) => {
  const user = useSelector((state) => state?.Account?.user)
  const handleDownloadInvoice = () => {
    downloadInvoice(item.invoice_ref, user?.token, item.token).then((r) => {
      if (r.data?.data?.error || r.data?.message) {
        toastr.error(
          r.data?.data?.error || r.data?.message || 'An error occurred',
        )
        return
      }
      const url = window.URL.createObjectURL(new Blob([r.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${item?.invoice_ref}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }
  return (
    <tr>
      <td className='py-3 px-4'>
        {moment(item?.invoice_date * 1000).format('MM/DD/YYYY')}
      </td>
      <td className='py-3 px-4'>
        <ContractRef isAdmin={false} contractId={item?.contract_ref} />
      </td>
      <td className='py-3 px-4'>{item?.contractor}</td>
      <td className='py-3 px-4'>{item?.invoice_ref}</td>
      <td className='py-3 px-4 text-center'>
        {item.queued ? (
          <i
            className={classnames({
              'fas fa-sync-alt mr-2': true,
              'bx-spin': true,
            })}
          />
        ) : (
          <Col>
            <Row>
              {' '}
              <XeroStatus status={item.status} />
              <div style={{ position: 'relative' }}>
                {!!item.error && (
                  <>
                    <i
                      className='bx bx-info-circle mx-2'
                      id={`xero-sync-status-${item?.invoice_ref}`}
                    />
                    <UncontrolledTooltip
                      placement='top'
                      target={`xero-sync-status-${item?.invoice_ref}`}
                    >
                      {item.error}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </Row>
          </Col>
        )}
      </td>
      <td className='py-3 px-4 text-right'>
        <button
          className='tw-flex tw-items-center tw-gap-2 tw-text-sm tw-text-black hover:tw-text-primary-100'
          onClick={handleDownloadInvoice}
          id='download-invoice'
        >
          <Download size={16} weight='bold' />
          Invoice
        </button>
      </td>
    </tr>
  )
}

export default XeroLogs
