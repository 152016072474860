import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import React, { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import ControlledInput from '../../../../../components/ControlledInput'
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import ControlledSelect from '../../../../../components/ControlledSelect'
import ControlledCheckbox from '../../../../../components/controlled-checkbox'
import Button from '../../../../../components/ui/button'
import { filterNullProps, getHighestOrder } from '../helpers/getOrder'
import { useFetch } from '../../../../../helpers/hooks'
import {
  addFormField,
  updateFormField,
  uploadTempFileAdmin,
} from '../../../../../services/api'
import IconButton from '../../../../../components/ui/icon-button'
import { Trash } from '@phosphor-icons/react/dist/ssr'
import toastr from 'toastr'
import Loader from '../../../../../components/ui/loader'
import { MAX_SIZE_READABLE } from '../../../../Contract/utils/constants'
import DropzoneInput from '../../../../../components/Common/dropzone-input'

const fieldTypes = {
  short_text_input: 'short_text_input',
  long_text_input: 'long_text_input',
  date_picker: 'date_picker',
  dropdown: 'dropdown',
  country_dropdown: 'country_dropdown',
  religion_dropdown: 'religion_dropdown',
  religious_faith_dropdown: 'religious_faith_dropdown',
  marital_status_dropdown: 'marital_status_dropdown',
  passport_type_dropdown: 'passport_type_dropdown',
  education_level_dropdown: 'education_level_dropdown',
  language_dropdown: 'language_dropdown',
  multi_select_dropdown: 'multi_select_dropdown',
  file_upload: 'file_upload',
  multi_file_upload: 'multi_file_upload',
}

const typeOptions = [
  {
    label: 'Text',
    value: fieldTypes.short_text_input,
  },
  {
    label: 'Text Area',
    value: fieldTypes.long_text_input,
  },
  {
    label: 'Date',
    value: fieldTypes.date_picker,
  },
  {
    label: 'Select',
    value: fieldTypes.dropdown,
  },
  {
    label: 'Country',
    value: fieldTypes.country_dropdown,
  },
  {
    label: 'Religion',
    value: fieldTypes.religion_dropdown,
  },
  {
    label: 'Religious faith',
    value: fieldTypes.religious_faith_dropdown,
  },
  {
    label: 'Marital status',
    value: fieldTypes.marital_status_dropdown,
  },
  {
    label: 'Passport type',
    value: fieldTypes.passport_type_dropdown,
  },
  {
    label: 'Education level',
    value: fieldTypes.education_level_dropdown,
  },
  {
    label: 'Language',
    value: fieldTypes.language_dropdown,
  },
  {
    label: 'Multi Select',
    value: fieldTypes.multi_select_dropdown,
  },
  {
    label: 'File',
    value: fieldTypes.file_upload,
  },
  {
    label: 'Multi File',
    value: fieldTypes.multi_file_upload,
  },
]

const dropDownTypes = [
  fieldTypes.dropdown,
  fieldTypes.country_dropdown,
  fieldTypes.religion_dropdown,
  fieldTypes.religious_faith_dropdown,
  fieldTypes.marital_status_dropdown,
  fieldTypes.passport_type_dropdown,
  fieldTypes.education_level_dropdown,
  fieldTypes.language_dropdown,
  fieldTypes.multi_select_dropdown,
]

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  type: yup.string().required('Type is required'),
  placeholder: yup.mixed().notRequired(),
  is_required: yup.boolean(),
  is_full_row: yup.boolean(),
  configs: yup.object().shape({
    has_other_option: yup.boolean(),
    other_option_label: yup.string().when('has_other_option', {
      is: true,
      then: (schema) => schema.required('Other option label is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
  options: yup.array().when('type', {
    is: (type) => ['dropdown', 'multi_select_dropdown'].includes(type),
    then: (schema) =>
      schema
        .of(yup.string().required('Option value is required'))
        .min(2, 'At least 2 options are required'),
    otherwise: (schema) => schema.notRequired(),
  }),
})

function AddOrUpdateField({ form, step, field, toggle, refetch, isOpen }) {
  const [filesToUpload, setFilesToUpload] = useState([])
  const formFields = step?.form_fields ?? form?.form_fields
  const order =
    field && field.order !== null
      ? field.order
      : formFields && formFields.length > 0
        ? getHighestOrder(formFields) + 1
        : 1
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      form_step_id: step?.id,
      order,
      ...field,
      placeholder: field?.placeholder,
      is_required: field?.is_required ?? false,
      is_full_row: field?.is_full_row ?? false,
      configs: field?.configs ?? {
        has_other_option: false,
        other_option_label: null,
      },
    },
    resolver: yupResolver(schema),
  })

  const { startFetch: addField, isLoading: isAdding } = useFetch({
    action: addFormField,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Field added successfully')
      refetch?.()
      toggle?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: updateField, isLoading: isUpdating } = useFetch({
    action: updateFormField,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Field updated successfully')
      refetch?.()
      toggle?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: uploadFile, isLoading: isUploading } = useFetch({
    action: uploadTempFileAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      if (formType === fieldTypes.multi_file_upload) {
        setValue(
          'placeholder',
          [placeholderFiles, data?.path].filter(Boolean).join(),
        )
      } else {
        setValue('placeholder', data?.path)
      }
      setFilesToUpload((files) => files.slice(1))
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    if (filesToUpload?.length) {
      uploadFile({
        file: filesToUpload?.[0],
        type: 'form_uploads',
      })
    }
  }, [filesToUpload])

  const onSubmit = (data) => {
    const filteredData = filterNullProps(data)
    if (
      data?.options?.length === 0 ||
      ![fieldTypes.dropdown, fieldTypes.multi_select_dropdown].includes(
        data.type,
      )
    ) {
      delete data.options
    }

    if (field) {
      updateField({
        ...filteredData,
        form_id: field?.form_id,
        form_field_id: field?.id,
      })
    } else {
      const formId = step?.form_id ?? form?.id
      addField({ ...filteredData, form_id: formId })
    }
  }

  const {
    fields: optionsFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: 'options',
  })

  const {
    placeholder: placeholderFiles,
    type: formType,
    configs: { has_other_option: hasOtherOptions },
  } = useWatch({
    control,
  })

  const isMultiOrSelectDropdown = useMemo(
    () =>
      [fieldTypes.dropdown, fieldTypes.multi_select_dropdown].includes(
        formType,
      ),
    [formType],
  )

  useEffect(() => {
    if (isMultiOrSelectDropdown) {
      setValue('configs.has_other_option', true)
    } else {
      setValue('configs.has_other_option', false)
    }
    setValue('configs.other_option_label', null)
    setValue('options', [])
    clearErrors() // reset errors
  }, [formType])

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggle}>
          {field ? 'Update ' : 'Add '} Field
        </ModalHeader>
        <ModalBody>
          <div className='tw-my-4'>
            <Row>
              <Col sm={12} className='!tw-mb-4'>
                <ControlledInput
                  name='title'
                  placeholder='Title'
                  className='!tw-h-14'
                  control={control}
                  error={errors?.title}
                />
              </Col>
              <Col sm={12}>
                <ControlledSelect
                  control={control}
                  placeholder='Select type'
                  name='type'
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '56px',
                      borderColor: 'var(--surface-30)',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  error={errors?.type}
                  options={typeOptions}
                />
              </Col>
              <div className='tw-mx-6 tw-flex tw-flex-grow tw-items-center tw-gap-4'>
                <label className='tw-mt-4 tw-flex tw-flex-1 tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-p-4'>
                  <div>Is required</div>
                  <ControlledCheckbox
                    id='is_required'
                    control={control}
                    error={errors?.is_required}
                    name='is_required'
                  />
                </label>
                <label className='tw-mt-4 tw-flex tw-flex-1 tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-p-4'>
                  <div>Full row</div>
                  <ControlledCheckbox
                    id='is_full_row'
                    control={control}
                    error={errors?.is_full_row}
                    name='is_full_row'
                  />
                </label>
              </div>
              {dropDownTypes.includes(formType) && (
                <div className='tw-mx-6 tw-flex-grow'>
                  <label className='tw-mt-4 tw-flex tw-flex-1 tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-p-4'>
                    <div>Has Other Options</div>
                    <ControlledCheckbox
                      id='configs.has_other_option'
                      control={control}
                      error={errors?.configs?.has_other_option}
                      name='configs.has_other_option'
                    />
                  </label>
                  {hasOtherOptions && (
                    <ControlledInput
                      name='configs.other_option_label'
                      wrapperClassName='tw-mt-4'
                      className='!tw-h-14'
                      placeholder='Other Option Label'
                      id='configs.other_option_label'
                      control={control}
                      error={errors?.configs?.other_option_label}
                    />
                  )}
                </div>
              )}
              {[fieldTypes.file_upload, fieldTypes.multi_file_upload].includes(
                formType,
              ) && (
                <Col sm={12} className='tw-px-3'>
                  <DropzoneInput
                    className='tw-mt-4'
                    multiple={formType === fieldTypes.multi_file_upload}
                    onDropAccepted={(acceptedFiles) => {
                      setFilesToUpload(acceptedFiles)
                    }}
                    accept={{ 'application/pdf': ['.pdf'] }}
                    error={errors?.placeholder?.message}
                  >
                    <div className='tw-flex tw-h-[100px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center'>
                      {isUploading ? (
                        <Loader />
                      ) : placeholderFiles ? (
                        <>
                          <span>
                            {placeholderFiles?.split(',')?.length} File(s)
                            Uploaded
                          </span>
                          <span className='tw-w-full tw-overflow-scroll tw-text-sm tw-text-text-80'>
                            {placeholderFiles?.split(',').map((url, i) => (
                              <p key={i}>- {url}</p>
                            ))}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>Drop file or click here</span>
                          <span className='tw-text-sm tw-text-text-60'>
                            Max file size {MAX_SIZE_READABLE}
                          </span>
                        </>
                      )}
                    </div>
                  </DropzoneInput>
                </Col>
              )}
            </Row>
            {(isMultiOrSelectDropdown || hasOtherOptions) && (
              <div className='tw-my-4'>
                <span className='tw-my-2 tw-text-base'>
                  Options {isMultiOrSelectDropdown ? '' : ' (Optional)'}
                </span>
                {optionsFields.map((f, index) => (
                  <div
                    className='tw-my-2 tw-flex tw-items-start tw-gap-2'
                    key={index}
                  >
                    <ControlledInput
                      wrapperClassName='tw-grow'
                      control={control}
                      name={`options.${index}`}
                      placeholder='option value'
                    />
                    <IconButton
                      icon={<Trash />}
                      onClick={() => removeOption(index)}
                    />
                  </div>
                ))}
                {errors?.options && (
                  <div className='tw-my-3 tw-text-red'>
                    {errors?.options?.root?.message ?? errors?.options?.message}
                  </div>
                )}
                <div>
                  <Button
                    color='primary'
                    outline
                    className='tw-mt-4'
                    block
                    onClick={() => appendOption()}
                  >
                    Add Option
                  </Button>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className='tw-flex tw-w-full tw-items-center tw-gap-2'>
          <Button color='secondary' outline onClick={() => toggle?.()}>
            Cancel
          </Button>
          <Button
            disabled={isAdding || isUpdating}
            loading={isAdding || isUpdating}
            color='primary'
          >
            {field ? 'Update' : 'Add'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default AddOrUpdateField
