import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import {
  addEmploymentContract,
  editEmploymentContract,
  uploadTempFileAdmin,
} from '../../../../../services/api'
import { useFetch } from '../../../../../helpers/hooks'
import toastr from 'toastr'
import {
  FILE_SIZE_LIMITS_IN_BYTES,
  MAX_SIZE_5MB_READABLE,
} from '../../../../Contract/utils/constants'

import ControlledDatePicker from '../../../../../components/ControlledDatePicker'
import Loader from '../../../../../components/ui/loader'

import DataTable from '../../../../../components/ui/data-table'
import openFileV2 from '../../../../../utils/file/open-v2'
import DropzoneInput from '../../../../../components/Common/dropzone-input'
import { ControlledCheckbox2 } from '../../../../../components/controlled-checkbox'
import { ActionsDropdown } from 'ui'

function EditEmploymentContract({
  isOpen,
  closeModal,
  refetch,
  contractId,
  contractToEdit = null,
}) {
  const schema = yup.object().shape({
    probation_end_date: yup.string().when('expiry_date', {
      is: (expiryDate) => !!expiryDate,
      then: (schema) =>
        schema.required(
          'Probation end date is required if contract has expiry date',
        ),
      otherwise: (schema) => schema.nullable(),
    }),
    expiry_date: yup.string().nullable(),
    notify_client: yup.boolean().notRequired(),
    file: yup.mixed().when([], {
      is: () => contractToEdit?.file,
      then: (_schema) => _schema.notRequired(),
      otherwise: (_schema) => _schema.required('Document file is required'),
    }),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      probation_end_date: contractToEdit?.probation_end_date,
      last_renewal_date: contractToEdit?.last_renewal_date,
      expiry_date: contractToEdit?.expiry_date,
      file: contractToEdit?.file,
      notify_client: contractToEdit?.notify_client,
    },
  })

  const { startFetch: uploadFile, isLoading: isUploading } = useFetch({
    action: uploadTempFileAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      setValue('file', data?.path)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: updateContract, isLoading: isUpdating } = useFetch({
    action: editEmploymentContract,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Contract updated successfully')
      closeModal()
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: submitContract, isLoading: isSubmitting } = useFetch({
    action: addEmploymentContract,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Contract added successfully')
      closeModal()
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const onSubmit = (data) => {
    if (contractToEdit) {
      const updateData = {
        ...data,
        employment_contract_contract_id: contractToEdit?.id,
      }
      const filteredData = Object.fromEntries(
        Object.entries(updateData).filter(([, value]) => value !== null),
      )
      if (!filteredData?.file?.startsWith('temp_uploads')) {
        delete filteredData.file
      }
      updateContract(filteredData)
    } else {
      submitContract({
        ...data,
        contract_id: contractId,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        {contractToEdit ? 'Edit' : 'Upload'} employment contract
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Label htmlFor='Probation end date' className='tw-mt-4'>
            Probation end date
          </Label>
          <ControlledDatePicker
            control={control}
            name='probation_end_date'
            error={errors?.probation_end_date}
          />
          <Label htmlFor='issue_date' className='tw-mt-4'>
            Expiry Date
          </Label>
          <ControlledDatePicker
            control={control}
            name='expiry_date'
            error={errors?.expiry_date}
            minDate={new Date()}
          />
          {!contractToEdit && (
            <ControlledCheckbox2
              className='tw-mt-4'
              control={control}
              id='notify_client'
              name='notify_client'
              label='Notify client'
            />
          )}
          <DropzoneInput
            className='tw-mt-4'
            onDropAccepted={(acceptedFiles) => {
              uploadFile({
                file: acceptedFiles[0],
                type: 'employment_contracts',
              })
            }}
            accept='application/pdf'
            maxSize={FILE_SIZE_LIMITS_IN_BYTES.FIVE_MB}
            error={errors?.file?.message}
          >
            <div className='tw-flex tw-h-[100px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center'>
              {isUploading ? (
                <Loader />
              ) : watch('file') ? (
                <>
                  <span>File Uploaded</span>
                  <span className='tw-w-full tw-overflow-hidden tw-text-ellipsis tw-text-sm tw-text-text-80'>
                    {watch('file')}
                  </span>
                </>
              ) : (
                <>
                  <span>Drop file or click here</span>
                  <span className='tw-text-sm tw-text-text-60'>
                    Max file size {MAX_SIZE_5MB_READABLE}
                  </span>
                </>
              )}
            </div>
          </DropzoneInput>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            disabled={isSubmitting || isUpdating}
            loading={isSubmitting || isUpdating}
          >
            {contractToEdit ? 'Update' : 'Submit'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

function EmploymentContractStep({ refetch, contractId, documents }) {
  const [isOpen, setIsOpen] = useState(false)
  const [contractToEdit, setContractToEdit] = useState(null)
  const closeModal = () => {
    setIsOpen(false)
    setContractToEdit(null)
  }

  useEffect(() => {
    if (contractToEdit) {
      setIsOpen(true)
    }
  }, [contractToEdit])

  const columns = [
    {
      Header: 'Probation End Date',
      accessor: 'probation_end_date',
      Cell: ({ cellData }) => cellData || 'N/A',
    },
    {
      Header: 'Last Renewal Date',
      accessor: 'last_renewal_date',
      Cell: ({ cellData }) => cellData || 'N/A',
    },
    {
      Header: 'Expiry Date',
      accessor: 'expiry_date',
      Cell: ({ cellData }) => cellData || 'N/A',
    },
    {
      Header: 'Actions',
      Cell: ({ rowData }) => {
        return (
          <ActionsDropdown
            position='top end'
            data={[
              {
                label: 'Edit',
                onClick: () => {
                  if (rowData.file) {
                    rowData.file = rowData.file.substring(
                      rowData.file.indexOf('employment_contracts'),
                      rowData.file.indexOf('?'),
                    )
                  }
                  setContractToEdit(rowData)
                },
              },
              {
                label: 'Download',
                onClick: () => {
                  openFileV2(rowData.file, {
                    name: rowData.name,
                    isDataUrl: true,
                  })
                },
              },
            ]}
          />
        )
      },
    },
  ]

  return (
    <>
      {documents?.length > 0 && (
        <div className='tw-mb-6'>
          <DataTable responsive columns={columns} data={documents} />
        </div>
      )}
      <Button block color='primary' onClick={() => setIsOpen(true)}>
        Upload an employment contract
      </Button>

      {isOpen && (
        <EditEmploymentContract
          isOpen={isOpen}
          closeModal={closeModal}
          refetch={refetch}
          contractId={contractId}
          contractToEdit={contractToEdit}
        />
      )}
    </>
  )
}

export default EmploymentContractStep
