import React from 'react'
import {
  EnvelopeSimple,
  PlusCircle,
  Queue,
  SlackLogo,
  MagnifyingGlass,
} from '@phosphor-icons/react'
import Pagination from '../../../../../components/ui/pagination'
import { format } from 'date-fns'
import DataTable from '../../../../../components/ui/data-table'
import { Link } from 'react-router-dom'
import { AutomationStatusColumn } from './automation-status-column'
import SearchBar from '../../../../../components/SearchBar'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import {
  ACTION_TYPE,
  actionTypes,
  statuses,
  triggers,
} from '../tools/constants'
import { useAutomations } from '../hooks/use-automations'
import Loader from '../../../../../components/ui/loader'
import TabEmpty from '../../../../Contract/components/tab/tab-empty'
import Button from '../../../../../components/ui/button'
import { AutomationMenuColumn } from './automation-menu-column'
import { AutomationsTemplates } from '../automations-templates'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import truncate from 'lodash/truncate'

export function AutomationTable(props) {
  const { onShowAll } = props
  const {
    refetch,
    automations,
    pagination: { onPageChange, page, total, perPage } = {},
    isLoading,
    filters,
    setFilters,
  } = useAutomations({
    onlyTemplates: false,
  })
  const isFiltersEmpty = isEmpty(omitBy(filters, isNil))
  const columns = [
    {
      Header: 'Automation title',
      accessor: 'name',
      Cell: ({ rowData }) => (
        <Link
          className='tw-flex tw-flex-col tw-text-text'
          to={`/automations/add?id=${rowData._id}`}
        >
          <span className='tw-text-ellipsis tw-font-bold'>
            {truncate(rowData.name, { length: 100 })}
          </span>
          <span className='tw-mr-10 tw-font-light'>{rowData.category}</span>
        </Link>
      ),
    },
    {
      Header: 'Created on',
      accessor: 'created_at',
      Cell: ({ rowData }) => {
        const date = new Date(rowData.created_at)
        return (
          <>
            {format(date, 'yyyy-MM-dd')}
            <br />
            <span className='tw-text-s tw-text-secondary-80'>
              {format(date, 'HH:mm')}
            </span>
          </>
        )
      },
    },
    {
      Header: 'Last modified',
      accessor: 'updated_at',
      Cell: ({ rowData }) => {
        const date = new Date(rowData.updated_at)
        return (
          <>
            {format(date, 'yyyy-MM-dd')}
            <br />
            <span className='tw-text-s tw-text-secondary-80'>
              {format(date, 'HH:mm')}
            </span>
          </>
        )
      },
    },
    {
      Header: 'Action type',
      accessor: 'action_channels',
      Cell: ({ rowData }) => (
        <div className='tw-flex tw-flex-row tw-gap-1'>
          {rowData?.action_channels
            ? rowData.action_channels.map((i) =>
                i === ACTION_TYPE.EMAIL ? (
                  <EnvelopeSimple
                    key={i}
                    size={25}
                    className='tw-text-text-60'
                  />
                ) : i === ACTION_TYPE.SLACK ? (
                  <SlackLogo key={i} size={25} className='tw-text-text-60' />
                ) : null,
              )
            : 'N/A'}
        </div>
      ),
    },
    {
      Header: 'Status',
      Cell: AutomationStatusColumn,
    },
    {
      Header: '',
      Cell: ({ rowData }) => (
        <AutomationMenuColumn refetch={refetch} rowData={rowData} />
      ),
    },
  ]
  return (
    <div className='tw-mt-4'>
      {automations.length || !isFiltersEmpty ? (
        <div className='tw-grid tw-grid-cols-1 tw-gap-2 tw-px-0.5 sm:tw-grid-cols-2 lg:tw-grid-cols-4'>
          <SearchBar
            placeholder='Search by name'
            inputClassName='!tw-h-full'
            onQueryChanged={(query) =>
              setFilters((prev) => ({ ...prev, name: query || null }))
            }
            query={filters?.name ?? ''}
          />
          <CustomSelect
            placeholder='Action type'
            options={actionTypes}
            value={filters?.actionType}
            onChange={(actionType) =>
              setFilters((prev) => ({ ...prev, actionType }))
            }
            isClearable
          />
          <CustomSelect
            placeholder='Trigger type'
            isClearable
            options={triggers}
            value={filters?.triggerType}
            onChange={(triggerType) =>
              setFilters((prev) => ({ ...prev, triggerType }))
            }
          />
          <CustomSelect
            placeholder='Status'
            options={statuses}
            isClearable
            value={filters?.status}
            onChange={(status) => setFilters((prev) => ({ ...prev, status }))}
          />
        </div>
      ) : null}

      {isLoading ? (
        <Loader minHeight='28rem' />
      ) : automations?.length ? (
        <>
          <DataTable striped columns={columns} data={automations} responsive />
          <div className='tw-w-100 tw-my-3 tw-flex tw-justify-end'>
            <Pagination
              activePage={page}
              itemsCountPerPage={perPage}
              totalItemsCount={total}
              onChange={onPageChange}
            />
          </div>
        </>
      ) : (
        <>
          <TabEmpty
            className='tw-p-0'
            title={
              isFiltersEmpty
                ? 'No automation set up yet'
                : 'No automation found'
            }
            subtitle={
              isFiltersEmpty
                ? 'Alert set up will be listed here'
                : 'We couldn’t find any automation with the entered details'
            }
            icon={
              isFiltersEmpty ? (
                <Queue size={250} className='tw-text-primary' />
              ) : (
                <MagnifyingGlass size={250} className='tw-text-primary' />
              )
            }
          >
            <Button
              to='/automations/add'
              tag={Link}
              icon={<PlusCircle size={20} />}
              circle={false}
              className='tw-mt-2'
              text='Add new'
            />
          </TabEmpty>
        </>
      )}

      {!isLoading && !automations.length && isFiltersEmpty ? (
        <AutomationsTemplates onShowAll={onShowAll} />
      ) : null}
    </div>
  )
}
