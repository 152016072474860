import React from 'react'
import logoMain from '../../../assets/images/identity/logo_main.png'

function AuthPageHeader() {
  return (
    <div
      className='tw-flex tw-h-14 tw-items-center tw-justify-center tw-bg-white md:tw-h-20'
      style={{
        boxShadow: '0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea',
      }}
    >
      <img src={logoMain} style={{ height: 24 }} alt='' />
    </div>
  )
}

export default AuthPageHeader
