import moment from 'moment'
import React, { useState } from 'react'
import Pagination from 'react-js-pagination'
import { Badge, Card, Col, Container, Row, Spinner, Table } from 'reactstrap'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { StyledH5, StyledH6 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import UserFlagging from '../../../../components/userFlag'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import { getTransferList, transferTransaction } from '../../../../services/api'
import ContractRef from '../../components/ContractRef'

const PendingTransfers = () => {
  const [activePage, setActivePage] = useState(1)
  const defaultCompany = { label: 'Select Company', value: null }

  const [company, setCompany] = useState(defaultCompany)

  function handleChangeCompany(v) {
    setCompany(v)
    setActivePage(1)
  }

  const transfers = useFetch(
    {
      action: getTransferList,
      autoFetch: true,
      withAdminAccess: true,
      body: {
        transferred: 0,
        page: activePage,
        company_id: company?.value ?? undefined,
      },
    },
    [activePage, company?.value],
  )
  const transfer = useFetch({
    autoFetch: false,
    initResult: null,
    action: transferTransaction,
    withAdminAccess: true,
    onComplete: () => {
      transfers.startFetch({ transferred: 0 })
    },
  })

  const handlePageChange = (page) => {
    setActivePage(page)
  }
  const handleTransfer = () => {
    const selectedList = transfers.data?.transfers?.filter((e) => e.selected)
    if (selectedList?.length > 0) {
      transfer.startFetch({
        transaction_item_ids: selectedList.map((t) => t?.transaction_item_id),
      })
    }
  }
  const handleCheck = (i, e) => {
    transfers.setData({
      ...transfers.data,
      transfers: transfers?.data?.transfers?.map((c, idx) => ({
        ...c,
        selected: i === idx ? e.target.checked : c.selected,
      })),
    })
  }
  const isAllSelect = () => {
    return !transfers?.data?.transfers?.find((e) => !e?.selected)
  }

  const setIsAllSelect = () => {
    const check = isAllSelect()
    transfers.setData({
      ...transfers.data,
      transfers: transfers?.data?.transfers?.map((c) => ({
        ...c,
        selected: !check,
      })),
    })
  }

  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_TRANSFERS)

  const transferEnabled =
    transfers.data?.transfers?.filter((e) => e.selected)?.length > 0

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <Row className='justify-content-end mb-3 mb-md-4 p-0 m-0'>
          <Col xs={12} md={6} className='p-0 m-0'>
            <StyledH5 min='22px' max='32px'>
              Pending Transfers
            </StyledH5>
          </Col>
          <Col xs={12} md={6} className='d-flex justify-content-md-end p-0 m-0'>
            {!hasEditPermission ? null : (
              <Button
                onClick={handleTransfer}
                disabled={!transferEnabled || transfer?.isLoading}
                loading={transfers?.isLoading || transfer?.isLoading}
                color={transferEnabled ? 'primary' : 'secondary'}
              >
                Transfer
              </Button>
            )}
          </Col>
        </Row>
        <Row className='p-0 m-0'>
          <Col className='p-0 m-0'>
            <Card className='p-0 m-0'>
              <Container fluid>
                <Row className='p-3' style={{ gap: '0.75rem' }}>
                  <Col md={4} xs={12} className='px-0'>
                    <CustomSelect
                      options={
                        Array.isArray(transfers?.data?.companies)
                          ? [
                              defaultCompany,
                              ...(transfers?.data?.companies?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultCompany]
                      }
                      value={company}
                      onChange={handleChangeCompany}
                    />
                  </Col>
                  <Col md={4} xs={12} className='px-0'>
                    <button
                      className='btn btn-primary'
                      onClick={() => {
                        handleChangeCompany(defaultCompany)
                      }}
                    >
                      Clear Filter
                    </button>
                  </Col>
                </Row>
              </Container>

              {transfers.isLoading ? (
                <Container style={{ minHeight: '30rem' }}>
                  <Col style={{ minHeight: '30rem' }}>
                    <Row
                      style={{ minHeight: '30rem' }}
                      className='justify-content-center align-items-center'
                    >
                      <Spinner type='grow' className='mr-2' color='primary' />
                    </Row>
                  </Col>
                </Container>
              ) : (
                <>
                  <div className='d-block d-md-none p-3'>
                    {transfers?.data?.transfers?.map((order, key) => (
                      <TransferCardAdmin
                        order={order}
                        handleCheck={(index, e) => handleCheck(index, e)}
                        index={key}
                        key={key}
                      />
                    ))}
                  </div>
                  <div className='table-responsive d-none d-md-block'>
                    <Table className='table table-centered table-nowrap text-muted'>
                      <thead className='thead-light'>
                        <tr>
                          <th className='border-top-0 text-muted sticky-col first-col'>
                            {!hasEditPermission ? null : (
                              <div
                                className='custom-control custom-checkbox'
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type='checkbox'
                                  className='custom-control-input'
                                  onChange={() => setIsAllSelect()}
                                  checked={isAllSelect() ? 'checked' : null}
                                  id='genInvIsAllSelect'
                                />
                                <label
                                  className='custom-control-label'
                                  htmlFor='genInvIsAllSelect'
                                >
                                  &nbsp;
                                </label>
                              </div>
                            )}
                          </th>
                          <th className='border-top-0 text-muted sticky-col second-col'>
                            TRX ID
                          </th>
                          <th
                            className='border-top-0 text-muted sticky-col third-col border-col'
                            style={{ '--col-width': '180px' }}
                          >
                            Item ID
                          </th>
                          <th className='border-top-0 text-muted'>
                            Contract ID
                          </th>
                          <th className='border-top-0 text-muted'>Company</th>
                          <th className='border-top-0 text-muted'>
                            Client name
                          </th>
                          <th className='border-top-0 text-muted'>
                            Contractor name
                          </th>
                          <th className='border-top-0 text-muted'>Amount</th>
                          <th className='border-top-0 text-muted'>
                            TRX Amount
                          </th>
                          <th className='border-top-0 text-muted'>ETA</th>
                          <th className='border-top-0 text-muted'>
                            Payment Method
                          </th>
                          <th className='border-top-0 text-muted'>Due date</th>
                          <th className='border-top-0 text-muted'>TRX date</th>
                          <th className='border-top-0 text-muted'>
                            TRX Confirmed date
                          </th>
                          <th className='border-top-0 text-muted'>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transfers?.data?.transfers?.map((order, key) => {
                          const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: order?.currency?.code || 'USD',
                          })
                          const sourceFormatter = new Intl.NumberFormat(
                            'en-US',
                            {
                              style: 'currency',
                              currency: order?.source_currency?.code || 'USD',
                            },
                          )
                          return (
                            <tr key={'_order_' + key}>
                              <th
                                style={{ width: '20px' }}
                                className='sticky-col first-col'
                              >
                                {!hasEditPermission ? null : (
                                  <div
                                    className='custom-control custom-checkbox'
                                    style={{ zIndex: 0 }}
                                  >
                                    <input
                                      type='checkbox'
                                      className='custom-control-input'
                                      onChange={(e) => handleCheck(key, e)}
                                      checked={
                                        order.selected ? 'checked' : null
                                      }
                                      id={`genInv${key}`}
                                    />
                                    <label
                                      className='custom-control-label'
                                      htmlFor={`genInv${key}`}
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                )}
                              </th>
                              <td className='sticky-col second-col'>
                                {order.transaction_ref}
                              </td>
                              <td
                                className='sticky-col third-col border-col text-truncate'
                                style={{
                                  width: '100%',
                                  '--col-width': '180px',
                                }}
                              >
                                {order.transaction_item_id}
                              </td>
                              <td>
                                <ContractRef
                                  isAdmin
                                  contractId={order.contract_ref}
                                />
                              </td>
                              <td>{order.company_name}</td>
                              <td>
                                <div className='d-flex'>
                                  {order.client.is_flagged ? (
                                    <UserFlagging
                                      user={order?.client}
                                      onCompleteAction={() => {
                                        transfers.startFetch({ transferred: 0 })
                                      }}
                                    />
                                  ) : null}
                                  {order.client?.first_name}{' '}
                                  {order.client?.last_name}
                                </div>
                              </td>
                              <td>
                                <div className='d-flex'>
                                  {order.contractor.is_flagged ? (
                                    <UserFlagging
                                      user={order?.contractor}
                                      onCompleteAction={() => {
                                        transfers.startFetch({ transferred: 0 })
                                      }}
                                    />
                                  ) : null}
                                  {order.contractor?.first_name}{' '}
                                  {order.contractor?.last_name}
                                </div>
                              </td>
                              <td className='text-right'>
                                {formatter.format(order.amount)}
                              </td>
                              <td className='text-right'>
                                {sourceFormatter.format(order.source_amount)}
                              </td>
                              <td>{order.method?.eta}</td>
                              <td>{order.method?.name}</td>
                              <td>
                                {moment(order.due_date).format('MM/DD/YYYY')}
                              </td>
                              <td>
                                {moment(order.created_at).format('MM/DD/YYYY')}
                              </td>
                              <td>
                                {moment(order.confirmed_at).format(
                                  'MM/DD/YYYY',
                                )}
                              </td>
                              <td>
                                <Badge
                                  className='font-size-12 rounded bg-soft-success my-2 py-1'
                                  pill
                                >
                                  <p className='text-success mb-0'>
                                    {order.transferred === 0
                                      ? 'Not transferred'
                                      : 'Transferred'}
                                  </p>
                                </Badge>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <Col>
                    <Row className='px-3 justify-content-end'>
                      <Pagination
                        itemClass='page-item'
                        linkClass='page-link'
                        activePage={activePage}
                        itemsCountPerPage={transfers.paginator?.per_page}
                        totalItemsCount={transfers.paginator?.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </Row>
                  </Col>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export const TransferCardAdmin = (props) => {
  const { index, order, handleCheck } = props

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: order?.currency?.code || 'USD',
  })

  return (
    <div
      className='py-0 mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={12} className='p-0 m-0 d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            {!!handleCheck && (
              <div className='custom-control custom-checkbox'>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  onChange={(e) => handleCheck(index, e)}
                  checked={order.checked ? 'checked' : null}
                  id={`genInvc${index}`}
                />
                <label
                  className='custom-control-label'
                  htmlFor={`genInvc${index}`}
                >
                  &nbsp;
                </label>
              </div>
            )}
            <StyledH5 className='text-dark font-weight-normal mb-0 font-size-14'>
              TRX ID
            </StyledH5>
          </div>
          <StyledH6 className='text-primary mb-0 border-secondary font-size-12'>
            {order.transaction_ref}
          </StyledH6>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Item ID
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.transaction_item_id}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Contract ID
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.contract_ref}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Client name
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.client?.first_name} {order.client?.last_name}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Contractor name
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.contractor?.first_name} {order.contractor?.last_name}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Amount
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {formatter.format(order.amount)}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Payment Method
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark font-size-14'>
            {order.method.name}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Due date
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {moment(order.due_date).format('MM/DD/YYYY')}
          </h6>
        </div>
        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            TRX date
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {moment(order.created_at).format('MM/DD/YYYY')}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            TRX Confirmed date
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {moment(order.confirmed_by).format('MM/DD/YYYY')}
          </h6>
        </div>
        <div className='d-flex mb-0 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            TRX Status
          </h6>
          <div className='w-50 d-block text-right'>
            <Badge
              className='font-size-12 rounded bg-soft-success my-2 py-1'
              pill
            >
              <p className='text-success mb-0'>
                {order.transferred === 0 ? 'Not transferred' : 'Transferred'}
              </p>
            </Badge>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingTransfers
