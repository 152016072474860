import { yupResolver } from '@hookform/resolvers/yup'
import { Trash } from '@phosphor-icons/react/dist/ssr'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'
import ControlledInput from '../../../../../components/ControlledInput'
import ControlledSelect from '../../../../../components/ControlledSelect'
import { fieldTypes } from '../../../../../components/Forms/dynamic-form'
import Button from '../../../../../components/ui/button'
import IconButton from '../../../../../components/ui/icon-button'
import { useFetch } from '../../../../../helpers/hooks'
import {
  addFormCondition,
  updateFormCondition,
} from '../../../../../services/api'
import { mapCountryToOption } from '../../../../../utils/map-to-option'
import { PlusCircle } from '@phosphor-icons/react'

const conditionTypeOptions = [
  {
    label: 'Show',
    value: 'show',
  },
  {
    label: 'Hide',
    value: 'hide',
  },
]

const targetItemTypeOptions = [
  {
    label: 'A Step',
    value: 'step',
  },
  {
    label: 'A Field',
    value: 'field',
  },
]

const ruleOperatorOptions = [
  {
    label: 'Is equal to',
    value: 'is_equal_to',
  },
  {
    label: 'Is not equal to',
    value: 'is_not_equal_to',
  },
]

const ruleEvaluationStrategyOptions = [
  {
    label: 'Apply condition if ANY of the above rule is true',
    value: 'any',
  },
  {
    label: 'Apply condition if ALL of the above rules are true',
    value: 'all',
  },
]

const schema = yup.object().shape({
  target_item_id: yup.number().required('Target item id is required'),
  target_item_type: yup
    .string()
    .oneOf(targetItemTypeOptions.map((v) => v.value))
    .required('Target item type is required'),
  type: yup
    .string()
    .oneOf(conditionTypeOptions.map((v) => v.value))
    .required('Type of condition is required'),
  rules: yup
    .array()
    .of(
      yup.object({
        form_field_id: yup.number().required('This is a required field'),
        operator: yup
          .string()
          .oneOf(ruleOperatorOptions.map((v) => v.value))
          .required('This is a required field'),
        value: yup.mixed().required('This is a required field'),
      }),
    )
    .min(1, 'There must be at least 1 rule'),
  rules_evaluation_strategy: yup
    .string()
    .label('Rules evaluation strategy')
    .oneOf(ruleEvaluationStrategyOptions.map((v) => v.value))
    .required(),
})

function EditFormCondition({
  form,
  toggle,
  isOpen,
  refetch,
  editCondition = null,
}) {
  const {
    eor_countries: eorCountries,
    languages,
    religions,
    education_levels: educationLevels,
    passport_types: passportTypes,
    religious_faiths: religiousFaiths,
    marital_statuses: maritalStatuses,
  } = useSelector((state) => state?.Layout?.staticData ?? { eor_countries: [] })

  /**
   * Retrieves options for a select input based on the field type.
   * @param {object} field - The field object.
   * @returns {array} - Array of options for the select input.
   */
  const getSelectInputOptions = useCallback(
    (field) => {
      let options = []
      switch (field?.type) {
        case fieldTypes.country_dropdown: {
          options = (eorCountries ?? [])?.map((c) =>
            mapCountryToOption(c, 'id', true),
          )
          break
        }
        case fieldTypes.language_dropdown: {
          options = languages?.map((l) => ({
            label: l.name,
            value: l.id?.toString(),
          }))
          break
        }
        case fieldTypes.religion_dropdown: {
          options = religions?.map((r) => ({
            label: r.name,
            value: r.id?.toString(),
          }))
          break
        }
        case fieldTypes.passport_type_dropdown: {
          options = passportTypes?.map((p) => ({
            label: p.name,
            value: p.id?.toString(),
          }))
          break
        }
        case fieldTypes.religious_faith_dropdown: {
          options = religiousFaiths?.map((r) => ({
            label: r.name,
            value: r.id?.toString(),
          }))
          options?.push({ label: 'None of the above', value: 'none' })
          break
        }
        case fieldTypes.marital_status_dropdown: {
          options = maritalStatuses?.map((m) => ({
            label: m.name,
            value: m.id?.toString(),
          }))
          break
        }
        case fieldTypes.education_level_dropdown: {
          options = educationLevels?.map((e) => ({
            label: e.name,
            value: e.id?.toString(),
          }))
          break
        }
        default: {
          options = field.options?.map((o) => ({
            label: o,
            value: o.toString(),
          }))
          break
        }
      }

      if (field.configs?.has_other_option) {
        options?.push({
          label: field.configs.other_option_label,
          value: 'other',
        })
      }

      return options
    },
    [
      educationLevels,
      eorCountries,
      languages,
      maritalStatuses,
      passportTypes,
      religions,
      religiousFaiths,
    ],
  )

  const defaultValues = {
    target_item_id: editCondition?.target_item_id,
    target_item_type: editCondition?.target_item_type,
    type: editCondition?.type,
    rules_evaluation_strategy: editCondition?.rules_evaluation_strategy,
    rules: editCondition?.rules,
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {
    fields: rulesFields,
    append: appendRule,
    remove: removeRule,
  } = useFieldArray({
    control,
    name: 'rules',
  })

  const { target_item_id: targetItemId, target_item_type: targetItemType } =
    useWatch({
      control,
    })

  useEffect(() => {
    const watchSubscription = watch((values, { name }) => {
      if (name === 'target_item_type') {
        // reset target_item_id on target_item_type change
        setValue('target_item_id', null)
      }

      if (name === 'target_item_id') {
        // reset form_field_id in the rules array
        const newRules = values?.rules?.map((_rule) => {
          if (
            values?.target_item_type === 'field' &&
            _rule.form_field_id === values?.target_item_id
          ) {
            _rule.form_field_id = null
          } else if (values?.target_item_type === 'step') {
            // process for 'step'
            // make sure _rule.form_field_id is not among the selected step's form fields
            if (
              form?.form_steps
                ?.find((_step) => _step.id === values?.target_item_id)
                ?.form_fields?.map((_formFields) => _formFields.id)
                .includes(_rule.form_field_id)
            ) {
              _rule.form_field_id = null
            }
          }

          return _rule
        })

        setValue('rules', newRules)
      }
    })
    return () => watchSubscription.unsubscribe()
  }, [watch])

  const { startFetch: addCondition, isLoading: isAdding } = useFetch({
    action: addFormCondition,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Condition added successfully')
      toggle?.()
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: updateCondition, isLoading: isUpdating } = useFetch({
    action: updateFormCondition,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Condition updated successfully')
      toggle?.()
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const formStepOptions = form?.form_steps?.map((_step) => ({
    value: _step.id,
    label: _step.title,
  }))

  /**
   * Syntax for grouped options
   * https://react-select.com/home#getting-started
   * https://github.com/JedWatson/react-select/blob/master/docs/data.ts#L133
   */
  const formFieldsOptions = form?.form_steps
    ? form?.form_steps?.map((_step) => ({
        label: _step.title,
        options: _step?.form_fields?.map((_field) => ({
          value: _field.id,
          label: _field.title,
        })),
      }))
    : form?.form_fields?.map((_step) => ({
        value: _step.id,
        label: _step.title,
      }))

  const getFilteredFormFieldOptions = () => {
    return form?.form_steps
      ? form?.form_steps?.map((_step) => ({
          label: _step.title,
          options: _step?.form_fields
            ?.filter((_f) =>
              targetItemType === 'field'
                ? _f.id !== targetItemId
                : _f.form_step_id !== targetItemId,
            )
            ?.map((_field) => ({
              value: _field.id,
              label: _field.title,
            })),
        }))
      : form?.form_fields
          ?.filter((_f) => {
            return targetItemType === 'field'
              ? _f.id !== targetItemId
              : _f.form_step_id !== targetItemId
          })
          .map((_field) => ({
            value: _field.id,
            label: _field.title,
          }))
  }

  const filteredFormFieldsOptions = useMemo(
    () => getFilteredFormFieldOptions(),
    [targetItemId],
  )

  const _forms = useMemo(
    () =>
      form.form_steps
        ? form.form_steps?.map((s) => s.form_fields)?.flat()
        : form.form_fields,
    [form],
  )

  const getFieldRuleIsFor = (index) => {
    const field = watch('rules')?.[index]
    const fieldRuleIsFor = _forms?.find((f) => f.id === field?.form_field_id)
    return fieldRuleIsFor
  }

  const onSubmit = (data) => {
    if (editCondition) {
      updateCondition({
        ...data,
        form_condition_id: editCondition.id,
        form_id: form?.id,
      })
    } else {
      addCondition({ ...data, form_id: form?.id })
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggle}>Add Condition</ModalHeader>
        <ModalBody>
          <div className='tw-flex tw-flex-col tw-gap-4'>
            <div className=''>
              <ControlledSelect
                control={control}
                name='target_item_type'
                label='This condition is for?'
                error={errors?.target_item_type}
                options={targetItemTypeOptions}
              />
            </div>

            <div className=''>
              <ControlledSelect
                control={control}
                label={
                  'Select the ' +
                  (targetItemType === 'field' ? 'Field' : 'Step')
                }
                name='target_item_id'
                error={errors?.target_item_id}
                disabled={!targetItemType}
                options={
                  targetItemType === 'field'
                    ? formFieldsOptions
                    : formStepOptions
                }
              />
            </div>

            <div className=''>
              <ControlledSelect
                control={control}
                label='Type of condition'
                name='type'
                error={errors?.type}
                options={conditionTypeOptions}
                disabled={!targetItemId}
              />
            </div>
          </div>

          {/* // Have a add rule */}
          {rulesFields.map((field, index) => (
            <div
              key={field.id}
              className='tw-my-4 tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-bg-surface-10 tw-p-4'
            >
              <div className='tw-flex tw-items-center tw-justify-between'>
                <div className='tw-text-base tw-font-bold'>
                  Rule {index + 1}
                </div>
                <IconButton
                  color='transparent'
                  icon={<Trash size={20} />}
                  onClick={() => removeRule(index)}
                />
              </div>
              <ControlledSelect
                control={control}
                label='Field'
                name={`rules.${index}.form_field_id`}
                error={errors?.target_item_id}
                options={filteredFormFieldsOptions}
              />

              <ControlledSelect
                control={control}
                label='Condition'
                name={`rules.${index}.operator`}
                error={errors?.target_item_id}
                options={ruleOperatorOptions}
              />

              {getFieldRuleIsFor(index)?.type?.includes('dropdown') ? (
                <ControlledSelect
                  control={control}
                  label='Value'
                  name={`rules.${index}.value`}
                  options={getSelectInputOptions(getFieldRuleIsFor(index))}
                />
              ) : (
                <ControlledInput
                  wrapperClassName='tw-grow'
                  control={control}
                  label='Value:'
                  name={`rules.${index}.value`}
                  placeholder='Rule value'
                />
              )}
            </div>
          ))}
          {errors?.rules && (
            <div className='tw-my-3 tw-text-red'>
              {errors?.rules?.root?.message ?? errors?.rules?.message}
            </div>
          )}

          <Button
            className='tw-my-4'
            textClassName='tw-flex tw-place-items-center tw-gap-2'
            block
            outline
            onClick={() => appendRule()}
          >
            <PlusCircle size={18} /> Add Rule
          </Button>

          <Row>
            <Col sm={12}>
              <ControlledSelect
                control={control}
                label='Rule evaluation strategy'
                name='rules_evaluation_strategy'
                error={errors?.rules_evaluation_strategy}
                options={ruleEvaluationStrategyOptions}
                disabled={!rulesFields?.length}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className='tw-flex tw-w-full tw-items-center tw-gap-2'>
          <Button color='secondary' outline onClick={() => toggle?.()}>
            Cancel
          </Button>
          <Button
            disabled={isAdding || isUpdating}
            loading={isAdding || isUpdating}
            color='primary'
            type='submit'
          >
            {editCondition ? 'Update' : 'Add'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default EditFormCondition
