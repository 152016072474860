import {
  DotsThreeOutlineVertical,
  IdentificationBadge,
  PencilSimple,
  Person,
} from '@phosphor-icons/react'
import React, { useCallback, useState } from 'react'
import { flushSync } from 'react-dom'
import { Link, useLocation } from 'react-router-dom'
import ReactFlow, {
  Background,
  Controls,
  Handle,
  MiniMap,
  Position,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow'
import { useTimeout } from 'usehooks-ts'
import { UncontrolledTooltip } from 'reactstrap'

import { Avatar, cn } from 'ui'
import Flag from '../../components/ui/flag'
import { departmentColors } from './department-colors'
import { d3HierarchyLayout } from './d3-auto-layout'

export function CompanyProfile({
  data: { name = '', logo = '', handless = false },
}) {
  return (
    <>
      <div className='tw-h-[70px] !tw-w-[220px] tw-overflow-hidden tw-rounded tw-border tw-border-surface-30 tw-bg-white'>
        <div className='tw-flex tw-flex-nowrap tw-items-center tw-gap-2 tw-border-t-2 tw-border-t-primary-100 tw-p-3'>
          <Avatar name={name} photo={logo} />
          <div className='tw-ml-2 tw-min-w-0' translate='no'>
            <p className='mb-0 tw-truncate tw-font-bold tw-text-text-80'>
              {name}
            </p>
            <span className='tw-text-secondary-80'>Entity</span>
          </div>
        </div>
      </div>

      <Handle
        type='source'
        position={Position.Bottom}
        className={handless ? invisibleHandleClassName : handleClassName}
        isConnectable={false}
      />
    </>
  )
}

const handleClassName = '!tw-w-px !tw-min-w-px !tw-bg-[#b1b1b7]'
const invisibleHandleClassName = '!tw-w-px !tw-min-w-px !tw-bg-transparent'
export function UserProfile({
  data: {
    name = '',
    role = '',
    flag = '',
    id = '',
    photo = null,
    isTopLevel = false,
    isLastLevel = false,
    handless = false,
    className = '',
    linkToContract = false,
    linkToContractHash,
  },
  selected,
  onIdBadgeClick,
}) {
  const { pathname } = useLocation()
  const inTimeOff = pathname.includes('time-off-policies')
  return (
    <>
      {!handless && (
        <Handle
          type='target'
          position={Position.Top}
          className={isTopLevel ? invisibleHandleClassName : handleClassName}
          isConnectable={false}
        />
      )}
      <div
        className={cn(
          'tw-w-52 tw-rounded tw-border tw-border-surface-30 tw-bg-white',
          selected &&
            'tw-ring-4 tw-ring-primary-60 tw-ring-offset-4 tw-ring-offset-surface-20',
          'tw-w-[220px]',
          className,
        )}
      >
        <div className='tw-flex tw-flex-nowrap tw-items-center tw-gap-2 tw-p-3'>
          <Avatar
            name={name}
            photo={photo}
            size='lg'
            flag={inTimeOff ? flag : undefined}
            className={cn({ 'tw-bg-systemGold-20': !name && inTimeOff })}
            customFlag={
              !flag || inTimeOff ? null : (
                <Flag
                  url={flag}
                  className='tw-absolute -tw-left-1.5 -tw-top-1.5 tw-block !tw-h-5 !tw-w-5 tw-rounded-full tw-border-2 tw-border-solid tw-border-white tw-object-cover'
                />
              )
            }
            icon={
              !name &&
              inTimeOff && (
                <DotsThreeOutlineVertical
                  size={20}
                  className='tw-fill-systemGold-110'
                />
              )
            }
          />
          <div translate='no' className='tw-min-w-0 tw-leading-none'>
            {!inTimeOff ? (
              <p className='tw-mb-0 tw-truncate tw-text-sm/5 tw-font-semibold'>
                {linkToContract ? (
                  <Link
                    className='!tw-text-black'
                    to={`/contract/detail?id=${id}${linkToContractHash ?? ''}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {name}
                  </Link>
                ) : (
                  name
                )}
              </p>
            ) : (
              <span className='tw-flex tw-items-center'>
                <p className='tw-mb-0 tw-truncate tw-text-sm tw-font-semibold'>
                  {name || 'Pending onboarding'}
                </p>
                <button
                  type='button'
                  onClick={onIdBadgeClick}
                  id={`to-contract-${id}`}
                >
                  <IdentificationBadge
                    size={16}
                    className='tw-m-2 tw-fill-secondary'
                  />
                </button>
                <UncontrolledTooltip target={`to-contract-${id}`}>
                  View details
                </UncontrolledTooltip>
              </span>
            )}
            <div className='tw-mt-0.5 tw-overflow-hidden tw-truncate tw-text-ellipsis !tw-whitespace-nowrap tw-text-wrap tw-text-xs tw-font-medium tw-text-text-80'>
              {role}
            </div>
          </div>
        </div>
      </div>
      {handless ? null : (
        <Handle
          type='source'
          position={Position.Bottom}
          className={isLastLevel ? invisibleHandleClassName : handleClassName}
          isConnectable={false}
        />
      )}
    </>
  )
}

export function DepartmentInfo({
  data: { employee_count: employeeCount, name, color },
}) {
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        className={handleClassName}
        isConnectable={false}
      />
      <div>
        <div className='tw-m-auto tw-flex tw-w-[230px] tw-flex-nowrap tw-items-center tw-gap-2 tw-rounded-full tw-border tw-border-surface-30 tw-bg-white tw-p-2'>
          <div
            className={cn(
              'tw-flex tw-items-center tw-gap-0.5 tw-rounded-full tw-border tw-border-surface-30 tw-bg-white tw-py-1 tw-pl-2 tw-pr-2',
              departmentColors[color]?.className,
            )}
          >
            <div className='tw-text-[10px]'>{employeeCount}</div>
            <Person size={12} />
          </div>

          <span className='tw-min-w-28 tw-text-center tw-font-bold tw-uppercase'>
            {name}
          </span>

          <PencilSimple size={16} className='tw-mx-2 tw-flex-shrink-0' />
        </div>
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        className={handleClassName}
        isConnectable={false}
      />
    </>
  )
}

export function DepartmentEmployeesNumber({ data: { noOfEmployees } }) {
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        className={handleClassName}
        isConnectable={false}
      />
      <div className='tw-flex tw-h-7 tw-w-[50px] tw-items-center tw-justify-center tw-gap-0.5 tw-rounded-full tw-border tw-border-surface-30 tw-bg-white tw-py-1 tw-pl-2 tw-pr-2'>
        <div className='tw-text-[10px]'>{noOfEmployees}</div>
        <Person size={12} />
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        className={handleClassName}
        isConnectable={false}
      />
    </>
  )
}

const nodeTypes = {
  userProfile: UserProfile,
  companyProfile: CompanyProfile,
  departmentInfo: DepartmentInfo,
  departmentEmployeesNumber: DepartmentEmployeesNumber,
}

function fitLayoutedView({ nodes, edges, setNodes, setEdges, fitView, view }) {
  const layouted = d3HierarchyLayout({
    nodes,
    edges,
    options: { spacing: [30, 50] },
    view,
  })

  flushSync(() => {
    setNodes([...layouted.nodes])
    setEdges([...layouted.edges])
  })

  window.requestAnimationFrame(() => {
    window.requestAnimationFrame(() => {
      fitView()
    })
  })
}

export function LayoutFlow({ nodes, edges, children, onNodeClick, view }) {
  /* Workaround to hide flashing layout */
  const [show, setShow] = useState(false)

  const { fitView } = useReactFlow()
  const [myNodes, setNodes, onNodesChange] = useNodesState(nodes)
  const [myEdges, setEdges, onEdgesChange] = useEdgesState(edges)

  const onLayout = useCallback(() => {
    fitLayoutedView({
      nodes: myNodes,
      edges: myEdges,
      setNodes,
      setEdges,
      fitView,
      view,
    })
  }, [fitView, myEdges, myNodes, setEdges, setNodes, view])

  useTimeout(() => {
    onLayout()
    /* Workaround to hide flashing layout */
    setShow(true)
  }, 500)

  return (
    <ReactFlow
      nodes={myNodes}
      edges={myEdges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      panOnScroll
      className='tw-pt-16'
      fitView
      nodesDraggable={false}
      nodesConnectable={false}
      onNodeClick={onNodeClick}
    >
      <Controls
        className='tw-flex tw-flex-row-reverse tw-rounded tw-bg-white [&>button]:tw-box-border [&>button]:tw-size-10 [&>button]:tw-border-none'
        position='top-right'
        showInteractive={false}
      />
      <MiniMap />
      <Background variant='dots' gap={12} size={1} />

      {children}

      {/* Workaround to hide flashing layout */}
      <div
        className={cn(
          'tw-pointer-events-none tw-absolute tw-inset-0 tw-z-10 tw-bg-surface-20 tw-transition-opacity tw-duration-200',
          show && 'tw-opacity-0',
        )}
      ></div>
    </ReactFlow>
  )
}

export function OrganizationChart(props) {
  return (
    <ReactFlowProvider>
      <LayoutFlow {...props} />
    </ReactFlowProvider>
  )
}
