import React, { Suspense, useEffect, useState } from 'react'
import { Container, Spinner } from 'reactstrap'
import Row from 'reactstrap/lib/Row'
import './style.css'
import ModalHeader from '../../../components/ModalHeader'
import Col from 'reactstrap/lib/Col'
import SaveChangesModal from './SaveChangesModal'
import DiscardModal from './DiscardModal'
import { useFetch } from '../../../helpers/hooks'
import {
  getAdminTemplate,
  getContractTemplate,
  updateContractTemplate,
  updateTemplateAdmin,
} from '../../../services/api'
import { Prompt, useHistory, useLocation } from 'react-router'
import toastr from 'toastr'
import Loader from '../../../components/ui/loader.jsx'

const MyEditor = React.lazy(() => import('./Editor.js'))

const ContractEditor = () => {
  const [data, setData] = useState(null)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')
  const update = useFetch({
    action: isAdmin ? updateTemplateAdmin : updateContractTemplate,
    withAdminAccess: isAdmin,
    onComplete: () => {
      history.goBack()
    },
    onError: (err) => {
      toastr.error(err)
      setEditing(true)
    },
  })
  const getTemplate = useFetch({
    action: isAdmin ? getAdminTemplate : getContractTemplate,
    withAdminAccess: isAdmin,
    autoFetch: true,
    body: isAdmin
      ? { template_id: 2 }
      : { contract_id: location.state?.contract_id },
  })
  const handleChange = (sections) => {
    setData(sections)
  }
  const saveChanges = (text) => {
    setEditing(false)
    update.startFetch(
      isAdmin
        ? {
            template_id: 2,
            sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
          }
        : {
            contract_id: location.state?.contract_id,
            name: text,
            sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
          },
    )
    setSaveChangesModal(false)
  }
  const handleDiscard = () => {
    setEditing(false)
    setDiscardModal(false)
    history.goBack()
  }
  useEffect(() => {
    const handler = (event) => {
      if (!editing) return
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    }
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [editing])

  const [saveChangesModal, setSaveChangesModal] = useState(false)
  const [discardModal, setDiscardModal] = useState(false)
  return (
    <div>
      <Container fluid className='!tw-px-0'>
        <ModalHeader noExit>
          <Col>
            <Row className='justify-content-end'>
              <button
                className='btn btn-outline-light'
                onClick={() => setDiscardModal(true)}
              >
                Discard changes
              </button>
              <button
                className='btn btn-primary ml-2'
                onClick={() => setSaveChangesModal(true)}
              >
                Save changes
              </button>
            </Row>
          </Col>
        </ModalHeader>
        <Col className='p-0'>
          <Suspense fallback={<Loader />}>
            <MyEditor
              onChange={handleChange}
              loading={getTemplate.isLoading}
              name={getTemplate.data?.name}
              agreement={getTemplate.data?.default}
              sections={getTemplate.data?.sections || []}
              onEdit={() => setEditing(true)}
            />
          </Suspense>
        </Col>
      </Container>
      <SaveChangesModal
        isOpen={saveChangesModal}
        toggle={() => setSaveChangesModal(false)}
        onSaveChanges={saveChanges}
        isAdmin={isAdmin}
      />
      <DiscardModal
        isOpen={discardModal}
        toggle={() => setDiscardModal(false)}
        onDiscard={handleDiscard}
      />
      {(update?.isLoading || getTemplate.isLoading) && (
        <div
          className='page-content min-vh-100 min-vw-100 p-0 m-0 bg-soft-primary'
          style={{ position: 'fixed', top: 0, zIndex: 999999999 }}
        >
          <Col style={{ minHeight: '100vh' }}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </div>
      )}
      <Prompt
        when={editing}
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
    </div>
  )
}

export default ContractEditor
