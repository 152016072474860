import React from 'react'
import rpLogoWhite from '../../../../../assets/images/identity/logo_white.png'
import { useLocation } from 'react-router'
import toastr from 'toastr'
import { ArrowSquareOut } from '@phosphor-icons/react'
import { addWorkspace } from '../../../../../services/api-automations'
import { useFetch } from '../../../../../helpers/hooks'
import Head from '../../../../../components/head'

export default function AutomationSlackCallback() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const state = params.get('state')

  const { completed } = useFetch({
    action: addWorkspace,
    autoFetch: !!code,
    body: { params: { code, state } },
    onComplete: () => toastr.success('Slack authorization successful'),
    onError: (error) => toastr.error(error),
  })

  return (
    <div className='min-vh-100 tw-flex tw-flex-col tw-gap-40'>
      <Head title='Slack Auth' />
      <div
        className='bg-white py-4 rp-border tw-flex tw-items-center tw-justify-center tw-text-center'
        style={{ height: 70 }}
      >
        <img src={rpLogoWhite} style={{ height: '24px' }} />
      </div>

      {!completed ? (
        <h1 className='font-size-28 tw-text-center'>Slack Authorization</h1>
      ) : (
        <div className='mx-auto my-0'>
          <h1 className='font-size-28'>Slack Connected</h1>
          <p>
            Congratulations! Your RemotePass account is now connected to Slack.
          </p>
          <p>
            You will now receive notifications directly in the RemotePass App on
            Slack.
          </p>
          <button
            className='btn btn-primary !tw-flex tw-w-fit tw-items-end tw-self-center'
            onClick={() => {
              window.close()
            }}
          >
            Open Automations <ArrowSquareOut className='tw-ml-1' size={24} />
          </button>
        </div>
      )}
    </div>
  )
}
