import React, { useEffect } from 'react'
import Loader from '../../components/ui/loader'
import AuthPageHeader from './components/auth-page-header'
import { useHandleLoginResponse } from '../../helpers/hooks'
import { oktaLogin } from '../../services/api'
import toastr from 'toastr'

function OktaLoginPage() {
  const { handleLoginResponse } = useHandleLoginResponse()
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    if (token) {
      oktaLogin(token)
        .then((data) => {
          handleLoginResponse(data?.data?.data)
        })
        .catch(() => {
          toastr.error('An error occurred while logging you in')
        })
    }
  }, [])
  return (
    <>
      <AuthPageHeader />

      <div className='tw-mb-10 tw-mt-12 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8'>
        <div className='tw-flex tw-w-[432px] tw-flex-col tw-items-center tw-gap-2 tw-bg-white tw-px-6 tw-py-20'>
          <Loader />
          <div className='tw-text-2xl'>Logging you in...</div>
          <div className='tw-text-center tw-text-text-80'>
            Your Okta login was successful. Please hold on as we log you into
            RemotePass.
          </div>
        </div>

        {/* trademark */}
        <div className='tw-text-secondary-80'>
          {' '}
          © {new Date().getFullYear()} RemotePass
        </div>
      </div>
    </>
  )
}

export default OktaLoginPage
