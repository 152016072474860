import { userTypes } from '../../helpers/enum'

export function track(event, data) {
  if (!window.analytics) return

  window.analytics.track(event, data)
}

export function tag(event, data) {
  if (!window?.dataLayer || !window?.dataLayer?.push) return

  window.dataLayer.push({ event, ...data })
}

export function getIntercomData(data) {
  const userTypeInfo =
    data?.type === userTypes.CONTRACTOR
      ? {
          entity_or_individual: data.contractor_type,
          has_full_kyc: data.has_full_kyc,
          number_of_payments_method:
            (data.paysend_card_accounts?.length ?? 0) +
            (data.coinbase_accounts?.length ?? 0) +
            (data.cash_points?.length ?? 0) +
            (data['bank-accounts']?.length ?? 0) +
            (data.payoneer_accounts?.length ?? 0) +
            (data.paypal_accounts?.length ?? 0),
        }
      : {
          company_id: data.company.id,
          number_of_users: data.users_count,
          kyb_status: data.kyb_status,
          number_of_approval_flows: data.approval_flows_count,
          is_Active: data.status === 'active',
          can_sign: data.permissions.includes('sign contracts'),
        }

  const intercomData = {
    name: data?.first_name,
    first_name: data?.first_name,
    last_name: data?.last_name,
    email: data?.email,
    user_id: data?.id,
    type: data?.type,
    'kyc-verified': data?.kyc_verified === 1,
    country: data?.country?.name || data?.company?.country?.name,
    phone: data?.phone,
    role: data?.role_name,
    'company-name': data?.company?.name,
    '2fa': data?.is_2fa_enabled,
    number_of_active_contracts: data.active_contracts_count,
    ...userTypeInfo,
  }

  return intercomData
}
