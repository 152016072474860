import { Check, Coins, Question, X } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { createInsuranceRequest } from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { RedIndicator } from '../../Activity/physical-card-banner'
import RequestModalNew from './RequestModalNew'

export function Plan({
  features,
  title,
  price,
  desc,
  currency,
  plan,
  onUpdate,
  dependents,
  ctaText = 'Get Started',
  insuranceAllowance,
  data,
}) {
  const prepareReq = useFetch({
    action: createInsuranceRequest,
    onComplete: onUpdate,
    onError: toastr.error,
  })

  const [isOpen, setIsOpen] = useState(false)
  const onSelectPlan = () => {
    setIsOpen(true)
  }
  const onBuy = (v) => {
    setIsOpen(false)
    const body = {
      ...v,
      planId: plan.id,
    }
    prepareReq.startFetch(body)
  }
  return (
    <div className='bg-white rp-shadow-2 rounded mt-3 border border-1'>
      <div className='tw-p-6'>
        {insuranceAllowance && (
          <>
            <RedIndicator />
            <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-1.5 tw-rounded tw-bg-primary-20 tw-p-6'>
              <Coins size={28} className='tw-self-center tw-text-primary' />
              <span className='tw-p-4 tw-text-center'>
                You have a monthly ${insuranceAllowance} allowance. You can use
                it for {plan?.label}.
              </span>
              <Button
                type='button'
                onClick={onSelectPlan}
                loading={prepareReq.isLoading}
                disabled={prepareReq.isLoading}
              >
                Activate Now
              </Button>
            </div>
          </>
        )}

        <SinglePlanFeatures
          features={features}
          plan={plan}
          title={title}
          desc={desc}
          price={price}
          currency={currency}
        />
      </div>

      {!insuranceAllowance && (
        <div className='tw-p-6'>
          <Button
            type='button'
            block
            onClick={onSelectPlan}
            loading={prepareReq.isLoading}
            disabled={prepareReq.isLoading}
          >
            {ctaText}
          </Button>
        </div>
      )}
      {isOpen && (
        <RequestModalNew
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
          onConfirm={onBuy}
          dependents={dependents}
          planeName={plan.name}
          data={data}
        />
      )}
    </div>
  )
}

export function SinglePlanFeatures({
  features,
  plan,
  title,
  price,
  desc,
  currency,
}) {
  const formatter = getCurrencyFormatter(currency)

  return (
    <>
      <div className='tw-mb-8'>
        <h1 className='tw-mb-0 tw-text-base tw-font-bold md:tw-text-2xl'>
          {title}
        </h1>
        <h2 className='tw-mb-0 tw-mt-2 tw-text-base tw-font-bold md:tw-text-2xl'>
          {formatter.format(price)}
          <span className='text-secondary'>/mo</span>
        </h2>
        {!desc ? null : (
          <h3 className='tw-mb-0 tw-mt-2 tw-text-xs tw-font-normal tw-text-secondary-80'>
            {desc}
          </h3>
        )}
      </div>

      <div>
        {features.map((feature, index) => (
          <div
            key={`feature-${index}`}
            className='tw-mb-6 tw-flex tw-min-w-full tw-flex-nowrap tw-items-center tw-gap-2 md:tw-min-w-[20vw]'
          >
            {feature?.included ? (
              <Check
                className='tw-size-6 tw-rounded-full tw-bg-systemGreen-20 tw-p-1.5 tw-text-systemGreen-110'
                weight='bold'
              />
            ) : (
              <X
                className='tw-size-6 tw-rounded-full tw-bg-systemRed-20 tw-p-1.5 tw-text-systemRed-110'
                weight='bold'
              />
            )}
            <h1 className='tw-mb-0 tw-text-sm tw-font-bold'>{feature.name}</h1>
            <Question
              size={18}
              className='tw-text-secondary-70'
              weight='bold'
              id={`feature-${plan?.id}-${index}`}
            />
            <UncontrolledTooltip
              placement='top'
              target={`feature-${plan?.id}-${index}`}
            >
              {feature.details}
            </UncontrolledTooltip>
          </div>
        ))}
      </div>
    </>
  )
}
