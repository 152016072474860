import React, { useMemo, useState } from 'react'
import OktaFullLogo from '../../assets/images/brands/Okta-full.svg'
import { Button } from 'ui'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ControlledInput from '../../components/ControlledInput'
import { useFetch } from '../../helpers/hooks'
import ControlledSelect from '../../components/ControlledSelect'
import { useHistory } from 'react-router-dom'
import { mapListToOption } from '../../utils/map-to-option'
import Alert from '../../components/ui/alert'
import AuthPageHeader from './components/auth-page-header'
import { getOktaCompanies } from '../../services/api'
import toastr from 'toastr'

function OktaLoginPage() {
  const history = useHistory()
  const [companies, setCompanies] = useState([])
  const [isRedirecting, setIsRedirecting] = useState(false)
  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email('Invalid email')
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Invalid email',
          )
          .required('Email is required'),
        company_id: yup.string().when([], {
          is: () => companies.length > 1,
          then: (schema) => schema.required('Company is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    [companies],
  )
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const selectedCompany = watch('company_id')
  const email = watch('email')

  function onSubmit(data) {
    if (selectedCompany) {
      setIsRedirecting(true)
      redirectToOkta()
    } else {
      _getOktaCompanies(data)
    }
  }
  const { isLoading: isLoadingCompanies, startFetch: _getOktaCompanies } =
    useFetch({
      action: getOktaCompanies,
      onComplete: (data) => {
        if (data.companies?.length === 1) {
          setValue('company_id', data.companies?.[0].rpCompanyId)
        }
        setCompanies(data.companies)
      },
      onError: (_, res) => {
        if (res?.statusCode === 404) {
          setError('email', {
            message: res?.message,
          })
        } else {
          toastr.error('Something went wrong. Please try again later.')
        }
      },
    })
  function redirectToOkta() {
    window.location.href = `${process.env.REACT_APP_API_HOST_V1}/auth-service/api/v1/saml/okta/${selectedCompany}`
  }

  function handleBack() {
    if (companies.length) {
      setCompanies([])
      setValue('company_id', null)
    } else {
      history.goBack()
    }
  }

  return (
    <>
      <AuthPageHeader />
      <div className='tw-mb-10 tw-mt-12 tw-flex tw-flex-col tw-items-center tw-gap-8'>
        <img src={OktaFullLogo} alt='Okta' className='tw-h-20' />
        <div className='tw-text-2xl tw-text-secondary'>SSO sign in</div>
        <div className='tw-w-[432px] tw-bg-white tw-py-6'>
          <div className='tw-px-6'>
            {companies.length ? (
              <>
                <Alert color='info'>
                  The email <span>{email}</span> is associated with the
                  following
                  {companies.length > 1 ? ' companies' : ' company'} :
                </Alert>
                <ControlledSelect
                  control={control}
                  disabled={companies.length === 1}
                  name='company_id'
                  options={companies.map((company) =>
                    mapListToOption(company, { valueKey: 'rpCompanyId' }),
                  )}
                />
              </>
            ) : (
              <ControlledInput
                control={control}
                name='email'
                placeholder='Email'
                type='email'
                label='Email'
              />
            )}
          </div>
          <hr />
          <div className='tw-flex tw-items-center tw-justify-between tw-px-6'>
            <Button lightOutline color='secondary' onClick={handleBack} outline>
              Back
            </Button>
            <Button
              loading={isLoadingCompanies || isRedirecting}
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || isLoadingCompanies || isRedirecting}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default OktaLoginPage
