import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import Button from '../../../../components/ui/button'
import {
  SideMenu,
  sideMenuGridClass,
  SideMenuHeading,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { withdrawCurrencies } from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import { AddBankAccountV2Form } from '../../../withdrawProcess/add-bank-account-v2'
import {
  getBankAccountData,
  useCreateVendorBankAccount,
  validateBankAccountData,
} from '../../../withdrawProcess/use-bank-account-data'
import { track } from '../../../../utils/analytics'
import UploadPreview from '../../../../components/Common/upload-preview'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import { cn } from 'ui'

const addBankAccountFormId = 'addBankAccountFormId'
export function AddBankSideMenu({
  isOpen,
  toggle,
  vendor,
  updateVendors,
  source = 'vendor_list',
}) {
  const [showBill, setShowBill] = useState(false)
  const user = useSelector((state) => state.Account?.user)
  const profileCurrency = useSelector(
    (state) => state.userProfile?.userProfile?.company?.currency?.code,
  )

  const { data: currencies } = useFetch(
    {
      action: withdrawCurrencies,
      autoFetch: isOpen && !!profileCurrency,
      body: { currency: profileCurrency },
      initResult: [],
    },
    [profileCurrency, isOpen],
  )

  const formMethods = useForm()
  const { handleSubmit, control, setError, clearErrors, watch } = formMethods
  const data = watch()
  const { createBankAccount, isLoading: isCreatingBankAccount } =
    useCreateVendorBankAccount({
      successMessage: [
        `You successfully edited the bank details for ${vendor?.name}`,
        'Bank details added',
      ],
      onSuccess: () => {
        const logCreateVendorData = {
          userID: user?.id,
          vendorID: vendor?.id,
          'Vendor Name': vendor?.name,
          Source: source,
          Currency: data?.currency,
          'Account Holder Name': data?.accountHolderName,
        }
        track('Vendor Updated', logCreateVendorData)
        toggle?.()
        updateVendors?.()
      },
    })

  if (!isOpen) return null

  function onSubmit(values) {
    const isValid = validateBankAccountData({ values, setError, clearErrors })

    if (!isValid) {
      toastr.error('Please check field errors')
      return
    }

    const bankAccountData = getBankAccountData(values, {
      user: { firstName: user?.first_name, lastName: user?.last_name },
    })

    createBankAccount({ ...bankAccountData, vendorId: vendor?.id })
  }

  return (
    <SideMenu
      isOpen={isOpen}
      onClose={toggle}
      preventScrollBehavior={false}
      itemListClassName='tw-flex'
      className={showBill ? 'tw-max-w-[70%]' : ''}
    >
      <div className={cn('tw-flex-grow')}>
        {showBill ? (
          <UploadPreview
            preview={{
              type: 'application/pdf',
              data: vendor?.latest_bill?.pdf,
            }}
          />
        ) : null}
      </div>
      <div
        className={sideMenuGridClass({
          className: '!tw-grid tw-max-w-md tw-flex-shrink-0',
        })}
      >
        <SideMenu.Header toggle={toggle}>
          <SideMenuHeading
            title='Bank details'
            subTitle='Edit vendor bank details'
            onClickBack={toggle}
          />
        </SideMenu.Header>

        <SideMenu.Body>
          {vendor?.latest_bill?.pdf && (
            <p className='tw-mb-0 tw-flex tw-items-center tw-justify-between !tw-text-sm tw-text-black'>
              There is an invoice uploaded for this vendor
              <Button
                className='!tw-py-0 !tw-pr-0 !tw-ps-1'
                size='sm'
                color='link'
                iconRight={
                  showBill ? <EyeSlash size={16} /> : <Eye size={16} />
                }
                onClick={() => setShowBill((prev) => !prev)}
              >
                {showBill ? 'Hide' : 'Preview'}
              </Button>
            </p>
          )}

          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} id={addBankAccountFormId}>
              <AddBankAccountV2Form
                control={control}
                currency={profileCurrency}
                currencies={currencies?.map((c) => mapCurrencyToOption(c))}
                className='tw-pt-2'
                fieldsClassName='!tw-grid-cols-1'
                isBillVendor
              />
            </form>
          </FormProvider>
        </SideMenu.Body>

        <SideMenu.Footer className='bg-white tw-sticky tw-bottom-0'>
          <Button
            type='button'
            onClick={toggle}
            outline
            color='light'
            disabled={isCreatingBankAccount}
          >
            Cancel
          </Button>
          <Button
            formId={addBankAccountFormId}
            type='submit'
            disabled={isCreatingBankAccount}
            loading={isCreatingBankAccount}
          >
            Save
          </Button>
        </SideMenu.Footer>
      </div>
    </SideMenu>
  )
}
