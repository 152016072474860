import { Info } from '@phosphor-icons/react'
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { cn } from 'ui'
import { Box, BoxIcon } from '../../../components/ui/box'
import Shimmer from '../../../components/ui/shimmer'

export default function DefaultApprovalFlow({
  icon,
  cta,
  description,
  tip,
  infoId,
  title,
  subtitle,
  isLoading,
  nameList,
  className,
}) {
  return (
    <Box noPadding className={cn('tw-flex-1', className)}>
      <div className='tw-flex tw-items-center tw-gap-4 tw-p-4'>
        <BoxIcon>{icon}</BoxIcon>

        <div>
          <span className='tw-flex tw-gap-2'>
            {isLoading ? (
              <Shimmer height={20} />
            ) : (
              <p className='tw-mb-0 tw-font-bold tw-text-black'>{title}</p>
            )}
            {tip && (
              <>
                <Info size={20} className='tw-fill-secondary' id={infoId} />
                <UncontrolledTooltip target={infoId}>{tip}</UncontrolledTooltip>
              </>
            )}
          </span>
          {isLoading ? (
            <Shimmer height={20} className='tw-mt-2' width={206} />
          ) : (
            <p className='tw-mb-0 tw-mt-0.5 tw-text-secondary-100'>
              {subtitle}
            </p>
          )}
        </div>
      </div>
      <div className='tw-flex tw-justify-between tw-border-t tw-border-surface-30 tw-bg-surface-10 tw-p-6'>
        {nameList || (
          <p className='tw-mb-0 tw-mt-0.5 tw-text-text-80'>{description}</p>
        )}
        {cta}
      </div>
    </Box>
  )
}
