import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

import Button from '../../../../components/ui/button'
import { useFetch, usePermissions, useResize } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'
import { getAdminContractPDF, getContractPDF } from '../../../../services/api'

function SignModal({
  showSignModal,
  setShowSignModal,
  handleDownloadContract,
  downloading,
  data,
  handleSignClick,
  loadingSignature,
  isAdmin,
}) {
  const { hasAccess } = usePermissions()
  const iframeRef = useRef(null)
  const isMobile = useResize()
  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const [pdfUrl, setPdfUrl] = useState()
  const toggleModal = () => setShowSignModal(false)
  useFetch({
    action: isAdmin ? getAdminContractPDF : getContractPDF,
    autoFetch: true,
    withAdminAccess: isAdmin,
    body: data,
    onComplete: (data) => {
      const objectUrl = URL.createObjectURL(data)
      setPdfUrl(objectUrl)
    },
  })
  return (
    <Modal
      size='lg'
      centered
      isOpen={showSignModal}
      scrollable
      toggle={toggleModal}
    >
      <ModalHeader
        close={
          <div className='d-flex align-items-center gap-8'>
            <Button
              color='light'
              size='sm'
              outline
              onClick={handleDownloadContract}
              disabled={!hasAccess(permissions.DownloadContracts)}
              loading={downloading}
            >
              Download
            </Button>

            <button
              onClick={toggleModal}
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        }
      >
        Contract <span className='text-secondary'>#{data?.ref}</span>
      </ModalHeader>

      <ModalBody style={{ height: isMobile ? '20vh' : '60vh' }} className='p-0'>
        <iframe
          id='signatureFrame'
          title='Contract view'
          width='100%'
          src={pdfUrl}
          height='98%'
          ref={iframeRef}
          style={{ backgroundColor: '#fff', border: 'none' }}
        />
      </ModalBody>

      <ModalFooter className='p-3 p-md-4'>
        <Container>
          <Row className='p-0 align-items-end d-flex'>
            {isAdmin && (
              <Col className='p-0 m-0 pr-md-3 mb-3 mb-md-0' sm={12} md={8}>
                <label className='font-size-14'>Provider Full Name</label>
                <Input
                  placeholder='Full name'
                  value={`${data?.contractor?.first_name || ''} ${
                    data?.contractor?.middle_name
                      ? data?.contractor?.middle_name + ' '
                      : ''
                  }${data?.contractor?.last_name || ''}`}
                  disabled
                />
              </Col>
            )}
            {user?.type === 'contractor' && !isAdmin && (
              <Col className='p-0 m-0 pr-md-3 mb-3 mb-md-0' sm={12} md={8}>
                <label className='font-size-14'>Your Full Name</label>
                <Input
                  placeholder='Full name'
                  disable={user?.type === 'client'}
                  value={`${userProfile?.first_name || ''} ${
                    userProfile?.middle_name
                      ? userProfile?.middle_name + ' '
                      : ''
                  }${userProfile?.last_name || ''}`}
                  disabled
                />
                <h6 className='text-muted d-none'>
                  Enter your full name [contractor]
                </h6>
              </Col>
            )}
            {user?.type === 'client' && !isAdmin && (
              <Col className='p-0 m-0 pr-md-3 mb-3 mb-md-0' sm={12} md={8}>
                <label className='font-size-14'>Your Full Name</label>
                <Input
                  placeholder='Full name'
                  disable={user?.type === 'contractor'}
                  value={`${userProfile?.first_name || ''} ${
                    userProfile?.middle_name
                      ? userProfile?.middle_name + ' '
                      : ''
                  }${userProfile?.last_name || ''}`}
                />
                <h6 className='text-muted d-none'>
                  Enter your full name [client]
                </h6>
              </Col>
            )}
            <Col className='p-0 m-0' sm={12} md={4}>
              <Button
                onClick={() => {
                  window.analytics.track('Clicked sign contract', {
                    contract_id: data?.ref,
                    contract_type: data?.type,
                  })
                  handleSignClick()
                }}
                type='button'
                loading={loadingSignature}
                disabled={loadingSignature}
                block
              >
                Agree & Sign
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  )
}

export default SignModal
