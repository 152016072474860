import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import toastr from 'toastr'
import * as yup from 'yup'

import { Card } from 'ui'
import {
  PasswordChecklistComp,
  validatePassword,
} from '../../components/CommonForBoth/ChangePassword'
import { ControlledPasswordInput } from '../../components/controlled-password-input'
import Button from '../../components/ui/button'
import { HeaderAnonym } from '../../components/ui/header-anonym'
import { useFetch } from '../../helpers/hooks'
import { updatePassword } from '../../services/api'

export default function UpdatePassword() {
  const history = useHistory()
  const location = history.location

  const resetToken = new URLSearchParams(location.search).get('token')

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        password: yup
          .string()
          .test(
            'password-strength',
            'Weak password easy to guess, password should respect checklist condition',
            (value) => validatePassword(value).isValid,
          )
          .required('New Password is required'),
      }),
    ),
  })

  const password = useWatch({ control, name: 'password' })

  function onSubmit(values) {
    updatePass.startFetch({ ...values, token: resetToken })
  }

  const updatePass = useFetch({
    action: updatePassword,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while updating password')
      } else {
        history.push('/login')
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  return (
    <>
      <HeaderAnonym />

      <div className='tw-mx-auto tw-mt-12 tw-w-full tw-max-w-md'>
        <Card className='tw-p-6'>
          <form onSubmit={handleSubmit(onSubmit)} className='tw-space-y-4'>
            <ControlledPasswordInput
              control={control}
              id='passWord'
              name='password'
              autoComplete='new-password'
              label='New Password'
              type='password'
              placeholder='Enter new Password'
            />
            <PasswordChecklistComp password={password} TooltipId='passWord' />

            <Button
              loading={updatePass.isLoading}
              disabled={updatePass.isLoading}
              type='submit'
              block
            >
              Reset
            </Button>
          </form>
        </Card>

        <div className='tw-my-4 tw-text-center md:tw-my-6'>
          <Link
            to='/login'
            className='tw-my-4 tw-block tw-text-sm tw-font-medium md:tw-my-6'
          >
            Go back to the login page
          </Link>

          <p className='tw-mb-0'>© {new Date().getFullYear()} RemotePass. </p>
        </div>
      </div>
    </>
  )
}
