import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Play, PlayCircle, PauseCircle } from '@phosphor-icons/react'
import Button from '../../../../../components/ui/button'
import Toggle from '../../../../../components/Forms/Toggle/Toggle'
import { Controller } from 'react-hook-form'

export function SaveConfirmModal(props) {
  const { isOpen, toggle, onSave } = props

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Play size={25} className='tw-text-systemGreen' />
      </ModalHeader>
      <Controller
        name='isPublished'
        render={({ field: { value, onChange } }) => (
          <>
            <ModalBody className='d-flex flex-column tw-gap-1'>
              <span className='tw-text-base tw-font-bold'>Save changes</span>
              <span className='tw-text-s tw-text-secondary'>
                This is an ongoing automation, all pending actions will be
                canceled
              </span>
              <div className='tw-mt-5 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-rounded tw-border tw-border-surface-30 tw-p-2'>
                {value ? (
                  <PlayCircle size={25} className='tw-text-systemGreen' />
                ) : (
                  <PauseCircle size={25} className='tw-text-systemGold' />
                )}

                <div className='tw-flex tw-flex-1 tw-flex-col'>
                  <span>{value ? 'Published' : 'Unpublished'}</span>
                  <span>
                    {value
                      ? 'This automation is published'
                      : 'This automation is unpublished'}
                  </span>
                </div>
                <Toggle
                  check={value}
                  change={(e) => onChange(e.target.checked)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className='tw-mb-2 !tw-bg-white'
                textClassName='!tw-text-black'
                color='surface-30'
                outline={true}
                onClick={toggle}
              >
                Stay on this page
              </Button>
              <Button
                color='primary'
                onClick={() => {
                  onSave()
                  toggle()
                }}
              >
                Yes, {value ? 'Publish' : 'Unpublish'}
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}
