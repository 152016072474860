import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Paginations from 'react-js-pagination'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import Shimmer from '../../../../../components/ui/shimmer'
import { useFetch } from '../../../../../helpers/hooks'
import { getContractsListAdmin } from '../../../../../services/api'
import ContractList from '../../../../Contract/ContractList/ContractList'
import AdminTabNav from '../../../../../components/admin-tab-nav'

const ContractsListAdmin = () => {
  const [activePage, setActivePage] = useState(1)
  const defaultState = { label: 'Select Status', value: -1 }
  const defaultCompany = { label: 'Select Company', value: null }
  const [state, setState] = useState(defaultState)
  const [company, setCompany] = useState({
    label: 'Select Company',
    value: null,
  })
  const [query, setQuery] = useState(null)
  const [activeTab, setActiveTab] = useState('1')
  const [tabOptions] = useState([
    {
      label: 'Active Contracts',
      value: '1',
    },
    {
      label: 'Archived Contracts',
      value: '2',
    },
  ])
  const contractsList = useFetch({
    action: getContractsListAdmin,
    withAdminAccess: true,
  })
  const status = useSelector(
    (state) => state?.Layout?.staticData?.contract_statuses,
  )

  const handlePageChange = (page) => {
    setActivePage(page)
  }

  useEffect(() => {
    let cancel
    // eslint-disable-next-line no-return-assign
    applyFilter(new axios.CancelToken((c) => (cancel = c)))
    return () => {
      cancel()
    }
  }, [state, company, activePage, query])

  useEffect(() => {
    setActivePage(1)
    contractsList.startFetch({ archived: activeTab === '1' ? 0 : 1 })
  }, [activeTab])

  const applyFilter = (cancelToken) => {
    const body = { archived: activeTab === '1' ? 0 : 1 }
    if (company?.value) {
      body.company_id = company?.value
    }
    if (activePage !== 1) {
      body.page = activePage
    }
    if (state?.value !== -1) {
      body.status_id = state?.value
    }
    if (query) {
      body.search = query
      setActivePage(1)
      body.page = undefined
    }
    contractsList.startFetch(
      body,
      !query && !company?.value && state?.value === -1,
      cancelToken,
    )
  }
  const updateList = () => {
    contractsList.startFetch(
      {
        page: activePage,
        archived: activeTab === '1' ? 0 : 1,
      },
      false,
    )
  }

  const tabItems = [
    {
      label: 'Active Contracts',
      isActive: activeTab === '1',
      onClick: () => setActiveTab('1'),
    },
    {
      label: 'Archived Contracts',
      isActive: activeTab === '2',
      onClick: () => setActiveTab('2'),
    },
  ]

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <Row className='mb-3 mb-md-4 p-0 m-0'>
          <Col xs={12} md={6} className='p-0 m-0'>
            <h5 className='tw-text-[22px] md:tw-text-[32px]'>Contracts</h5>
          </Col>
        </Row>

        <Card className='p-0 m-0' style={{ position: 'unset' }}>
          <CardBody className='p-0 m-0'>
            <Row
              style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
              className='d-block d-md-none p-3 p-0 m-0 justify-content-between align-items-center border-bottom bg-white'
            >
              {contractsList?.isLoading ? (
                <Shimmer width='100%' height='42px' />
              ) : (
                <CustomSelect
                  name='selectInput'
                  value={tabOptions[activeTab - 1]}
                  defaultValue={{
                    label: 'Active Contracts',
                    value: '1',
                  }}
                  onChange={(v) => setActiveTab(v.value)}
                  options={tabOptions}
                />
              )}
            </Row>

            <div className='d-none d-md-flex justify-content-between align-items-center m-0 p-3'>
              <AdminTabNav
                items={tabItems}
                loading={contractsList?.isLoading}
              />
            </div>

            <Container fluid>
              <Row className='tw-gap-3 tw-p-4'>
                <Col md={4} xs={12}>
                  {contractsList?.isLoading ? (
                    <Shimmer width='100%' height='42px' />
                  ) : (
                    <CustomSelect
                      options={
                        Array.isArray(status)
                          ? [
                              defaultState,
                              ...(status?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultState]
                      }
                      value={state}
                      onChange={setState}
                    />
                  )}
                </Col>

                <Col md={4} xs={12} className='p-0'>
                  {contractsList?.isLoading ? (
                    <Shimmer width='100%' height='42px' />
                  ) : (
                    <CustomSelect
                      options={
                        Array.isArray(contractsList?.data?.companies)
                          ? [
                              defaultCompany,
                              ...(contractsList?.data?.companies?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultCompany]
                      }
                      value={company}
                      onChange={setCompany}
                    />
                  )}
                </Col>

                <Col md={3} xs={12} className='p-0'>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      setActivePage(1)
                      setQuery(null)
                      setState(defaultState)
                      setCompany(defaultCompany)
                    }}
                  >
                    Clear Filter
                  </button>
                </Col>
              </Row>
            </Container>

            <ContractList
              isAdmin
              Orders={contractsList?.data?.contracts}
              withArchive
              onUpdate={updateList}
              archiveValue={activeTab === '1' ? 1 : 0}
              onQuery={setQuery}
              loading={contractsList?.isLoading}
            />
            <Col>
              <Row className='p-3 justify-content-end'>
                <Paginations
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={activePage}
                  itemsCountPerPage={50}
                  totalItemsCount={contractsList.paginator?.total}
                  onChange={handlePageChange}
                />
              </Row>
            </Col>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default ContractsListAdmin
