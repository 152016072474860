import React from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'ui'

export function BaseTextLegacy({
  children,
  className,
  as,
  min,
  mid,
  max,
  style,
  ...props
}) {
  const Component = as || 'p'

  return (
    <Component
      className={cn(
        max && 'tw-text-[length:var(--max)]',
        mid
          ? 'max-[1280px]:tw-text-[length:var(--mid)]'
          : max
            ? 'max-[1280px]:tw-text-[length:var(--max)]'
            : '',
        min
          ? 'max-[425px]:tw-text-[length:var(--min)]'
          : max
            ? 'max-[425px]:tw-text-[length:var(--max)]'
            : '',
        className,
      )}
      style={{ '--min': min, '--mid': mid, '--max': max, ...style }}
      {...props}
    >
      {children}
    </Component>
  )
}
export function H1(props) {
  return <BaseTextLegacy as='h1' {...props} />
}
export function H2(props) {
  return <BaseTextLegacy as='h2' {...props} />
}
export function H3(props) {
  return <BaseTextLegacy as='h3' {...props} />
}
export function H4(props) {
  return <BaseTextLegacy as='h4' {...props} />
}
export function H5(props) {
  return <BaseTextLegacy as='h5' {...props} />
}
export function H6(props) {
  return <BaseTextLegacy as='h6' {...props} />
}
export function Paragraph(props) {
  return <BaseTextLegacy as='p' {...props} />
}
export function RouterLink(props) {
  return <BaseTextLegacy {...props} as={Link} max={props.max || '16px'} />
}

export const StyledH1 = H1
export const StyledH2 = H2
export const StyledH3 = H3
export const StyledH4 = H4
export const StyledH5 = H5
export const StyledH6 = H6
export const StyledP = Paragraph
export const StyledLink = RouterLink
