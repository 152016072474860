import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import Button from '../../../../components/ui/button'
import {
  SideMenu,
  sideMenuGridClass,
  SideMenuHeading,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { withdrawCurrencies } from '../../../../services/api'
import { disableVendorBankAccount } from '../../../../services/api-bill-payments'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import { AddBankAccountV2Form } from '../../../withdrawProcess/add-bank-account-v2'
import {
  getBankAccountData,
  useCreateVendorBankAccount,
  validateBankAccountData,
} from '../../../withdrawProcess/use-bank-account-data'
import { track } from '../../../../utils/analytics'
import UploadPreview from '../../../../components/Common/upload-preview'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import { cn } from 'ui'

const editBankAccountFormId = 'editBankAccountFormId'

export function useDisableVendorBankAccount({
  onSuccess,
  watch,
  vendor,
  user,
}) {
  const { startFetch: deactivateBankAccount, isLoading } = useFetch({
    action: disableVendorBankAccount,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          'Something went wrong while disabling the vendor bank account',
        )
      } else {
        const bankValues = watch()
        const bankAccountData = getBankAccountData(bankValues, {
          user: { firstName: user?.first_name, lastName: user?.last_name },
        })
        onSuccess({ ...bankAccountData, vendorId: vendor.id })
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  return { deactivateBankAccount, isLoading }
}

export function EditBankSideMenu({
  isOpen,
  toggle,
  vendor,
  updateVendors,
  source,
}) {
  const user = useSelector((state) => state.Account?.user)
  const [showBill, setShowBill] = useState(false)

  const profileCurrency = useSelector(
    (state) => state.userProfile?.userProfile?.company?.currency?.code,
  )

  const { data: currencies } = useFetch(
    {
      action: withdrawCurrencies,
      autoFetch: isOpen && !!profileCurrency,
      body: { currency: profileCurrency },
      initResult: [],
    },
    [profileCurrency, isOpen],
  )

  const formMethods = useForm()
  const { handleSubmit, control, setError, clearErrors, watch } = formMethods
  const data = watch()
  const { createBankAccount, isLoading: isCreatingBankAccount } =
    useCreateVendorBankAccount({
      successMessage: [
        `You successfully edited the bank details for ${vendor?.name}`,
        'Bank details added',
      ],
      onSuccess: () => {
        const logCreateVendorData = {
          userID: user?.id,
          vendorID: vendor?.id,
          'Vendor Name': vendor?.name,
          Source: source,
          Currency: data?.currency,
          'Account Holder Name': data?.accountHolderName,
        }
        track('Vendor Updated', logCreateVendorData)
        toggle?.()
        updateVendors?.()
      },
    })

  const { deactivateBankAccount, isLoading: isDisablingVendorBankAccount } =
    useDisableVendorBankAccount({
      onSuccess: createBankAccount,
      watch,
      vendor,
      user,
    })

  if (!isOpen) return null

  function onSubmit(values) {
    const isValid = validateBankAccountData({ values, setError, clearErrors })

    if (!isValid) {
      toastr.error('Please check field errors')
      return
    }
    deactivateBankAccount({ bankAccount: vendor?.bank_account?.id })
  }
  return (
    <SideMenu
      isOpen={isOpen}
      onClose={toggle}
      preventScrollBehavior={false}
      itemListClassName='tw-flex'
      className={showBill ? 'tw-max-w-[70%]' : ''}
    >
      <div className={cn('tw-flex-grow')}>
        {showBill ? (
          <UploadPreview
            preview={{
              type: 'application/pdf',
              data: vendor?.latest_bill?.pdf,
            }}
          />
        ) : null}
      </div>
      <div
        className={sideMenuGridClass({
          className: '!tw-grid tw-max-w-md tw-flex-shrink-0',
        })}
      >
        <SideMenu.Header toggle={toggle}>
          <SideMenuHeading
            title='Bank details'
            subTitle='Edit vendor bank details'
            onClickBack={toggle}
          />
        </SideMenu.Header>
        <SideMenu.Body>
          {vendor?.latest_bill?.pdf && (
            <p className='tw-mb-0 tw-flex tw-items-center tw-justify-between !tw-text-sm tw-text-black'>
              There is an invoice uploaded for this vendor
              <Button
                className='!tw-p-0 !tw-ps-0.5 !tw-text-xs'
                color='link'
                iconRight={
                  showBill ? <EyeSlash size={20} /> : <Eye size={20} />
                }
                onClick={() => setShowBill((prev) => !prev)}
              >
                {showBill ? 'Hide' : 'Preview'}
              </Button>
            </p>
          )}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} id={editBankAccountFormId}>
              <AddBankAccountV2Form
                control={control}
                currency={profileCurrency}
                currencies={currencies?.map((c) => mapCurrencyToOption(c))}
                className='tw-pt-2'
                fieldsClassName='!tw-grid-cols-1'
                isBillVendor
              />
            </form>
          </FormProvider>
        </SideMenu.Body>
        <SideMenu.Footer className='bg-white tw-sticky tw-bottom-0'>
          <Button
            type='button'
            onClick={toggle}
            outline
            color='light'
            disabled={isCreatingBankAccount || isDisablingVendorBankAccount}
          >
            Cancel
          </Button>
          <Button
            formId={editBankAccountFormId}
            type='submit'
            disabled={isCreatingBankAccount || isDisablingVendorBankAccount}
            loading={isCreatingBankAccount || isDisablingVendorBankAccount}
          >
            Save
          </Button>
        </SideMenu.Footer>
      </div>
    </SideMenu>
  )
}
