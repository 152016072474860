import { yupResolver } from '@hookform/resolvers/yup'
import { FileArrowDown, Paperclip, XCircle } from '@phosphor-icons/react'
import { format, parse, subMonths } from 'date-fns'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import { FileList } from '../../../components/Common/dropzone-input'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import ControlledDropzoneInput from '../../../components/controlled-dropzone-input'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  getContractCustomFields,
  submitPaygWork,
  uploadTempFile,
} from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import { CONTRACT_TYPE_MAP } from '../ContractPage/settings/custom-fields-section'
import LabelContent from '../CreateContract/components/label-content'
import { getCustomFieldSchema } from './add-milestone-modal'

const formatString = 'MMMM yyyy'
const currentMonth = format(new Date(), formatString)
const previousMonth = format(subMonths(new Date(), 1), formatString)

const submissionDates = [
  { value: previousMonth, label: previousMonth },
  { value: currentMonth, label: currentMonth },
]

const formId = 'submit-work-form'
export function SubmitWorkModal({ isOpen, toggle, contract, onWorkSubmitted }) {
  const [showAttachment, setShowAttachment] = useState(false)
  function toggleAttachment() {
    setShowAttachment((open) => {
      // if closing, reset the attachment
      if (open) setValue('attachment', [])

      return !open
    })
  }

  const monthlyRateContract = contract?.monthly_rate === 1

  const { data: fields, isLoading: isFieldsLoading } = useFetch(
    {
      action: getContractCustomFields,
      autoFetch: !!contract?.id,
      initResult: [],
      body: {
        area: 'work',
        contract_type: CONTRACT_TYPE_MAP[contract?.type],
        active: 1,
        contractId: contract?.id,
      },
    },
    [contract?.id],
  )

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      submitted_at: null,
      details: '',
      qty: 0,
      month: null,
      attachment: [],
      // This default is important to avoid `custom_field` being an array
      custom_field: {},
    },
    resolver: yupResolver(
      yup.object().shape({
        submitted_at: yup.date().when('$', {
          is: () => !monthlyRateContract,
          then: (schema) => schema.required('Please select a date'),
          otherwise: (schema) => schema.nullable(),
        }),
        details: yup.string().when('$', {
          is: () => !monthlyRateContract,
          then: (schema) => schema.required('Please fill in the name'),
          otherwise: (schema) => schema.nullable(),
        }),
        qty: yup
          .number()
          .typeError('Please fill in the value')
          .when('$', {
            is: () => !monthlyRateContract,
            then: (schema) =>
              schema
                .positive('The value must be positive')
                .required('Please fill in the value'),
            otherwise: (schema) => schema.nullable(),
          }),
        month: yup
          .string()
          .oneOf(submissionDates.map((s) => s.value))
          .when('$', {
            is: () => monthlyRateContract,
            then: (schema) => schema.required('Please fill in the month'),
            otherwise: (schema) => schema.nullable(),
          }),

        attachment: yup.mixed().when('$', {
          is: () => showAttachment,
          then: (schema) =>
            schema
              .test({
                message: 'Attachment is required',
                test: (value) => value?.length > 0,
              })
              .required('Attachment is required'),
          otherwise: (schema) => schema.nullable(),
        }),
        custom_field: getCustomFieldSchema(yup.object(), fields),
      }),
    ),
  })

  const attachment = watch('attachment')

  const {
    startFetch: uploadWorkFile,
    isLoading: isUploadingWorkFile,
    data: uploadedWork,
  } = useFetch({ action: uploadTempFile })

  const { startFetch: submitWork, isLoading: isSubmittingWork } = useFetch({
    action: submitPaygWork,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('An error occurred while submitting the work.')
      } else {
        toastr.success('Work submitted successfully')
        onWorkSubmitted?.()
        toggle?.()
      }
    },
    onError: (error) => {
      toastr.error(getErrorMessage(error))
    },
  })

  const actionsDisabled =
    isFieldsLoading || isUploadingWorkFile || isSubmittingWork

  function onSubmit(values) {
    const dateToParse = monthlyRateContract
      ? parse(values.month, 'MMMM yyyy', new Date())
      : values.submitted_at

    const submittedAt = monthlyRateContract
      ? format(new Date(), 'yyyy-MM-dd')
      : format(dateToParse, 'yyyy-MM-dd')

    const body = { contract_id: contract.id, submitted_at: submittedAt }

    // Add the file if uploaded
    if (values.attachment?.length > 0 && showAttachment) {
      body.file = uploadedWork?.path
    }

    // add custom fields
    const customFieldsValues = Object.entries(values.custom_field).filter(
      ([, value]) => !!value,
    )
    if (fields?.length > 0 && customFieldsValues.length > 0) {
      body.attributes = customFieldsValues.map(([id, value]) => ({
        id: Number(id),
        value: value ?? '',
      }))
    }

    if (monthlyRateContract) {
      body.details = 'Work for ' + values.month
      body.qty = 1
    } else {
      body.details = values.details
      body.qty = values.qty
    }

    submitWork(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader isOpen={isOpen} close={<ModalCloseButton toggle={toggle} />}>
        Submit work
      </ModalHeader>
      <ModalBody>
        {isFieldsLoading ? (
          <Loader minHeight='240px' />
        ) : (
          <form
            className='tw-flex tw-flex-col tw-gap-4'
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            {monthlyRateContract ? (
              <ControlledSelect
                label={<LabelContent required>Month</LabelContent>}
                control={control}
                name='month'
                inputId='month'
                options={submissionDates}
              />
            ) : (
              <>
                <ControlledDatePicker
                  control={control}
                  name='submitted_at'
                  id='submitted_at'
                  label={<LabelContent required>Date</LabelContent>}
                  minDate={new Date(contract?.start_date)}
                  maxDate={new Date()}
                  placeholderText='Date'
                />

                <ControlledInput
                  control={control}
                  name='details'
                  id='details'
                  label={<LabelContent required>Name</LabelContent>}
                  placeholder='Name'
                />

                <FormGroup className='!tw-mb-0'>
                  <label htmlFor='qty'>
                    <LabelContent required>Value</LabelContent>
                  </label>

                  <InputGroup className='tw-w-full !tw-items-start'>
                    <ControlledInput
                      control={control}
                      name='qty'
                      id='qty'
                      placeholder='Value'
                      type='number'
                      wrapperClassName='tw-flex-grow'
                      className='!tw-rounded-r-none'
                      min={0}
                      step='.01'
                    />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText className='tw-capitalize'>
                        {contract?.rate_id?.code}
                        <span className='tw-normal-case'>(s)</span>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </>
            )}

            <AttributeFields
              fields={fields}
              control={control}
              errors={errors}
            />

            <AttachmentField
              control={control}
              errors={errors}
              setValue={setValue}
              attachment={attachment}
              isUploadingWorkFile={isUploadingWorkFile}
              uploadWorkFile={uploadWorkFile}
              showAttachement={showAttachment}
              toggleAttachement={toggleAttachment}
            />
          </form>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={toggle}
          color='light'
          outline
          disabled={actionsDisabled}
        >
          Cancel
        </Button>

        <Button
          type='submit'
          formId={formId}
          disabled={actionsDisabled}
          loading={isSubmittingWork}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export function AttachmentField({
  control,
  errors,
  setValue,
  attachment,
  isUploadingWorkFile,
  uploadWorkFile,
  showAttachement,
  toggleAttachement,
}) {
  return (
    <div>
      <button
        className='tw-mb-2 tw-flex tw-items-center tw-gap-2 tw-rounded tw-px-1 tw-py-1.5 tw-text-sm tw-font-bold tw-text-primary-100 tw-transition-colors hover:tw-bg-primary-10'
        onClick={toggleAttachement}
        type='button'
      >
        Add attachment <Paperclip size={16} weight='bold' />
      </button>

      {!showAttachement ? null : (
        <ControlledDropzoneInput
          control={control}
          name='attachment'
          id='attachment'
          label='Attachment'
          placeholder='Select file'
          className={cn(
            'p-3 tw-min-h-44',
            (isUploadingWorkFile || attachment?.length <= 0 || !attachment) &&
              'tw-flex tw-items-center tw-justify-center',
          )}
          accept={{ 'application/pdf': ['.pdf'] }}
          error={errors?.attachment}
          onDropAccepted={(files) => {
            setValue('attachment', files)

            const body = { file: files[0], type: 'works' }
            uploadWorkFile(body)
          }}
        >
          {isUploadingWorkFile ? (
            <Loader minHeight={false} />
          ) : attachment?.length <= 0 || !attachment ? (
            <div className='tw-flex tw-flex-col tw-items-center tw-gap-2'>
              <FileArrowDown size={32} weight='duotone' />

              <h4 className='tw-mb-0 tw-text-sm tw-text-current md:tw-hidden'>
                Click to upload files.
              </h4>
              <h4 className='tw-mb-0 tw-hidden tw-text-sm tw-text-current md:tw-block'>
                Drop here or click to upload file.
              </h4>
            </div>
          ) : (
            <>
              <FileList files={attachment} className='tw-mb-4 tw-text-sm' />

              <Button
                color='danger'
                outline
                size='sm'
                className='tw-p-2'
                type='button'
                onClick={(e) => {
                  e.stopPropagation()
                  setValue('attachment', [])
                }}
                icon={<XCircle size={16} weight='fill' />}
              >
                Remove file
              </Button>
            </>
          )}
        </ControlledDropzoneInput>
      )}
    </div>
  )
}

export function AttributeFields({ fields = [], control, errors, disabled }) {
  return fields?.map((field, i) => {
    const type = field?.attribute_type?.code

    const fieldName = `custom_field.${field.id}`

    const isRequired = field.required === 1
    const label = (
      <LabelContent required={isRequired}>{field.name}</LabelContent>
    )

    const error = !isRequired
      ? null
      : errors?.custom_field?.root?.message || errors?.custom_field?.message

    switch (type) {
      case 'text': {
        return (
          <ControlledInput
            key={i}
            control={control}
            name={fieldName}
            id={fieldName}
            label={label}
            placeholder={field.name}
            error={error}
            disabled={disabled}
          />
        )
      }
      case 'selection': {
        return (
          <ControlledSelect
            key={i}
            control={control}
            name={fieldName}
            inputId={fieldName}
            label={label}
            options={field.options.map((option) => ({
              label: option.name,
              value: option.name,
            }))}
            placeholder='Select option'
            error={error}
            openMenuOnFocus
            isDisabled={disabled}
          />
        )
      }
      case 'date': {
        return (
          <ControlledDatePicker
            key={i}
            control={control}
            name={fieldName}
            id={fieldName}
            label={label}
            placeholderText='Select date'
            error={error}
            disabled={disabled}
          />
        )
      }
      default: {
        return null
      }
    }
  })
}
