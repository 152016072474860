import { CalendarBlank, X } from '@phosphor-icons/react'
import { isDate, isValid, parse } from 'date-fns'
import { isMoment } from 'moment'
import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'

import { cn } from 'ui'
import isNill from '../../../utils/is-nill'
import InputFeedback from '../../ui/input-feedback'
import { getInputErrorMessage } from '../get-input-error-message'
import './datePicker.css'

function getDateValue(value, { showMonthYearPicker = false } = {}) {
  if (isNill(value) || value === '') {
    return null
  }

  if (isDate(value)) {
    return value
  }

  if (isMoment(value)) {
    return value.toDate()
  }

  if (typeof value === 'string') {
    const formatString = showMonthYearPicker ? 'yyyy-MM' : 'yyyy-MM-dd'
    const splitDate = value.split('-')
    if (splitDate.length === 3 && showMonthYearPicker) {
      value = `${splitDate[0]}-${splitDate[1]}`
    }
    const formattedDate = parse(value, formatString, new Date())
    return isValid(formattedDate) ? formattedDate : null
  }

  return null
}

function CustomDatePicker({
  label,
  value,
  handleOnChange,
  placeholder,
  minDate,
  maxDate,
  showMonthYearPicker,
  disabled,
  excludeDates,
  datePickerClassName,
  wrapperClassName,
  wrapperStyles,
  inputClassName,
  name = 'pick-date',
  handleClear,
  clearable,
  error,
  hasError,
  showError = true,
  dateFormat = 'yyyy-MM-dd',
  ...otherProps
}) {
  const ref = useRef(null)

  return (
    <div className={wrapperClassName} style={wrapperStyles}>
      {!!label && (
        <label
          onClick={() => {
            ref?.current?.setFocus()
            ref?.current?.setOpen(true)
          }}
          htmlFor={otherProps?.id}
        >
          {label}
        </label>
      )}

      <DatePicker
        ref={ref}
        name={name}
        className={cn('form-control', datePickerClassName)}
        selected={getDateValue(value, { showMonthYearPicker })}
        onChange={handleOnChange}
        style={{ width: '100%' }}
        disabled={disabled}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        customInput={
          <CustomDateInput
            hasError={hasError}
            className={inputClassName}
            disabled={disabled}
            clearable={clearable}
            handleClear={handleClear}
            id={otherProps?.id}
          />
        }
        minDate={minDate}
        maxDate={maxDate}
        popperClassName='custom-popper'
        popperPlacement='bottom-start'
        popperModifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [5, 10],
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              escapeWithReference: false,
              boundariesElement: 'viewport',
            },
          },
        ]}
        excludeDates={excludeDates}
        placeholderText={placeholder}
        showMonthYearPicker={showMonthYearPicker}
        dateFormat={dateFormat}
        {...otherProps}
      />

      {!error || !showError ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

export const CustomDateInput = React.forwardRef(function CustomInput(
  {
    value,
    onClick,
    placeholder,
    hasError,
    className,
    disabled,
    clearable,
    handleClear,
    id,
  },
  ref,
) {
  const canClearInput =
    Boolean(clearable) && Boolean(value) && Boolean(handleClear)

  return (
    <div
      ref={ref}
      id={id}
      className={cn(
        'form-control tw-relative !tw-h-11 tw-cursor-pointer',
        { '!tw-border-systemRed-100': hasError, '!tw-bg-surface-30': disabled },
        className,
      )}
      onClick={onClick}
      tabIndex={disabled ? undefined : 0}
    >
      <span className={cn({ 'tw-truncate tw-text-text-40': !value })}>
        {value || placeholder}
      </span>

      <button
        className={cn(
          'tw-absolute tw-right-3 tw-top-1/2 tw-flex -tw-translate-y-1/2 tw-rounded tw-text-current',
          {
            'tw-cursor-pointer hover:tw-bg-surface-30': canClearInput,
            'tw-pointer-events-none': !canClearInput,
          },
        )}
        onClick={handleClear}
        tabIndex={!canClearInput ? -1 : undefined}
        type='button'
      >
        {clearable && value ? (
          <X size={22} className='tw-p-0.5' />
        ) : (
          <CalendarBlank size={18} />
        )}
      </button>
    </div>
  )
})

export default CustomDatePicker
