import React, { Suspense, useEffect, useState } from 'react'
import { Prompt, useHistory } from 'react-router'
import { Col } from 'reactstrap'
import toastr from 'toastr'

import ModalHeader from '../../../components/ModalHeader'
import Head from '../../../components/head'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  createTemplateAdmin,
  getAdminContractTemplate,
  getAdminTemplate,
  getContractTemplate,
  updateContractTemplate,
  updateContractTemplateAdmin,
  updateTemplateAdmin,
} from '../../../services/api'
import DiscardModal from '../ContractEditor/DiscardModal'
import SaveChangesModal from '../ContractEditor/SaveChangesModal'
import './style.css'

const Editor = React.lazy(() => import('./Editor'))

function TemplateEditor() {
  const [data, setData] = useState(null)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const location = history.location
  const contractId = location.state?.contract_id
  const isAdmin = location.state?.isAdmin

  const update = useFetch({
    action: isAdmin
      ? contractId
        ? updateContractTemplateAdmin
        : updateTemplateAdmin
      : updateContractTemplate,
    withAdminAccess: isAdmin,
    onComplete: () => {
      setTimeout(() => {
        history.goBack()
      }, 500)
    },
    onError: (err) => {
      toastr.error(err)
      setEditing(true)
    },
  })
  const create = useFetch({
    action: createTemplateAdmin,
    withAdminAccess: true,
    onComplete: () => {
      setTimeout(() => {
        history.goBack()
      }, 500)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const getTemplate = useFetch({
    action: isAdmin
      ? contractId
        ? getAdminContractTemplate
        : getAdminTemplate
      : getContractTemplate,
    withAdminAccess: isAdmin,
    autoFetch: true,
    body: isAdmin
      ? contractId
        ? { contract_id: contractId }
        : { template_id: location.state?.template_id || 1 }
      : { contract_id: contractId },
  })
  const handleChange = (sections) => {
    setData(sections)
  }
  const saveChanges = (text, country) => {
    setEditing(false)
    if (location.state?.template_id === 0 && isAdmin) {
      create.startFetch({
        country_id: country,
        sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
      })
    } else {
      update.startFetch(
        isAdmin
          ? contractId
            ? {
                contract_id: contractId,
                name: text,
                sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
              }
            : {
                template_id: location.state?.template_id,
                sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
              }
          : {
              contract_id: location.state?.contract_id,
              name: text,
              sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
            },
      )
    }
    setSaveChangesModal(false)
  }
  const handleDiscard = () => {
    setEditing(false)
    setDiscardModal(false)
    setTimeout(() => {
      history.goBack()
    }, 500)
  }
  useEffect(() => {
    const handler = (event) => {
      if (!editing) return
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    }
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [editing])

  const [saveChangesModal, setSaveChangesModal] = useState(false)
  const [discardModal, setDiscardModal] = useState(false)

  const templateName = getTemplate.data?.name

  return (
    <div>
      <Head
        title={(templateName ? `${templateName} | ` : '') + 'Template Editor'}
      />

      <ModalHeader noExit childrenClassName='tw-flex'>
        <Col className='align-items-center d-flex justify-content-center'>
          <h5 className='m-0 mx-2 text-center'>{templateName}</h5>
        </Col>

        <button
          className='btn btn-outline-light'
          onClick={() => setDiscardModal(true)}
        >
          Discard changes
        </button>
        <button
          className='btn btn-primary ml-2'
          onClick={() => setSaveChangesModal(true)}
        >
          Save changes
        </button>
      </ModalHeader>
      <Suspense fallback={<Loader />}>
        <Editor
          onChange={handleChange}
          agreement={getTemplate.data?.default}
          sections={getTemplate.data?.sections}
          placeholders={getTemplate.data?.placeholders}
          onEdit={() => setEditing(true)}
        />
      </Suspense>

      <SaveChangesModal
        isOpen={saveChangesModal}
        toggle={() => setSaveChangesModal(false)}
        onSaveChanges={saveChanges}
        defaultName={getTemplate.data?.name}
        isAdmin={isAdmin}
        templateId={location.state?.template_id}
      />
      <DiscardModal
        isOpen={discardModal}
        toggle={() => setDiscardModal(false)}
        onDiscard={handleDiscard}
      />
      {(update?.isLoading || getTemplate.isLoading || create.isLoading) && (
        <Loader
          minHeight='100vh'
          className='bg-soft-primary min-vw-100 position-fixed top-0'
          style={{ zIndex: 999999999 }}
        />
      )}
      <Prompt
        when={editing}
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
    </div>
  )
}

export default TemplateEditor
