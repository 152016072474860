import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { forwardRef, Suspense, useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Label, Popover, Row } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { CONTRACT_TYPES } from '../../../../core/config/contract-types'
import {
  Fixed,
  Fulltime,
  Milestone,
  PayAsYouGo,
} from '../../../../helpers/SvgIcons'
import { contractTypes, userTypes } from '../../../../helpers/enum'
import { mapCountryToOption } from '../../../../utils/map-to-option'
import ContractTypeSelect from '../../CreateContract/ContractTypeSelect'
import PremiumRecruitment from '../../CreateContract/components/premium'
import PremiumEligibilityModal from '../../CreateContract/components/premium-eligibility-modal'
import Loader from '../../../../components/ui/loader'

const ReactQuill = React.lazy(() => import('react-quill'))

const SCOPE_OF_WORK_MIN_CHARACTERS = 0

const InfoForm = forwardRef(function InfoForm(
  {
    data,
    duplicating,
    onSubmit,
    amendData,
    withContractType,
    onContractTypeChanged = () => {},
  },
  ref,
) {
  const options = [
    {
      label: 'Fixed',
      value: contractTypes.FIXED,
      description: 'Pay a fixed rate monthly, bi-weekly or weekly',
      icon: <Fixed />,
      activeIcon: <Fixed isActive />,
    },
    {
      label: 'Pay as you go',
      value: contractTypes.PAYG,
      description: 'Pay per day or per hour based on timesheets',
      icon: <PayAsYouGo />,
      activeIcon: <PayAsYouGo isActive />,
    },
    {
      label: 'Milestones',
      value: contractTypes.MILESTONES,
      description: 'Pay as milestones are completed',
      icon: <Milestone />,
      activeIcon: <Milestone isActive />,
    },
  ]
  const [premiumModalOpen, setPremiumModalOpen] = useState(false)
  const user = useSelector((state) => state?.Account?.user)
  const staticData = useSelector((state) => state.Layout.staticData)
  const amending = data?.can_amend && !duplicating

  if (user?.type === userTypes.COMPANY) {
    options.push({
      label: 'Full time',
      value: contractTypes.FULL_TIME,
      description: 'Hire a full-time employee',
      icon: <Fulltime />,
      activeIcon: <Fulltime isActive />,
    })
  }
  const [taxResidence, setTaxResidence] = useState(
    amendData?.taxResidence ||
      (data?.tax_residence
        ? { label: data?.tax_residence.name, value: data?.tax_residence.id }
        : null),
  )
  const [scope, setScope] = useState(data?.scope || '')
  const [scopeText, setScopeText] = useState('')
  function handleScopeChange(content, _, __, editor) {
    const text = editor.getText()
    const textContent = String(text ?? '').replaceAll('\n', '')
    setScope(content)
    setScopeText(textContent)
  }
  const [contractType, setContractType] = useState(
    withContractType
      ? options.indexOf(
          options.find(
            (e) => data?.type?.toUpperCase() === e?.label?.toUpperCase(),
          ),
        )
      : -1,
  )
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isPremium, setIsPremium] = useState(false)

  const toggle = () => setTooltipOpen((open) => !open)

  useEffect(() => {
    if (data) {
      onContractTypeChanged(
        options.find(
          (e) => data?.type?.toUpperCase() === e?.label?.toUpperCase(),
        ),
      )
    }
  }, [data])

  const [suggestion, setSuggestions] = useState([])
  const [watchValue, setWatchValue] = useState('')
  const [suggestedValue, setSuggestedValue] = useState('')
  const titles = useSelector((state) => state?.Layout?.staticData?.job_titles)

  useEffect(() => {
    const selectedValue =
      titles?.filter((word) => word.title === watchValue).length > 0
    if (watchValue?.length <= 0) {
      setSuggestions([])
    } else {
      if (!selectedValue) {
        const regex = new RegExp(`^${watchValue?.replace(/[^a-z]/gi, '')}`, 'i')
        const newSuggestions = titles?.filter((word) => regex.test(word?.title))
        setSuggestions(newSuggestions?.length > 0 ? newSuggestions : [])
      }
    }
  }, [watchValue])

  return (
    <div>
      <AvForm
        ref={ref}
        onValidSubmit={(e, v) => {
          if (!scope || scope === '<p><br></p>') {
            toastr.error('Scope of work is missing', 'error')
          } else if (scopeText.length < SCOPE_OF_WORK_MIN_CHARACTERS) {
            toastr.error(
              `The description field is a little short. The field must contain at least ${SCOPE_OF_WORK_MIN_CHARACTERS} characters.`,
            )
          } else if (!taxResidence && data?.type !== CONTRACT_TYPES.FULL_TIME) {
            toastr.error('Please select the Contractor Tax country', 'error')
          } else if (contractType === -1 && withContractType) {
            toastr.error('Contract Type is missing', 'error')
          } else {
            const defaultBody = {
              ...v,
              scope,
              ...(taxResidence?.value
                ? { tax_residence_id: taxResidence.value }
                : {}),
              tax: taxResidence,
              is_cor: isPremium,
              type: options[contractType]?.label,
            }
            if (withContractType) {
              onSubmit({
                ...defaultBody,
                contractType: options[contractType],
              })
            } else {
              onSubmit(defaultBody)
            }
          }
        }}
      >
        <Row className='justify-content-center'>
          <Col>
            {withContractType && (
              <ContractTypeSelect
                options={options}
                onContractTypeChanged={(type) => {
                  setContractType(type)
                  onContractTypeChanged(options[type])
                }}
                value={contractType}
              />
            )}
            {options[contractType]?.value === contractTypes.FULL_TIME ? null : (
              <div className='position-relative'>
                {amending || data?.type === CONTRACT_TYPES.FULL_TIME ? null : (
                  <FormGroup>
                    <Label for='basicpill-pancard-input6'>
                      Contractor Tax Country
                      <span className='text-danger font-size-16 ml-1'>*</span>
                    </Label>
                    <CustomSelect
                      name='country'
                      value={taxResidence}
                      onChange={setTaxResidence}
                      options={staticData?.countries.map((c) =>
                        mapCountryToOption(c),
                      )}
                    />
                  </FormGroup>
                )}
                {contractType === -1 ? null : (
                  <PremiumRecruitment
                    checked={isPremium}
                    onChange={(val) => {
                      if (!val) {
                        setIsPremium(false)
                      } else {
                        setPremiumModalOpen(true)
                      }
                    }}
                  />
                )}
                <FormGroup>
                  <Label>
                    Role<span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <AvField
                    onChange={(e) => setWatchValue(e.target.value)}
                    value={suggestedValue}
                    defaultValue={amendData?.name || data?.name}
                    autoComplete='off'
                    required
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='i.e. Website Design, Content generation'
                    id='basicpill-pancard-input5'
                  />
                  {titles && suggestion.length > 0 && (
                    <div className='tw-absolute tw-left-0 tw-right-[451px] tw-z-10 tw-max-h-64 tw-w-full tw-overflow-auto tw-rounded tw-border tw-border-surface-30 tw-bg-white tw-py-2 tw-shadow-md'>
                      {suggestion?.slice(0, 10)?.map((item, index) => {
                        return (
                          <button
                            className='tw-block tw-w-full tw-px-4 tw-py-2 tw-text-start tw-text-sm/6 hover:tw-bg-surface-20'
                            onClick={() => {
                              setSuggestedValue(item.title)
                              setSuggestions([])
                            }}
                            key={index}
                          >
                            {item?.title}
                          </button>
                        )
                      })}
                    </div>
                  )}
                </FormGroup>
                <FormGroup id='scope-contract-info'>
                  <Label>
                    Scope Of Work
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <Suspense fallback={<Loader />}>
                    <ReactQuill
                      formats={[
                        'font',
                        'size',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'indent',
                        'image',
                        'video',
                      ]}
                      modules={{ toolbar: null }}
                      value={scope}
                      onChange={handleScopeChange}
                      placeholder='Describe the deliverables'
                      className='p-0 ql-contract-info'
                      style={{ whiteSpace: 'pre-line' }}
                    />
                  </Suspense>

                  <div className='mt-2 ml-0'>
                    <button
                      type='button'
                      className='rp-btn-nostyle mx-n1 px-1 text-primary'
                      id='DisabledAutoHideExample'
                      onClick={toggle}
                    >
                      Predefined descriptions {'>>'}
                    </button>
                    <Popover
                      className='my-popover'
                      placement='top'
                      isOpen={tooltipOpen}
                      trigger='legacy'
                      target='DisabledAutoHideExample'
                      toggle={toggle}
                    >
                      <Row className='p-0 m-0' style={{ minWidth: 640 }}>
                        <AllTitles
                          click={(text) => {
                            setScope(text)

                            toggle()
                          }}
                        />
                      </Row>
                    </Popover>
                  </div>
                </FormGroup>
              </div>
            )}
          </Col>
        </Row>
      </AvForm>
      {premiumModalOpen && (
        <PremiumEligibilityModal
          isOpen={premiumModalOpen}
          toggle={(eligible) => {
            if (eligible === true) {
              setIsPremium(true)
            }
            setPremiumModalOpen(false)
          }}
        />
      )}
    </div>
  )
})

export const AllTitles = ({ click, wrapperRef }) => {
  const jobDescriptions = useSelector(
    (state) => state.Layout?.staticData?.job_descriptions,
  )

  const [text, setText] = useState(jobDescriptions?.[0]?.scope)

  return (
    <div
      ref={wrapperRef}
      className='tw-grid tw-h-56 tw-w-full tw-grid-cols-3 tw-overflow-auto'
    >
      <div
        className={cn(
          'tw-relative tw-divide-y tw-divide-surface-30 tw-overflow-auto tw-border-surface-30 tw-py-2 max-md:tw-border-b md:tw-col-span-1 md:tw-border-r [&::-webkit-scrollbar]:tw-hidden',
          'after:tw-pointer-events-none after:tw-fixed after:tw-bottom-0 after:tw-left-0 after:tw-h-10 after:tw-w-full after:tw-bg-gradient-to-t after:tw-from-black/10',
        )}
      >
        {jobDescriptions?.map((t, index) => (
          <button
            onClick={(e) => {
              e.preventDefault()
              click(jobDescriptions[index].scope)
            }}
            onMouseOver={() => setText(jobDescriptions[index].scope)}
            key={index}
            className='tw-block tw-w-full tw-px-4 tw-py-2 tw-text-start tw-text-xs/5 tw-font-bold tw-text-text-100 hover:tw-bg-surface-20 hover:tw-text-primary-100'
          >
            {t.title}
          </button>
        ))}
      </div>

      <div className='tw-overflow-auto tw-whitespace-pre-line tw-px-4 tw-py-4 tw-text-xs/5 md:tw-col-span-2 md:tw-px-6'>
        {text}
      </div>
    </div>
  )
}

export default InfoForm
