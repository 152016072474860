import React, { createContext, useContext, useMemo } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import { getRecipientUsers } from '../../../../../services/api-automations'
import { emailRecipientUserType } from '../tools/constants'
import { ListBullets, UserCircleGear } from '@phosphor-icons/react'

export const EmailRecipientsContext = createContext({
  isLoading: true,
  items: [],
  quillRef: null,
  insertPlaceholder: null,
})

export function EmailRecipientsProvider(props) {
  const { children } = props
  const { isLoading, data } = useFetch({
    autoFetch: true,
    action: getRecipientUsers,
  })

  const recipients = useMemo(() => {
    if (data?.list?.length) {
      return [
        {
          label: 'User Type',
          options: emailRecipientUserType,
          icon: (props) => <UserCircleGear {...props} />,
        },
        {
          label: 'All users',
          icon: (props) => <ListBullets {...props} />,
          options: data.list.map((i) => ({
            value: i.email,
            email: i.email,
            description: i.email,
            label: `${i?.first_name} ${i?.last_name}`,
          })),
        },
      ]
    } else return []
  }, [data])

  return (
    <EmailRecipientsContext.Provider value={{ isLoading, recipients }}>
      {children}
    </EmailRecipientsContext.Provider>
  )
}

export function useEmailRecipients() {
  return useContext(EmailRecipientsContext)
}
