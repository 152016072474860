import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useRef, useState } from 'react'
import GoogleLogin from 'react-google-login'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import TextInput from '../../components/Forms/TextInput'
import { StyledH5 } from '../../components/Typo'
import Footer from '../../components/VerticalLayout/Footer'
import Button from '../../components/ui/button'
import { useFetch, useHandleLoginResponse } from '../../helpers/hooks'
import useTdGuid from '../../helpers/hooks/use-td-guid'
import useUserAgent from '../../helpers/hooks/use-user-agent'
import { googleLogin, userLogin as userLoginAction } from '../../services/api'
import { updateRecaptchaRequired, updateUserData } from '../../store/actions'
import { tag, track } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { getDefaultEventData } from '../../utils/analytics/get-default-event-data'
import googleGIcon from './../../assets/images/Google__G__Logo.svg'
import OktaLogo from '../../assets/images/brands/Okta.svg'
import './style.css'
import AuthPageHeader from './components/auth-page-header'
import FEATURE_FLAGS from '../../config/feature-flags'

function Login(props) {
  const dispatch = useDispatch()
  const link = useSelector((state) => state.Account?.link)
  const loggedIn = useSelector((state) => state.Account?.loggedIn)
  const ref = useRef(null)
  const isRecaptchaRequired = useSelector(
    (state) => state.Account?.isRecaptchaRequired,
  )
  const [captchaVerified, setCaptchaVerified] = useState(false)

  const { handleLoginResponse } = useHandleLoginResponse()
  function onChange() {
    setCaptchaVerified(true)
  }

  useEffect(() => {
    if (loggedIn) {
      props.history.push('/')
    }
  }, [loggedIn, props.history])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const hasOktaError = urlParams.get('oktaLoginFailed')
    if (hasOktaError) {
      window.history.replaceState({}, document.title, window.location.pathname)
      toastr.error(
        'If the issue persists, please contact support.',
        'Authentication failed',
      )
    }
  }, [])

  const userAgent = useUserAgent()
  const { guid } = useTdGuid()

  function handleError(data) {
    toastr.clear()
    let message = null
    if (data === 'Email or/and password is wrong.') {
      dispatch(updateRecaptchaRequired(true))
      setCaptchaVerified(false)
      message = 'Invalid email or password. Please try again.'
    } else if (data?.status === 429 && data?.success === false) {
      message =
        'Maximum number of failed login attempts exceeded, please try again in 5 minutes.'
    }

    toastr.error(message ?? data)
  }

  const { startFetch: userLogin, isLoading: logging } = useFetch({
    action: userLoginAction,
    onComplete: (data, values) => {
      if (data?.success === false) {
        handleError(data)
      } else {
        track('Signed in', { email_id: values?.email })

        const email = data?.email
        const clientId = data?.id

        const defaultEventData = getDefaultEventData(email)
        const eventData = {
          ...defaultEventData,
          userID: clientId,
          email_id: email,
        }

        if (!data?.is_contractor) {
          eventData.companyID = data?.company_id
        }

        // We track this event only if the user doesn't have two accounts
        // If they have two accounts, we track the event in the UserTypeChooser.js
        if (!Array.isArray(data) || data?.length <= 1) {
          tag(EVENTS.SIGNED_IN, eventData)
        }

        handleLoginResponse(data, values.password)
      }
    },
    onError: handleError,
  })

  // handleValidSubmit
  function handleValidSubmit(_, values) {
    if (captchaVerified || !isRecaptchaRequired) {
      dispatch(updateRecaptchaRequired(false))
      let data = {
        email: values.email,
        password: values.password,
      }

      if (guid) {
        data = { ...data, user_agent: userAgent, guid }
      }

      userLogin(data)
    }
    // props.loginUser(values, props.history);
  }
  const loginGoogle = useFetch({
    action: googleLogin,
    onComplete: (data) => {
      handleLoginResponse(data)
    },
    onError: (err) => {
      toastr.error(err)
    },
    checkSuccess: true,
  })
  const handleGoogleLogin = (e) => {
    dispatch(updateUserData(e))
    loginGoogle.startFetch({
      token: e?.tokenId,
    })
  }

  const handleGoogleLoginError = (e) => {
    if (
      e?.error === 'idpiframe_initialization_failed' &&
      e?.details === 'Cookies are not enabled in current environment.'
    ) {
      return
    }

    toastr.error(e.error)
  }

  const hasForwardLink = link?.startsWith('/contract/detail')

  return (
    <>
      {!hasForwardLink && <AuthPageHeader />}

      <div
        className={`account-pages ${
          hasForwardLink ? 'blur-image' : 'blur-container'
        } p-0 m-0`}
        style={{
          minHeight: hasForwardLink ? '100vh' : null,
        }}
      >
        <StyledH5
          min='22px'
          max='32px'
          className={`text-center mb-0 pt-4 pt-md-5 pb-3 pb-md-4 ${
            hasForwardLink ? 'text-white' : 'text-dark'
          }`}
        >
          Sign in
        </StyledH5>

        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card
                style={{ boxShadow: '0px 1px 0px #DFE1E6' }}
                className='overflow-hidden p-3 m-0'
              >
                <CardBody className='m-0 p-0'>
                  <AvForm
                    className='form-horizontal'
                    onValidSubmit={handleValidSubmit}
                  >
                    {props.error && props.error ? (
                      <Alert color='danger'>{props.error}</Alert>
                    ) : null}

                    <div className='form-group'>
                      <TextInput
                        name='email'
                        label='Email'
                        placeholder='Enter email'
                        autocomplete='email'
                        type='email'
                        required
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Email is required',
                          },
                        }}
                      />
                    </div>

                    <div className='form-group'>
                      <TextInput
                        name='password'
                        label='Current Password'
                        type='password'
                        placeholder='Current Password'
                        autocomplete='current-password'
                        required
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Current Password is required',
                          },
                        }}
                      />
                    </div>
                    <div className='mt-4'>
                      <Button
                        className='font-size-16'
                        type='submit'
                        loading={logging}
                        disabled={logging}
                        block
                      >
                        Sign In
                      </Button>
                    </div>

                    {isRecaptchaRequired && (
                      <Row className='justify-content-center p-3'>
                        <ReCAPTCHA
                          ref={ref}
                          sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY_V2}
                          onChange={onChange}
                        />
                      </Row>
                    )}
                    <div className='mt-4 text-center d-flex justify-content-between'>
                      <div />
                      <Link
                        to='/forgot-password'
                        className='text-primary font-size-14 font-weight-medium'
                      >
                        Forgot password?
                      </Link>
                    </div>

                    <span
                      style={{
                        position: 'absolute',
                        right: '46%',
                        padding: '15px',
                        marginTop: '-1%',
                      }}
                      className='bg-white text-muted'
                    >
                      Or
                    </span>
                    <div className='mt-4 border-top pt-4 tw-flex tw-flex-col tw-gap-2'>
                      <GoogleLogin
                        className='btn-block btn-outline-light rp-font-bold text-dark text-center google-btn border border-light rounded'
                        clientId='434551861221-untotaur31aeerf1t5hoj7jra3s7a5ru.apps.googleusercontent.com'
                        buttonText='Continue with Google'
                        render={(renderProps) => (
                          <button
                            className='btn btn-block btn-outline-light rp-font-bold text-dark text-center google-btn border border-light rounded'
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <img
                              className='mr-3'
                              src={googleGIcon}
                              style={{
                                height: 16,
                                display: 'inline',
                              }}
                              alt=''
                            />
                            Continue with Google
                          </button>
                        )}
                        onSuccess={handleGoogleLogin}
                        onFailure={handleGoogleLoginError}
                        cookiePolicy='single_host_origin'
                        uxMode='popup'
                      />
                    </div>
                    {FEATURE_FLAGS.OKTA_SIGN_IN ? (
                      <Link
                        tag='button'
                        to='/okta-login'
                        className='tw-mt-2 tw-flex tw-h-[44px] tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded tw-border tw-border-surface-30 !tw-text-text-100 hover:tw-bg-surface-20'
                      >
                        <img src={OktaLogo} height={20} width={20} />
                        <span className='tw-font-bold'>Continue with Okta</span>
                      </Link>
                    ) : null}
                  </AvForm>
                </CardBody>
              </Card>

              <Footer
                className={cn(
                  'tw-mb-4 tw-mt-12 tw-text-center',
                  hasForwardLink ? 'tw-text-text-10' : 'tw-text-text-60',
                )}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withRouter(Login)
