import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useSelector } from 'react-redux'
import toastr from 'toastr'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import { useFetch, useResize } from '../../../../helpers/hooks'
import {
  deleteEORPayslip,
  getEORAdminPayslips,
  getEORContractsPayslips,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import { getFullName } from '../../../../utils/get-full-name'
import AddPayslipModal from '../../../Fulltime/Payslips/addPayslipModal'
import ContractRef from '../../components/ContractRef'
import { useFilters } from '../cards/use-filters'

function ContractPayslipsSlider({ show, contractId, toggle }) {
  const isMobile = useResize()
  const [page, setPage] = useState(1)
  const { data, paginator } = useFetch(
    {
      action: getEORAdminPayslips,
      autoFetch: true,
      withAdminAccess: true,
      body: { contract_id: contractId, page },
    },
    [page],
  )

  const columns = [
    {
      Header: 'Month',
      accessor: 'month',
      Cell: ({ cellData, rowData }) => {
        return (
          <>
            {cellData}-{rowData.year}
          </>
        )
      },
    },
    {
      Header: 'Uploaded on',
      accessor: 'uploaded_at',
      Cell: ({ cellData }) => {
        return format(new Date(cellData), 'MM-dd-yyyy hh:mm')
      },
    },
    {
      Header: 'Uploaded by',
      accessor: 'uploaded_by',
      Cell: ({ cellData }) => {
        return getFullName(cellData)
      },
    },
    {
      Header: 'Download',
      accessor: 'link',
      Cell: ({ cellData }) => {
        return (
          <>
            <Button
              onClick={() => {
                openFileV2(cellData, {
                  isDataUrl: true,
                  download: false,
                  name: `Payslip.pdf`,
                })
              }}
              size='sm'
              style={{ height: 30 }}
            >
              Download
            </Button>
          </>
        )
      },
    },
  ]

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  return (
    <div
      style={{ position: 'fixed', top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <Menu
        onClose={toggle}
        className='bg-white'
        isOpen={show}
        width={isMobile ? '100%' : '40%'}
        right
      >
        <div className='w-100'>
          <div className='font-size-20 px-4 py-2'>Contract Payslips</div>
          <DataTable responsive columns={columns} data={data} />
          {showPaginator && (
            <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={page}
                itemsCountPerPage={paginator?.per_page ?? 10}
                totalItemsCount={paginator?.total ?? 0}
                onChange={(newPage) => setPage(newPage)}
              />
            </div>
          )}
        </div>
      </Menu>
    </div>
  )
}

function PayrollPayslipsActions({ rowData, openUploadModal, openDeleteModal }) {
  if (rowData.payslip) {
    return (
      <>
        <div className='d-flex gap-8'>
          <Button
            onClick={() => {
              openFileV2(rowData.payslip.link, {
                isDataUrl: true,
                download: false,
                name: `Payslip.pdf`,
              })
            }}
            size='sm'
            style={{ height: 30 }}
          >
            Download
          </Button>
          <Button
            onClick={() => {
              openDeleteModal({
                payslip_id: rowData.payslip.id,
                contract_id: rowData.id,
              })
            }}
            color='danger'
            size='sm'
            style={{ height: 30 }}
          >
            Delete
          </Button>
        </div>
      </>
    )
  } else {
    return (
      <Button
        onClick={() => {
          openUploadModal()
        }}
        size='sm'
        style={{ height: 30 }}
      >
        Upload
      </Button>
    )
  }
}

function PayrollPayslipsFilters({ handleFiltersChange, filters }) {
  const staticData = useSelector((state) => state?.Layout?.staticData)

  const { contract_statuses: statuses, countries } = staticData || {}
  return (
    <div className='d-flex align-items-end gap-8 p-4 tw-flex-wrap'>
      <CustomDatePicker
        value={filters.target_month}
        handleOnChange={(val) =>
          handleFiltersChange('target_month', val, { action: 'clear' })
        }
        placeholder='Select Month...'
        clearable
        label='Month'
        dateFormat='MM-yyyy'
        wrapperStyles={{ minWidth: 170 }}
        handleClear={() =>
          handleFiltersChange('target_month', null, { action: 'clear' })
        }
        showMonthYearPicker
        showFullMonthYearPicker
      />
      <CustomSelect
        label='Status'
        isClearable
        placeholder='Select Status...'
        defaultValue={filters.status_id}
        options={statuses?.map((status) => ({
          label: status.name,
          value: status.id,
        }))}
        wrapperStyles={{ minWidth: 200 }}
        onChange={(option) =>
          handleFiltersChange('status_id', option?.value, {
            action: 'clear',
          })
        }
      />
      <CustomSelect
        name='country'
        label='Country'
        isClearable
        handleClear={() =>
          handleFiltersChange('country_id', null, { action: 'clear' })
        }
        wrapperStyles={{ minWidth: '170px' }}
        onChange={(country) => {
          handleFiltersChange('country_id', country?.id)
        }}
        options={
          countries
            ? countries.map((c) => ({
                ...c,
                label: c.name,
                value: c.id,
                flag: `/${c.svg}`,
              }))
            : []
        }
      />
      <SearchBar
        query={filters.contract_ref}
        placeholder='Search by contract ID'
        onQueryChanged={(query) => handleFiltersChange('contract_ref', query)}
        className='mr-md-2'
        style={{ minWidth: '220px' }}
      />
      <SearchBar
        query={filters.employee_name}
        placeholder='Search by employee name'
        onQueryChanged={(query) => handleFiltersChange('employee_name', query)}
        className='mr-md-2'
        style={{ minWidth: '220px' }}
      />
    </div>
  )
}

function Payslips({ date }) {
  const dateObject = parseISO(date + '-01')
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    status_id: 4,
    contract_ref: null,
    country_id: null,
    employee_name: null,
    target_month: date ? new Date(dateObject) : new Date(),
  })
  const [isLoadingForAction, setIsLoadingForAction] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(null)
  const [showContractPayslips, setShowContractPayslips] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { data, paginator, isLoading, startFetch } = useFetch(
    {
      action: getEORContractsPayslips,
      autoFetch: true,
      withAdminAccess: true,
      body: {
        ...filters,
        target_month: filters?.target_month
          ? format(filters?.target_month, 'yyyy-MM-dd')
          : null,
      },
      onComplete: () => {
        setIsLoadingForAction(false)
      },
    },
    [filters],
  )

  const { startFetch: deletePayslip, isLoading: isDeleteLoading } = useFetch({
    action: deleteEORPayslip,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Deleted payslip successfully')
      setShowConfirmationModal(false)
      setIsLoadingForAction(true)
      startFetch()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url
  const columns = [
    {
      Header: 'Employee Name',
      accessor: 'employee_name',
      Cell: ({ cellData, rowData }) => {
        return (
          <Button
            onClick={() => {
              setShowContractPayslips({ id: rowData.id })
            }}
            color='transparent'
            className='text-primary cursor-pointer'
          >
            {cellData}
          </Button>
        )
      },
    },
    {
      Header: 'Contract ID',
      accessor: 'ref',
      Cell: ({ cellData }) => {
        return <ContractRef isAdmin contractId={cellData} />
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Provider',
      accessor: 'provider_name',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },
    {
      Header: 'Month',
      accessor: 'payslip',
      Cell: ({ cellData }) => {
        if (cellData.month) {
          return `${cellData.month}-${cellData.year}`
        }
        return <></>
      },
    },
    {
      Header: 'Uploaded On',
      accessor: 'payslip.uploaded_at',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd') : null
      },
    },
    {
      Header: 'Actions',
      accessor: 'payslip',
      Cell: ({ rowData }) => {
        return (
          <PayrollPayslipsActions
            rowData={rowData}
            openUploadModal={() => {
              setShowUploadModal({ id: rowData.id })
            }}
            openDeleteModal={(options) => {
              setShowConfirmationModal(options)
            }}
          />
        )
      },
    },
  ]
  return (
    <>
      <PayrollPayslipsFilters
        filters={filters}
        handleFiltersChange={handleFiltersChange}
      />
      {isLoading && !isLoadingForAction ? (
        <Loader style={{ minHeight: 300 }} className='w-100' />
      ) : data?.length === 0 ? (
        <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
          No Adjustments Pending
        </div>
      ) : (
        <DataTable data={data} responsive columns={columns} />
      )}
      {showPaginator && (
        <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={paginator?.per_page ?? 10}
            totalItemsCount={paginator?.total ?? 0}
            onChange={(newPage) => handleFiltersChange('page', newPage)}
          />
        </div>
      )}
      {showUploadModal ? (
        <AddPayslipModal
          show={showUploadModal}
          isAdmin
          contractId={showUploadModal.id}
          hide={() => {
            setShowUploadModal(null)
          }}
          onSuccessAdd={() => {
            toastr.success('Payslip was added successfully')
            startFetch()
            setIsLoadingForAction(true)
          }}
        />
      ) : null}
      {showContractPayslips ? (
        <ContractPayslipsSlider
          show={!!showContractPayslips}
          contractId={showContractPayslips.id}
          toggle={() => {
            setShowContractPayslips(false)
          }}
        />
      ) : null}
      {showConfirmationModal ? (
        <ConfirmationModal
          isOpen={!!showConfirmationModal}
          title='Delete payslip'
          content='Are you sure you want to delete this payslip?'
          loading={isDeleteLoading}
          onConfirm={() => {
            deletePayslip(showConfirmationModal)
          }}
          toggle={() => {
            setShowConfirmationModal(false)
          }}
        />
      ) : null}
    </>
  )
}

export default Payslips
