import { yupResolver } from '@hookform/resolvers/yup'
import { Minus, Plus } from '@phosphor-icons/react'
import { differenceInYears } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Label, Popover, PopoverBody, Row } from 'reactstrap'

import { cn } from 'ui'
import * as yup from 'yup'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../components/ui/button'

const schema = yup.object().shape({
  country_id: yup.string().required(),
  members: yup.number(),
  age: yup.number().typeError('Age must be a number').max(99).required(),
})
const InsuranceForm = ({ onSubmit, loading, onError }) => {
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const { birth_date: birthDate, country } = useSelector(
    (state) => state.userProfile?.userProfile,
  )
  const contractorAge = differenceInYears(new Date(), new Date(birthDate))

  const [savedAges, setSavedAges] = useState([])
  const [isAgesValid, setIsAgesValid] = useState(true)
  const ref = useRef(null)

  const defaultValues = {
    age: contractorAge,
    country_id: country?.id,
    members: 0,
  }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const [isAgesPopOpened, setIsAgesPopOpened] = useState(false)

  const onFormSubmit = (v) => {
    if (!isAgesValid) {
      return onError('Invalid dependent age')
    }
    onSubmit({ ...v, ages: savedAges })
  }
  useEffect(() => {
    if (!isAgesValid) {
      return onError('Invalid dependent age')
    }
    onSubmit(defaultValues)
  }, [])

  const onAgesChange = (ages) => {
    setSavedAges(ages)

    const invalidAges = ages.filter((age) => !age)
    if (invalidAges.length > 0) {
      setIsAgesValid(false)
    } else {
      setIsAgesValid(true)
    }
  }

  return (
    <div className='bg-white rounded rp-shadow-2 mb-3'>
      <form ref={ref} onSubmit={handleSubmit(onFormSubmit)}>
        <Row className='p-0 m-0 pb-2 pt-3'>
          <Col md={5} sm={12} className='p-0 mb-3 pr-md-3 px-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='country_id'
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Country of residence
              </Label>
              <ControlledSelect
                error={errors.country_id}
                control={control}
                name='country_id'
                options={
                  countries
                    ? countries.map((c) => ({
                        ...c,
                        label: c.name,
                        value: c.id,
                        flag: `/${c.svg}`,
                      }))
                    : []
                }
              />
            </FormGroup>
          </Col>
          <Col md={2} sm={12} className='p-0 px-3 pl-md-0 pr-md-0 mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='age'
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Age
              </Label>
              <ControlledInput
                maxLength='2'
                type='number'
                control={control}
                name='age'
                id='age'
                error={errors.age}
                disabled
                defaultValue={contractorAge}
              />
            </FormGroup>
          </Col>
          <Col md={3} sm={12} className='p-0 px-3 pr-md-3 mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Dependents
              </Label>
              <div
                className='form-control'
                id='DisabledAutoHideExample'
                onClick={() => setIsAgesPopOpened(true)}
              >
                {savedAges?.length} Dependents
              </div>
            </FormGroup>

            <Popover
              className='[&>.popover]:tw-left-48 [&>.popover]:tw-z-[1010] [&>.popover]:tw-min-w-96 [&>.popover]:tw-rounded [&>.popover]:tw-shadow-lg [&>.popover]:max-md:tw-max-w-96 [&>.popover]:md:tw-left-auto'
              placement='bottom'
              isOpen={isAgesPopOpened}
              trigger='legacy'
              target='DisabledAutoHideExample'
              toggle={() => setIsAgesPopOpened(false)}
            >
              <PopoverBody className='!tw-p-0'>
                <Ages onChange={onAgesChange} savedAges={savedAges} />
              </PopoverBody>
            </Popover>
          </Col>
          <Col md={2} sm={12} className='m-0 p-0 px-3 pl-md-0 pr-md-3 mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                {' '}
              </Label>
              <Button
                className='mt-2'
                block
                type='submit'
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export const Ages = ({ onChange, savedAges = [] }) => {
  const [ages, setAges] = useState([])

  const increment = () => {
    setAges([...ages, {}])
  }
  const decrement = () => {
    const newAges = [...ages]
    newAges.pop()
    setAges(newAges)
  }

  useEffect(() => {
    onChange(ages.map((e) => e.value))
  }, [ages])

  useEffect(() => {
    const newAges = savedAges.map((e) => ({ label: e, value: e }))
    setAges(newAges)
  }, [])

  return (
    <div>
      <div
        className={cn(
          'tw-flex tw-items-center tw-justify-between tw-border-b tw-p-4',
          ages.length <= 0 ? 'tw-border-transparent' : 'tw-border-surface-30',
        )}
      >
        <div>
          <h4>Dependents</h4>
          <p className='tw-mb-0 tw-text-text-80'>Spouse and children</p>
        </div>
        <div className='tw-flex tw-items-center tw-p-4'>
          <button
            onClick={decrement}
            className='tw-rounded tw-bg-primary-100 tw-p-1 tw-text-white hover:tw-bg-primary-110 disabled:tw-bg-primary-80'
            disabled={ages?.length === 0}
            type='button'
          >
            <Minus weight='bold' size={16} />
          </button>
          <div className='tw-flex tw-h-6 tw-w-12 tw-items-center tw-justify-center tw-px-4 tw-text-xl'>
            {ages?.length}
          </div>
          <button
            onClick={increment}
            className='tw-rounded tw-bg-primary-100 tw-p-1 tw-text-white hover:tw-bg-primary-110 disabled:tw-bg-primary-80'
            type='button'
          >
            <Plus weight='bold' size={16} />
          </button>
        </div>
      </div>

      {ages.length <= 0 ? null : (
        <div className='tw-space-y-2 tw-p-4'>
          {ages.map((e, i) => {
            return (
              <div
                key={`age-${i}`}
                className='tw-flex tw-items-center tw-justify-between'
              >
                <div className='tw-text-lg'>Dependent {i + 1}</div>

                <CustomSelect
                  placeholder='Age range'
                  value={e?.value ? e : null}
                  options={[
                    { label: '0-17', value: '0-17' },
                    { label: '18-39', value: '18-39' },
                    { label: '40-49', value: '40-49' },
                    { label: '50-59', value: '50-59' },
                    { label: '60-74', value: '60-74' },
                  ]}
                  onChange={(v) => {
                    const newAges = [...ages]
                    newAges[i] = v
                    setAges(newAges)
                  }}
                  wrapperClassName='tw-w-44 tw-text-sm'
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default InsuranceForm
