import React, { forwardRef, Suspense } from 'react'
import Loader from '../../../../../components/ui/loader'
import InputFeedback from '../../../../../components/ui/input-feedback'
import { getInputErrorMessage } from '../../../../../components/Forms/get-input-error-message'
import { cn } from 'ui'
import { ActionPlaceholders } from './action-placeholders'

const ReactQuill = React.lazy(() => import('react-quill'))

export const ActionEditor = forwardRef(function ActionEditor(props, ref) {
  const { insertPlaceholder, items, isLoading, onChange, ...rest } = props

  const handleChange = (content, delta, source, editor) => {
    const currentDelta = editor.getContents()
    if (currentDelta.ops.length === 1 && currentDelta.ops[0].insert === '\n')
      onChange('')
    else onChange(content)
  }

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={cn(
          'tw-rounded tw-border tw-border-surface-130 tw-transition tw-duration-150',
          props?.error && '!tw-border-systemRed',
          'focus-within:tw-border-[#bbbdd6] focus-within:tw-ring-2 focus-within:tw-ring-[#c6c8e069]',
        )}
      >
        <ReactQuill
          {...rest}
          onChange={handleChange}
          ref={ref}
          data-placeholder='Your dynamic text here'
          className='tw-flex tw-flex-col-reverse tw-overflow-auto [&>*:nth-child(2)]:!tw-min-h-[200px] [&>*:nth-child(2)]:!tw-p-[20px] [&_.ql-editor]:before:!tw-left-[20px] [&_.ql-editor]:before:!tw-not-italic [&_.ql-editor]:before:!tw-text-text-40 [&_.ql-editor]:before:!tw-content-[attr(data-placeholder)]'
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
            ],
          }}
        />
      </div>
      {props?.error ? (
        <InputFeedback>{getInputErrorMessage(props?.error)}</InputFeedback>
      ) : null}

      <ActionPlaceholders
        items={items}
        isLoading={isLoading}
        onInsert={(key) => insertPlaceholder?.(key)}
      />
    </Suspense>
  )
})
