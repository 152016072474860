import Head from '../../../../components/head'
import Loader from '../../../../components/ui/loader'
import { Container } from 'reactstrap'
import React from 'react'

export function AutomationFormLoading() {
  return (
    <Container fluid className='tw-px-0'>
      <Head title='Automation set up' />
      <Loader />
    </Container>
  )
}
