import { X } from '@phosphor-icons/react'
import React, { Suspense, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'

import PageModalHeader from '../../../components/ModalHeader'
import Head from '../../../components/head'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import { rpSignCorSow, getCorSowDefaultTemplate } from '../../../services/api'

const Editor = React.lazy(() => import('../TemplateEditor/Editor'))

const defaultCorSowName = 'RemotePass+ SOW default template'

export default function CorSowCreation() {
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [discardModalOpen, setDiscardModalOpen] = useState(false)
  const [sowData, setSowData] = useState([])

  const { data, isLoading } = useFetch({
    action: getCorSowDefaultTemplate,
    onComplete: (data) => {
      setSowData(data.sections)
    },
    autoFetch: true,
    withAdminAccess: true,
  })

  const history = useHistory()
  const location = history?.location
  const searchParams = new URLSearchParams(location.search)
  const contractId = searchParams.get('contract_id')
  const defaultName = searchParams.get('default_name')

  function goToContract() {
    history.push(`/admin/contract-detail?id=${contractId}`)
  }

  const { startFetch: createAndSignSow, isLoading: signingSow } = useFetch({
    action: rpSignCorSow,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Error while creating the SOW')
      } else {
        goToContract()
      }
    },
    onError: (error) => {
      toastr.error(error?.message, 'Error while creating the SOW')
    },
  })

  function handleSignSow({ name }) {
    const body = {
      name,
      contract_id: contractId,
      content: data?.default,
      sections: sowData,
    }
    createAndSignSow(body)
  }

  const templateName = data?.name || defaultCorSowName

  return (
    <div className='min-vh-100'>
      <Head title={`#${contractId} - ${templateName}`} />

      <PageModalHeader noExit>
        <h5 className='mb-0 d-flex justify-content-center align-items-center flex-grow-1'>
          {templateName}
        </h5>

        <div className='d-flex gap-12'>
          <Button
            color='light'
            outline
            onClick={() => setDiscardModalOpen(true)}
          >
            Discard changes
          </Button>
          <Button onClick={() => setSaveModalOpen(true)}>Save changes</Button>
        </div>
      </PageModalHeader>

      <div className='position-relative'>
        <Suspense fallback={<Loader />}>
          <Editor
            onChange={setSowData}
            agreement={data?.default}
            sections={data?.sections}
          />
        </Suspense>

        {!isLoading ? null : (
          <Loader
            minHeight='100vh'
            className='bg-soft-primary min-vw-100 position-fixed top-0'
            style={{ zIndex: 999999999 }}
          />
        )}
      </div>

      {!saveModalOpen ? null : (
        <SaveChangesModal
          isOpen={saveModalOpen}
          toggle={() => setSaveModalOpen((open) => !open)}
          onConfirm={handleSignSow}
          defaultName={defaultName}
          loading={signingSow}
        />
      )}
      {!discardModalOpen ? null : (
        <DiscardModal
          isOpen={discardModalOpen}
          toggle={() => setDiscardModalOpen((open) => !open)}
          onConfirm={goToContract}
        />
      )}
    </div>
  )
}

function SaveChangesModal({ defaultName, onConfirm, ...props }) {
  const [name, setName] = useState(defaultName)

  return (
    <PromptModal
      {...props}
      onConfirm={() => onConfirm({ name })}
      title='Create and sign SOW'
      description={
        <>
          <p>This action will create and sign the SOW for this contract.</p>

          <FormGroup className='mb-0'>
            <label className='font-size-14'>Signatory name</label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled
            />
          </FormGroup>
        </>
      }
      confirmText='Create and sign SOW'
    />
  )
}
function DiscardModal(props) {
  return (
    <PromptModal
      {...props}
      title='Discard SOW changes'
      description='Are you sure you want to discard changes?'
      confirmText='Discard changes'
      confirmColor='danger'
    />
  )
}

function PromptModal({
  isOpen,
  toggle,
  confirmColor,
  confirmText,
  onConfirm,
  title,
  description,
  loading,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        close={
          <button
            type='button'
            onClick={toggle}
            className='close text-secondary-100 p-1'
            aria-label='Close'
            style={{ width: 'auto', height: 'auto' }}
          >
            <X size={20} />
          </button>
        }
      >
        {title}
      </ModalHeader>
      <ModalBody
        className='font-size-16 modal-body mx-auto py-5 text-center text-text-70'
        style={{ maxWidth: '45ch' }}
      >
        {description}
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={loading}>
          Cancel
        </Button>
        <Button
          color={confirmColor}
          onClick={onConfirm}
          disabled={loading}
          loading={loading}
        >
          {confirmText || 'Confirm'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
