import React from 'react'
import { Controller } from 'react-hook-form'
import { EnvelopeSimple } from '@phosphor-icons/react'
import { ActionEditor } from './action-editor'
import { usePlaceholders } from '../contexts/placeholders-context'
import { ActionSchedule } from './action-schedule'
import ControlledInput from '../../../../../components/ControlledInput'
import { useEmailRecipients } from '../contexts/email-recipient-context'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'

export function ActionEmailForm(props) {
  const { quillRef, ...placeholder } = usePlaceholders()
  const { recipients } = useEmailRecipients()
  const { index, className } = props
  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      quillRef.current.focus()
    }
  }

  return (
    <>
      <ActionSchedule index={index} type='email' />
      <div className='tw-my-3 tw-flex tw-flex-row tw-items-center tw-gap-2'>
        <EnvelopeSimple size={20} className='tw-text-primary' />
        <span className='tw-font-bold'>Customize your email</span>
      </div>
      <div className='tw-flex tw-flex-col tw-gap-3'>
        <ControlledInput
          name={`steps.${index}.from`}
          disabled={true}
          defaultValue='From: no-reply@remotepass.team'
          placeholder='Email Subject'
        />

        <Controller
          render={({ field, fieldState }) => (
            <CustomSelect
              isMulti
              value={field.value}
              options={recipients}
              error={fieldState.error}
              hasError={!!fieldState.error}
              onChange={field.onChange}
              isOptionDisabled={() => field?.value?.length >= 5}
              placeholder='Recipient(s)'
            />
          )}
          name={`steps.${index}.recipients`}
        />

        <ControlledInput
          className='!tw-p-[20px]'
          name={`steps.${index}.subject`}
          placeholder='Email Subject'
          onKeyDown={handleKeyDown}
        />

        <Controller
          name={`steps.${index}.body`}
          render={({
            field: { value = '', onChange },
            fieldState: { error },
          }) => (
            <ActionEditor
              {...placeholder}
              ref={quillRef}
              error={error}
              value={value}
              className={className}
              onChange={onChange}
              placeholder='Email body'
            />
          )}
        />
      </div>
    </>
  )
}
