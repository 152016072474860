import { yupResolver } from '@hookform/resolvers/yup'
import {
  CaretDown,
  CaretUp,
  Eye,
  Info,
  PencilSimple,
  TrashSimple,
  UsersThree,
  Warning,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ActionsDropdown } from 'ui'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import ControlledInput from '../../components/ControlledInput'
import { getInputErrorMessage } from '../../components/Forms/get-input-error-message'
import { ControlledCheckbox2 } from '../../components/controlled-checkbox'
import ControlledRadioList from '../../components/controlled-radio-list'
import { PermissionTooltip } from '../../components/permission-tooltip'
import Button from '../../components/ui/button'
import DataTable from '../../components/ui/data-table'
import IconButton from '../../components/ui/icon-button'
import InputFeedback from '../../components/ui/input-feedback'
import Loader from '../../components/ui/loader'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  createUserRole,
  deleteUserRole,
  getRoleGroups,
  updateUserRole,
} from '../../services/api'
import { getErrorMessage } from '../../utils/get-errors'
import isNill from '../../utils/is-nill'
import { ViewUsersSlider } from './manage-role-view-users'
import { BILL_MODULE_STATUS } from '../bills/list'

const addRoleBtnId = 'add-role-btn'
export function AddRole({ onUpdated, hasPermission }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((isOpen) => !isOpen)
  }

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const kycNotVerified =
    userProfile?.kyc_verified !== 1 &&
    userProfile?.type === userTypes.COMPANY &&
    userProfile?.is_company_creator

  const { startFetch: createRole, isLoading: creatingRole } = useFetch({
    action: createUserRole,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'An error occurred', 'Error')
      } else {
        toastr.success(data?.message || 'Role created successfully')
        toggle?.()
        onUpdated?.()
      }
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred', 'Error')
    },
  })

  return (
    <>
      <PermissionTooltip
        showing={!hasPermission}
        id='add-role-btn-tooltip'
        area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
      >
        <Button
          disabled={kycNotVerified || !hasPermission}
          onClick={toggle}
          id={addRoleBtnId}
          color='link'
          className='!tw-px-0'
        >
          Add New
        </Button>
      </PermissionTooltip>

      {!isOpen ? null : (
        <ManageRoleSlider
          state={{ show: isOpen }}
          toggle={toggle}
          onSubmit={createRole}
          actionsLoading={creatingRole}
        />
      )}
    </>
  )
}

export const PERMISSION_GROUP = {
  COMPANY_SETTINGS: {
    id: 1,
    name: 'Company Settings',
    order: 1,
    parent_id: null,
  },
  CREATE_CONTRACTS: {
    id: 15,
    name: 'Create Contracts',
    order: 2,
    parent_id: null,
  },
  CREATE_CONTRACTS_FIXED: {
    id: 16,
    name: 'Fixed Contracts',
    order: 3,
    parent_id: 15,
  },
  CREATE_CONTRACTS_PAYG: {
    id: 17,
    name: 'PAYG Contracts',
    order: 4,
    parent_id: 15,
  },
  CREATE_CONTRACTS_MILESTONE: {
    id: 18,
    name: 'Milestone Contracts',
    order: 5,
    parent_id: 15,
  },
  CREATE_CONTRACTS_EOR: {
    id: 19,
    name: 'EOR Contracts',
    order: 6,
    parent_id: 15,
  },
  CREATE_CONTRACTS_DIRECT_EMPLOYEE: {
    id: 20,
    name: 'Direct Employee Contracts',
    order: 7,
    parent_id: 15,
  },
  CONTRACTS: {
    id: 21,
    name: 'Contracts',
    order: 8,
    parent_id: null,
  },
  MANAGE_CONTRACTS: {
    id: 2,
    name: 'Manage Contracts',
    order: 9,
    parent_id: 21,
  },
  CONTRACT_SETTINGS: {
    id: 14,
    name: 'Contract Settings',
    order: 10,
    parent_id: 21,
  },
  WORKS: {
    id: 4,
    name: 'Works',
    order: 11,
    parent_id: 21,
  },
  MILESTONES: {
    id: 5,
    name: 'Milestones',
    order: 12,
    parent_id: 21,
  },
  EQUIPMENT: {
    id: 6,
    name: 'Equipment',
    order: 13,
    parent_id: 21,
  },
  EXPENSES: {
    id: 7,
    name: 'Expenses',
    order: 14,
    parent_id: 21,
  },
  TIME_OFF: {
    id: 8,
    name: 'Time Off',
    order: 15,
    parent_id: 21,
  },
  DOCUMENTS: {
    id: 9,
    name: 'Documents',
    order: 16,
    parent_id: 21,
  },
  PAYMENTS: {
    id: 22,
    name: 'Payments',
    order: 17,
    parent_id: null,
  },
  MANAGE_PAYMENTS: {
    id: 3,
    name: 'Manage Payments',
    order: 18,
    parent_id: 22,
  },
  ADJUST_PAYMENTS: {
    id: 13,
    name: 'Adjust Payments',
    order: 19,
    parent_id: 22,
  },
  INVOICES: {
    id: 10,
    name: 'Invoices',
    order: 20,
    parent_id: null,
  },
  REPORTING: {
    id: 11,
    name: 'Reporting',
    order: 21,
    parent_id: null,
  },
  TRANSACTIONS: {
    id: 12,
    name: 'Transactions',
    order: 26,
    parent_id: null,
  },
  BILL_PAYMENT: {
    id: 24,
    name: 'Bill Payment',
    order: 21,
    parent_id: null,
  },
  MANAGE_VENDORS: {
    id: 25,
    name: 'Manage Vendors',
    order: 22,
    parent_id: 24,
  },
  CREATE_BILL: {
    id: 26,
    name: 'Create Bills',
    order: 23,
    parent_id: 24,
  },
  MANAGE_ALL_BILLS: {
    id: 27,
    name: 'Manage All Bills',
    order: 24,
    parent_id: 24,
  },
}

const editOnlyGroups = [
  PERMISSION_GROUP.ADJUST_PAYMENTS.id,
  PERMISSION_GROUP.CREATE_CONTRACTS.id,
  PERMISSION_GROUP.CREATE_CONTRACTS_FIXED.id,
  PERMISSION_GROUP.CREATE_CONTRACTS_PAYG.id,
  PERMISSION_GROUP.CREATE_CONTRACTS_MILESTONE.id,
  PERMISSION_GROUP.CREATE_CONTRACTS_EOR.id,
  PERMISSION_GROUP.CREATE_CONTRACTS_DIRECT_EMPLOYEE.id,
  PERMISSION_GROUP.MANAGE_VENDORS.id,
  PERMISSION_GROUP.CREATE_BILL.id,
]

const viewOnlyGroups = [PERMISSION_GROUP.INVOICES.id]

const manageRoleFormId = 'manage-role-form'
function ManageRoleSlider({
  title,
  subtitle,
  state,
  toggle,
  onSubmit,
  actionsLoading,
}) {
  const [collapsedGroups, setCollapsedGroups] = useState([])

  function handleCollapse(id) {
    setCollapsedGroups((collapsedGroups) => {
      return collapsedGroups.includes(id)
        ? collapsedGroups.filter((groupId) => groupId !== id)
        : [...collapsedGroups, id]
    })
  }

  function openGroup(ids = []) {
    setCollapsedGroups((collapsedGroups) => {
      return collapsedGroups.filter((groupId) => !ids.includes(groupId))
    })
  }

  function isCollapsed(id) {
    return collapsedGroups.includes(id)
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: state?.data
      ? {
          name: state.data.name,
          description: state.data.description,
          groups: state.data.groups,
          is_assigned_contracts_enabled:
            state.data.is_assigned_contracts_enabled,
        }
      : { name: '', description: '', groups: {} },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().max(20).required('Name is required'),
        description: yup.string().max(40).nullable(),
        is_assigned_contracts_enabled: yup
          .string()
          .oneOf(['1', '0'], 'Please select one option')
          .required('Please select one option'),
        groups: yup.lazy((value) => {
          if (!value) return yup.object().nullable()

          const isAllFalse = Object.values(value).every(
            (group) => group?.view === false && group?.manage === false,
          )

          if (isAllFalse) {
            // Generating a dummy schema to validate the case when no permission is selected
            return yup.object().shape({
              1: yup.object().shape({
                id: yup.number(),
                view: yup.boolean().oneOf([true], 'Please select one option'),
                manage: yup.boolean().nullable(),
              }),
            })
          }

          return yup.object().shape(
            Object.keys(value).reduce((cum, key) => {
              return {
                ...cum,
                [key]: yup.object().shape({
                  id: yup.number(),
                  view: yup.boolean().nullable(),
                  manage: yup.boolean().nullable(),
                }),
              }
            }, {}),
          )
        }),
      }),
    ),
  })
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const isEditable = isNill(state.data) ? true : state.isEditable !== false

  function filterBillPermission(role) {
    const billPermissionsNames = [
      PERMISSION_GROUP.BILL_PAYMENT.name,
      PERMISSION_GROUP.MANAGE_VENDORS.name,
      PERMISSION_GROUP.CREATE_BILL.name,
      PERMISSION_GROUP.MANAGE_ALL_BILLS.name,
    ]
    if (!billPermissionsNames.includes(role.name)) {
      return true
    }
    return userProfile?.company?.bill_status === BILL_MODULE_STATUS.APPROVED
  }

  const { data: roleGroupsData, isLoading: rolesLoading } = useFetch(
    {
      action: getRoleGroups,
      autoFetch: !!state.show,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'An error occurred', 'Error')
        } else {
          // Set the default values for the groups
          const defaultGroups = data?.reduce((cum, role) => {
            const defaultGroup = state.data?.groups?.[role.id]

            return {
              ...cum,
              [role.id]: {
                id: role.id,
                view: defaultGroup?.view ?? false,
                manage: defaultGroup?.manage ?? false,
                parent_id: role.parent_id,
              },
            }
          }, {})
          setValue('groups', defaultGroups)

          if (isEditable) {
            // Set the parent groups as collapsed
            setCollapsedGroups(
              data?.reduce((cum, role) => {
                return role.parent_id ? [...cum, role.parent_id] : cum
              }, []),
            )
          }
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    },
    [state.show],
  )

  function _onSubmit(values) {
    const body = {
      name: values?.name,
      description: values?.description || undefined,
      groups: Object.values(values?.groups)
        .map((group) => {
          if (
            (isNill(group?.manage) || group?.manage === false) &&
            (isNill(group?.view) || group?.view === false)
          ) {
            return null
          }
          return { id: group?.id, view_only: !group?.manage }
        })
        .filter(Boolean),
      is_assigned_contracts_enabled:
        values?.is_assigned_contracts_enabled === '1',
    }

    onSubmit?.(body)
  }

  const groups = useWatch({ control, name: 'groups' })

  function handleDependencies(newValue, rowData, type) {
    /* ---- START Dependency */
    // When selecting manage, select view as well
    if (newValue && type === 'manage') {
      // If we don't have view permission, we can't select view
      if (editOnlyGroups.includes(rowData?.id)) {
        setValue(`groups.${rowData?.id}.view`, false)
      } else {
        setValue(`groups.${rowData?.id}.view`, newValue)
      }
    } else {
      setValue(`groups.${rowData?.id}.view`, groups?.[rowData?.id]?.view)
      return newValue
    }
    /* ---- END Dependency */

    /* ---- START Dependency */
    // Permissions (payment related) that depend on edit MANAGE_CONTRACTS permission
    const contractDependencies = [
      PERMISSION_GROUP.PAYMENTS.id,
      PERMISSION_GROUP.WORKS.id,
      PERMISSION_GROUP.MILESTONES.id,
      PERMISSION_GROUP.EXPENSES.id,
      PERMISSION_GROUP.TIME_OFF.id,
    ]

    // When clicking on MANAGE_CONTRACTS (id=2), select payment related permissions
    if (newValue && rowData?.id === PERMISSION_GROUP.MANAGE_CONTRACTS.id) {
      contractDependencies.forEach((id) => {
        setValue(`groups.${id}.view`, newValue)
      })
    }

    // When clicking on MANAGE_ALL_BILLS , select CREATE_BILL and MANAGE_VENDORS to be true as well
    if (newValue && rowData?.id === PERMISSION_GROUP.MANAGE_ALL_BILLS.id) {
      setValue(`groups.${PERMISSION_GROUP.CREATE_BILL.id}.manage`, newValue)
      setValue(`groups.${PERMISSION_GROUP.MANAGE_VENDORS.id}.manage`, newValue)
    }

    // When selecting a payment related permission, select MANAGE_CONTRACTS (id=2)
    if (newValue && contractDependencies.includes(rowData?.id)) {
      setValue(`groups.${PERMISSION_GROUP.MANAGE_CONTRACTS.id}.view`, newValue)
    }
    /* ---- END Dependency */

    /* ---- START Dependency */
    const dependOnParentGroup = [
      PERMISSION_GROUP.MANAGE_CONTRACTS.id,
      PERMISSION_GROUP.CONTRACT_SETTINGS.id,
      PERMISSION_GROUP.WORKS.id,
      PERMISSION_GROUP.MILESTONES.id,
      PERMISSION_GROUP.EQUIPMENT.id,
      PERMISSION_GROUP.EXPENSES.id,
      PERMISSION_GROUP.TIME_OFF.id,
      PERMISSION_GROUP.DOCUMENTS.id,
    ]

    if (type === 'view' && dependOnParentGroup.includes(rowData?.id)) {
      setValue(`groups.${rowData?.parent_id}.${type}`, newValue)
    }
    /* ---- END Dependency */

    /* ---- START Dependency */
    // When selecting Payment Adjustments Edit we have to check Payments View
    if (
      type === 'manage' &&
      rowData?.id === PERMISSION_GROUP.ADJUST_PAYMENTS.id
    ) {
      setValue(`groups.${PERMISSION_GROUP.MANAGE_PAYMENTS.id}.view`, newValue)
      setValue(`groups.${PERMISSION_GROUP.MANAGE_CONTRACTS.id}.view`, newValue)

      // collapse the parent contract
      openGroup([
        PERMISSION_GROUP.MANAGE_PAYMENTS.parent_id,
        PERMISSION_GROUP.MANAGE_CONTRACTS.parent_id,
      ])
    }
    /* ---- END Dependency */

    /* ---- START Dependency */
    // if child is selected, select parent
    if (newValue && rowData?.parent_id) {
      setValue(`groups.${rowData?.parent_id}.${type}`, newValue)
    }
    /* ---- END Dependency */

    /* ---- START Dependency */
    const permissionDependOnContractEdit = [
      PERMISSION_GROUP.CREATE_CONTRACTS_FIXED.id,
      PERMISSION_GROUP.CREATE_CONTRACTS_PAYG.id,
      PERMISSION_GROUP.CREATE_CONTRACTS_MILESTONE.id,
      PERMISSION_GROUP.CREATE_CONTRACTS_EOR.id,
      PERMISSION_GROUP.CREATE_CONTRACTS_DIRECT_EMPLOYEE.id,
    ]

    if (
      type === 'manage' &&
      permissionDependOnContractEdit.includes(rowData?.id)
    ) {
      setValue(
        `groups.${PERMISSION_GROUP.MANAGE_CONTRACTS.id}.manage`,
        newValue,
      )

      handleDependencies(
        newValue,
        {
          id: PERMISSION_GROUP.MANAGE_CONTRACTS.id,
          parent_id: PERMISSION_GROUP.MANAGE_CONTRACTS.parent_id,
        },
        'manage',
      )

      // open the parent group
      openGroup([PERMISSION_GROUP.MANAGE_CONTRACTS.parent_id])
    }
    /* ---- END Dependency */

    return newValue
  }

  function getIsParent(itemId) {
    // An item is a parent if an item has a parent_id equal to its id
    const isParent = roleGroupsData?.some((item) => {
      return item.parent_id === itemId
    })

    return isParent
  }

  return (
    <SideMenu
      onClose={toggle}
      isOpen={state.show}
      className='!tw-w-full tw-max-w-[612px]'
      itemListClassName={cx(
        'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
        isEditable ? 'tw-grid-rows-[auto_1fr_91px]' : 'tw-grid-rows-[auto_1fr]',
      )}
    >
      <SideMenuHeader toggle={toggle}>
        <div className='tw-flex tw-flex-col'>
          <div className='tw-font-semibold'>{title || 'New role'}</div>
          <span className='tw-text-sm tw-text-text-60'>
            {subtitle || 'Create a new role'}
          </span>
        </div>
      </SideMenuHeader>
      <SideMenuBody>
        {rolesLoading ? (
          <Loader className='tw-h-full' />
        ) : (
          <form onSubmit={handleSubmit(_onSubmit)} id={manageRoleFormId}>
            <fieldset
              disabled={!isEditable}
              className='tw-flex tw-flex-col tw-gap-6'
            >
              <ControlledInput
                name='name'
                label='Title'
                control={control}
                placeholder='Title of the role'
              />

              <ControlledInput
                name='description'
                label='Description'
                control={control}
                placeholder='A short description of this role'
              />

              <div>
                <legend className='tw-mb-0 tw-text-base tw-font-bold'>
                  Contracts access
                </legend>
                <p className='tw-text-sm tw-text-text-80'>
                  What type of contracts can this role access?
                </p>

                {!errors.is_assigned_contracts_enabled ? null : (
                  <InputFeedback className='-tw-mt-3 tw-mb-4'>
                    {getInputErrorMessage(errors.is_assigned_contracts_enabled)}
                  </InputFeedback>
                )}

                <ControlledRadioList
                  name='is_assigned_contracts_enabled'
                  control={control}
                  fancyLabelClassName='tw-flex tw-justify-center tw-w-full'
                  options={[
                    { label: 'Assigned contracts', value: '1' },
                    { label: 'All contracts', value: '0' },
                  ]}
                  horizontal
                  isFancy
                />
              </div>

              <div>
                <legend className='tw-mb-0 tw-text-base tw-font-bold'>
                  Permissions
                </legend>
                <p className='tw-text-sm tw-text-text-80'>
                  Manage this role’s permissions
                </p>

                {!errors?.groups?.[1]?.view?.message ? null : (
                  <InputFeedback className='-tw-mt-3 tw-mb-4'>
                    {getInputErrorMessage(errors.groups[1].view.message)}
                  </InputFeedback>
                )}

                <DataTable
                  rowClassName={({ rowData }) => {
                    const hasParent = Boolean(rowData?.parent_id)

                    return cx(hasParent && 'tw-bg-surface-10')
                  }}
                  columns={[
                    {
                      Header: 'Area',
                      accessor: 'name',
                      Cell: ({ rowData }) => {
                        const hasParent = Boolean(rowData?.parent_id)

                        return (
                          <div className={cx(hasParent && 'tw-pl-8')}>
                            <span
                              className={cx(
                                'tw-mb-0.5 tw-text-sm tw-font-medium',
                                hasParent
                                  ? 'tw-text-secondary-100'
                                  : 'tw-text-black',
                              )}
                            >
                              {rowData.name}
                            </span>
                            <div className='tw-max-w-[40ch] tw-whitespace-break-spaces tw-text-xs'>
                              {rowData.description}
                            </div>
                          </div>
                        )
                      },
                    },
                    {
                      Header: (
                        <div className='tw-flex tw-items-center tw-gap-1'>
                          View
                          <InfoTooltip id='head-view-col'>
                            Allow users View-only access
                          </InfoTooltip>
                        </div>
                      ),
                      Cell: ({ rowData }) => {
                        const isParent = getIsParent(rowData?.id)

                        if (isParent) {
                          return null
                        }

                        // We don't have view permissions for ADJUST_PAYMENTS
                        if (editOnlyGroups.includes(rowData?.id)) {
                          return null
                        }

                        // if any child is selected, parent should be disabled
                        const isSomeChildSelected = Object.values(groups).some(
                          (group) =>
                            group?.parent_id === rowData?.id && group?.view,
                        )

                        const isManageSelected = Boolean(
                          groups?.[rowData?.id]?.manage,
                        )

                        return (
                          <ControlledCheckbox2
                            control={control}
                            id={`groups.${rowData?.id}.view`}
                            name={`groups.${rowData?.id}.view`}
                            disabled={isSomeChildSelected || isManageSelected}
                            transform={{
                              output: (event) => {
                                return handleDependencies(
                                  event.target.checked,
                                  rowData,
                                  'view',
                                )
                              },
                            }}
                          />
                        )
                      },
                    },
                    {
                      Header: (
                        <div className='tw-flex tw-items-center tw-gap-1'>
                          Edit
                          <InfoTooltip id='head-edit-col'>
                            Allow users View & Edit access
                          </InfoTooltip>
                        </div>
                      ),
                      Cell: ({ rowData }) => {
                        const isParent = getIsParent(rowData?.id)

                        if (isParent) {
                          if (!isEditable) {
                            return null
                          } else {
                            return (
                              <IconButton
                                type='button'
                                color='link'
                                onClick={() => handleCollapse(rowData?.id)}
                                size={24}
                                icon={
                                  isCollapsed(rowData?.id) ? (
                                    <CaretDown size={24} />
                                  ) : (
                                    <CaretUp size={24} />
                                  )
                                }
                              />
                            )
                          }
                        }

                        // We don't have view permissions for INVOICES
                        if (viewOnlyGroups.includes(rowData?.id)) {
                          return null
                        }

                        // If any child is selected, parent should be disabled
                        const isDisabled = Object.values(groups).some(
                          (group) =>
                            group?.parent_id === rowData?.id && group?.manage,
                        )

                        return (
                          <ControlledCheckbox2
                            control={control}
                            id={`groups.${rowData?.id}.manage`}
                            name={`groups.${rowData?.id}.manage`}
                            disabled={isDisabled}
                            transform={{
                              output: (event) => {
                                return handleDependencies(
                                  event.target.checked,
                                  rowData,
                                  'manage',
                                )
                              },
                            }}
                          />
                        )
                      },
                    },
                  ]}
                  data={roleGroupsData
                    ?.filter(
                      ({ parent_id: parentId }) => !isCollapsed(parentId),
                    )
                    .filter((role) => filterBillPermission(role))}
                />
              </div>
            </fieldset>
          </form>
        )}
      </SideMenuBody>
      {!isEditable ? null : (
        <SideMenuFooter>
          <Button
            color='light'
            outline
            onClick={toggle}
            disabled={actionsLoading || rolesLoading}
          >
            Cancel
          </Button>
          <Button
            formId={manageRoleFormId}
            disabled={actionsLoading || rolesLoading}
            loading={actionsLoading}
          >
            Save
          </Button>
        </SideMenuFooter>
      )}
    </SideMenu>
  )
}

export function ManageRoleActions({ role, onUpdated, canManageRoles }) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [viewUsersIsOpen, setViewUsersIsOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((isOpen) => !isOpen)
  }

  const isEditable = role.isEditable && canManageRoles

  const { startFetch: deleteRole, isLoading: deletingUserRole } = useFetch({
    action: deleteUserRole,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'An error occurred', 'Error')
      } else {
        toastr.success(data?.message || 'Role deleted successfully')
        onUpdated?.()
        setShowDeleteConfirm(false)
      }
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred', 'Error')
    },
  })

  const { startFetch: updateRole, isLoading: updatingRole } = useFetch({
    action: updateUserRole,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'An error occurred', 'Error')
      } else {
        toastr.success(data?.message || 'Role updated successfully')
        onUpdated?.()
      }
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred', 'Error')
    },
  })

  const options = [
    isEditable
      ? {
          label: 'Edit role',
          onClick: () => setIsOpen(true),
          className: '!tw-p-4',
          icon: <PencilSimple size={16} />,
        }
      : {
          label: 'View role',
          onClick: () => setIsOpen(true),
          className: '!tw-p-4',
          icon: <Eye size={16} />,
        },
    role?.assigned_users > 0 && {
      label: 'View users',
      onClick: () => setViewUsersIsOpen(true),
      className: '!tw-p-4',
      icon: <UsersThree size={16} />,
    },
    isEditable &&
      role.assigned_users <= 0 && {
        label: 'Delete role',
        onClick: () => setShowDeleteConfirm(true),
        className: '!tw-p-4 tw-text-red-100',
        icon: <TrashSimple size={16} />,
      },
  ]

  const dataRole = {
    ...role,
    groups: role.groups.reduce((cum, group) => {
      return {
        ...cum,
        [group.id]: {
          id: group.id,
          view: true,
          manage: group.is_view_only === 0,
        },
      }
    }, {}),
  }

  return (
    <>
      <ActionsDropdown
        className='tw-bg-transparent'
        data={options}
        loading={deletingUserRole}
      />

      <ConfirmationModal
        isOpen={!!showDeleteConfirm}
        content={
          <>
            <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
              <Warning size={24} className='tw-fill-red-100' />

              <ModalCloseButton toggle={() => setShowDeleteConfirm(false)} />
            </div>

            <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Are you sure you want to delete the {role.name} role?
            </p>
            <p className='tw-text-sm tw-text-text-80'>
              Once this role is deleted it can’t be recovered
            </p>
          </>
        }
        onConfirm={() => deleteRole({ id: role.id })}
        toggle={() => setShowDeleteConfirm(false)}
        confirmLoading={deletingUserRole}
        caption='Delete'
        buttonColor='danger'
      />

      {!isOpen ? null : (
        <ManageRoleSlider
          state={{ show: isOpen, data: dataRole, isEditable }}
          toggle={toggle}
          title={role.name}
          subtitle={
            isEditable
              ? 'Edit this role'
              : 'You can’t edit this role’s permissions'
          }
          onSubmit={(values) => updateRole({ id: role.id, ...values })}
          actionsLoading={updatingRole}
        />
      )}

      {!viewUsersIsOpen ? null : (
        <ViewUsersSlider
          toggle={() => setViewUsersIsOpen(false)}
          isOpen={viewUsersIsOpen}
          title={role.name}
          roleId={role.id}
        />
      )}
    </>
  )
}

export function InfoTooltip({
  id,
  children,
  placement = 'bottom',
  iconSize = 16,
}) {
  return (
    <>
      <Info size={iconSize} id={id} />

      <UncontrolledTooltip placement={placement} target={id}>
        {children}
      </UncontrolledTooltip>
    </>
  )
}
