import React, { useState } from 'react'
import Loader from '../../../../../components/ui/loader'
import { useFormContext } from 'react-hook-form'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap'
import Button from '../../../../../components/ui/button'
import { PlusCircle } from '@phosphor-icons/react'

export function ActionPlaceholders({ onInsert, items, isLoading }) {
  const { watch } = useFormContext()
  const triggerCategory = watch(`steps.0`)?.event?.category
  const [search, setSearch] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const placeholders =
    items
      ?.filter((i) => i.available_categories.includes(triggerCategory))
      ?.filter((i) => i.title?.toLowerCase()?.includes(search)) ?? []

  return (
    <>
      {isLoading ? (
        <Loader minHeight='20px' className='tw-my-2 tw-flex-1' />
      ) : (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen((prevState) => !prevState)}
        >
          <DropdownToggle
            tag={(props) => (
              <Button
                outline={!dropdownOpen}
                circle
                disabled={!triggerCategory}
                iconRight={
                  <PlusCircle
                    size={20}
                    style={{ marginLeft: '-5px' }}
                    weight='fill'
                  />
                }
                size='sm'
                {...props}
              />
            )}
          >
            Add Variable
          </DropdownToggle>
          <DropdownMenu className='tw-max-h-[300px] tw-w-[250px] tw-overflow-auto'>
            <div className='tw-flex tw-flex-col tw-gap-2 tw-px-2 tw-py-2'>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Search...'
                id='search-input'
              />
              {placeholders.map((f, index) => {
                return (
                  <DropdownItem
                    onClick={() => onInsert(f.key)}
                    key={`${f.title}-${index}`}
                  >
                    {f.title}
                  </DropdownItem>
                )
              })}
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  )
}
