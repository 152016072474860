import {
  CheckCircle,
  FlowArrow,
  HourglassHigh,
  UserCircle,
} from '@phosphor-icons/react'
import { TrashSimple } from '@phosphor-icons/react/dist/ssr'
import { AvForm } from 'availity-reactstrap-validation'
import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import { Avatar } from 'ui'
import warningIllustration from '../../../assets/images/warning.png'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledSelect from '../../../components/ControlledSelect'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import TableH from '../../../components/Table/TableH'
import ApprovalFlowNameList from '../../../components/approval-flow-name-list'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Alert from '../../../components/ui/alert'
import { Box, BoxIcon } from '../../../components/ui/box'
import Button from '../../../components/ui/button'
import UsdSwiftMessage, {
  UsdSwiftMessageContent,
} from '../../../components/usd-swift-message'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  getApprovalFlows,
  getCompanyApprovers,
  updateContract,
  updateDEContract,
} from '../../../services/api'
import { getFullName } from '../../../utils/get-full-name'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../../CompanySetting/components/settings-section-heading'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import RecurringExpenses from '../RecurringExpenses'
import { LoadingFlowSteps } from './payment-approvals'
import { CustomFieldsSection } from './settings/custom-fields-section'
import ApprovalFlowSummary from '../../../components/approval-flow-summary'
import { track } from '../../../utils/analytics'
import { APPROVAL_FLOW_EVENTS } from '../../CompanySetting/approvals/approval-flow-events'

export default function Settings({ onUpdate, contractLoading }) {
  const isAdmin = location.pathname.startsWith('/admin')

  const contract = useSelector((state) => state.Contract?.details)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const isCoverFeeEnabled = contract?.is_cover_fee_enabled

  const { hasAccess } = usePermissions()

  const canManageContractSettings =
    !isAdmin && hasAccess(permissions.manageContractSettings)

  return (
    <Card className='m-0'>
      <CardBody className='p-0 bg-white rounded rp-shadow-2'>
        <TableH title='Settings' />

        {[CONTRACT_TYPES.DIRECT_EMPLOYEE, CONTRACT_TYPES.FULL_TIME].includes(
          contract?.type,
        ) ? null : !isCoverFeeEnabled ? null : (
          <div className='px-4 pb-4 pt-3 mb-md-4 mb-3 tw-border-b tw-border-surface-30'>
            <SettingSectionHeading>Transfer fees</SettingSectionHeading>

            <SettingSectionSubHeading className='tw-max-w-max'>
              The transfer fee varies by currency and country. If enabled, it
              will be added to your payment and displayed during the payment
              process.
            </SettingSectionSubHeading>

            <UsdSwiftMessagePopup
              canManage={canManageContractSettings}
              onUpdate={onUpdate}
            />
          </div>
        )}

        {[CONTRACT_TYPES.FIXED, CONTRACT_TYPES.PAY_AS_YOU_GO].includes(
          contract?.type,
        ) && (
          <div className='mb-md-4 mb-3'>
            <RecurringExpenses
              isAdmin={isAdmin}
              canManage={canManageContractSettings}
            />
          </div>
        )}

        {[CONTRACT_TYPES.DIRECT_EMPLOYEE, CONTRACT_TYPES.FULL_TIME].includes(
          contract?.type,
        ) || contractLoading ? null : (
          <div className='px-3 pb-3 px-md-4 pb-md-4 mb-3 mb-md-4 tw-border-b tw-border-surface-30'>
            <SettingSectionHeading>VAT</SettingSectionHeading>

            <SettingSectionSubHeading>
              VAT will be automatically applied to new & unpaid payments.
            </SettingSectionSubHeading>

            <VatModal
              onUpdate={onUpdate}
              canManage={canManageContractSettings}
            />
          </div>
        )}

        {userProfile?.company?.country?.id === 194 && (
          <div className='px-3 pb-3 px-md-4 pb-md-4 mb-3 mb-md-4 tw-border-b tw-border-surface-30'>
            <SettingSectionHeading>Withholding tax</SettingSectionHeading>

            <SettingSectionSubHeading>
              The set rate will be automatically deducted from each work payment
              and displayed on the contractor invoice.{' '}
              <a
                className='text-primary'
                target='_blank'
                rel='noreferrer'
                href='https://help.remotepass.com/en/articles/5602817-how-to-automatically-deduct-withholding-tax-for-ksa-contracts'
              >
                Learn more
              </a>
            </SettingSectionSubHeading>

            <WithHoldingTaxModal
              onUpdate={onUpdate}
              isAdmin={isAdmin}
              canManage={canManageContractSettings}
            />
          </div>
        )}

        <PaymentApprovalSetting
          isAdmin={isAdmin}
          canManage={canManageContractSettings}
          contract={contract}
          onUpdate={onUpdate}
          contractLoading={contractLoading}
        />

        {!FEATURE_FLAGS.CUSTOM_FIELDS ? null : (
          <CustomFieldsSection
            canManage={canManageContractSettings}
            contract={contract}
            contractLoading={contractLoading}
            onUpdate={onUpdate}
          />
        )}
      </CardBody>
    </Card>
  )
}

export const VatModal = ({ onUpdate, canManage }) => {
  const [rate, setRate] = useState(null)
  const ref = useRef(null)
  const [editing, setEditing] = useState(false)
  const contract = useSelector((state) => state.Contract?.details)
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const update = useFetch({
    action: isDeContract ? updateDEContract : updateContract,
    onComplete: () => {
      toastr.success('VAT updated successfully')
      setEditing(false)
      onUpdate()
    },
    onError: toastr.error,
  })
  useEffect(() => {
    setRate(contract?.vat)
  }, [contract])

  const isMobile = useResize()

  const editingDisabled = !canManage

  return (
    <AvForm
      onValidSubmit={() => {
        if (rate) {
          update.startFetch({
            contract_id: contract?.id,
            vat: rate,
          })
        } else {
          toastr.error('You need to set VAT rate')
        }
      }}
    >
      <Row className='p-0 m-0 align-items-center md:w-75'>
        <Col md={5} sm={8} xs={12} className='p-0 m-0 mr-2 position-relative'>
          <Input
            placeholder='Enter a percentage value'
            name='rate'
            type='text'
            ref={ref}
            onKeyPress={(e) => {
              if (!/[0-9.]/.test(e.key)) {
                e.preventDefault()
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setEditing(false)
              }
            }}
            disabled={!editing || editingDisabled}
            autoComplete='off'
            value={rate ? `${rate}%` : ''}
            onChange={(e) => {
              setRate(e.target.value.replace('%', ''))
            }}
          />

          {editing ? null : (
            <div
              className='position-absolute'
              style={{ right: 12, bottom: 12 }}
            >
              <PermissionTooltip
                showing={editingDisabled}
                id='edit-vat-btn'
                area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
              >
                <Button
                  className='p-0'
                  color='link'
                  onClick={() => {
                    setEditing(true)
                    ref.current.focus()
                  }}
                  disabled={editingDisabled}
                >
                  Edit
                </Button>
              </PermissionTooltip>
            </div>
          )}
        </Col>

        {editing && (
          <Button
            className={isMobile && 'mt-3'}
            block={isMobile}
            loading={update.isLoading}
            disabled={update.isLoading}
            type='submit'
          >
            Apply
          </Button>
        )}
      </Row>
    </AvForm>
  )
}

const WithHoldingTaxModal = ({ onUpdate, canManage }) => {
  const [rate, setRate] = useState()
  const ref = useRef(null)
  const [editing, setEditing] = useState(false)
  const [applyDeduction, setApplyDeduction] = useState(false)
  const contract = useSelector((state) => state.Contract?.details)

  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const update = useFetch({
    action: isDeContract ? updateDEContract : updateContract,
    onComplete: () => {
      toastr.success('Withholding Tax updated successfully')
      setEditing(false)
      onUpdate()
    },
    onError: toastr.error,
  })
  useEffect(() => {
    if (!update.isLoading) {
      setRate(contract?.withholding_tax)
      setApplyDeduction(contract?.withholding_tax_expense)
    }
  }, [contract])

  const applyChanges = (value) => {
    const body = {
      contract_id: contract?.id,
      withholding_tax_expense: value ? 1 : 0,
      withholding_tax: rate,
    }
    update.startFetch(body)
  }

  const isMobile = useResize()

  const editingDisabled = !canManage
  const applyDeductionDisabled = !canManage || !rate || update.isLoading

  return (
    <AvForm
      onValidSubmit={() => {
        if (rate) {
          update.startFetch({
            contract_id: contract?.id,
            withholding_tax: rate,
          })
        } else {
          toastr.error('You need to set withholding tax rate')
        }
      }}
    >
      <Row className='align-items-start p-0 m-0 md:w-75'>
        <Col className='p-0 m-0 mr-2' md={5} sm={8} xs={12}>
          <Col className='p-0 m-0 mr-2 position-relative'>
            <Input
              placeholder='Enter a percentage value'
              name='withholding_tax'
              type='text'
              ref={ref}
              onKeyPress={(e) => {
                if (!/[0-9.]/.test(e.key)) {
                  e.preventDefault()
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setEditing(false)
                }
              }}
              disabled={!editing || editingDisabled}
              autoComplete='off'
              value={rate ? `${rate}%` : ''}
              onChange={(e) => {
                setRate(e.target.value.replace('%', ''))
              }}
            />

            {editing ? null : (
              <div
                className='position-absolute'
                style={{ right: 12, bottom: 12 }}
              >
                <PermissionTooltip
                  showing={editingDisabled}
                  id='edit-withholding-tax-btn'
                  area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
                >
                  <Button
                    className='p-0'
                    color='link'
                    onClick={() => {
                      setEditing(true)
                      ref.current.focus()
                    }}
                    disabled={editingDisabled}
                  >
                    Edit
                  </Button>
                </PermissionTooltip>
              </div>
            )}
          </Col>

          {!!Number(rate) && (
            <PermissionTooltip
              showing={!canManage}
              id='deduction-switch'
              area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
            >
              <div className='custom-control custom-switch align-items-center mt-3'>
                <Input
                  name='apply_deduction'
                  type='checkbox'
                  disabled={applyDeductionDisabled}
                  className='custom-control-input'
                  onChange={(e) => {
                    if (!update.isLoading) {
                      setApplyDeduction(e.target.checked)
                      applyChanges(e.target.checked)
                    }
                  }}
                  id='customApplyDeduction'
                  checked={applyDeduction}
                />
                <label
                  className='custom-control-label'
                  htmlFor='customApplyDeduction'
                >
                  Apply deduction to expense payments.
                </label>
              </div>
            </PermissionTooltip>
          )}
        </Col>

        {editing && (
          <button
            className={`btn btn-primary ${isMobile && 'btn-block mt-3'}`}
            type='submit'
          >
            {update.isLoading && (
              <i className='bx bx-loader bx-spin align-middle mr-2' />
            )}
            Apply
          </button>
        )}
      </Row>
      {!!Number(rate) && (
        <Row className='p-0 m-0 mt-3 text-muted'>
          <i className='font-size-16 bx bx-info-circle mr-1' />
          <p style={{ lineHeight: '16px' }} className='m-0 font-size-12'>
            Make sure your team member is informed about the deduction
          </p>
        </Row>
      )}
    </AvForm>
  )
}

const approverFormId = 'approver-form-id'
function TimeOffApproverModal({ onUpdate, isAdmin, canManage }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggle() {
    setIsModalOpen((open) => !open)
  }

  return (
    <>
      <PermissionTooltip
        showing={!canManage}
        id='change-approver-btn'
        area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
      >
        <Button
          color='link'
          className='!tw-p-2'
          onClick={toggle}
          disabled={!canManage}
        >
          Change
        </Button>
      </PermissionTooltip>

      <Modal isOpen={isModalOpen} toggle={toggle} centered>
        <ModalHeader
          close={<ModalCloseButton toggle={toggle} />}
          cssModule={{ 'modal-title': 'modal-title tw-flex tw-flex-col' }}
        >
          <span className='tw-mb-2 tw-text-xl tw-font-semibold'>Approvers</span>
          <span className='tw-text-sm tw-font-medium tw-text-text-80'>
            Assign up to two approvers
          </span>
        </ModalHeader>
        <ModalBody>
          <TimeOffApproverSelection
            onUpdate={onUpdate}
            isAdmin={isAdmin}
            formId={approverFormId}
          />
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='light' outline onClick={toggle}>
            Cancel
          </Button>
          <Button type='submit' formId={approverFormId}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function TimeOffApproverSelection({ onUpdate, isAdmin, formId }) {
  const contract = useSelector((state) => state.Contract?.details)

  const getApprovers = useApproversList({ isAdmin })

  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const { startFetch: updateContractApprover, isLoading: isUpdatingApprovers } =
    useFetch({
      action: isDeContract ? updateDEContract : updateContract,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Something went wrong')
        } else {
          toastr.success('Approver updated successfully')
          onUpdate()
        }
      },
      onError: (err) => {
        toastr.error(err || 'Something went wrong')
      },
    })

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      approver_id: contract?.approver?.id,
      approver2_id: contract?.approver2?.id,
    },
  })

  const approverId = watch('approver_id')
  const approver2Id = watch('approver2_id')

  function onSubmit(values) {
    const body = {
      ...values,
      contract_id: contract?.id,
    }

    if (!body?.approver2_id) {
      body.approver2_id_removed = 1
    }

    updateContractApprover(body)
  }

  return (
    <form
      className='tw-flex tw-flex-col tw-gap-4'
      onSubmit={handleSubmit(onSubmit)}
      id={formId}
    >
      <ControlledSelect
        label='1st Approver'
        control={control}
        name='approver_id'
        isDisabled={isAdmin || isUpdatingApprovers}
        options={getApprovers(approver2Id)}
      />

      <ControlledSelect
        label='2nd Approver'
        control={control}
        name='approver2_id'
        isDisabled={isAdmin || isUpdatingApprovers}
        options={getApprovers(approverId)}
        isClearable
        clearIcon={<TrashSimple size={18} className='tw-text-systemRed-100' />}
      />
    </form>
  )
}

function mapUserToOption(user, currentUser) {
  const fullName = getFullName(user)
  const label =
    (fullName?.length > 0 ? fullName : user.email) +
    (currentUser?.id === user?.id ? ' (You)' : '')

  return {
    label,
    value: user.id,
    photo: user?.photo,
    icon: (
      <Avatar
        photo={user?.photo}
        name={fullName || user?.email}
        size='sm'
        className='tw-mr-2'
      />
    ),
  }
}

function useApproversList({ isAdmin = false } = {}) {
  const currentUser = useSelector((state) => state.Account?.user)

  const { data: users, isLoading } = useFetch({
    action: getCompanyApprovers,
    autoFetch: !isAdmin,
    onError: (err) => {
      toastr.error(err)
    },
  })

  function getApprovers(value) {
    if (!users || users?.length <= 0 || isLoading || isAdmin) {
      return []
    }

    const allUsers = users?.map((user) => mapUserToOption(user, currentUser))

    if (!value) {
      return allUsers
    }

    return allUsers?.filter(({ value: uVal }) => uVal !== value)
  }

  return getApprovers
}

function UsdSwiftMessagePopup({ onUpdate, canManage }) {
  const contract = useSelector((state) => state.Contract?.details)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [feeCovered, setFeeCovered] = useState(contract?.fee_covered === 1)

  useEffect(() => {
    setFeeCovered(contract?.fee_covered === 1)
  }, [contract])

  const showUsdSwiftMessage = contract?.currency?.code === 'USD'
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const update = useFetch({
    action: isDeContract ? updateDEContract : updateContract,
    onComplete: onUpdate,
  })

  function handleFeeCoveredChange(e) {
    const isCheck = e.target.checked

    if (showUsdSwiftMessage && isCheck) {
      openModal()
    } else {
      toggleFee(isCheck)
    }
  }

  function toggleFee(isCheck) {
    setFeeCovered(isCheck)
    if (!update.isLoading) {
      update.startFetch({ contract_id: contract?.id, fee_covered: isCheck })
    }
  }

  function handleToggleFee() {
    toggleFee(true)
    closeModal()
  }

  function closeModal() {
    setIsModalOpen(false)
  }
  function openModal() {
    setIsModalOpen(true)
  }
  function toggleModal() {
    setIsModalOpen((o) => !o)
  }

  const disabled = !canManage || update.isLoading

  return (
    <>
      <PermissionTooltip
        showing={!canManage}
        id='take-in-charge-toggle'
        area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
        className='d-inline-flex'
      >
        <label
          className={cx(
            'align-items-center d-flex mb-0',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          )}
        >
          <Toggle
            check={feeCovered}
            change={handleFeeCoveredChange}
            disabled={disabled}
          />
          <span>Client will take in-charge the transfer fees.</span>
        </label>
      </PermissionTooltip>

      <UsdSwiftMessage
        showMessage={showUsdSwiftMessage && feeCovered}
        className='!tw-mt-2'
      />

      <Modal
        style={{ maxWidth: 527 }}
        isOpen={isModalOpen}
        toggle={toggleModal}
        centered
      >
        <ModalBody className='px-4 py-5'>
          <img
            src={warningIllustration}
            alt=''
            style={{ width: 160 }}
            className='d-block mx-auto mb-2'
          />
          <div className='h2 text-gray-h text-center rp-font-light mb-4'>
            Important Note
          </div>
          <p className='font-size-16 mb-0 text-gray-h'>
            <UsdSwiftMessageContent />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            outline
            style={{ minWidth: 124 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type='button'
            style={{ minWidth: 144 }}
            onClick={handleToggleFee}
          >
            I agree
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function PaymentApprovalSetting({
  isAdmin,
  canManage,
  contract,
  onUpdate,
  contractLoading,
}) {
  const approvalFlow = contract?.approval_flow
  const nameList = approvalFlow?.steps?.map((step) => step?.user_name) ?? []

  return (
    <div className='px-3 pb-3 px-md-4 pb-md-4 mb-3 mb-md-4 tw-border-b tw-border-surface-30'>
      <div className='tw-mb-4 tw-flex tw-items-center tw-justify-between tw-gap-4'>
        <div>
          <SettingSectionHeading>Approvals</SettingSectionHeading>
          <SettingSectionSubHeading className='tw-mb-0'>
            The assigned logic will be applied when an item is submitted for
            approval
          </SettingSectionSubHeading>
        </div>

        {contractLoading ? null : (
          <ManageContractPaymentApproval
            contract={contract}
            onUpdate={onUpdate}
            canManage={canManage}
          />
        )}
      </div>
      <div
        className={cx(
          'tw-grid tw-items-start tw-gap-4',
          !approvalFlow?.id ? null : 'md:tw-grid-cols-2',
        )}
      >
        {contractLoading ? (
          <Box className='tw-flex tw-animate-pulse tw-items-center tw-gap-3'>
            <BoxIcon>
              <div className='tw-flex tw-items-center tw-gap-4 tw-p-4' />
            </BoxIcon>

            <div className='tw-flex tw-grow tw-flex-wrap tw-items-center tw-gap-2 tw-rounded tw-bg-surface-20 tw-px-4 tw-py-4' />
          </Box>
        ) : !approvalFlow?.id ? (
          <TimeOffSummary
            canManage={canManage}
            isAdmin={isAdmin}
            contract={contract}
            onUpdate={onUpdate}
            approvalFlow={approvalFlow}
          />
        ) : (
          <>
            <ApprovalFlowSummary
              icon={
                !approvalFlow?.id ? (
                  <UserCircle size={24} />
                ) : (
                  <FlowArrow size={24} className='tw-fill-primary-100' />
                )
              }
              iconClassName={!!approvalFlow?.id && '!tw-bg-primary-20'}
              title={approvalFlow?.name || 'Default flow - 1 level flow'}
              description='Sequence for Expenses, Work Submission, Invoices & Payments approvals'
              footer={<ApprovalFlowNameList nameList={nameList} />}
            />

            <TimeOffSummary
              canManage={canManage}
              isAdmin={isAdmin}
              contract={contract}
              onUpdate={onUpdate}
              approvalFlow={approvalFlow}
            />
          </>
        )}
      </div>
    </div>
  )
}

function TimeOffSummary({
  isAdmin,
  contract,
  onUpdate,
  approvalFlow,
  canManage,
}) {
  return (
    <ApprovalFlowSummary
      icon={
        approvalFlow?.id ? (
          <HourglassHigh size={24} className='tw-fill-primary-100' />
        ) : (
          <CheckCircle size={24} className='tw-fill-primary-100' />
        )
      }
      iconClassName='!tw-bg-primary-20'
      title={approvalFlow?.id ? 'Time Off' : 'Payments & Time Off'}
      description={
        approvalFlow?.id
          ? 'Any of the assigned users can approve Time Off requests'
          : 'Any of the assigned users can approve Expenses, Work Submissions & Time Off'
      }
      footerClassName='tw-justify-between !tw-py-4 !tw-pr-4'
      footer={
        <>
          <span>
            {[contract?.approver, contract?.approver2]
              .filter(Boolean)
              .map(getFullName)
              .join(' and ')}
          </span>

          <TimeOffApproverModal
            onUpdate={onUpdate}
            isAdmin={isAdmin}
            canManage={canManage}
          />
        </>
      }
    />
  )
}

const defaultFlow = {
  label: 'Default flow - 1 level flow',
  id: 'default-flow',
  value: 'default-flow',
  description: 'Any of the assigned users can approve',
}

function ManageContractPaymentApproval({ contract, onUpdate, canManage }) {
  const [selectedFlow, setSelectedFlow] = useState(
    String(contract?.approval_flow?.id ?? defaultFlow.value),
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  function toggle() {
    setIsModalOpen((open) => !open)
  }

  const { data: approvalFlows, isLoading: loadingApprovalFlows } = useFetch({
    action: getApprovalFlows,
    autoFetch: true,
  })

  const flowOptions =
    approvalFlows?.map((flow) => {
      return {
        id: flow.id,
        label: flow.name,
        description: `${flow.steps.length}-level sequential approval flow`,
        value: String(flow.id),
      }
    }) ?? []

  flowOptions.unshift(defaultFlow)
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
  const user = useSelector((state) => state.Account?.user)

  const {
    startFetch: updateApprovalFlow,
    isLoading: isUpdatingContractApprovalFlow,
  } = useFetch({
    action: isDeContract ? updateDEContract : updateContract,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
      } else {
        const flow = approvalFlows.find(
          (flow) => flow.id === Number(selectedFlow),
        )
        onUpdate?.()
        toggle?.()
        track(APPROVAL_FLOW_EVENTS.ASSIGNED_FLOW, {
          user_id: user.id,
          type: 'Contractor',
          approval_flow_id: flow?.id,
          approval_flow_name: flow?.name,
          contractor_id: contract?.contractor?.id,
          contractor_name: contract?.contractor?.full_name,
          is_default: selectedFlow === 'default-flow' ? 1 : 0,
        })
      }
    },
    onError: (err) => {
      toastr.error(err || 'Something went wrong')
    },
  })

  const actionsDisabled = loadingApprovalFlows || isUpdatingContractApprovalFlow

  function handleSave() {
    const newId =
      selectedFlow === defaultFlow.value ? false : Number(selectedFlow)

    if (!contract?.id) {
      return
    }

    updateApprovalFlow({ contract_id: contract?.id, approval_flow_id: newId })
  }

  return (
    <>
      <PermissionTooltip
        showing={!canManage}
        id='manage-approval-btn'
        area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
      >
        <Button
          className='px-0'
          color='link'
          onClick={toggle}
          disabled={!canManage}
        >
          Manage
        </Button>
      </PermissionTooltip>

      <Modal isOpen={isModalOpen} toggle={toggle}>
        <ModalHeader
          tag='div'
          close={<ModalCloseButton toggle={toggle} size={24} />}
          className='tw-gap-5 !tw-p-5'
        >
          <h5 className='tw-text-xl tw-text-secondary-120'>Payment approval</h5>
          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
            Assign an approval flow for Expenses, Work Submissions, Invoices,
            and Payments
          </p>
        </ModalHeader>
        <ModalBody className='!tw-p-5'>
          {loadingApprovalFlows ? (
            <LoadingFlowSteps length={3} className='tw-mb-4' />
          ) : (
            <CustomRadioGroup
              options={flowOptions}
              name='approval_flow'
              className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'
            >
              {(option) => {
                return (
                  <Box
                    tag='label'
                    key={option.id}
                    className='tw-group tw-mb-0 tw-flex tw-cursor-pointer tw-items-center tw-gap-4 tw-ring-1 tw-ring-transparent tw-transition-shadow hover:tw-ring-surface-30 has-[:checked]:tw-border-primary-100 has-[:checked]:tw-bg-primary-10 has-[:checked]:tw-ring-primary-100'
                  >
                    <BoxIcon
                      className={
                        option.id !== 'default-flow' && '!tw-bg-primary-20'
                      }
                    >
                      {option.id === 'default-flow' ? (
                        <UserCircle size={24} />
                      ) : (
                        <FlowArrow size={24} className='tw-fill-primary-100' />
                      )}
                    </BoxIcon>

                    <div>
                      <p className='tw-mb-0 tw-flex tw-items-center tw-gap-1 tw-break-all tw-font-bold tw-text-black group-has-[:checked]:tw-text-primary-100'>
                        {option.label}
                      </p>
                      {!option.description ? null : (
                        <p className='tw-mb-0 tw-mt-0.5 tw-text-secondary-100 group-has-[:checked]:tw-text-primary-100'>
                          {option.description}
                        </p>
                      )}
                    </div>

                    <input
                      type='radio'
                      name='approval_flow'
                      id={option.id}
                      value={option.value}
                      className='tw-ml-auto tw-h-5 tw-w-5 tw-shrink-0 tw-accent-primary-100 focus:tw-outline-none [&:not(:checked)]:tw-opacity-30'
                      checked={selectedFlow === option.value}
                      onChange={(e) => setSelectedFlow(e.target.value)}
                    />
                  </Box>
                )
              }}
            </CustomRadioGroup>
          )}

          <Alert
            color='danger'
            className='!tw-mb-0 !tw-border-surface-30 !tw-bg-systemRed-20 !tw-p-6 !tw-text-black'
            iconClassName='!tw-text-systemRed-100'
          >
            Assigning a different flow will reset the status of items that are
            not-yet-approved within this contract to the initial stage of
            approval.
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            outline
            onClick={toggle}
            disabled={actionsDisabled}
          >
            Cancel
          </Button>
          <Button
            type='button'
            onClick={handleSave}
            disabled={actionsDisabled}
            loading={isUpdatingContractApprovalFlow}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function CustomRadioGroup({ children, options, className }) {
  return (
    <div className={cx(className)}>
      {options.map((option, index) => {
        return children(option, index)
      })}
    </div>
  )
}
