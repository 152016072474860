import { useFetch } from '../../../../../helpers/hooks'
import toastr from 'toastr'
import { getAutomationDetail } from '../../../../../services/api-automations'

export function useAutomationDetail({ id }) {
  return useFetch(
    {
      action: getAutomationDetail,
      autoFetch: !!id,
      body: { id },
      isOpenApi: true,
      onError: (err) => toastr.error(err),
    },
    [id],
  )
}
