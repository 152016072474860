import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowLeft, Warning } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Panel } from 'reactflow'
import toastr from 'toastr'
import * as yup from 'yup'

import { Avatar, cn } from 'ui'
import ControlledSelect from '../../components/ControlledSelect'
import SearchBar from '../../components/SearchBar'
import Alert from '../../components/ui/alert'
import Button from '../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { updateOrgChartContract } from '../../services/api'
import {
  getDepartmentsList,
  getValidManagers,
} from '../../services/api-org-charts'
import { ConfirmFormField } from '../Contract/CreateContract/components/confirm-field'
import { DepartmentColor } from './department-colors'
import { UserProfile } from './organization-chart'
import permissions from '../../helpers/permissions'

export function MissingManagers({ companyChartData, refreshData }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggleSidebar() {
    setIsOpen((open) => !open)
  }

  const missingManagerCount =
    companyChartData?.contracts_missing_managers?.length
  const showMissingManagers = missingManagerCount > 0

  if (!showMissingManagers) {
    return null
  }

  return (
    <Panel position='top-right' className='!tw-top-[calc(27px+25px)] tw-z-10'>
      <Alert
        customIcon={<Warning size={24} className='tw-text-systemGold-100' />}
        className='!tw-mb-0 tw-flex-col !tw-border-surface-30 !tw-bg-systemGold-20 !tw-p-6'
        iconClassName='tw-block'
        innerTag='div'
        color='warning'
      >
        <h5 className='tw-mb-0 tw-text-sm tw-font-bold tw-text-black'>
          Missing managers
        </h5>
        <p className='tw-mb-0 tw-max-w-48 tw-text-sm tw-font-medium tw-text-black'>
          {missingManagerCount} member
          {missingManagerCount === 1 ? '' : 's'} don’t have a manager assigned.
        </p>

        <Button
          type='button'
          color='link'
          className='tw-mt-4 !tw-p-0 !tw-text-secondary-100'
          onClick={toggleSidebar}
        >
          View
        </Button>
      </Alert>

      {!isOpen ? null : (
        <ManageMissingManagers
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          companyChartData={companyChartData}
          onSuccess={refreshData}
        />
      )}
    </Panel>
  )
}

const missingManagersFormId = 'missingManagersForm'
export function ManageMissingManagers({
  isOpen,
  toggleSidebar,
  companyChartData,
  onSuccess,
  defaultValues = { contractId: null, managerId: null, departmentId: null },
}) {
  const [search, setSearch] = useState('')
  const { hasAccess } = usePermissions()
  const formDisabled = !hasAccess([permissions.ManageOrgChart])

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        contractId: yup.number().required(),
        managerId: yup.number().required(),
        departmentId: yup.number().notRequired(),
        isHead: yup.string().notRequired(),
      }),
    ),
  })
  const selectedContractId = useWatch({ control, name: 'contractId' })

  const filteredContracts = companyChartData.contracts_missing_managers.filter(
    (contract) => {
      return contract.name.toLowerCase().includes(search.toLowerCase())
    },
  )

  // When passing default values, we assume that the sidebar is opened for editing
  const isEditing = !!defaultValues.contractId

  const selectedContract = isEditing
    ? companyChartData.all_contracts.find(
        (contract) => contract.id === defaultValues.contractId,
      )
    : !selectedContractId
      ? null
      : filteredContracts.find((contract) => contract.id === selectedContractId)

  const { startFetch: updateManager, isLoading: isUpdatingManager } = useFetch({
    action: updateOrgChartContract,
    onComplete: (data, body) => {
      if (body.department_id === null) {
        toastr.success('Manager unassigned successfully')
      } else if (data?.success === false) {
        toastr.error('Failed to assign manager')
      } else {
        toastr.success('Manager assigned successfully')
        toggleSidebar()
        onSuccess?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function onSubmit(data) {
    const body = {
      contract_id: data.contractId,
      manager_contract_id: data.managerId,
      department_id: data.departmentId || undefined,
      is_head_of_department: data.isHead === 'yes',
    }
    updateManager(body)
  }

  function unAssignManager() {
    const body = {
      contract_id: selectedContract.id,
      manager_contract_id: null,
    }
    updateManager(body)
  }

  return (
    <SideMenu
      className='!tw-w-full tw-max-w-[480px]'
      itemListClassName={cn(
        'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
        selectedContract
          ? 'tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
          : 'tw-grid-rows-[minmax(auto,max-content)_1fr]',
      )}
      isOpen={isOpen}
      onClose={toggleSidebar}
    >
      <SideMenuHeader toggle={toggleSidebar} className='tw-items-start'>
        <div className='tw-flex-grow'>
          <div className='tw-flex tw-items-start tw-gap-2'>
            {!selectedContract || isEditing ? null : (
              <Button
                color='link'
                size='sm'
                className='!tw-p-0.5 !tw-text-secondary-100'
                type='button'
                onClick={() => {
                  setValue('contractId', null)
                  setValue('managerId', null)
                  setValue('departmentId', null)
                }}
                icon={
                  <ArrowLeft
                    size={24}
                    className='tw-flex-shrink-0'
                    weight='bold'
                  />
                }
              />
            )}
            <div className='tw-flex tw-flex-col'>
              <div className='tw-font-semibold'>
                {isEditing ? 'Edit manager assignment' : 'Assign a manager'}
              </div>
              <span className='tw-text-sm tw-text-text-60'>
                {isEditing
                  ? 'Change the direct manager for this member'
                  : 'The members below are without a manager'}
              </span>
            </div>
          </div>

          {!selectedContract ? null : (
            <div className='tw-mt-6'>
              <UserProfile
                data={{
                  name: selectedContract.name,
                  role: isEditing
                    ? `#${selectedContract?.contract_ref}`
                    : selectedContract.title,
                  flag: selectedContract.flag,
                  photo: selectedContract.photo,
                  handless: true,
                  className: 'tw-w-auto',
                }}
              />
            </div>
          )}
        </div>
      </SideMenuHeader>

      <SideMenuBody className='tw-flex-grow !tw-px-0'>
        <form onSubmit={handleSubmit(onSubmit)} id={missingManagersFormId}>
          {selectedContract ? (
            <div className='tw-px-6'>
              <ManageOrgChartFields
                control={control}
                errors={errors}
                disabled={formDisabled}
                contractId={selectedContractId}
              />
            </div>
          ) : (
            <>
              <div className='tw-px-6'>
                <SearchBar query={search} onQueryChanged={setSearch} />
              </div>

              <ControlledContractSelections
                control={control}
                name='contractId'
                disabled={formDisabled}
                options={filteredContracts}
              />
            </>
          )}
        </form>
      </SideMenuBody>

      {!selectedContract ? null : (
        <SideMenuFooter>
          <div className='tw-flex tw-w-full tw-grow tw-items-center tw-justify-between'>
            <div>
              {selectedContract?.parent_id === null ? null : (
                <Button
                  type='button'
                  color='transparent'
                  textClassName='tw-text-red'
                  onClick={unAssignManager}
                  disabled={isUpdatingManager}
                >
                  Unassign
                </Button>
              )}
            </div>
            <div className='tw-flex tw-items-center tw-gap-3'>
              <Button
                type='button'
                color='light'
                outline
                onClick={toggleSidebar}
                disabled={isUpdatingManager}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                formId={missingManagersFormId}
                loading={isUpdatingManager}
                disabled={isUpdatingManager}
              >
                Save
              </Button>
            </div>
          </div>
        </SideMenuFooter>
      )}
    </SideMenu>
  )
}

export function ControlledContractSelections({
  control,
  name,
  options,
  disabled: isDisabled,
  isSearch = false,
  findNode,
}) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isDisabled}
      render={({ field: { onChange, disabled } }) => (
        <div className='tw-mt-6 tw-flex tw-flex-col tw-gap-4 tw-px-6'>
          {options.map((contract) => {
            return (
              <div
                key={contract.id}
                onClick={() => (disabled ? null : onChange(contract.id))}
                className={cn(
                  'tw-group tw-relative tw-rounded tw-transition-colors',
                  disabled
                    ? 'tw-cursor-default'
                    : 'tw-cursor-pointer hover:tw-bg-surface-10 [&>*]:tw-bg-transparent',
                )}
              >
                <UserProfile
                  data={{
                    name: contract.name,
                    role: contract.title,
                    flag: contract.flag,
                    photo: contract.photo,
                    handless: true,
                    className: 'tw-w-full',
                  }}
                />

                <Button
                  className='tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2 !tw-text-secondary-100 !tw-transition-all md:tw-opacity-0 md:group-hover:tw-opacity-100'
                  size='sm'
                  color='link'
                  onClick={(e) => {
                    if (isSearch) {
                      e.stopPropagation()
                      findNode?.(contract.id)
                    }
                  }}
                >
                  {isSearch ? 'Find' : 'Assign'}
                </Button>
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

export function ManageOrgChartFields({
  control,
  errors,
  contractId,
  disabled,
}) {
  const { data: departmentsList, isLoading: departmentsLoading } = useFetch({
    action: getDepartmentsList,
    autoFetch: true,
  })

  const { data: validManagers, isLoading: managersLoading } = useFetch(
    {
      action: getValidManagers,
      autoFetch: true,
      body: { for_contract: contractId },
    },
    [contractId],
  )

  const managerOptions = validManagers
    ?.filter((manager) => manager.id !== contractId)
    ?.map((manager) => {
      return {
        label: manager?.name,
        description: manager?.title,
        value: manager.id,
        icon: (
          <Avatar
            photo={manager?.photo}
            name={manager?.name || manager?.email}
            size='sm'
            className='tw-mr-2'
          />
        ),
      }
    })

  const departmentId = useWatch({ control, name: 'departmentId' })

  return (
    <>
      <ControlledSelect
        control={control}
        name='managerId'
        placeholder='Select direct manager'
        label='Direct Manager'
        options={managerOptions}
        isLoading={managersLoading}
        isDisabled={managersLoading || disabled}
      />

      <ControlledSelect
        control={control}
        name='departmentId'
        placeholder='Select department (Optional)'
        label='Department'
        options={departmentsList?.map(({ id, name, color }) => {
          return {
            label: name,
            value: id,
            icon: <DepartmentColor color={color} />,
          }
        })}
        isLoading={departmentsLoading}
        isDisabled={departmentsLoading || disabled}
        wrapperClassName='tw-mt-4'
      />

      {!departmentId ? null : (
        <ConfirmFormField
          control={control}
          error={errors.isHead}
          name='isHead'
          defaultValue='no'
          title='Are they head of the department?'
          fieldOptions={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ]}
          className='tw-mt-4'
          disabled={disabled}
        />
      )}
    </>
  )
}
