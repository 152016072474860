import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios'
import { createBrowserHistory } from 'history'
import React, { useLayoutEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  Route as ReactRouterRoute,
  Redirect,
  Router,
  Switch,
} from 'react-router-dom'

// layouts Format
import HorizontalLayout from './components/HorizontalLayout'
import ModalLayout from './components/ModalLayout'
import NonAuthLayout from './components/NonAuthLayout'
import VerticalLayout from './components/VerticalLayout'
import FEATURE_FLAGS from './config/feature-flags'
import fakeBackend from './helpers/AuthType/fakeBackend'
import NotFoundPage from './pages/404'
import AdminLayout from './pages/AdminPanel/components/VerticalLayout'

// Import Routes all
import {
  adminPanelModalRoutes,
  adminPanelRoutes,
  anonymousRoutes,
  authAdminRoutes,
  authRoutes,
  modalRoutes,
  ReactNativeRoutes,
  userRoutes,
} from './routes/allRoutes'
// Import all middleware
import AdminMiddleware from './routes/middleware/AdminMiddleware'
import AuthMiddleware from './routes/middleware/auth-middleware'

import { getStaticData } from './services/api'
import { updateStaticData } from './store/layout/actions'

// Import scss
import './assets/scss/theme.scss'

import { isProduction } from './utils/isProduction'

const queryClient = new QueryClient()
export const history = createBrowserHistory()

// Create Custom Sentry Route component
export const SentryRoute = FEATURE_FLAGS.SENTRY_LOGGING
  ? ReactRouterRoute
  : Sentry.withSentryRouting(ReactRouterRoute)

// Activating fake backend
if (!isProduction()) {
  fakeBackend()
}

const sentryDsn =
  'https://2393bb139949477da8ae55f009b2049e@o4503976654733312.ingest.sentry.io/4504022871900160'

if (FEATURE_FLAGS.SENTRY_LOGGING) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    tracesSampleRate: 0.1,

    allowUrls: [
      /https?:\/\/((app|sandbox)\.)?remotepass\.com/, // matches app.remotepass.com and sandbox.remotepass.com
    ],
  })
}

const NonAuthMiddleware = ({ component: Component, layout: Layout }) => {
  const Account = useSelector((state: { Account }) => state.Account)
  const { loggedIn } = Account

  return (
    <SentryRoute
      render={(props) => {
        if (loggedIn && props.location.pathname !== '/admin/login') {
          return (
            <Redirect
              to={{ pathname: '/activity', state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

const App = (props) => {
  const dispatch = useDispatch()
  const loginToken = useSelector((state: { Login }) => state.Login?.loginToken)

  useLayoutEffect(() => {
    let cancel

    getStaticData(loginToken, null, {
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((r) => {
        if (r.data?.success) {
          dispatch(updateStaticData(r.data?.data))
        }
      })
      .catch((e) => {
        if (e.message !== 'canceled') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
      })

    return () => {
      if (cancel) cancel()
    }
  }, [dispatch, loginToken])

  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <Switch>
            {authRoutes.map((route, idx) => (
              <NonAuthMiddleware layout={NonAuthLayout} {...route} key={idx} />
            ))}

            {authAdminRoutes.map((route, idx) => (
              <NonAuthMiddleware layout={NonAuthLayout} {...route} key={idx} />
            ))}

            {adminPanelRoutes.map((route, idx) => (
              <AdminMiddleware layout={AdminLayout} {...route} key={idx} />
            ))}
            {adminPanelModalRoutes.map((route, idx) => (
              <AdminMiddleware {...route} layout={ModalLayout} key={idx} />
            ))}

            {userRoutes.map((route, idx) => (
              <AuthMiddleware {...route} layout={Layout} key={idx} />
            ))}
            {modalRoutes.map((route, idx) => (
              <AuthMiddleware {...route} layout={ModalLayout} key={idx} />
            ))}

            {ReactNativeRoutes.map((route, idx) => (
              <SentryRoute {...route} key={idx} />
            ))}

            {anonymousRoutes.map((route, idx) => (
              <SentryRoute {...route} key={idx} />
            ))}

            <SentryRoute render={() => <NotFoundPage />} />
          </Switch>
        </Router>
      </QueryClientProvider>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
