import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'

import {
  PasswordChecklistComp,
  validatePassword,
} from '../../components/CommonForBoth/ChangePassword'
import TextInput from '../../components/Forms/TextInput'
import Footer from '../../components/VerticalLayout/Footer'
import Button from '../../components/ui/button'
import { HeaderAnonym } from '../../components/ui/header-anonym'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import {
  checkRegisterToken,
  checkTokenUpdatePass,
  confirmInvitation,
  signupEmployee,
} from '../../services/api'
import {
  OTPSuccessful,
  registerUserSuccessful,
  toggleLogin,
} from '../../store/auth/register/actions'

const Invite = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [password, setPassword] = useState('')
  const token = new URLSearchParams(props.location.search).get('token')
  const email = new URLSearchParams(props.location.search).get('email')
  const typeP = new URLSearchParams(props.location.search).get('type')

  useEffect(() => {
    if (token && typeP) {
      checkRegisterToken(token)
        .then((r) => {
          if (r.data?.success) {
            if (!r?.data?.data.is_valid) {
              history.push('/activity')
            }
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
    }
  }, [])

  function handleValidSubmit(event, values) {
    if (values['confirm-password'] === values.password) {
      if (['employee', 'contractor'].includes(typeP)) {
        dispatch(OTPSuccessful({ token }))
        signUp.startFetch(
          { email, password: values.password },
          true,
          null,
          token,
        )
      } else {
        const body = {
          token: new URLSearchParams(props.location.search).get('token'),
          ...values,
        }
        delete body['confirm-password']
        confirmInvitation(body).then((r) => {
          if (r.data.success) {
            props.history.push('/login')
          } else {
            toastr.error(r.data.message, 'Error')
          }
        })
      }
    } else {
      toastr.error('invalid password', 'Error')
    }
  }

  const check = useFetch({
    action: checkTokenUpdatePass,
    autoFetch: !['employee', 'contractor'].includes(typeP),
    body: { token: new URLSearchParams(props.location.search).get('token') },
    onError: () => {
      props.history.push('/login')
    },
  })

  const signUp = useFetch({
    action: signupEmployee,
    autoFetch: false,
    onComplete: (data) => {
      dispatch(registerUserSuccessful(data))
      dispatch(toggleLogin(true))
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  return (
    <div>
      <HeaderAnonym />

      {check.isLoading ? (
        <Loader minHeight='calc(100vh - var(--header-height-anonym))' />
      ) : (
        <Container className='blur-image' fluid>
          <Row
            style={{ minHeight: 'calc(100vh - var(--header-height-anonym))' }}
            className='align-content-center justify-content-center'
          >
            <Col
              sm={10}
              md={7}
              lg={5}
              className='my-5'
              style={{ maxWidth: 500 }}
            >
              <Card>
                <CardBody className='p-3 p-md-4'>
                  <AvForm
                    autocomplete='off'
                    className='form-horizontal'
                    onValidSubmit={(e, v) => {
                      const { isValid } = validatePassword(password)

                      if (isValid) {
                        handleValidSubmit(e, v)
                      } else {
                        toastr.error(
                          'Weak password easy to guess, password should respect checklist condition',
                        )
                      }
                    }}
                  >
                    {props.error && props.error ? (
                      <Alert color='danger'>{props.error}</Alert>
                    ) : null}

                    <div className='form-group pb-3 mb-0'>
                      {!typeP && (
                        <>
                          <TextInput
                            label='First name'
                            name='first_name'
                            type='text'
                            className='form-control'
                            id='first-name'
                            required
                          />
                          <TextInput
                            label='Middle name'
                            name='middle_name'
                            type='text'
                            className='form-control'
                            id='middle-name'
                          />
                          <TextInput
                            required
                            name='last_name'
                            type='text'
                            className='form-control'
                            label='Last name'
                            id='last-name'
                          />
                        </>
                      )}

                      <TextInput
                        name='confirm-password'
                        label='Password'
                        type='password'
                        required
                        placeholder='Enter Password'
                        id='passWord'
                        onChange={(e) => setPassword(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Password is required',
                          },
                        }}
                      />
                      <PasswordChecklistComp
                        password={password}
                        TooltipId='passWord'
                      />

                      <TextInput
                        name='password'
                        label='Confirm Password'
                        type='password'
                        className='pb-3'
                        required
                        placeholder='Confirm Password'
                        validate={{
                          match: {
                            value: 'confirm-password',
                            errorMessage: 'Value must match with Password',
                          },
                          required: {
                            value: true,
                            errorMessage: 'Confirm Password is required',
                          },
                        }}
                      />
                    </div>

                    <Button
                      text='Create Account'
                      style={{ height: 48, width: '100%' }}
                      className='btn btn-primary btn-block waves-effect waves-light'
                      type='submit'
                      block
                    />
                  </AvForm>
                </CardBody>
              </Card>

              <Footer className='text-white text-center' />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default withRouter(Invite)
