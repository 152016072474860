import { useFetch } from '../../../../../helpers/hooks'
import { getAutomationTitle } from '../../../../../services/api-automations'
import { defaultValue } from '../tools/constants'

export function useAutomationDefaultValues({ enabled = false }) {
  const { data, isLoading } = useFetch({
    action: getAutomationTitle,
    isOpenApi: true,
    autoFetch: enabled,
    body: {
      params: {
        name: defaultValue.name,
      },
    },
  })

  return {
    values: {
      ...defaultValue,
      name: data ?? defaultValue.name,
    },
    isLoading,
  }
}
