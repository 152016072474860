import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, getConfig } from './api'

export function requestBillFeature(token) {
  return api.post('/bill/enablement_request', null, getConfig(token))
}

/**
 *
 * @param {string} token - The user session token
 * @param {Object} data - The endpoint data
 * @param {string} data.name - The vendor name
 * @param {string} data.address - The vendor address
 * @param {string} data.city - The vendor city
 * @param {number} data.country_id - The vendor country id
 * @param {string} data.zip_code - The vendor zip code
 * @param {number} data.category_id - The vendor category id
 * @param {string} data.email - The vendor email
 */
export function createVendor(token, data) {
  return api.post('/bill/vendor', data, getConfig(token))
}

/**
 *
 * @param {string} token - The user session token
 * @param {Object} data - The endpoint data
 */
export function createVendorBankAccount(token, data) {
  const { vendorId, ...body } = data

  return api.post(
    `/bill/vendor/${vendorId}/bank_account`,
    body,
    getConfig(token),
  )
}

export function disableVendorBankAccount(token, data) {
  const { bankAccount } = data

  return api.put(
    `/bill/vendor/bank_account/${bankAccount}/disable`,
    {},
    getConfig(token),
  )
}

export function getVendors(token, data, extra) {
  const sp = searchParamsFromObject(data)
  return api.get('bill/vendor' + sp, getConfig(token, extra))
}

export function getBills(token, data, extra) {
  const sp = searchParamsFromObject(data)
  return api.get('bill' + sp, getConfig(token, extra))
}

export function getBill(token, data, extra) {
  return api.get(`bill/${data.id}`, getConfig(token, extra))
}

export function updateVendor(token, data) {
  const { id, ...body } = data
  return api.put(`bill/vendor/${id}`, body, getConfig(token))
}

/**
 *
 * @param {string} token - The user session token
 * @param {Object} data - The endpoint data
 * @param {string} data.name - The bill name
 * @param {number} data.vendor_id - The vendor id
 * @param {number} data.category_id - The bill category id
 * @param {string} data.issue_date - The bill issue date
 * @param {string} data.due_date - The bill due date
 * @param {string} data.currency_id - The bill currency id
 * @param {number} data.amount - The bill total amount
 * @param {Object[]} data.items - The bill invoice items
 * @param {string} data.items.description - The bill invoice item: Description
 * @param {number} data.items.amount - The bill invoice item: Amount
 * @param {number} data.items.category_id - The bill invoice item: Category id
 * @param {string} data.note - The bill note (optional)
 * @param {string} data.file - The bill invoice file path (optional)
 */
export function createBill(token, data) {
  return api.post('/bill', data, getConfig(token))
}

export function updateBill(token, data) {
  const { id, ...body } = data

  return api.put(`/bill/${id}`, body, getConfig(token))
}

export function deleteVendor(token, data) {
  return api.delete(`bill/vendor/${data.id}`, getConfig(token))
}

export function deleteBill(token, data) {
  return api.delete(`/bill/${data.id}`, getConfig(token))
}

export function approveBill(token, { id }) {
  return api.put(`bill/${id}/approve`, {}, getConfig(token))
}

export function assignBillApprovalFlow(token, data) {
  return api.put(`bill/approval_flow`, data, getConfig(token))
}

export function getDefaultBillApprovalFlow(token, _, extra) {
  return api.get('bill/approval_flow', getConfig(token, extra))
}

export function declineBill(token, { id, reason }) {
  return api.put(`bill/${id}/decline`, { comment: reason }, getConfig(token))
}

export function getBillCategories(token, _, extra) {
  return api.get('bill/integration/accounts', getConfig(token, extra))
}

export function assignVendorBillApprovalFlow(token, { vendorId, flowId }) {
  return api.put(
    `/bill/approval_flow/${vendorId}/${flowId}`,
    {},
    getConfig(token),
  )
}

export function getBillCount(token) {
  return api.get('bill/count', getConfig(token))
}
