import React from 'react'
import { useForm } from 'react-hook-form'

import ControlledInput from '../../../components/ControlledInput'
import Button from '../../../components/ui/button'
import ModalBase from './modal-base'

const questionsFormId = 'questionsFormId'

export default function ModalQuestions({
  isOpen,
  modalHeading = 'Eligibility questions',
  fields = [],
  toggle = () => {},
  isSubmitting,
  onSubmit,
}) {
  const { handleSubmit, control } = useForm()

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      heading={modalHeading}
      actions={
        <>
          <Button
            type='button'
            color='light'
            outline
            onClick={toggle}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            formId={questionsFormId}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Redeem
          </Button>
        </>
      }
    >
      <form id={questionsFormId} onSubmit={handleSubmit(onSubmit)}>
        {fields?.map((field) => {
          return (
            <ControlledInput
              key={field.name}
              control={control}
              name={field.name?.toString()}
              label={field.label}
            />
          )
        })}
      </form>
    </ModalBase>
  )
}
