import React from 'react'
import { useFormContext } from 'react-hook-form'
import { triggerFilters } from '../tools/constants'
import ControlledInput from '../../../../../components/ControlledInput'

export function TriggerFilter({ index }) {
  const { watch } = useFormContext()
  const filter = watch(`steps.${index}.event`)?.filter
  if (!filter) return null

  return (
    <div className='tw-flex tw-flex-row tw-gap-3.5'>
      <ControlledInput
        name={`steps.${index}.filters.${filter.key}`}
        type='number'
        wrapperClassName='tw-flex-1'
        placeholder={triggerFilters[filter.key]}
      />
    </div>
  )
}
