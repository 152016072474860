import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation'
import uniqueId from 'lodash/uniqueId'
import React, { useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import {
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import DropzoneInput from '../../../../components/Common/dropzone-input'
import customStyles from '../../../../components/Common/react-select-custom-styles'
import UploadPreview from '../../../../components/Common/upload-preview'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { parseInvoiceV2 } from '../../../../services/api'
import isNill from '../../../../utils/is-nill'
import invoiceImage from './../../../../assets/images/Invoice.png'

import './modal-override.css'
import { toBase64 } from '../../../../helpers/helper'

function CurrencyInputCustom({ onChange, ...props }) {
  return (
    <CurrencyInput
      // We don't want to pass onBlur and onFocus to the component
      {...{ ...props, onBlur: undefined, onFocus: undefined }}
      onValueChange={onChange}
    />
  )
}

export default function EorPartnerInvoiceModal({
  isOpen,
  toggle: _toggle,
  partner,
  isEditing,
  onConfirm,
  isConfirmLoading,
}) {
  const [selectedContract, setSelectedContract] = useState(null)
  const [attachment, setAttachment] = useState(null)
  const [categories, setCategories] = useState({})
  const [invoiceLines, setInvoiceLines] = useState([{ id: uniqueId() }])
  const [uiState, setUiState] = React.useState(UI_STATE.IDLE)
  const [preview, setPreview] = useState(null)
  const [formKey, setFormKey] = useState(uniqueId())

  function handleParsedInvoiceData(data) {
    setUiState(UI_STATE.ADDING_DETAILS)

    const entitiesData = getEntitiesData(data?.entities)
    setInvoiceLines(entitiesData.map((item) => ({ id: uniqueId(), ...item })))
  }

  const { startFetch: parseInvoice } = useFetch({
    action: parseInvoiceV2,
    withAdminAccess: true,
    onError: (err) => {
      toastr.error(err)
      setUiState(UI_STATE.IDLE)
    },
    onComplete: handleParsedInvoiceData,
  })

  const contractOptions = useMemo(() => {
    if (!isOpen || isEditing) {
      return []
    }
    return partner.milestone_contracts.map((c) => {
      return {
        ...c,
        value: c.contract_ref,
        label: `${c.contract_ref} - ${c.job_title}`,
      }
    })
  }, [isEditing, isOpen, partner?.milestone_contracts])

  function toggle() {
    _toggle()
    handleReset()
  }

  async function handleAcceptedFiles(acceptedFiles) {
    setUiState(UI_STATE.UPLOADING)
    const rawFile = acceptedFiles[0]
    setAttachment(rawFile)

    const base64File = await toBase64(rawFile)
    const b64 = base64File.split(',')[1]

    setPreview({ data: base64File, type: rawFile.type })
    parseInvoice({ file: b64 })
  }

  function handleReset() {
    setUiState(UI_STATE.IDLE)
    setCategories({})
    setInvoiceLines([{ id: uniqueId() }])
    setFormKey(uniqueId())
    setSelectedContract(null)
  }

  function handleCategoryChange(item, value) {
    setCategories((categories) => ({
      ...categories,
      [item?.id ?? item]: value,
    }))
  }

  function handleAddItem() {
    setInvoiceLines((invoiceLines) => [...invoiceLines, { id: uniqueId() }])
  }

  function handleDeleteItem(item) {
    setCategories((categories) => {
      delete categories[item.id]
      return { ...categories }
    })
    setInvoiceLines((invoiceLines) => {
      const newInvoiceLines = [...invoiceLines].filter(({ id }) => {
        return id !== item.id
      })
      return newInvoiceLines
    })
  }

  function handleValidSubmit(_, data) {
    const items = getItems(data, categories)

    const isCatsValid = items.every((item) => {
      return !!item?.classification
    })
    if (!isCatsValid) {
      toastr.error('Please select all categories')
      return
    }

    const body = {
      name: data?.invoice_name,
      contract_id: selectedContract?.contract_id,
      amount: Number(data?.invoice_amount?.replaceAll(',', '')),
      file: attachment,
      data: { items },
    }

    const formData = new FormData()
    Object.entries(body).forEach(([k, item]) => {
      if (!isNill(item) && !Number.isNaN(item)) {
        if (k === 'data') {
          formData.append(k, JSON.stringify(item))
        } else {
          formData.append(k, item)
        }
      }
    })

    onConfirm(formData)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='xl'>
      <ModalHeader toggle={toggle}>Add partner invoice</ModalHeader>
      <ModalBody>
        <div className='d-flex' style={{ gap: '2rem' }}>
          <div style={{ flexBasis: '35%', flexShrink: 0 }}>
            {uiState === UI_STATE.ADDING_DETAILS ? (
              <>
                <div className='d-flex' style={{ minHeight: 360 }}>
                  <UploadPreview preview={preview} />
                </div>

                <p className='font-size-10 mb-2 text-secondary'>
                  {attachment?.name}
                </p>

                <Button size='sm' outline onClick={handleReset}>
                  Reset
                </Button>
              </>
            ) : (
              <DropzoneInput
                className='d-flex justify-content-center align-items-center flex-column text-center w-full'
                style={{ minHeight: 245 }}
                onDropAccepted={handleAcceptedFiles}
                accept={{ 'application/pdf': ['.pdf'] }}
              >
                {uiState === UI_STATE.UPLOADING || uiState === UI_STATE.IDLE ? (
                  <div className='p-3 position-relative'>
                    <img
                      src={invoiceImage}
                      alt='invoice'
                      style={{ width: 48, height: 48 }}
                    />
                    {uiState !== UI_STATE.UPLOADING ? null : (
                      <Spinner
                        color='primary'
                        className='position-absolute'
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    )}
                  </div>
                ) : null}
                {uiState === UI_STATE.IDLE ? (
                  <>
                    <p className='mb-1 h5 text-body'>Drop your invoice here</p>
                    <p className='mb-0 text-size-14 text-secondary'>
                      Drag and drop your invoice pdf here, Or browse your
                      computer.
                    </p>
                  </>
                ) : null}
              </DropzoneInput>
            )}
          </div>
          <div className='w-100'>
            {isEditing ? null : (
              <FormGroup>
                <Label htmlFor='partner-contract'>Partner Contract:</Label>
                <Select
                  id='partner-contract'
                  name='partner-contract'
                  components={{ IndicatorSeparator: null }}
                  styles={customStyles}
                  value={selectedContract}
                  onChange={setSelectedContract}
                  options={contractOptions}
                />
              </FormGroup>
            )}
            {!isEditing && !selectedContract ? null : (
              <AvForm
                id={partnerInvoiceForm}
                key={formKey}
                onValidSubmit={handleValidSubmit}
              >
                {isEditing ? null : (
                  <>
                    <AvField
                      label='Name:'
                      name='invoice_name'
                      id='invoice_name'
                      placeholder='Invoice name'
                      autoComplete='off'
                      required
                    />
                    <AvGroup>
                      <Label htmlFor='invoice_amount'>Amount:</Label>
                      <InputGroup>
                        <AvInput
                          name='invoice_amount'
                          id='invoice_amount'
                          placeholder='Invoice amount'
                          className='form-control'
                          tag={CurrencyInputCustom}
                          autoComplete='off'
                          required
                          // currency input props
                          allowDecimals={true}
                          decimalsLimit={2}
                          decimalSeparator='.'
                          groupSeparator=','
                        />
                        <InputGroupText
                          className='border-left-0'
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                        >
                          {selectedContract?.currency?.code}
                        </InputGroupText>
                      </InputGroup>
                    </AvGroup>
                  </>
                )}
                <Container className='px-0'>
                  {invoiceLines.map((item) => {
                    return (
                      <InvoiceLineField
                        key={item.id}
                        item={item}
                        onCategoryChange={handleCategoryChange}
                        onDelete={handleDeleteItem}
                        showDelete={invoiceLines.length > 1}
                        currency={selectedContract?.currency}
                      />
                    )
                  })}
                </Container>
                <div className='d-flex justify-content-end'>
                  <Button outline size='sm' onClick={handleAddItem}>
                    + Add Line
                  </Button>
                </div>
              </AvForm>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={toggle}
          disabled={isConfirmLoading}
          color='light'
          outline
        >
          Cancel
        </Button>
        <Button
          disabled={isConfirmLoading}
          loading={isConfirmLoading}
          formId={partnerInvoiceForm}
        >
          Add invoice
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const UI_STATE = {
  IDLE: 'IDLE',
  UPLOADING: 'UPLOADING',
  ADDING_DETAILS: 'ADDING_DETAILS',
}

const partnerInvoiceForm = 'partnerInvoiceForm'
const invoiceLineKey = 'invoice-line'
const nameSep = '--'

function getIds(id) {
  function getId(name) {
    return [invoiceLineKey, name, id].join(nameSep)
  }

  return {
    description: getId('description'),
    amount: getId('amount'),
    category: getId('category'),
  }
}
function InvoiceLineField({
  item,
  onCategoryChange,
  onDelete,
  showDelete,
  currency,
}) {
  const ids = getIds(item.id)

  const classifications = useSelector(
    (state) => state?.Layout?.staticData?.payment_classifications,
  )
  const classOptions = useMemo(() => {
    return classifications
      ? classifications.map(({ id, name }) => {
          return { value: id, label: name }
        })
      : []
  }, [classifications])

  return (
    <Row className='mx-0'>
      <Col className='px-0'>
        <AvGroup>
          <Label htmlFor={ids.description}>Description:</Label>
          <AvInput
            name={ids.description}
            id={ids.description}
            defaultValue={item.desc}
            placeholder='Description'
            autoComplete='off'
            required
          />
        </AvGroup>
      </Col>
      <Col>
        <AvGroup>
          <Label htmlFor={ids.amount}>Amount:</Label>
          <InputGroup>
            <AvInput
              name={ids.amount}
              id={ids.amount}
              defaultValue={item.amount}
              placeholder='Invoice amount'
              className='form-control'
              tag={CurrencyInputCustom}
              autoComplete='off'
              allowDecimals={true}
              decimalsLimit={2}
              decimalSeparator='.'
              groupSeparator=','
              required
            />
            <InputGroupText
              className='border-left-0'
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              {currency?.code}
            </InputGroupText>
          </InputGroup>
        </AvGroup>
      </Col>
      <Col className='px-0'>
        <FormGroup>
          <Label htmlFor={ids.category}>Category:</Label>
          <Select
            inputId={ids.category}
            openMenuOnFocus
            components={{ IndicatorSeparator: null }}
            styles={customStyles}
            options={classOptions}
            // value={selectedContract}
            onChange={(value) => onCategoryChange(item, value)}
          />
        </FormGroup>
      </Col>
      {!showDelete ? null : (
        <div
          className='align-items-center d-flex justify-content-center mt-auto mb-3 ml-2'
          style={{ height: 42 }}
        >
          <Button
            size='sm'
            className='p-1'
            color='danger'
            outline
            onClick={() => onDelete(item)}
            icon={<i className='bx bx-trash font-size-18' />}
          />
        </div>
      )}
    </Row>
  )
}

function getItems(data, categories) {
  const items = {}

  Object.entries(data)
    .filter(([key]) => key.startsWith(invoiceLineKey))
    .map(([key, value]) => [
      key.substring(invoiceLineKey.length + nameSep.length),
      value,
    ])
    .forEach(([key, value]) => {
      const parts = key.split(nameSep)
      const dataKey = parts[0]
      const id = parts[1]
      items[id] = { ...(items[id] ?? {}), [dataKey]: value }
    })

  return Object.entries(items).reduce(
    (acc, [key, item]) => [
      ...acc,
      { ...item, classification: categories[key]?.value },
    ],
    [],
  )
}

function getTextContent(node) {
  return node?.normalizedValue?.text ?? node?.mentionText ?? null
}

function getLineWithHighestConfidence(lines) {
  const sorted = lines.sort((a, b) => b.confidence - a.confidence)
  return sorted[0]
}

function getLineItemText(properties, filterConditionArr = []) {
  let line = properties?.filter((f) => filterConditionArr.includes(f.type))
  line = line.length > 1 ? getLineWithHighestConfidence(line) : line[0]

  const lineText = getTextContent(line)

  return lineText
}

export function getEntitiesData(entities) {
  return entities
    ?.filter((e) => e?.type === 'line_item')
    ?.map((item) => {
      const desc = getLineItemText(item?.properties, [
        'line_item/description',
        'line_item/product_code',
      ])
      const amount = getLineItemText(item?.properties, ['line_item/amount'])

      return {
        desc,
        amount: parseFloat(amount?.replace(/[^\d.,]/g, '')) || amount,
      }
    })
}
