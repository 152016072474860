import React, { useState } from 'react'
import { ClockCountdown, Play, Plus } from '@phosphor-icons/react'
import { cn } from 'ui'
import { Tooltip, UncontrolledTooltip } from 'reactstrap'
import { getStepsCount } from './tools'
import { maximumActionsCount, maximumDelaysCount } from './tools/constants'

export function Divider(props) {
  const { last, onClick, index, steps } = props
  const [adding, setAdding] = useState(false)
  const { actions, delays } = getStepsCount(steps)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen((prv) => !prv)

  return (
    <div className='tw-ml-5 tw-flex tw-flex-row tw-items-center'>
      <div className='tw-flex tw-w-10 tw-flex-col tw-items-center'>
        <div className='tw-h-6 tw-w-0.5 tw-border tw-border-secondary-30' />
        <button
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          id={`divider-${index}`}
          onClick={() => {
            setTooltipOpen(false)
            setAdding((prev) => !prev)
          }}
          className={cn(
            'tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-2xl',
            adding && 'tw-bg-primary tw-text-white',
          )}
        >
          <Plus size={17.5} />
        </button>

        <Tooltip
          trigger='manual'
          toggle={toggle}
          isOpen={tooltipOpen}
          placement='right'
          target={`divider-${index}`}
        >
          Add action or delay
        </Tooltip>

        <div
          className={cn(
            `tw-h-6 tw-w-0.5 tw-border tw-border-secondary-30`,
            last && 'tw-opacity-0',
          )}
        />
      </div>

      {adding ? (
        <div className='tw-ml-2 tw-rounded-full tw-bg-white tw-p-1'>
          <div className='tw-flex tw-items-center tw-gap-2 tw-rounded-full tw-border-2 tw-border-secondary-30 tw-p-2'>
            <button
              id={`divider-action-${index}`}
              disabled={actions === maximumActionsCount}
              onClick={() => {
                onClick({ type: 'action' })
                setAdding(false)
              }}
              className='tw-flex tw-items-center tw-gap-1 hover:tw-text-primary disabled:tw-text-text-50'
            >
              <Play />
              <span>Action</span>
            </button>

            {actions === maximumActionsCount ? (
              <UncontrolledTooltip target={`divider-action-${index}`}>
                An automation can’t have more than {maximumActionsCount} actions
              </UncontrolledTooltip>
            ) : null}

            <span className='tw-text-secondary-30'>|</span>
            <button
              id={`divider-delay-${index}`}
              disabled={delays === maximumDelaysCount}
              onClick={() => {
                onClick({ type: 'delay' })
                setAdding(false)
              }}
              className='tw-flex tw-items-center tw-gap-1 hover:tw-text-primary disabled:tw-text-text-50'
            >
              <ClockCountdown />
              <span>Delay</span>
            </button>

            {delays === maximumDelaysCount ? (
              <UncontrolledTooltip target={`divider-delay-${index}`}>
                {`An automation can’t have more than ${maximumDelaysCount} delays`}
              </UncontrolledTooltip>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  )
}
