import { format, formatDistanceToNow } from 'date-fns'
import React, { useState, useMemo } from 'react'
import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Label,
  FormGroup,
  ModalFooter,
  FormText,
} from 'reactstrap'
import toastr from 'toastr'

import { useFetch } from '../../../../helpers/hooks'
import {
  getForbiddenDomains,
  updateForbiddenDomain,
  deleteForbiddenDomain,
  createForbiddenDomain,
} from '../../../../services/api'
import { StyledH5 } from '../../../../components/Typo'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import Pagination from '../../../../components/ui/pagination'
import emptyTable from '../../../../assets/images/empty_icon.svg'

export default function ForbiddenDomains() {
  const [domain, setDomain] = useState(null)
  const [deleteDomain, setDeleteDomain] = useState(null)
  const [forbiddenDomainsList, setForbiddenDomainsList] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const getForbiddenDomainsList = useFetch({
    autoFetch: true,
    action: getForbiddenDomains,
    onComplete: (data) => {
      setForbiddenDomainsList(data)
    },
    withAdminAccess: true,
    onError: (err) => {
      toastr.error(err)
    },
  })

  const filteredForbiddenDomainsList = useMemo(
    () =>
      forbiddenDomainsList?.filter((item) => {
        if (!search) {
          return true
        }
        return item.domain.toLowerCase().includes(search.toLowerCase())
      }),
    [search, forbiddenDomainsList],
  )

  const dateFormat = 'do MMM yyyy'
  const dataPerPage = 10

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <Row className='justify-content-end mx-0 mb-3 mb-md-4'>
          <Col xs={12} md={6} className='px-0 mb-2 mb-md-0'>
            <StyledH5 min='22px' max='32px' className='mb-0'>
              Forbidden domains
            </StyledH5>
          </Col>
          <Col>
            <Row className='justify-content-md-end' style={{ gap: '0.75rem' }}>
              <Button
                onClick={() => {
                  setDomain(-1)
                }}
                color='primary'
                className='px-2 py-1 px-md-4 py-md-2'
              >
                Add New Domain
              </Button>
              <Button
                onClick={() => {
                  getForbiddenDomainsList.startFetch()
                }}
                className='px-2 py-1 px-md-4 py-md-2'
              >
                Refresh List
              </Button>
            </Row>
          </Col>
        </Row>

        <Row className='mx-0 mb-3 mb-md-4'>
          <Col className='px-0'>
            <SearchBar
              query={search}
              placeholder='Search by domain name'
              onQueryChanged={setSearch}
            />
          </Col>
        </Row>

        <Card className='p-0 m-0'>
          {getForbiddenDomainsList.isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ minHeight: 145 }}
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : forbiddenDomainsList?.length ? (
            <>
              <div className='table-responsive mb-0'>
                <Table
                  responsive
                  className='table table-centered table-nowrap text-muted'
                >
                  <thead className='thead-light'>
                    <tr>
                      <th className='border-top-0'>Domain</th>
                      <th className='border-top-0 text-center'>Added at</th>
                      <th className='border-top-0 text-center'>Added by</th>
                      <th className='border-top-0 text-center'>Edit</th>
                      <th className='border-top-0 text-center'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredForbiddenDomainsList
                      ?.slice(dataPerPage * (page - 1), page * dataPerPage)
                      .map((domain) => {
                        return (
                          <tr key={'_row_' + domain?.id}>
                            <td>{domain?.domain}</td>
                            <td className='text-center'>
                              {format(new Date(domain?.created_at), dateFormat)}
                              ,{' '}
                              {formatDistanceToNow(
                                new Date(domain?.created_at),
                                {
                                  addSuffix: true,
                                },
                              )}
                              .
                            </td>
                            <td className='text-center'>
                              {domain?.created_by?.first_name}{' '}
                              {domain?.created_by?.last_name}
                            </td>
                            <td className='text-center'>
                              <Button
                                size='sm'
                                onClick={() => {
                                  setDomain(domain)
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                            <td className='text-center'>
                              <Button
                                size='sm'
                                color='danger'
                                onClick={() => {
                                  setDeleteDomain(domain)
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>

                <Col>
                  <Row className='px-3 justify-content-end'>
                    <Pagination
                      activePage={page}
                      onChange={setPage}
                      itemsCountPerPage={dataPerPage}
                      totalItemsCount={
                        filteredForbiddenDomainsList?.length ?? 0
                      }
                    />
                  </Row>
                </Col>
              </div>
            </>
          ) : (
            <div className='text-center'>
              <img
                src={emptyTable}
                alt='Empty table image'
                style={{ width: 160, height: 178 }}
              />
              <p className='text-secondary pb-3'>No data to display.</p>
            </div>
          )}
        </Card>
      </Container>

      {domain !== null ? (
        <EditModal
          forbiddenDomain={domain}
          isOpen={domain !== null}
          toggle={() => setDomain(null)}
          onUpdate={getForbiddenDomainsList}
        />
      ) : null}

      {deleteDomain !== null ? (
        <DeleteModal
          forbiddenDomain={deleteDomain}
          isOpen={deleteDomain !== null}
          toggle={() => setDeleteDomain(null)}
          onUpdate={getForbiddenDomainsList}
        />
      ) : null}
    </div>
  )
}
const EditModal = ({ isOpen, toggle, forbiddenDomain, onUpdate }) => {
  const [newDomain, setNewDomain] = useState(forbiddenDomain?.domain)
  const [errorMessage, setErrorMessage] = useState('')

  const getRequiredAction = () => {
    if (forbiddenDomain?.id) {
      return updateForbiddenDomain
    } else {
      return createForbiddenDomain
    }
  }

  const handleError = (err) => {
    toastr.error(
      typeof err === 'string'
        ? err
        : err?.data && typeof err.data === 'string'
          ? err?.data
          : err?.data?.domain
            ? err?.data?.domain[0]
            : err?.message || 'Something went wrong',
    )
  }

  const updateDomain = useFetch(
    {
      action: getRequiredAction(),
      autoFetch: false,
      withAdminAccess: true,
      onComplete: (data) => {
        if (data && data.success === false) {
          handleError(data)
          return
        }
        toggle()
        onUpdate.startFetch()
      },
      onError: handleError,
    },
    [],
  )

  const handleNewDomain = () => {
    if (newDomain) {
      updateDomain.startFetch({
        forbidden_domain_id: forbiddenDomain?.id,
        domain: newDomain,
      })
    } else {
      setErrorMessage('Please enter a value.')
    }
  }
  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div className=''>
          <FormGroup>
            <Label for='domain'>Forbidden domian</Label>
            <Input
              name='domain'
              id='domain'
              defaultValue={newDomain}
              onChange={(e) => setNewDomain(e.target.value)}
            />
            {errorMessage && <FormText color='danger'>{errorMessage}</FormText>}
            <FormText>e.g. @nft.ru, .com.tl, @scam.com, @ai.st, etc.</FormText>
          </FormGroup>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color='light' type='button' outline onClick={toggle}>
          Cancel
        </Button>
        <Button color='primary' type='button' onClick={handleNewDomain}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const DeleteModal = ({ isOpen, toggle, onUpdate, forbiddenDomain }) => {
  const deleteDomain = useFetch(
    {
      action: deleteForbiddenDomain,
      body: { forbidden_domain_id: forbiddenDomain?.id },
      autoFetch: false,
      withAdminAccess: true,
      onComplete: (data) => {
        if (data && data.success === false) {
          toastr.error(data?.message || 'Something went wrong')
          return
        }
        toastr.success('Domain deleted successfully')
        toggle()
        onUpdate.startFetch()
      },
      onError: (err) => {
        toastr.error(err)
      },
    },
    [],
  )

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <ModalHeader toggle={toggle}>Delete domain</ModalHeader>
      <ModalBody>
        <div className='m-3'>
          Are you sure you want to delete this forbidden domain{' '}
          <b>{forbiddenDomain?.domain}</b> ?
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color='light' type='button' outline onClick={toggle}>
          Cancel
        </Button>
        <Button
          color='danger'
          type='button'
          onClick={() => {
            deleteDomain.startFetch()
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}
