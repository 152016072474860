import { yupResolver } from '@hookform/resolvers/yup'
import {
  Archive,
  FileArrowUp,
  ImageSquare,
  PlusCircle,
  X,
} from '@phosphor-icons/react'
import React, { useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'toastr'
import { cn } from 'ui'
import { number, object, string } from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import DynamicForm, {
  removeFormFields,
} from '../../../../components/Forms/dynamic-form'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { PermissionTooltip } from '../../../../components/permission-tooltip'
import BadgeX from '../../../../components/Table/BadgeX'
import Alert from '../../../../components/ui/alert'
import Button from '../../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import {
  formatEOSPlanData,
  getEOSPlansDefaultValues,
} from '../../../../helpers/eos-plans-helpers'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'
import {
  deGetCountryJurisdictions,
  updateCompanyInfo,
} from '../../../../services/api'
import {
  archiveEntity,
  createEntity,
  unarchiveEntity,
  updateEntity,
} from '../../../../services/api-direct-employee'
import { updateProfileCompany } from '../../../../store/profile/actions'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../../utils/map-to-option'
import LabelContent from '../../../Contract/CreateContract/components/label-content'
import { PERMISSION_GROUP } from '../../manage-role'

export default function CompanyDetails({
  visible,
  onClose,
  isNew,
  data,
  mainCompany,
  onUpdate,
  isArchived,
}) {
  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()
  const staticData = useSelector((state) => state.Layout?.staticData)
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const [uploading, setUploading] = useState(false)
  const [newEntityLogo, setNewEntityLogo] = useState()

  const isMain = mainCompany?.id === data?.id
  if (isMain) {
    data = mainCompany
  }
  const entityHasContracts = !isMain && data?.contracts > 0

  const {
    reset,
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: data?.name,
      type_id: data?.type?.id,
      dba: data?.dba,
      registration_no: data?.registration_no,
      nb_employees: data?.nb_employees,
      currency_id: data?.currency?.id,
      po_number: data?.po_number,
      vat: data?.vat,
      tax_number: data?.tax_number,
      duns_number: data?.duns_number,
      mol_establishment_id: data?.mol_establishment_id,
      city: data?.city,
      country_id: data?.country?.id,
      address: data?.address,
      zip_code: data?.zip_code,
      jurisdiction_id: data?.jurisdiction?.id,
      ...getEOSPlansDefaultValues(data),
    },
    resolver: yupResolver(
      object().shape({
        country_id: string().required('Country is required'),
        city: string().required('City is required'),
        type_id: string().required('Company Type is required'),
        currency_id: string().required('Currency is required'),
        name: string().required('Company Legal Name is required'),
        registration_no: string().required('Registration Number is required'),
        vat: string().notRequired(),
        po_number: string().notRequired().label('PO Number'),
        duns_number: string().notRequired().label('DUNS Number'),
        tax_number: string().notRequired().label('Tax Number'),
        mol_establishment_id: string().notRequired(),
        address: string().required('Address is required'),
        nb_employees: number()
          .notRequired()
          .typeError('Invalid numeric value')
          .positive()
          .integer()
          .label('Number of employees'),
        zip_code: string().notRequired().label('Zip Code'),
        jurisdiction_id: string().when(['country_id'], {
          is: (countryId) => {
            return countryId === '231' && !isMain // UAE
          },
          then: (schema) => schema.required('Jurisdiction is required'),
        }),
      }),
    ),
  })

  const { country_id: countryId } = useWatch({ control })

  const { startFetch: updateCompany, isLoading: updatingCompanyInfo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success !== false) {
          dispatch(updateProfileCompany(data))
          toastr.success('Company updated successfully.')
          setUploading(false)
          reset({
            ...data.data,
            currency_id: data.data.currency.id,
            po_number: data.data.po_number,
            vat: data.data.vat,
            tax_number: data.data.tax_number,
            duns_number: data.data.duns_number,
          })
        }
      },
    })

  const { isLoading: creatingEntity, startFetch: _createEntity } = useFetch({
    action: createEntity,
    onComplete: () => {
      toastr.success('Entity added successfully.')
      onUpdate()
    },
    onError: (error) => toastr.error(error),
  })

  const { isLoading: updatingEntity, startFetch: _updateEntity } = useFetch({
    action: updateEntity,
    onComplete: () => {
      toastr.success('Entity updated successfully.')
      onUpdate?.()
      setUploading(false)
    },
    onError: (error) => {
      toastr.error(error)
      setUploading(false)
    },
  })

  const countryIdIsUAE = countryId === 231 // UAE

  const { data: jurisdictions, isLoading: loadingJurisdictions } = useFetch(
    {
      action: deGetCountryJurisdictions,
      body: { country_id: countryId },
      autoFetch: !!countryId,
    },
    [countryId],
  )

  function handleToggleLogos(e) {
    const { checked } = e.target ?? {}
    const newEnabledValue = checked ? 1 : 0

    if (isMain) {
      updateCompany({ official_logo_enabled: newEnabledValue })
    } else {
      _updateEntity({ official_logo_enabled: newEnabledValue, id: data?.id })
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }
  const jurisdictionIsDisabled =
    !isNew &&
    data?.jurisdiction?.id &&
    (loadingJurisdictions ||
      !jurisdictions?.length ||
      !countryId ||
      entityHasContracts)

  const jurisdictionPlans = data?.jurisdiction_eos_plan_fields

  const jurisdictionId = watch('jurisdiction_id')

  const selectedJurisdiction = useMemo(() => {
    return jurisdictions?.find((j) => j.id === jurisdictionId)
  }, [jurisdictionId, jurisdictions])

  const onSubmit = (formData) => {
    const updatableFieldsForMainCompany = {
      currency_id: formData.currency_id,
      po_number: formData.po_number,
      vat: formData.vat,
      tax_number: formData.tax_number,
      duns_number: formData.duns_number,
    }
    if (isMain) {
      updateCompany(updatableFieldsForMainCompany)
    } else if (!data) {
      _createEntity({ ...formData, logo: newEntityLogo })
    } else {
      if (jurisdictionIsDisabled) {
        delete formData.jurisdiction_id
        delete formData.jurisdiction_eos_plan_inputs
      }
      if (entityHasContracts) {
        const unUpdatableFieldsIfEntityHasContracts = [
          'name',
          'type_id',
          'dba',
          'registration_no',
          'city',
          'country_id',
          'address',
          'zip_code',
          // can't update jurisdiction if entity already has jurisdiction
          ...(data?.jurisdiction?.id ? ['jurisdiction_id'] : []),
        ]
        const entityWithContractsData = { ...formData }
        for (const key of unUpdatableFieldsIfEntityHasContracts) {
          delete entityWithContractsData?.[key]
        }

        _updateEntity({
          ...removeFormFields(
            formatEOSPlanData({
              ...entityWithContractsData,
            }),
          ),
          id: data.id,
        })
      } else {
        _updateEntity({
          ...removeFormFields(formatEOSPlanData(formData)),
          id: data.id,
        })
      }
    }
  }

  function uploadPhoto(acceptedFiles) {
    const file = acceptedFiles[0]
    if (
      file &&
      ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(file.type)
    ) {
      const fileSize = file.size / 1024 / 1024

      if (fileSize > 25) {
        toastr.error('File size exceeds 25 MB')
        return
      }

      setUploading(true)

      const isNotMainAndIsNotNew = !isMain && !isNew
      const body = {
        logo: file,
        ...(data?.official_logo_enabled && { official_logo: file }),
        ...(isNotMainAndIsNotNew && { id: data.id }),
      }

      if (isMain) {
        updateCompany(body)
      } else {
        if (isNew) {
          setNewEntityLogo(file)
        } else {
          _updateEntity(body)
        }
      }
    } else {
      toastr.error('File type not supported')
    }
  }

  const { startFetch: _moveToArchive, isLoading: isMovingToArchive } = useFetch(
    {
      action: archiveEntity,
      body: { id: data?.id },
      onComplete: () => {
        toastr.success('Entity moved to archive successfully.')
        onUpdate()
      },
      onError: (error) => {
        toastr.error(error)
      },
    },
  )

  const { startFetch: _removeFromArchive, isLoading: isRemovingFromArchive } =
    useFetch({
      action: unarchiveEntity,
      body: { id: data?.id },
      onComplete: () => {
        toastr.success('Entity removed from archive successfully.')
        onUpdate()
      },
    })

  const updatingInfo = updatingCompanyInfo || creatingEntity || updatingEntity

  return (
    <div className='tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[1050]'>
      <SideMenu
        onClose={handleClose}
        isOpen={visible}
        className='tw-flex !tw-w-full tw-flex-col tw-bg-white md:!tw-w-[50%] lg:!tw-w-[30%]'
      >
        <SideMenuHeader>
          <div className='!tw-flex tw-w-full tw-items-center tw-justify-between'>
            <span className='tw-flex'>
              <span className='tw-mr-2 tw-text-xl tw-font-semibold tw-text-black'>
                {isNew ? 'New Entity' : data.name}
              </span>
              {!isNew && (
                <BadgeX
                  className={cn(
                    '!tw-flex !tw-items-center !tw-justify-center !tw-rounded-[32px] !tw-px-2 !tw-py-1',
                    isMain ? '!tw-bg-primary' : '!tw-bg-primary-20',
                  )}
                  textClassName={cn('tw-font-bold tw-leading-[18px]', {
                    'tw-text-primary': !isMain,
                  })}
                  size='sm'
                >
                  {isMain ? 'MAIN COMPANY' : 'ENTITY'}
                </BadgeX>
              )}
            </span>
            <button onClick={handleClose}>
              <X size={24} />
            </button>
          </div>
        </SideMenuHeader>

        <SideMenuBody>
          <div>
            <div className='tw-flex tw-items-center tw-justify-between'>
              <span>
                <div className='tw-text-base tw-font-bold'>Logo</div>
                <span className='tw-text-sm tw-font-normal tw-text-text-80'>
                  Add your company logo
                </span>
              </span>
              {isNew && (
                <span className='tw-relative tw-rounded tw-bg-primary-10 tw-p-4 tw-outline-dashed tw-outline-surface-40'>
                  <Dropzone
                    onDrop={uploadPhoto}
                    accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div style={{ zIndex: 99999999999 }}>
                        <div {...getRootProps()} className='tw-cursor-pointer'>
                          <input {...getInputProps()} />
                          {!newEntityLogo ? (
                            <>
                              <ImageSquare
                                size={44}
                                className='tw-text-surface-90'
                              />
                              <span className='tw-absolute tw-right-3 tw-top-3 tw-bg-white tw-p-[2px]'>
                                <PlusCircle
                                  size={16}
                                  weight='fill'
                                  className='tw-text-surface-90'
                                />
                              </span>
                            </>
                          ) : (
                            <img
                              src={URL.createObjectURL(newEntityLogo)}
                              alt='Entity logo'
                              className='tw-h-w-11 tw-w-11 tw-object-cover'
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </span>
              )}
            </div>
            {!isNew && (
              <div className='tw-my-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded tw-border tw-border-surface-30 tw-p-6'>
                <div className='tw-relative tw-mb-4 tw-h-[72px] tw-w-[72px] tw-rounded tw-bg-primary-10 tw-p-4 tw-outline-dashed tw-outline-surface-40'>
                  <span className='tw-absolute -tw-right-[10px] -tw-top-[10px]'>
                    {uploading && (
                      <i className='bx bx-loader bx-spin font-size-16 align-middle' />
                    )}
                  </span>
                  <Dropzone
                    onDrop={uploadPhoto}
                    accept={{
                      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div style={{ zIndex: 99999999999 }}>
                        <div
                          {...getRootProps()}
                          className='tw-flex tw-cursor-pointer tw-items-center tw-justify-center'
                        >
                          <input {...getInputProps()} />
                          {data.logo ? (
                            <img
                              className='tw-h-10 tw-w-10 tw-object-cover'
                              src={data.logo}
                              alt='Company logo'
                            />
                          ) : (
                            <>
                              <ImageSquare
                                size={44}
                                className='tw-text-surface-90'
                              />
                              <span className='tw-absolute tw-right-3 tw-top-3 tw-bg-white tw-p-[2px]'>
                                <PlusCircle
                                  size={16}
                                  weight='fill'
                                  className='tw-text-surface-90'
                                />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className='tw-text-2xl tw-font-medium'>{data.name}</div>
                <span className='tw-text-sm tw-font-normal tw-text-text-80'>
                  {data.type.name}
                </span>
              </div>
            )}

            {!isNew && (
              <PermissionTooltip
                showing={!hasAccess(permissions.manageCompanySettings)}
                id='add-logo-toggle'
                area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
              >
                <div className='tw-flex tw-rounded tw-border tw-border-surface-30 tw-p-6'>
                  <span className='tw-mr-6 tw-flex-1'>
                    <div className='tw-text-base tw-font-bold tw-text-black'>
                      Add logo to documents
                    </div>
                    <div className='tw-text-sm tw-font-normal tw-text-text-60'>
                      It will be shown in documents you generate
                    </div>
                  </span>
                  <Toggle
                    change={handleToggleLogos}
                    disabled={
                      updatingInfo ||
                      !hasAccess(permissions.manageCompanySettings)
                    }
                    check={data?.official_logo_enabled === 1}
                  />
                </div>
              </PermissionTooltip>
            )}
          </div>

          <hr className='-tw-mx-6 tw-my-6' />
          <div>
            <div className='tw-text-base tw-font-bold tw-text-black'>
              Details
            </div>
            <div className='tw-mb-4 tw-text-sm tw-font-normal tw-text-text-80'>
              Manage your company details
            </div>
            <div className='tw-flex tw-flex-col tw-gap-3'>
              <ControlledInput
                control={control}
                label={<LabelContent required>Company Legal Name</LabelContent>}
                name='name'
                error={errors.name}
                disabled={isMain || entityHasContracts}
              />

              <ControlledSelect
                control={control}
                label='Company Type'
                options={staticData?.company_types?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
                name='type_id'
                error={errors.type_id}
                disabled={isMain || entityHasContracts}
                required
              />

              <ControlledInput
                control={control}
                label='Doing Business As (Optional)'
                name='dba'
                error={errors.dba}
                disabled={isMain || entityHasContracts}
              />

              <ControlledInput
                control={control}
                label={
                  <LabelContent required>Registration Number</LabelContent>
                }
                name='registration_no'
                error={errors.registration_no}
                disabled={isMain || entityHasContracts}
              />

              <ControlledInput
                control={control}
                label='Number of Employees'
                name='nb_employees'
                type='number'
                error={errors.nb_employees}
                disabled={isMain}
              />

              <ControlledSelect
                control={control}
                label='Currency'
                required
                options={
                  staticData?.company_currencies?.map((c) =>
                    mapCurrencyToOption(c, 'id'),
                  ) ?? []
                }
                name='currency_id'
                error={errors.currency_id}
              />

              <>
                <ControlledInput
                  control={control}
                  name='po_number'
                  error={errors.po_number}
                  label='PO Number'
                />

                <ControlledInput
                  control={control}
                  name='vat'
                  error={errors.vat}
                  label='VAT Number'
                />

                <ControlledInput
                  control={control}
                  name='tax_number'
                  error={errors.tax_number}
                  label='Tax Number'
                />

                <ControlledInput
                  control={control}
                  name='duns_number'
                  error={errors.duns_number}
                  label='DUNS Number'
                />
              </>

              {!isMain && countryIdIsUAE && (
                <ControlledInput
                  control={control}
                  id='mol_establishment_id'
                  name='mol_establishment_id'
                  label='MOL Establishment ID'
                />
              )}

              {isNew && (
                <div>
                  <hr className='-tw-mx-4 tw-mb-6 tw-mt-2' />
                  <div className='tw-text-base tw-font-bold tw-text-black'>
                    Address
                  </div>
                  <div className='tw-mb-4 tw-text-sm tw-font-normal tw-text-text-80'>
                    Add your company’s address
                  </div>
                </div>
              )}

              <ControlledInput
                control={control}
                label={<LabelContent required>City</LabelContent>}
                name='city'
                error={errors.city}
                disabled={isMain || entityHasContracts}
              />

              <ControlledSelect
                control={control}
                label='Country'
                options={countries?.map((c) => mapCountryToOption(c) ?? [])}
                name='country_id'
                error={errors.country_id}
                disabled={isMain || entityHasContracts}
                required
              />
              {countryIdIsUAE && !isMain && (
                <>
                  <ControlledSelect
                    control={control}
                    name='jurisdiction_id'
                    error={errors?.jurisdiction_id}
                    label={<LabelContent required>Jurisdiction:</LabelContent>}
                    options={jurisdictions?.map((j) => ({
                      ...j,
                      label: j?.name,
                      value: j?.id,
                    }))}
                    disabled={jurisdictionIsDisabled}
                    isLoading={loadingJurisdictions}
                  />
                  {data?.jurisdiction?.id && entityHasContracts && (
                    <Alert color='info'>
                      The jurisdiction cannot be changed because there are
                      associated contracts
                    </Alert>
                  )}
                  {selectedJurisdiction?.contribution_plan ? (
                    <Alert color='info'>
                      The <span className='tw-font-bold'>DEWS</span> End of
                      Service Benefit will be paid under this contract for
                      non-UAE and non-GCC nationals
                    </Alert>
                  ) : null}
                </>
              )}

              <ControlledInput
                control={control}
                label={<LabelContent required>Address</LabelContent>}
                error={errors.address}
                name='address'
                disabled={isMain || entityHasContracts}
              />

              {!isMain && (
                <ControlledInput
                  control={control}
                  name='zip_code'
                  error={errors.zip_code}
                  label='Zip Code'
                  disabled={entityHasContracts}
                />
              )}

              {jurisdictionPlans?.length > 0 && (
                <div>
                  <hr className='tw-mx-2 tw-my-6' />
                  <div className='tw-text-base tw-font-bold tw-text-black'>
                    Pension plans
                  </div>
                  <div className='tw-mb-4 tw-text-sm tw-font-normal tw-text-text-80'>
                    Manage your pension plans details
                  </div>
                  <div className='tw-mx-[-24px]'>
                    {jurisdictionPlans.map((jurisdictionPlan) => {
                      if (jurisdictionPlan?.fields?.length === 0) {
                        return null
                      }
                      return (
                        <div key={jurisdictionPlan?.id}>
                          <div className='tw-mx-6 tw-text-base tw-font-bold tw-text-black'>
                            {jurisdictionPlan?.contribution_plan_name}
                          </div>
                          <DynamicForm
                            control={control}
                            fields={jurisdictionPlan?.fields.map((field) => ({
                              ...field,
                              id: `${field.name}-${jurisdictionPlan?.jurisdiction_id}-${jurisdictionPlan?.contribution_plan_id}`,
                              is_full_row: true,
                            }))}
                            newUI={false}
                            conditions={[]}
                            setValue={setValue}
                            watch={watch}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </SideMenuBody>
        <SideMenuFooter
          className={cn(
            'tw-flex',
            isMain || !data ? 'tw-justify-end' : 'tw-justify-between',
          )}
        >
          {isArchived ? (
            <Button
              className='tw-grow'
              onClick={() => _removeFromArchive()}
              disabled={updatingInfo || isRemovingFromArchive}
              loading={isRemovingFromArchive}
              outline
              icon={<FileArrowUp size={20} />}
            >
              UnArchive
            </Button>
          ) : (
            <>
              {!isMain && data && (
                <Button
                  className='tw-mr-2 !tw-border-none !tw-p-1 !tw-text-black hover:!tw-bg-transparent'
                  outline
                  icon={<Archive size={16} />}
                  type='button'
                  color='link'
                  onClick={() => _moveToArchive()}
                  disabled={updatingInfo || isMovingToArchive}
                  loading={isMovingToArchive}
                >
                  Move To Archive
                </Button>
              )}

              <span className='tw-shrink-0'>
                <Button
                  className='tw-mr-2 !tw-border-surface-30 !tw-text-black hover:!tw-bg-red-10'
                  outline
                  onClick={handleClose}
                  type='button'
                  disabled={updatingInfo}
                >
                  Cancel
                </Button>
                <Button
                  type='button'
                  onClick={handleSubmit(onSubmit)}
                  disabled={updatingInfo}
                  loading={updatingInfo}
                >
                  Save
                </Button>
              </span>
            </>
          )}
        </SideMenuFooter>
      </SideMenu>
    </div>
  )
}
