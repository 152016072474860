import React, { useRef, useState } from 'react'
import FEATURE_FLAGS from '../../config/feature-flags'
import Shimmer from '../../components/ui/shimmer'
import Entity from './components/Entity'
import CompanyDetails from './components/company-details'
import { getEntities } from '../../services/api-direct-employee'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { Col, Container, Row } from 'reactstrap'
import { ContractLogoManagement } from './components/contract-logo-management'
import permissions from '../../helpers/permissions'
import { PERMISSION_GROUP } from './manage-role'
import { PermissionTooltip } from '../../components/permission-tooltip'
import BadgeX from '../../components/Table/BadgeX'
import { Archive, NewspaperClipping } from '@phosphor-icons/react'

import { getItem, setItem } from '../../utils/ls'
import { updateProfileCompany } from '../../store/profile/actions'
import Loader from '../../components/ui/loader'
import CompanyInfo from './CompanyInfo'
import { useDispatch, useSelector } from 'react-redux'

import toastr from 'toastr'
import { updateCompanyInfo } from '../../services/api'
import ManageAvatar from './manage-avatar'
import { cn, Toggle } from 'ui'
import NavTabs from '../../components/ui/nav-tabs'
import { EmptyState } from '../review-center/empty-state'

function CompanyEntities({
  companyInfoData,
  companyInfoLoading,
  fetchCompanyInfo,
  setCompanyInfoData,
}) {
  const formRef = useRef(null)
  const [selectedCompany, setSelectedCompany] = useState()
  const [activeTab, setActiveTab] = useState('active')
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const userProfile = useSelector(
    (state) => state.userProfile?.userProfile ?? {},
  )

  const { fromContractCreation } = location.state ?? {}

  const [showForm, setShowForm] = useState({
    slide: fromContractCreation,
    wrapper: fromContractCreation,
  })

  const handleCompanyDetailsClose = () => {
    // remove the entity id from the url
    window.history.replaceState({}, '', location.pathname)
    setShowForm((prev) => ({ ...prev, slide: false }))
    setTimeout(() => {
      setShowForm((prev) => ({ ...prev, wrapper: false }))
    }, 1000)
  }
  const {
    data,
    startFetch: _getEntities,
    isLoading,
  } = useFetch(
    {
      action: getEntities,
      autoFetch: true,
      body: {
        is_archived: activeTab === 'archived',
      },
      onComplete: (data) => {
        const urlEntityID = new URLSearchParams(location.search).get('entityId')
        const entity = data.entities.find((e) => e.id === Number(urlEntityID))
        if (entity) {
          setSelectedCompany(entity)
          setShowForm({ slide: true, wrapper: true })
        }
      },
    },
    [activeTab],
  )
  return FEATURE_FLAGS.NEW_DIRECT_EMPLOYEE &&
    companyInfoData?.is_direct_employee_enabled ? (
    <div className='tw-flex tw-flex-col tw-p-6'>
      <div className='tw-flex tw-items-center tw-justify-between'>
        <div className='tw-flex tw-flex-col'>
          <span className='tw-text-base tw-font-bold tw-text-black'>
            Company information
          </span>
          <span>Manage your company and entities details</span>
        </div>
        <button
          className='tw-text-sm tw-font-bold tw-text-primary'
          onClick={() => {
            setSelectedCompany(undefined)
            setShowForm({ slide: true, wrapper: true })
          }}
        >
          Add New
        </button>
      </div>

      <NavTabs
        className='tw-my-6 tw-border-b tw-border-b-surface-30'
        linkClassName='!tw-py-6 !tw-px-4'
        noBorder={false}
        options={[
          {
            label: (
              <div className='tw-flex tw-items-center tw-gap-2'>
                <span>Active entities</span>
                <div className='tw-flex tw-h-[18px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-surface-30 tw-px-1 tw-text-xs tw-text-black'>
                  {data?.active_count}
                </div>
              </div>
            ),
            value: 'active',
            disabled: companyInfoLoading,
          },
          {
            label: (
              <div className='tw-flex tw-items-center tw-gap-2'>
                <span>Archived</span>
                <div className='tw-flex tw-h-[18px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-surface-30 tw-px-1 tw-text-xs tw-text-black'>
                  {data?.archived_count}
                </div>
              </div>
            ),
            value: 'archived',
            disabled: companyInfoLoading,
          },
        ]}
        activeOption={activeTab}
        onClickOption={(option) => setActiveTab(option.value)}
      />

      <div
        className={cn(
          'tw-mt-6 tw-grid tw-auto-rows-auto tw-gap-4 tw-overflow-auto md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5',
          {
            'tw-flex tw-items-center tw-justify-center':
              !data?.entities?.length && !isLoading && activeTab === 'archived',
          },
        )}
      >
        {!companyInfoData ? (
          <Shimmer className='!tw-h-44 !tw-w-56' />
        ) : activeTab === 'archived' ? null : (
          <Entity
            data={companyInfoData}
            isMain
            onViewDetailsClick={() => {
              setSelectedCompany(companyInfoData)
              setShowForm({ slide: true, wrapper: true })
            }}
          />
        )}

        {showForm.wrapper && (
          <CompanyDetails
            mainCompany={userProfile.company}
            onClose={handleCompanyDetailsClose}
            visible={showForm.slide}
            isNew={!selectedCompany?.id}
            data={selectedCompany}
            isArchived={activeTab === 'archived'}
            onUpdate={() => {
              setShowForm({
                slide: false,
                wrapper: false,
              })
              _getEntities()
            }}
          />
        )}

        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <Shimmer
              className='tw-min-h-60 !tw-w-full tw-min-w-60'
              key={index}
            />
          ))
        ) : !data?.entities?.length && activeTab === 'archived' ? (
          <EmptyState
            title='No archived entities found'
            textElement='Archived entities will be shown here'
            pictureElement={<Archive size={250} className='tw-fill-primary' />}
            className='tw-shadow-none'
          />
        ) : (
          data?.entities?.map((entity) => (
            <Entity
              data={entity}
              isMain={false}
              key={entity.id}
              onViewDetailsClick={() => {
                setSelectedCompany(entity)
                setShowForm({ slide: true, wrapper: true })
              }}
            />
          ))
        )}
      </div>
    </div>
  ) : (
    <Container fluid>
      <Row>
        {!companyInfoLoading && (
          <Col md={3} className='border-right'>
            <ManageAvatarAndLogo
              companyInfoData={companyInfoData}
              setCompanyInfoData={setCompanyInfoData}
            />
          </Col>
        )}
        <Col className='px-0'>
          {companyInfoLoading ? (
            <Loader minHeight='40rem' />
          ) : (
            !!countries?.length &&
            !!companyInfoData && (
              <CompanyInfo
                ref={formRef}
                data={companyInfoData}
                countries={countries}
                onUpdate={() => fetchCompanyInfo(null, false)}
              />
            )
          )}
        </Col>
      </Row>
    </Container>
  )
}

function ManageAvatarAndLogo({ companyInfoData, setCompanyInfoData }) {
  const dispatch = useDispatch()
  const userProfile = useSelector(
    (state) => state.userProfile?.userProfile ?? {},
  )
  const user = useSelector((state) => state.Account?.user)
  const { company } = userProfile
  const [loading, setLoading] = useState(false)

  function uploadPhoto(acceptedFiles) {
    const file = acceptedFiles[0]
    if (
      file &&
      ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(file.type)
    ) {
      const fileSize = file.size / 1024 / 1024

      if (fileSize > 25) {
        toastr.error('File size exceeds 25 MB')
        return
      }

      const body = { logo: file }
      setLoading(true)
      updateCompanyInfo(user?.token, body)
        .then((r) => {
          if (r.data.success) {
            setCompanyInfoData(r.data.data)
            dispatch(updateProfileCompany(r?.data?.data))
          } else {
            toastr.error(r.data.data?.error || r.data?.message)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      toastr.error('File not supported')
    }
  }

  return (
    <div className='text-center py-4 d-flex flex-column gap-24'>
      <div className='px-3 py-4 border border-surface-30 rounded'>
        <ManageAvatar
          src={companyInfoData?.logo ?? company?.logo}
          name={company?.name}
          onUploadPhoto={uploadPhoto}
          isUploading={loading}
        />

        <h3 className='px-2 text-center text-gray-h mt-2' translate='no'>
          {company?.name}
        </h3>

        {!company?.type?.name ? null : (
          <p className='text-gray-600 font-size-14 px-2 text-center'>
            {company?.type?.name}
          </p>
        )}

        <BadgeX status='secondary'>Entity</BadgeX>

        <hr />

        <div
          className='font-size-14 text-gray-h'
          style={{ lineHeight: '24px' }}
        >
          Company information will be displayed as is in your contracts and
          invoices.
        </div>
      </div>

      <ContractLogoSetting />
    </div>
  )
}

function ContractLogoSetting() {
  const dispatch = useDispatch()
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const { hasAccess } = usePermissions()

  const enabled = company?.official_logo_enabled?.toString() === '1'

  const { startFetch: updateCompany, isLoading: updatingContractLogo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        dispatch(updateProfileCompany(data))
        toastr.success('Logo settings updated')
      },
    })

  return (
    <div className='border border-surface-30 rounded p-3'>
      {enabled ? null : <ContractLogoNotice />}

      <div className='d-flex flex-column gap-12'>
        <PermissionTooltip
          showing={!hasAccess(permissions.manageCompanySettings)}
          id='add-logo-toggle'
          area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
        >
          <label
            className='d-inline-flex align-items-center flex-wrap gap-8 mb-0 cursor-pointer'
            style={{ alignSelf: 'flex-start' }}
          >
            <Toggle
              isSelected={enabled}
              onChange={(checked) =>
                updateCompany({ official_logo_enabled: checked ? 1 : 0 })
              }
              isDisabled={
                updatingContractLogo ||
                !hasAccess(permissions.manageCompanySettings)
              }
              marginRight={null}
              aria-label='addLogoToDocuments'
            />
            <span className='rp-font-medium'>Add logo to documents</span>
          </label>
        </PermissionTooltip>

        {!enabled ? null : <hr className='mx-n3 my-0' />}

        {/* //TODO: show but disable dropzone later */}
        {hasAccess(permissions.manageCompanySettings) && (
          <ContractLogoManagement enabled={enabled} />
        )}
      </div>
    </div>
  )
}

const CONTRACT_LOGO_NOTICE_KEY = 'contract-logo-notice-shown'

function ContractLogoNotice() {
  const shown = JSON.parse(getItem(CONTRACT_LOGO_NOTICE_KEY) || 'false')
  const [isShown, setIsShown] = useState(shown)

  function hideNotice() {
    setItem(CONTRACT_LOGO_NOTICE_KEY, true)
    setIsShown(true)
  }

  if (isShown) {
    return null
  }

  return (
    <>
      <div className='text-center d-flex flex-column gap-12 align-items-center'>
        <div className='d-flex flex-column align-items-center'>
          <BadgeX
            color='primary'
            className='rounded-pill mb-n2 px-2 py-1.5 z-1000 position-relative uppercase'
            style={{ boxShadow: '0 0 0 3px white' }}
            size='md'
          >
            New
          </BadgeX>
          <NewspaperClipping
            size={64}
            weight='duotone'
            color='var(--primary)'
            className='p-3 rounded-circle bg-primary-20'
          />
        </div>

        <p className='mb-0 mx-auto text-gray-600' style={{ maxWidth: 210 }}>
          You can now add your logo to your future documents in RemotePass
        </p>

        <button
          className='rp-btn-nostyle text-text-black rp-font-bold hover:bg-slate-50'
          onClick={hideNotice}
        >
          Dismiss
        </button>
      </div>

      <hr className='mx-n3' />
    </>
  )
}

export default CompanyEntities
