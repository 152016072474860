import { IdentificationCard } from '@phosphor-icons/react'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import GoogleLogin from 'react-google-login'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReactInputVerificationCode from 'react-input-verification-code'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import FEATURE_FLAGS from '../../config/feature-flags'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { nameRegExp } from '../../helpers/info-latin-regex'
import CompanyInfo from '../../pages/CompanySetting/CompanyInfo'
import {
  getIPGeolocation,
  googleSignUp,
  requestNewLink,
  signup,
  verifyOTP,
  verifyReCaptchaV3,
} from '../../services/api'
import {
  OTPSuccessful,
  clearError,
  registerUser,
  toggleLogin,
  updateUserData,
} from '../../store/auth/register/actions'
import { tag, track } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { getDefaultEventData } from '../../utils/analytics/get-default-event-data'
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies'
import { getErrorMessage } from '../../utils/get-errors'
import { mapCountryToOption } from '../../utils/map-to-option'
import {
  PasswordChecklistComp,
  validatePassword,
} from '../CommonForBoth/ChangePassword'
import { StyledH6 } from '../Typo'
import Button from '../ui/button'
import TabContent from '../ui/tabs'
import googleGLogo from './../../assets/images/Google__G__Logo.svg'
import './../../pages/Authentication/style.css'
import CustomSelect from './CustomSelect/CustomSelect'
import './form-wizard-phone-input.css'
import CustomPhoneInput from './phoneInput/CustomPhoneInput'
import { PasswordInputTypeToggle } from './TextInput'
import UserTypeSelect, { useContractorType } from './UserTypeSelect'

function getGoogleSignUpError(e) {
  const errorKey = e?.error
  const errorDetails = e?.details
  if (
    errorKey === 'idpiframe_initialization_failed' &&
    errorDetails === 'Cookies are not enabled in current environment.'
  ) {
    return ''
  }

  switch (errorKey) {
    case 'popup_closed_by_user':
      return 'You have cancelled the sign up process, please try again.'

    default:
      return `An error occurred while signing up with Google (${errorKey}). Please try again.`
  }
}
const REFERRAL_PARAM = 'grsf'

const FormWizard = (props) => {
  const searchParams = new URLSearchParams(props.location.search)
  const tokenP = searchParams.get('token')
  const emailP = searchParams.get('email')
  const typeP = searchParams.get('type')
  const referralCode = searchParams.get(REFERRAL_PARAM)
  const params = searchParams.entries()
  const path = props.location.pathname.slice(1)
  // @todo: polly-fill fromEntries https://remotepass.sentry.io/issues/4217853647/?project=4504022871900160&query=is%3Aunresolved&referrer=issue-stream&stream_index=10
  const allParams = Object.fromEntries([...params])

  const { isDeEmployee } = useContractorType()

  const userData = useSelector((state) => state?.Account?.data)
  const [activeTab, setActiveTab] = useState(1)

  const [googleToken, setGoogleToken] = useState(userData?.tokenId || null)
  const [email, setEmail] = useState(
    emailP || userData?.profileObj?.email || null,
  )

  const [code, setCode] = useState(null)
  const [firstName, setFirstName] = useState(
    userData?.profileObj?.givenName || null,
  )
  const [lastName, setLastName] = useState(
    userData?.profileObj?.familyName || null,
  )
  const [middleName, setMiddleName] = useState(null)
  const [country, setCountry] = useState(null)
  const [phone, setPhone] = useState(null)
  const [password, setPassword] = useState(null)
  const [passwordFieldType, setPasswordFieldType] = useState('password')
  const [loading, setLoading] = useState(false)
  const [customCheck, setCustomCheck] = useState(false)
  const [signUpUserData, setSignUpUserData] = useState(null)

  // const [nbEmployees, setNbEmployees] = useState(null)
  const [acquisitionChannel, setAcquisitionChannel] = useState(null)
  const user = useSelector((state) => state.Account?.user)

  const link = useSelector((state) => state.Account?.link)
  const message = useSelector((state) => state.Account?.registrationError)
  const isLogged = useSelector((state) => state.Account?.loggedIn)
  const registerLoading = useSelector(
    (state) => state.Account?.loading || state.Account?.OTPloading,
  )
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const [submitLoading, setSubmitLoading] = useState(false)

  const { returnTabValue, userType, setUserType } = props
  const { startFetch: requestSharingLink } = useFetch({
    action: requestNewLink,
    onComplete: () => {
      deleteCookie(REFERRAL_PARAM)
    },
    onError: (err) => {
      toastr.error(getErrorMessage(err))
    },
  })

  const { startFetch: signUpGoogle, data: googleSignUpData } = useFetch({
    action: googleSignUp,
    onComplete: (data, body) => {
      const { eventData } = body
      setLoading(false)
      googleSignUpSuccess(eventData)
      dispatch(OTPSuccessful(data))
    },
    onError: (error) => {
      toastr.error(
        typeof error === 'string'
          ? error
          : typeof error?.message === 'string'
            ? error.message
            : 'An error occurred while signing up with Google.',
      )
      setLoading(false)
    },
  })

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTab(tab)
        if (returnTabValue) {
          returnTabValue(tab)
        }
      }
    }
  }

  function getInfoFromGoogleEvent(event) {
    return {
      email: event.profileObj.email,
      firstName: event.profileObj.givenName,
      lastName: event.profileObj.familyName,
      tokenId: event.tokenId,
    }
  }

  function googleSignUpSuccess(data) {
    setGoogleToken(data?.tokenId)
    setEmail(data?.email)
    setFirstName(data?.firstName)
    setLastName(data?.lastName)
    setActiveTab(3)
    returnTabValue(3)
  }

  const handleGoogleLogin = (e) => {
    const data = getInfoFromGoogleEvent(e)

    if (tokenP && data?.email !== emailP) {
      toastr.error("Invited email and email used for google login don't match.")
      return
    }

    setLoading(true)

    signUpGoogle({
      userType: userType === 1 ? userTypes.CONTRACTOR : userTypes.COMPANY,
      token: data?.tokenId,
      eventData: data,
      sourcePath: path,
      sourceData: allParams,
    })
  }

  const handleGoogleLoginError = (e) => {
    const message = getGoogleSignUpError(e)
    if (message) {
      toastr.error(message)
    }
  }

  const dispatch = useDispatch()
  useEffect(() => {
    if (referralCode) {
      setCookie(REFERRAL_PARAM, referralCode)
    }
  }, [])
  useEffect(() => {
    if (message) {
      toastr.error(message, 'error')
      dispatch(clearError())
    }
  }, [dispatch, message])

  useEffect(() => {
    if (isLogged) {
      if (link) {
        props.history.push(link)
      } else {
        props.history.push('/activity')
      }
    }
  }, [isLogged, link, props.history])

  useEffect(() => {
    if (userData) {
      dispatch(updateUserData(null))
    }
  }, [dispatch])

  const onSubmitEmail = () => {
    if (userType === -1) {
      toastr.error('Please set your account type', 'Error')
      return
    }
    if (!email) {
      toastr.error('Email is required', 'Error')
      return
    }
    if (!customCheck) {
      toastr.error(
        'You need to agree to the Terms of Service and the Use Policy',
        'Error',
      )
      return
    }
    if (tokenP) {
      setActiveTab(3)
      if (returnTabValue) {
        returnTabValue(3)
      }
      dispatch(OTPSuccessful({ token: tokenP }))
    } else {
      if (path === 'partner') {
        delete allParams.gspk
        delete allParams.gsxid
      }

      const body =
        path === 'signup' || path === 'partner'
          ? { email, source_path: path, source_data: allParams }
          : { email, source_path: path }

      setLoading(true)
      signup(body, userType === 1 ? userTypes.CONTRACTOR : userTypes.COMPANY)
        .then((r) => {
          setLoading(false)
          if (activeTab !== 3 && r.data.success) {
            setCode(null)
            toggleTab(activeTab + 1)
          } else {
            toastr.error(
              r.data?.data?.error ||
                (Array.isArray(r.data?.data)
                  ? r.data?.data?.[0]
                  : r.data?.message),
              'Error',
            )
          }

          setSignUpUserData(r.data?.data)
          const clientId = r.data?.data?.user_id

          track('onBoarding_submit_email', { email, clientID: clientId })

          const defaultEventData = getDefaultEventData(email)
          // This is for Google analytics events, 'Contractor' & 'Company' should not use BE enums
          const userTypeData = userType === 1 ? 'Contractor' : 'Company'
          const eventData = {
            ...defaultEventData,
            userID: clientId,
            userType: userTypeData,
          }
          tag(EVENTS.EMAIL_ADDRESS_PROVIDED, eventData)
        })
        .catch((e) => {
          toastr.error(e.toLocaleString())
          setLoading(false)
        })
    }
  }

  function onCompanyInfoCompleted(data) {
    if (data) {
      // We want to tag this event only in the onboarding flow
      track('onBoarding_submit_company_info', {
        email,
      })

      const eventData = {
        ...getDefaultEventData(email),
        userID: signUpUserData?.user_id,
        companyID: data?.id,
      }
      tag(EVENTS.ON_BOARDING_SUBMIT_COMPANY_INFO, eventData)
    }

    setSubmitLoading(false)
    dispatch(toggleLogin(true))
    const body = {
      id: user?.id,
      email: user?.email,
      firstName: user?.first_name,
      lastName: user?.last_name,
      type: user?.type,
    }
    const referralCookie = getCookie(REFERRAL_PARAM)
    if (FEATURE_FLAGS.REMOTEPASS_REFERRALS_REGISTRATION_ON_SIGNUP) {
      requestSharingLink(
        referralCookie ? { ...body, referrerId: referralCookie } : body,
      )
    }
  }
  const onOtpVerification = () => {
    if (!code) {
      toastr.error('Set the confirmation code you received in your email')
      return
    }

    setLoading(true)

    verifyOTP(
      code,
      email,
      userType === 1 ? userTypes.CONTRACTOR : userTypes.COMPANY,
    )
      .then((r) => {
        if (r.data.success) {
          setActiveTab(3)
          if (returnTabValue) {
            returnTabValue(3)
          }
          dispatch(OTPSuccessful(r.data.data))
          track('onBoarding_otp_verified', { email })

          const defaultEventData = getDefaultEventData(email)
          const clientId = signUpUserData?.user_id
          const eventData = { ...defaultEventData, userID: clientId }

          tag(EVENTS.OTP_RECEIVED, eventData)
        } else {
          track('onBoarding_otp_error', {
            email,
            error:
              r.data.data?.error ||
              r.data?.error ||
              r.data?.message ||
              'An error occurred',
          })
          toastr.error(
            r.data.data?.error ||
              r.data?.error ||
              r.data?.message ||
              'An error occurred',
          )
        }
      })
      .catch(() => {
        toastr.error('An error occurred')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onRegister = async () => {
    const names = [firstName, lastName, middleName].filter(Boolean)
    const namesAreLatin = names.every((name) => {
      if (name?.length > 0 && !nameRegExp.test(name)) {
        return false
      }
      return true
    })

    if (!firstName || !lastName || !phone || !country?.id) {
      toastr.error(
        'Make sure you fill all required fields before you register.',
      )
      return
    }

    if (!namesAreLatin) {
      toastr.error('Your name should contain only latin letters.')
      return
    }

    if (!googleToken && (!password || password.length <= 0)) {
      toastr.error('Password field is required.')
      return
    }

    const { isValid } = validatePassword(password)

    if (!password && !googleToken && !isValid) {
      toastr.error(
        'Weak password easy to guess, password should respect checklist conditions',
      )

      return
    }

    const requestData = {
      ...(userType === 1 && { new_contractor_onboard: true }),
      first_name: firstName,
      last_name: lastName,
      country_id: country?.id,
      email,
      phone,
    }

    const optionalField = [
      { key: 'acquisition_channel', value: acquisitionChannel },
      // { key: 'nb_employees', value: nbEmployees },
      { key: 'middle_name', value: middleName },
    ]

    optionalField.forEach(({ key, value }) => {
      if (value) {
        requestData[key] = value
      }
    })

    if (googleToken) {
      requestData.token = googleToken
    } else {
      requestData.password = password
    }

    if (googleSignUpData?.token) {
      requestData.nextStep = 'update-profile'
    }

    setSubmitLoading(true)

    window.grecaptcha?.ready(function () {
      window.grecaptcha
        .execute(process.env.REACT_APP_RE_CAPTCHA_SITE_KEY_V3, {
          action: 'register',
        })
        .then(async function (token) {
          const data = await verifyReCaptchaV3(token)
          setSubmitLoading(false)

          const score = data?.data?.data?.score

          if (score < 0.5) {
            toastr.error('Please verify you are not a robot.')
          } else {
            dispatch(
              registerUser(
                userType === 0 ? userTypes.COMPANY : userTypes.CONTRACTOR,
                requestData,
                user?.token,
                () => {
                  if (userType === 0) {
                    toggleTab(activeTab + 1)
                  } else {
                    dispatch(toggleLogin(true))
                  }
                  if (tokenP) {
                    dispatch(toggleLogin(true))
                  }

                  const trackData = {
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    country_id: country?.id,
                    phone,
                  }
                  if (userType === 0) {
                    const userID = signUpUserData?.user_id

                    track('onBoarding_create_account', { userID, ...trackData })
                  } else {
                    track('create_contractor_account', trackData)
                    localStorage.setItem('show_contractor_onboarding', true)
                  }
                },
              ),
            )
          }
        })
    })
  }

  const onSkip = () => {
    if (activeTab === 4) {
      setActiveTab((v) => v + 1)
      track('onBoarding_skip_company_info')
    }
    if (activeTab === 5) {
      track('onBoarding_skip_invite_users')
      dispatch(toggleLogin(true))
    }
  }
  const onNext = () => {
    if (activeTab === 1) {
      onSubmitEmail()
    }
    if (activeTab === 2) {
      onOtpVerification()
    }
    if (activeTab === 3) {
      onRegister()
    }
    if (activeTab === 4) {
      const companyInfoForm = document.getElementById('company-info-form')
      setSubmitLoading(true)
      if (companyInfoForm) companyInfoForm?.requestSubmit()
    }
    if (activeTab === 5) {
      const btn = document.getElementById('onboarding-invite-team-submit')
      if (btn) btn.click()
    }
  }

  const hearOfUs = [
    { label: 'A friend or colleague', value: 'A friend or colleague' },
    { label: 'A contractor', value: 'A contractor' },
    { label: 'Advertisement', value: 'Advertisement' },
    { label: 'Linkedin', value: 'Linkedin' },
    { label: 'Social media', value: 'Social media' },
    { label: 'Google', value: 'Google' },
    { label: 'Job posting', value: 'Job posting' },
    { label: 'Email', value: 'Email' },
    { label: 'Press', value: 'Press' },
    { label: 'Other', value: 'Other' },
  ]

  const { startFetch: getIPGeo, data: geoData } = useFetch({
    action: getIPGeolocation,
  })

  useEffect(() => {
    if (activeTab > 1) return

    getIPGeo({ fields: 'country,country_code' })
  }, [activeTab])

  useEffect(() => {
    if (/^\d+$/.test(code) && code?.length === 4) {
      onOtpVerification()
    }
  }, [code])

  return (
    <Container fluid className='align-items-center p-0 m-0'>
      <Row className='justify-content-center p-0 m-0'>
        <Col
          xs={activeTab === 5 ? 12 : 12}
          sm={activeTab === 5 ? 10 : 9}
          md={activeTab === 5 ? 10 : 7}
          lg={activeTab === 5 ? 8 : 6}
          xl={activeTab === 5 ? 6 : 4}
          className='p-0 m-0'
        >
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY_V3}
          />

          <div className='twitter-bs-wizard'>
            {activeTab === 5 && (
              <Row className='justify-content-center p-0 m-0'>
                <div className='px-3 px-md-4 p-0 m-0 pb-3 pb-md-4'>
                  <StyledH6
                    style={{ lineHeight: '24px' }}
                    min='14px'
                    max='14px'
                    className='mb-0 text-center font-weight-light text-secondary'
                  >
                    Add your team members who will be managing this account and
                    assign roles.
                  </StyledH6>
                </div>
              </Row>
            )}
            <Card style={{ boxShadow: '0px 1px 0px #dfe1e6' }}>
              <Row className='justify-content-center p-0 m-0'>
                <Col className='p-0 m-0'>
                  <div className='twitter-bs-wizard'>
                    <TabContent
                      activeTab={activeTab}
                      className='twitter-bs-wizard-tab-content p-0 m-0'
                    >
                      <TabPane tabId={1}>
                        <AvForm autoComplete='off'>
                          <Row className='justify-content-center px-3 px-md-4 pt-3 pt-md-4 m-0'>
                            <UserTypeSelect
                              onUserTypeChanged={setUserType}
                              withEmployee={typeP === 'employee'}
                              isDeEmployee={isDeEmployee}
                              value={userType}
                              disable={typeP}
                            />
                          </Row>
                          {userType !== -1 && (
                            <>
                              <Row className='justify-content-center px-3 px-md-4 m-0'>
                                <Col className='p-0 m-0'>
                                  <FormGroup className='justify-content-center p-0'>
                                    <Label for='basicpill-email-input5'>
                                      Email
                                      <span className='text-danger font-size-16 ml-1'>
                                        *
                                      </span>
                                    </Label>
                                    <AvField
                                      type='email'
                                      name='email'
                                      disabled={!!emailP}
                                      value={email}
                                      className='form-control'
                                      id='basicpill-email-input5'
                                      autoComplete='email'
                                      onChange={(e) => {
                                        setEmail(e.target.value)
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className='p-3 p-md-4 pt-0'>
                                <span
                                  style={{
                                    position: 'absolute',
                                    right: '46%',
                                    padding: '15px',
                                  }}
                                  className='bg-white text-muted'
                                >
                                  Or
                                </span>
                                <div className='mt-4 border-top pt-4'>
                                  <GoogleLogin
                                    className='btn-block btn-outline-light rp-font-bold text-dark text-center google-btn border border-light rounded'
                                    clientId='434551861221-untotaur31aeerf1t5hoj7jra3s7a5ru.apps.googleusercontent.com'
                                    buttonText='Sign up with Google'
                                    onSuccess={handleGoogleLogin}
                                    onFailure={handleGoogleLoginError}
                                    cookiePolicy='single_host_origin'
                                    // loginHint={tokenP ? emailP : undefined}
                                    // prompt={tokenP ? 'none' : undefined}
                                    render={(renderProps) => (
                                      <button
                                        className='btn btn-block btn-outline-light rp-font-bold text-dark text-center google-btn border border-light rounded'
                                        onClick={() => {
                                          if (!customCheck) {
                                            toastr.error(
                                              'You need to agree to the Terms of Service and the Use Policy',
                                              'Error',
                                            )
                                            return
                                          }
                                          renderProps.onClick()
                                        }}
                                        disabled={renderProps.disabled}
                                      >
                                        <img
                                          className='mr-3'
                                          src={googleGLogo}
                                          style={{
                                            height: 16,
                                            display: 'inline',
                                          }}
                                          alt=''
                                        />
                                        Sign up with Google
                                      </button>
                                    )}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div className='px-3 px-md-4'>
                            <label className='custom-control custom-checkbox tw-mb-4 tw-cursor-pointer'>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                onChange={() =>
                                  setCustomCheck((checked) => !checked)
                                }
                                checked={customCheck}
                              />
                              <div className='font-size-14 text-secondary custom-control-label'>
                                I agree to the{' '}
                                <a
                                  href='https://RemotePass.com/terms'
                                  target='_blank'
                                  rel='noreferrer'
                                  className='font-weight-medium'
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  Terms of Service
                                </a>{' '}
                                and the{' '}
                                <a
                                  href='https://remotePass.com/policy'
                                  target='_blank'
                                  rel='noreferrer'
                                  className='rp-font-medium'
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  Use Policy
                                </a>
                              </div>
                            </label>
                          </div>
                        </AvForm>
                      </TabPane>
                      {!tokenP && (
                        <TabPane tabId={2}>
                          <Form
                            autoComplete='off'
                            className='align-items-center mb-0'
                          >
                            <Row className='justify-content-center'>
                              <Col className='align-items-center'>
                                <FormGroup className='p-0 m-0 p-3 p-md-4'>
                                  <Row className='justify-content-center p-0 m-0'>
                                    <div className='text-center font-weight-normal text-text-60'>
                                      <p className='p-0 mb-0'>
                                        A confirmation code has been sent to{' '}
                                        <u>{email}</u>
                                      </p>
                                      <p className='mb-0'>
                                        Please enter the code promptly, as it
                                        will expire soon.
                                      </p>
                                    </div>
                                  </Row>
                                  <Row className='justify-content-center pt-3 p-0 m-0'>
                                    <Col className='align-items-center text-center p-0 m-0'>
                                      <Label className='font-size-16 mb-3 mb-md-4'>
                                        Enter Code
                                      </Label>
                                      <Row className='justify-content-center p-0 m-0 mb-3 mb-md-4'>
                                        <ReactInputVerificationCode
                                          type='number'
                                          length={4}
                                          inputStyle={{
                                            padding: '12px',
                                            margin: '12px',
                                          }}
                                          placeholder='-'
                                          value={code}
                                          onChange={setCode}
                                        />
                                      </Row>
                                      <p className='text-text-60 font-weight-normal font-size-14 mb-0'>
                                        Not received? check spam folder or{' '}
                                        <button
                                          type='button'
                                          className='rp-btn-nostyle text-primary-100 p-0 font-weight-normal'
                                          onClick={() => {
                                            signup(
                                              { email },
                                              userType === 1
                                                ? userTypes.CONTRACTOR
                                                : userTypes.COMPANY,
                                            )
                                              .then((r) => {
                                                if (r.data.success) {
                                                  setCode(null)
                                                  toastr.success(
                                                    'Code re-sent successfully. Please check your inbox.',
                                                  )
                                                } else {
                                                  toastr.error(
                                                    r.data?.data?.error ||
                                                      r.data?.message,
                                                    'Error',
                                                  )
                                                }
                                              })
                                              .catch((e) => {
                                                toastr.error(e.toLocaleString())
                                              })
                                          }}
                                        >
                                          Resend it
                                        </button>
                                      </p>
                                      <p className='text-text-60 font-weight-normal font-size-14 mb-0'>
                                        Already have an account ?{' '}
                                        <Link
                                          to='/login'
                                          className='font-weight-normal text-primary-100'
                                        >
                                          Sign In
                                        </Link>
                                      </p>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                      )}
                      <TabPane tabId={3}>
                        <AvForm autoComplete='off'>
                          <Row className='p-0 m-0'>
                            <Col className='m-0 p-3 p-md-4'>
                              {tokenP || userType === 1 ? (
                                <Alert
                                  color='warning'
                                  className='d-flex gap-16 mb-4 px-3 py-4 surface-surface-30'
                                >
                                  <IdentificationCard
                                    color='currentColor'
                                    className='flex-shrink-0'
                                    size={20}
                                  />

                                  <div className='text-text-black'>
                                    Spell your name exactly as it&apos;s shown
                                    on your passport or ID card.
                                  </div>
                                </Alert>
                              ) : null}

                              {typeP !== 'employee' && (
                                <>
                                  <FormGroup>
                                    <Label
                                      for='basicpill-firstname-input1 '
                                      className='font-size-14'
                                    >
                                      First name
                                      <span className='text-danger font-size-16 ml-1'>
                                        *
                                      </span>
                                    </Label>
                                    <Input
                                      type='text'
                                      className='form-control'
                                      id='basicpill-firstname-input1'
                                      onChange={(e) => {
                                        setFirstName(e.target.value)
                                      }}
                                      value={firstName}
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <Label
                                      for='basicpill-middlename-input1 '
                                      className='font-size-14'
                                    >
                                      Middle name
                                    </Label>
                                    <Input
                                      type='text'
                                      className='form-control'
                                      id='basicpill-middlename-input1'
                                      onChange={(e) => {
                                        setMiddleName(e.target.value)
                                      }}
                                      value={middleName}
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <Label
                                      for='basicpill-lastname-input2'
                                      className='font-size-14'
                                    >
                                      Last name
                                      <span className='text-danger font-size-16 ml-1'>
                                        *
                                      </span>
                                    </Label>
                                    <Input
                                      type='text'
                                      className='form-control'
                                      id='basicpill-lastname-input2'
                                      onChange={(e) =>
                                        setLastName(e.target.value)
                                      }
                                      value={lastName}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <CustomSelect
                                      required
                                      label='Country'
                                      placeholder='Country'
                                      value={country}
                                      options={countries?.map((c) =>
                                        mapCountryToOption(c),
                                      )}
                                      onChange={(country) =>
                                        setCountry(country)
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <CustomPhoneInput
                                      inputClass='form-control'
                                      inputStyle={{
                                        width: '100%',
                                        paddingBottom: '10px',
                                      }}
                                      country={
                                        geoData?.country_code?.toLowerCase() ??
                                        'ae'
                                      }
                                      label='Phone number'
                                      enableAreaCodes
                                      onChange={setPhone}
                                      required
                                    />
                                  </FormGroup>
                                </>
                              )}

                              {!tokenP ||
                                (userType !== 1 && (
                                  <>
                                    {/* <AvField
                                    type='number'
                                    name='nb_employees'
                                    className='form-control'
                                    id='basicpill-pancard-input5'
                                    label='Number of employees'
                                    onChange={(e) => {
                                      setNbEmployees(e.target.value)
                                    }}
                                  /> */}
                                    <FormGroup className='justify-content-center p-0'>
                                      <Label for='basicpill-pan card-input5'>
                                        How did you hear about us?
                                      </Label>
                                      <CustomSelect
                                        options={hearOfUs}
                                        onChange={(e) =>
                                          setAcquisitionChannel(e?.value)
                                        }
                                        isSearchable={false}
                                      />
                                    </FormGroup>
                                  </>
                                ))}

                              {!googleToken && (
                                <>
                                  <div hidden>
                                    <input
                                      type='text'
                                      name='email'
                                      value={email}
                                      autoComplete='email'
                                      id='email'
                                    />
                                  </div>
                                  <FormGroup className='!tw-mb-0'>
                                    <Label
                                      className='tw-text-sm'
                                      for='register-password-input'
                                    >
                                      Password
                                      <span className='tw-ml-1 tw-text-base tw-text-systemRed-100'>
                                        *
                                      </span>
                                    </Label>
                                    <div
                                      className='tw-relative'
                                      id='register-password-input-parent'
                                    >
                                      <Input
                                        type={passwordFieldType}
                                        className='form-control'
                                        id='register-password-input'
                                        autoComplete='new-password'
                                        onChange={(e) =>
                                          setPassword(e.target.value)
                                        }
                                      />
                                      <PasswordInputTypeToggle
                                        className='tw-absolute tw-right-3 tw-top-[21px] tw-flex -tw-translate-y-1/2 tw-rounded-sm tw-bg-white tw-p-1 focus-visible:tw-ring focus-visible:tw-ring-surface-30'
                                        inputType={passwordFieldType}
                                        toggle={setPasswordFieldType}
                                      />
                                    </div>
                                  </FormGroup>
                                  <PasswordChecklistComp
                                    TooltipId='register-password-input-parent'
                                    password={password || ''}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </AvForm>
                      </TabPane>
                      <TabPane tabId={4}>
                        <CompanyInfo
                          isCreation
                          isOnboarding
                          onComplete={onCompanyInfoCompleted}
                          countries={countries}
                          setLoading={setSubmitLoading}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>

              <div
                className='d-flex justify-content-between p-3 p-md-4 border-top'
                style={{ gap: '1rem' }}
              >
                <div>
                  {activeTab === 2 && (
                    <button
                      className='btn btn-outline-light'
                      style={{ padding: '0.65rem 2.75rem', height: 48 }}
                      onClick={() => {
                        if (userType === 0 && activeTab === 3) {
                          toggleTab(activeTab - 2)
                        } else {
                          toggleTab(activeTab - 1)
                        }
                      }}
                    >
                      Back
                    </button>
                  )}
                </div>
                <div className='d-flex' style={{ gap: '1rem' }}>
                  {[5].includes(activeTab) && (
                    <Button
                      onClick={onSkip}
                      color='light'
                      outline
                      className='border-0 text-muted'
                      style={{ minWidth: 90 }}
                    >
                      Skip
                    </Button>
                  )}
                  <Button
                    onClick={onNext}
                    type='button'
                    disabled={loading || registerLoading || submitLoading}
                    loading={loading || registerLoading || submitLoading}
                    style={{ minWidth: 120 }}
                  >
                    {userType === 1
                      ? 'Continue'
                      : activeTab === 3
                        ? 'Register'
                        : 'Next'}
                  </Button>
                </div>
              </div>
            </Card>

            <div className='mt-5 mb-4 text-center'>
              {activeTab > 1 ? null : (
                <p className='font-size-14'>
                  Already have an account?{' '}
                  <Link to='/login' className='font-weight-medium text-primary'>
                    Login
                  </Link>
                </p>
              )}
              <p className='mb-0'>© {new Date().getFullYear()} RemotePass</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default withRouter(FormWizard)
