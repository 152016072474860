import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Badge,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch, useResize } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  addAdminEorPartnerInvoice,
  CreateNewPartner,
  getPartners,
} from '../../../../services/api'
import EorPartnerInvoiceModal from './eor-partner-invoice-modal'

export default function Partners() {
  const [selectedPartner, setSelectedPartner] = useState()
  const [page, setPage] = useState(1)
  const filters = { page, perPage: 40 }

  const {
    startFetch: fetchPartners,
    paginator,
    isLoading,
    data: partners,
  } = useFetch(
    {
      action: getPartners,
      withAdminAccess: true,
      autoFetch: true,
      body: filters,
    },
    [page],
  )

  const [issOpen, setIsOpen] = useState(false)

  const countries = useSelector(
    (state) => state?.Layout?.staticData?.countries ?? [],
  )

  function handleCloseMenu() {
    setSelectedPartner(undefined)
  }

  function handleItemClick(item) {
    const details = Object.entries(item).map(([key, value]) => {
      return { title: key, value }
    })
    setSelectedPartner(details)
  }

  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_PARTNERS)
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url
  return (
    <div className='page-content'>
      <DetailsMenu
        isOpen={!!selectedPartner}
        onClose={handleCloseMenu}
        details={selectedPartner}
      />

      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0'>Partners</h1>
        <Button onClick={() => setIsOpen(true)}>Create Partner</Button>
      </div>
      <Card>
        {isLoading ? (
          <Loader minHeight='70vh' />
        ) : (
          <Table responsive className='table-centered table-nowrap text-muted'>
            <thead>
              <tr>
                <th className='border-top-0'>Company Name</th>
                <th className='border-top-0'>Country</th>
                <th className='border-top-0'>Email</th>
                <th className='border-top-0'>Status</th>
                {!hasEditPermission ? null : (
                  <th className='border-top-0'>Invoices</th>
                )}
              </tr>
            </thead>
            <tbody>
              {partners?.data.map((partner) => (
                <PartnerLine
                  key={partner.id}
                  partner={partner}
                  onItemClick={handleItemClick}
                  hasEditPermission={hasEditPermission}
                />
              ))}
            </tbody>
          </Table>
        )}
        {!showPaginator ? null : (
          <div className='ml-auto mx-3 mt-2'>
            <Pagination
              activePage={page}
              itemsCountPerPage={paginator?.per_page ?? 50}
              totalItemsCount={paginator?.total ?? 0}
              onChange={(newPage) => setPage(newPage)}
            />
          </div>
        )}
      </Card>
      <CreatePartnerModal
        onConfirm={() => fetchPartners(filters)}
        countries={countries}
        isOpen={issOpen}
        toggle={() => setIsOpen(false)}
      />
    </div>
  )
}

function PartnerLine({ partner, onItemClick, hasEditPermission }) {
  return (
    <tr>
      <td>
        <button
          className='rp-btn-nostyle text-primary'
          onClick={() => onItemClick(partner)}
          type='button'
        >
          {partner?.company_name}
        </button>
      </td>
      <td>{partner?.country_name}</td>
      <td>{partner?.email}</td>
      <td>
        <Badge
          className={cn(
            `tw-rounded tw-bg-systemGold/10 !tw-text-xs tw-text-systemGold md:!tw-text-sm`,
            {
              'tw-bg-systemGreen/10 tw-text-systemGreen': partner?.status,
            },
          )}
          color='white'
          pill
        >
          {partner?.status}
        </Badge>
      </td>
      {!hasEditPermission ? null : (
        <td>
          <EorPartnerInvoices partner={partner} />
        </td>
      )}
    </tr>
  )
}

function EorPartnerInvoices({ partner }) {
  const [invoiceData, setInvoiceData] = useState(null)

  function toggle() {
    setInvoiceData(null)
  }

  const { startFetch: addAdminInvoice, isLoading: addingInvoice } = useFetch({
    action: addAdminEorPartnerInvoice,
    withAdminAccess: true,
    checkSuccess: true,
    onComplete: () => {
      toggle()
      toastr.success('Invoice added successfully')
    },
    onError: (error) => {
      toastr.error(error, 'error')
    },
  })

  return (
    <>
      <Button size='sm' onClick={() => setInvoiceData(partner)}>
        Add invoice
      </Button>
      <EorPartnerInvoiceModal
        partner={invoiceData}
        isOpen={!!invoiceData}
        toggle={toggle}
        onConfirm={addAdminInvoice}
        isConfirmLoading={addingInvoice}
      />
    </>
  )
}

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  country_id: yup.string().required(),
  email: yup.string().email().required(),
  entity_name: yup.string().required(),
  entity_currency_id: yup.string().required(),
  entity_type_id: yup.string().required(),
  entity_city: yup.string().required(),
  entity_country_id: yup.string().required(),
  entity_address: yup.string().required(),
  entity_vat: yup.string().required(),
  entity_registration_no: yup.string().required(),
  entity_zip_code: yup.string().required(),
})

const CreatePartnerModal = ({
  isOpen,
  toggle,
  loading,
  onConfirm,
  countries,
}) => {
  const staticData = useSelector((state) => state.Layout?.staticData)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })
  const createPartner = useFetch({
    action: CreateNewPartner,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Partner created Successfully')
      onConfirm()
      toggle()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const onSubmit = (v) => {
    createPartner.startFetch(v)
  }

  return (
    <Modal isOpen={isOpen} size='lg' toggle={toggle}>
      <Form
        autoComplete='off'
        className='form-horizontal m-0'
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalHeader toggle={toggle}>Create Partner</ModalHeader>
        <ModalBody>
          <h5 className='my-3 text-secondary'>Profile Info</h5>
          <Row>
            <Col md={6} className='mb-2'>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='first_name'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  First name
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='first_name'
                  id='first_name'
                  error={errors.first_name}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='last_name'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Last name
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='last_name'
                  id='last_name'
                  error={errors.last_name}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup className='d-inline p-0 m-0 mb-2'>
                <Label
                  htmlFor='country_id'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Country
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.country_id}
                  control={control}
                  name='country_id'
                  options={
                    countries
                      ? countries.map((c) => ({
                          ...c,
                          label: c.name,
                          value: c.id,
                          flag: `/${c.svg}`,
                        }))
                      : []
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='email'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Email address
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='email'
                  id='email'
                  type='email'
                  error={errors.email}
                />
              </FormGroup>
            </Col>
          </Row>
          <h5 className='my-3 text-secondary'>Company info</h5>
          <Row>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                  htmlFor='entity_name'
                >
                  Entity name
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='entity_name'
                  id='entity_name'
                  error={errors.entity_name}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_currency_id'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Entity currency
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.entity_currency_id}
                  control={control}
                  name='entity_currency_id'
                  id='entity_currency_id'
                  options={
                    staticData?.currencies
                      ? staticData.currencies.map((c) => ({
                          label: c.code,
                          value: c.id,
                        }))
                      : []
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_type_id'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Entity type
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>

                <ControlledSelect
                  name='entity_type_id'
                  id='entity_type_id'
                  error={errors.entity_type_id}
                  control={control}
                  options={staticData?.company_types?.map((e) => ({
                    value: e.id,
                    label: e.name,
                  }))}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_city'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Entity city
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='entity_city'
                  id='entity_city'
                  error={errors.entity_city}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_country_id'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Entity country
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.entity_country_id}
                  control={control}
                  name='entity_country_id'
                  id='entity_country_id'
                  options={
                    countries
                      ? countries.map((c) => ({
                          ...c,
                          label: c.name,
                          value: c.id,
                          flag: `/${c.svg}`,
                        }))
                      : []
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_address'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Entity address
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='entity_address'
                  id='entity_address'
                  error={errors.entity_address}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='cutoff_day_of_month'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Cutoff day of month
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='cutoff_day_of_month'
                  id='cutoff_day_of_month'
                  type='number'
                  error={errors.cutoff_day_of_month}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_vat'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Entity vat
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='entity_vat'
                  id='entity_vat'
                  error={errors.entity_vat}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_registration_no'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Registration number
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='entity_registration_no'
                  id='entity_registration_no'
                  error={errors.entity_registration_no}
                />
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 mb-2'
              >
                <Label
                  htmlFor='entity_zip_code'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Zip code
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='entity_zip_code'
                  id='entity_zip_code'
                  error={errors.entity_zip_code}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type='button'
            color='light'
            outline
            onClick={toggle}
            text='Cancel'
            className='font-size-14'
            style={{ width: 146 }}
          />

          <Button
            style={{ width: 146 }}
            text='Confirm'
            className='font-size-14'
            type='submit'
            loading={loading}
          />
        </ModalFooter>
      </Form>
    </Modal>
  )
}

function DetailsMenu({ isOpen, onClose, details = [] }) {
  const isMobile = useResize()

  return (
    <div
      className='position-absolute'
      style={{ top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <Menu
        isOpen={isOpen}
        onClose={onClose}
        className='bg-white'
        width={isMobile ? '100%' : '45%'}
        itemListElement='div'
        customCrossIcon={false}
        customBurgerIcon={false}
        disableAutoFocus
        right
      >
        <div className='p-4'>
          <DisplayItems
            items={details.filter((p) => !Array.isArray(p.value))}
          />

          {details
            .filter((p) => Array.isArray(p.value))
            .map((item, index) => {
              const head = item.value.reduce((acc, curr) => {
                Object.keys(curr).forEach((key) => {
                  if (!acc.includes(key)) {
                    acc.push(key)
                  }
                })

                return acc
              }, [])

              return (
                <div key={index}>
                  <h4 className='text-capitalize'>{item.title}</h4>

                  {item.value.length <= 0 ? (
                    <p className='mb-4 mt-2 font-size-16 text-muted'>
                      This partner has no contracts.
                    </p>
                  ) : (
                    <DisplayContracts tableData={{ head, body: item.value }} />
                  )}
                </div>
              )
            })}
        </div>
      </Menu>
    </div>
  )
}

function removeUnderscores(string) {
  return string.replace(/_/g, ' ')
}

function DisplayContracts({ tableData = {} }) {
  return (
    <Table bordered>
      <thead>
        <tr>
          {tableData?.head?.map((item, index) => {
            return (
              <th
                key={index}
                className='!tw-px-[0.325rem] !tw-py-2 tw-capitalize'
              >
                {removeUnderscores(item)}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {tableData?.body?.map((bodyItem, index) => {
          return (
            <tr key={index}>
              {tableData?.head?.map((item, index) => {
                if (index === 0) {
                  return (
                    <td key={index} className='!tw-px-[0.325rem] !tw-py-2'>
                      <Link
                        to={`/admin/contract-detail?id=${bodyItem[item]}`}
                        target='_blank'
                      >
                        {bodyItem[item] ?? 'N/A'}
                      </Link>
                    </td>
                  )
                }
                const value =
                  typeof bodyItem[item] === 'object' ? (
                    <pre>{JSON.stringify(bodyItem[item], undefined, 2)}</pre>
                  ) : (
                    bodyItem[item]
                  )

                return (
                  <td key={index} className='!tw-px-[0.325rem] !tw-py-2'>
                    {value ?? 'N/A'}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

function DisplayItems({ items = [] }) {
  return items.map((item, index) => <DisplayItem key={index} item={item} />)
}

function DisplayItem({ item }) {
  return (
    <div>
      <h5 className='text-capitalize'>{removeUnderscores(item.title)}</h5>
      <p
        className='p-2 font-size-14'
        style={{
          border: '1px solid #E7E8F2',
          borderRadius: 4,
          color: '#777F9E',
        }}
      >
        {item.value ?? 'N/A'}
      </p>
    </div>
  )
}
