import {
  ArrowSquareOut,
  CheckCircle,
  FastForward,
  ListPlus,
  PlusCircle,
  ShieldPlus,
  Sparkle,
  Trash,
} from '@phosphor-icons/react'
import { Circle } from '@phosphor-icons/react/dist/ssr'
import cx from 'classnames'
import React, { useState } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FormGroup, Label } from 'reactstrap'
import ControlledInput from '../../../../components/ControlledInput'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { getOptionFromList } from '../../../../utils/map-to-option'
import { USD_CODE } from '../../CreateContract/utils/usd-id'
import { useFormData } from '../../CreateContract/utils/use-form-data-context'
import { ControlledToggle } from '../../../../components/Forms/Toggle/controlled-toggle'

function BenefitsForm({ control, watch }) {
  const { formData } = useFormData()
  const regionConfig = formData?.regionConfig
  const { currencies } = useSelector((state) => state.Layout.staticData ?? {})
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allowances',
  })
  const { currency_id: currencyId } = useWatch({
    control,
  })
  const currencyCode =
    getOptionFromList(currencies, currencyId, 'id')?.code ?? USD_CODE
  const [showSigningBonus, setShowSigningBonus] = useState(false)
  const {
    formState: { errors },
  } = useFormContext()
  return (
    <div>
      <h5 className='tw-mb-0 tw-text-xl tw-font-semibold'>Allowances</h5>
      <p className='tw-text-sm tw-text-secondary-70'>
        Add monthly allowances to be specified in the employment contract.
      </p>

      {fields.map((item, index) => {
        return (
          <div
            className='tw-flex tw-w-full tw-flex-col tw-items-stretch sm:tw-flex-row sm:tw-items-start sm:tw-gap-6'
            key={item.id}
          >
            <FormGroup className='tw-grow'>
              <Label>
                Name <span className='tw-text-red'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name={`allowances.${index}.name`}
                error={errors.allowances?.[index]?.name}
                placeholder='Name'
                maxLength={20}
              />
            </FormGroup>
            <FormGroup className='tw-flex tw-grow'>
              <div className='tw-flex tw-grow tw-flex-col'>
                <Label>
                  Gross amount <span className='tw-text-red'>*</span>
                </Label>
                <div className='tw-flex tw-grow'>
                  <ControlledInput
                    control={control}
                    type='number'
                    showError={false}
                    className={cx('!tw-rounded-br-none !tw-rounded-tr-none', {
                      '!tw-border-red !tw-border-r-surface-30':
                        errors.allowances?.[index]?.amount?.message,
                    })}
                    wrapperClassName='tw-grow'
                    name={`allowances.${index}.amount`}
                    placeholder='Gross amount'
                  />
                  <span
                    className={cx(
                      'tw-flex tw-items-center tw-justify-center tw-rounded-br tw-rounded-tr tw-border tw-border-l-0 tw-border-surface-30 tw-px-4 tw-text-sm tw-text-secondary-70',
                      {
                        '!tw-border-red !tw-border-l-surface-30':
                          errors.allowances?.[index]?.amount?.message,
                      },
                    )}
                  >
                    {currencyCode}
                  </span>
                  <button
                    className='tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-border tw-border-surface-30 tw-p-4 sm:tw-ml-6 sm:tw-h-10 sm:tw-w-10 sm:tw-rounded-full sm:tw-p-0'
                    onClick={() => {
                      remove(index)
                    }}
                  >
                    <Trash size={20} color='red' />
                  </button>
                </div>
                {errors.allowances?.[0]?.amount && (
                  <span className='tw-mt-3 tw-text-xs tw-text-red'>
                    {errors.allowances?.[0]?.amount?.message}
                  </span>
                )}
              </div>
            </FormGroup>
          </div>
        )
      })}
      <button
        className='tw-mt-6 tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary tw-bg-primary-20 tw-p-4 tw-text-sm tw-font-semibold tw-text-primary'
        onClick={(e) => {
          e.preventDefault()
          append({ name: null, amount: null })
        }}
      >
        <PlusCircle size={24} />
        Add Monthly Allowance
      </button>
      <hr className='tw-my-6' />
      <h5 className='tw-mb-0 tw-text-xl tw-font-semibold'>Terms</h5>
      <div className='tw-flex tw-flex-col tw-gap-6 sm:tw-flex-row'>
        <div className='tw-flex tw-grow tw-flex-col'>
          <Label>
            Probation period <span className='tw-text-red'>*</span>
          </Label>
          <div
            className={cx('tw-flex', {
              'tw-border tw-border-red': errors?.trial_period,
            })}
          >
            <ControlledInput
              control={control}
              type='number'
              showError={false}
              className='!tw-rounded-bl-none !tw-rounded-br-none !tw-rounded-tr-none'
              wrapperClassName='tw-grow'
              name='trial_period'
              placeholder='Probation period'
            />
            <span className='tw-flex tw-items-center tw-justify-center tw-rounded-tr tw-border tw-border-l-0 tw-border-surface-30 tw-px-4 tw-text-sm tw-text-secondary-70'>
              Calendar Days
            </span>
          </div>
          {regionConfig && regionConfig?.is_enabled ? (
            <div className='tw-rounded-bl tw-rounded-br tw-border-b tw-border-l tw-border-r tw-border-surface-30 tw-bg-surface-10 tw-p-4 tw-text-secondary'>
              Probation Max{' '}
              <span className='tw-text-primary'>
                {regionConfig?.max_probation_months * 30} days
              </span>
            </div>
          ) : null}
          <div className='tw-mt-1 tw-text-red'>
            {errors?.trial_period?.message}
          </div>
        </div>
        <div className='tw-flex tw-grow tw-flex-col'>
          <Label>
            Vacation days <span className='tw-text-red'>*</span>
          </Label>
          <div
            className={cx('tw-flex', {
              'tw-border tw-border-red': errors?.annual_leave_days,
            })}
          >
            <ControlledInput
              control={control}
              type='number'
              showError={false}
              className='!tw-rounded-bl-none !tw-rounded-br-none !tw-rounded-tr-none'
              wrapperClassName='tw-grow'
              name='annual_leave_days'
              placeholder='Vacation days'
            />
            <span className='tw-flex tw-items-center tw-justify-center tw-rounded-tr tw-border tw-border-l-0 tw-border-surface-30 tw-px-4 tw-text-sm tw-text-secondary-70'>
              Working Days
            </span>
          </div>
          {regionConfig && regionConfig?.is_enabled ? (
            <div className='tw-rounded-bl tw-rounded-br tw-border-b tw-border-l tw-border-r tw-border-surface-30 tw-bg-surface-10 tw-p-4 tw-text-secondary'>
              Minimum requirement{' '}
              <span className='tw-text-primary'>
                {' '}
                {regionConfig?.min_annual_leave_days} working days
              </span>
            </div>
          ) : null}
          <div className='tw-mt-1 tw-text-red'>
            {errors?.annual_leave_days?.message}
          </div>
        </div>
      </div>
      <hr className='tw-my-6' />
      <h5 className='tw-mb-4 tw-text-xl tw-font-semibold'>Benefits</h5>
      <div className='tw-mb-4'>
        <div
          className={cx(
            'tw-flex tw-items-center tw-justify-between tw-rounded-tl tw-rounded-tr tw-border tw-border-surface-30 tw-p-6',
            {
              'tw-rounded-bl tw-rounded-br': !showSigningBonus,
            },
          )}
        >
          <div className='tw-flex tw-gap-4'>
            <div>
              <ShieldPlus color='var(--text-30)' size={32} />
            </div>
            <div>
              <div className='tw-text-base tw-font-semibold'>
                Add Health Insurance
              </div>
              <div className='tw-text-sm tw-text-text-30'>
                Do you want to offer health insurance for this employee?
              </div>
            </div>
          </div>
          <div>
            <ControlledToggle
              control={control}
              name='insurance'
              disabled={
                regionConfig?.is_healthcare_required && regionConfig?.is_enabled
              }
              defaultValue={
                regionConfig?.is_healthcare_required && regionConfig?.is_enabled
              }
            />
          </div>
        </div>
        {watch('insurance') &&
        regionConfig?.supported_insurance_providers?.length > 0 &&
        regionConfig?.is_enabled ? (
          <div className='tw-grid tw-grid-cols-2 tw-gap-4 tw-rounded-bl tw-rounded-br tw-border-b tw-border-l tw-border-r tw-border-surface-30 tw-bg-surface-10 tw-p-4 sm:tw-grid-cols-3'>
            {[
              ...regionConfig.supported_insurance_providers,
              { id: 'later' },
            ]?.map((item) => {
              const isSelected = watch('insurance_provider_id') === item.id
              return (
                <ControlledInsuranceItem
                  key={item?.id}
                  isSelected={isSelected}
                  error={errors?.insurance_provider_id}
                  control={control}
                  name='insurance_provider_id'
                  item={item}
                />
              )
            })}
          </div>
        ) : null}
      </div>
      <div className='tw-mb-4'>
        <div
          className={cx(
            'tw-flex tw-items-center tw-justify-between tw-rounded-tl tw-rounded-tr tw-border tw-border-surface-30 tw-p-6',
            {
              'tw-rounded-bl tw-rounded-br': !showSigningBonus,
            },
          )}
        >
          <div className='tw-flex tw-gap-4'>
            <div>
              <Sparkle color='var(--text-30)' size={32} />
            </div>
            <div>
              <div className='tw-text-base tw-font-semibold'>
                Add a signing bonus
              </div>
              <div className='tw-text-sm tw-text-text-30'>
                This amount will be paid on the first payroll
              </div>
            </div>
          </div>
          <div>
            <Toggle
              check={showSigningBonus}
              change={(val) => setShowSigningBonus(val.currentTarget.checked)}
            />
          </div>
        </div>
        {showSigningBonus && (
          <div className='tw-rounded-bl tw-rounded-br tw-border-b tw-border-l tw-border-r tw-border-surface-30 tw-bg-surface-10 tw-p-6'>
            <Label>
              Gross Amount <span className='tw-text-red'>*</span>
            </Label>
            <div className='tw-flex'>
              <ControlledInput
                control={control}
                type='number'
                className='!tw-rounded-bl-none !tw-rounded-br-none !tw-rounded-tr-none'
                wrapperClassName='tw-grow'
                name='signing_bonus_amount'
                placeholder='Signing bonus amount'
              />
              <span className='tw-flex tw-items-center tw-justify-center tw-rounded-tr tw-border tw-border-l-0 tw-border-surface-30 tw-px-4 tw-text-sm tw-text-secondary-70'>
                {currencyCode}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='tw-mb-4'>
        <div
          className={cx(
            'tw-flex tw-items-center tw-justify-between tw-rounded-tl tw-rounded-tr tw-border tw-border-surface-30 tw-p-6',
            {
              'tw-rounded-bl tw-rounded-br': !watch('variable_comp'),
            },
          )}
        >
          <div className='tw-flex tw-gap-4'>
            <div>
              <ListPlus color='var(--text-30)' size={32} />
            </div>
            <div>
              <div className='tw-text-base tw-font-semibold'>
                Add Variable Compensation
              </div>
              <div className='tw-text-sm tw-text-text-30'>
                Additional compensation will be included within the employment
                contract
              </div>
            </div>
          </div>
          <div>
            <ControlledToggle control={control} name='variable_comp' />
          </div>
        </div>
        {watch('variable_comp') && (
          <div className='tw-rounded-bl tw-rounded-br tw-border-b tw-border-l tw-border-r tw-border-surface-30 tw-bg-surface-10 tw-p-6'>
            <Label>
              Description <span className='tw-text-red'>*</span>
            </Label>
            <ControlledInput
              type='textarea'
              control={control}
              name='bonus_details'
              error={errors?.bonus_details?.message}
              id='bonus_details'
              labelFor='bonus_details'
              rows={8}
              placeholder='Describe the nature of variable compensation, amount or percentage, frequency and conditions'
            />
          </div>
        )}
      </div>
    </div>
  )
}

function ControlledInsuranceItem({
  control,
  defaultValue,
  name,
  error,
  rules,
  onClick,
  item,
  isSelected,
}) {
  return (
    <Controller
      control={control}
      error={error}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange } }) => {
        return (
          <div className='tw-flex tw-h-full tw-w-full tw-flex-col'>
            <label onClick={onClick} className='tw-grow tw-cursor-pointer'>
              <input
                type='radio'
                name={name}
                value={item?.id}
                className='tw-invisible tw-absolute'
                onChange={() => onChange(item?.id)}
              />
              <div
                className={cx(
                  'tw-h-full tw-rounded tw-p-4 tw-outline',
                  isSelected
                    ? 'tw-bg-primary-10 tw-outline-2 tw-outline-primary'
                    : 'tw-bg-white tw-outline-1 tw-outline-surface-30',
                )}
              >
                <div className='tw-flex tw-h-full tw-items-start tw-justify-between'>
                  <div className='tw-flex tw-h-full tw-flex-col tw-justify-between'>
                    {item.id === 'later' ? (
                      <div className='tw-flex tw-h-full tw-flex-col tw-justify-center'>
                        <FastForward
                          size={24}
                          className='tw-mb-2'
                          color='var(---secondary)'
                        />
                        <div className='font-size-14 py-2 tw-font-bold'>
                          Choose Later
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>
                          <img
                            className='tw-max-h-6 tw-w-auto'
                            src={item.logo}
                            alt={item.name}
                          />
                        </div>
                        <div className='font-size-14 py-2 tw-font-bold'>
                          {item.title}
                        </div>
                        <div>
                          <p className='font-size-12 mb-0 tw-font-semibold'>
                            {item.name}
                          </p>
                          <p className='font-size-12 text-text-60 mb-0'>
                            {item.starting_price}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  {isSelected ? (
                    <CheckCircle
                      size={20}
                      weight='fill'
                      color='var(--primary)'
                    />
                  ) : (
                    <Circle size={20} color='var(--secondary-50)' />
                  )}
                </div>
              </div>
            </label>
            <a
              href={item?.brochure}
              rel='noreferrer'
              target='_blank'
              className={cx(
                'tw-mt-2 tw-flex tw-h-6 tw-items-center tw-gap-2 tw-text-primary',
                { 'tw-invisible': item.id === 'later' },
              )}
            >
              Learn More
              <ArrowSquareOut size={16} color='var(--primary)' />
            </a>
          </div>
        )
      }}
    />
  )
}

export default BenefitsForm
