import { yupResolver } from '@hookform/resolvers/yup'
import { Check, X } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import { useFetch } from '../../../helpers/hooks'
import {
  changePassword,
  getClientInfo,
  getContractorInfo,
} from '../../../services/api'
import { updateUserInfo } from '../../../store/auth/register/actions'
import { updateUserProfileInfo } from '../../../store/profile/actions'
import { getIntercomData } from '../../../utils/analytics'
import { setCookie } from '../../../utils/cookies'
import { TFA_REMINDER } from '../../../utils/cookies/cookie-names'
import { ControlledPasswordInput } from '../../controlled-password-input'
import Toggle from '../../Forms/Toggle/Toggle'
import Button from '../../ui/button'
import TwoFactorAuthentication from '../TwoFactorAuthentication'

export default function ChangePassword() {
  const user = useSelector((state) => state?.Account?.user)
  const profile = useSelector((state) => state?.userProfile?.userProfile)

  const [isEnabled, setIsEnabled] = useState(false)
  const [show2FA, setShow2FA] = useState(false)

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        currentPassword: yup.string().required('Password is required'),
        newPassword: yup
          .string()
          .test(
            'password-strength',
            'Weak password easy to guess, password should respect checklist condition',
            (value) => validatePassword(value).isValid,
          )
          .required('New Password is required'),
        confirm_password: yup
          .string()
          .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
          .required('Confirm Password is required'),
      }),
    ),
  })

  const { newPassword } = useWatch({ control })

  function onSubmit(values) {
    change.startFetch(values)
  }

  const { startFetch: updateProfileInfo } = useFetch({
    action: user?.type === 'client' ? getClientInfo : getContractorInfo,
    autoFetch: false,
    onComplete: (data) => {
      dispatch(
        updateUserProfileInfo({
          is_2fa_enabled: data?.is_2fa_enabled,
          type_2fa: data?.type_2fa,
        }),
      )
    },
  })

  const dispatch = useDispatch()
  const change = useFetch({
    action: changePassword,
    onComplete: (data) => {
      toastr.success('Password changed successfully')
      reset()
      const token = data?.token
      dispatch(updateUserInfo({ token }))
      window.analytics.track('Changed password', { email_id: user?.email })
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    window.analytics.track('Viewed password', {
      email_id: user?.email,
    })
  }, [])

  useEffect(() => {
    if (profile) {
      setIsEnabled(profile?.is_2fa_enabled)
    }
  }, [profile])

  function closeModalAndUpdateUser(data) {
    setShow2FA(false)

    updateProfileInfo()

    const intercomData = getIntercomData(data)
    window.analytics.identify(data?.id, intercomData)
  }

  return (
    <div className='tw-p-6'>
      <h3 className='tw-mb-3 tw-text-xl tw-text-black md:tw-mb-6 md:tw-text-3xl'>
        Change Password
      </h3>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='tw-grid tw-gap-x-6 tw-gap-y-4 md:tw-grid-cols-2 xl:tw-gap-x-10'
      >
        <ControlledPasswordInput
          control={control}
          name='currentPassword'
          autoComplete='current-password'
          label='Current Password'
          placeholder='Current Password'
          type='password'
        />

        <div></div>

        <ControlledPasswordInput
          control={control}
          id='passWord'
          name='newPassword'
          autoComplete='new-password'
          label='New Password'
          type='password'
          placeholder='New Password'
        />
        <PasswordChecklistComp password={newPassword} TooltipId='passWord' />

        <ControlledPasswordInput
          control={control}
          id='confirm_password'
          name='confirm_password'
          label='Confirm Password'
          type='password'
          placeholder='Confirm Password'
        />

        <Button
          loading={change.isLoading}
          disabled={change.isLoading}
          type='submit'
          className='tw-justify-self-start'
        >
          Change Password
        </Button>
      </form>

      <hr className='-tw-mx-6 tw-my-8' />

      <h3 className='tw-mb-3 tw-text-xl tw-text-black md:tw-mb-6 md:tw-text-3xl'>
        Two-Factor Authentication
      </h3>

      <label className='tw-mb-4 tw-inline-flex tw-cursor-pointer tw-items-center'>
        <Toggle check={isEnabled} change={() => setShow2FA(true)} />

        <span className='tw-text-sm'>{isEnabled ? 'Enabled' : 'Disabled'}</span>
      </label>

      <div className='tw-text-xs tw-text-text-60'>
        Require an authentication code when you log in with an email and
        password
      </div>

      {show2FA && (
        <TwoFactorAuthentication
          toggle={() => setShow2FA(false)}
          isOpen={show2FA}
          is2FAEnabled={isEnabled}
          defaultTfaType={profile?.type_2fa}
          onDisabled={(data) => {
            setIsEnabled(false)
            closeModalAndUpdateUser(data)
            setCookie(TFA_REMINDER, user?.token)
          }}
          onEnabled={(data) => {
            setIsEnabled(true)
            closeModalAndUpdateUser(data)
          }}
        />
      )}
    </div>
  )
}

export function validatePassword(password) {
  password = password || ''

  const requirementList = [
    ['An uppercase letter (A-Z)', password.toLowerCase() !== password],
    ['A lowercase letter (a-z)', password.toUpperCase() !== password],
    ['A number (0-9)', /\d/.test(password)],
    ['At least 8 characters', password.length >= 8],
  ]

  const isValid = requirementList.every(([, isValid]) => isValid)

  return { isValid, requirementList }
}

export function PasswordChecklistComp({ password, TooltipId }) {
  const { requirementList } = validatePassword(password)

  return (
    <UncontrolledTooltip
      className={cn(
        '[&.show>*]:!tw-opacity-100',
        '[&.show>*[x-placement^=bottom]_.arrow:before]:!tw-border-b-surface-40',
        '[&.show>*[x-placement^=top]_.arrow:before]:!tw-border-t-surface-40',
      )}
      innerClassName='!tw-bg-white !tw-border tw-border-surface-30 !tw-shadow-md !tw-max-w-fit'
      placement='bottom'
      target={TooltipId}
    >
      {requirementList.map(([requirement, isValid], i) => {
        const Icon = isValid ? Check : X
        return (
          <div
            key={`rule-${i}`}
            className='tw-flex tw-max-w-60 tw-items-center'
          >
            <Icon
              weight='bold'
              className={cn(
                'tw-mr-1 tw-size-4',
                isValid ? 'tw-text-systemGreen-100' : 'tw-text-systemRed-100',
              )}
            />
            <h6
              className={cn(
                'tw-mb-0 tw-text-sm',
                isValid ? 'tw-font-bold' : 'tw-text-secondary-100',
              )}
            >
              {requirement}
            </h6>
          </div>
        )
      })}
    </UncontrolledTooltip>
  )
}
